import { Button, Stack } from '@mui/material';
import { DateObject } from 'react-multi-date-picker';

const numberOfDays = [7, 14, 28];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function WeekSelectorPlugin({ onSelect }: { onSelect?: (startDate: DateObject, endDate: DateObject) => void; position?: string }) {
  return (
    <Stack height="inherit" justifyContent="center" paddingX={2} data-test="week-selector-plugin">
      {numberOfDays.map((day) => (
        <Button
          key={`day-${day}`}
          data-test={`day-${day}`}
          variant="text"
          onClick={() => {
            const currentDate = new DateObject();
            const endDate = new DateObject();
            endDate.setDay(endDate.day + (day - 1));
            if (onSelect) {
              onSelect(currentDate, endDate);
            }
          }}
        >
          {day} days
        </Button>
      ))}
    </Stack>
  );
}
