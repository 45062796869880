import { useContext } from 'react';
import { ActivitiesAssignmentContext } from '../../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import { useStudentStore } from '../../../../../../stores/StudentStore';
import MultiSelectorWithSearch from '../../../../../../ui/selector/MultiSelectorWithSearch';
import ArrayUtils from '../../../../../../utils/ArrayUtils';

enum ItemType {
  GROUP = 'Group',
  STUDENT = 'Student',
}

export default function StudentAndGroupMultiSelectorInput() {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);
  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);

  const studentGroupData = activitiesAssignment?.studentGroupData;
  const currentStudent = activitiesAssignment?.currentStudent;

  const students = useStore(useStudentStore, (state) => state.students);
  const selectedGroupIds = useStore(activitiesAssignmentContext!, (state) => state.selectedGroupIds);
  const selectedStudentIds = useStore(activitiesAssignmentContext!, (state) => state.selectedStudentIds);

  const setSelectedGroupIds = useStore(activitiesAssignmentContext!, (state) => state.setSelectedGroupIds);
  const setSelectedStudentIds = useStore(activitiesAssignmentContext!, (state) => state.setSelectedStudentIds);
  const setFilterStudentsAndGroupText = useStore(
    activitiesAssignmentContext!,
    (state) => state.setFilterStudentsAndGroupText
  );

  const activitiesAssignmentValidationResult = useStore(
    activitiesAssignmentContext!,
    (state) => state.activitiesAssignmentValidationResult
  );
  const validateActivitiesAssignment = useStore(
    activitiesAssignmentContext!,
    (state) => state.validateActivitiesAssignment
  );

  const getErrorText = () => {
    if (!activitiesAssignmentValidationResult?.success) {
      const selectedDatesError =
        activitiesAssignmentValidationResult?.error.formErrors.fieldErrors.selectedStudentsAndGroupIds;
      return selectedDatesError?.join(',');
    }
    return '';
  };

  const groupSelectorItems =
    studentGroupData?.map((group) => ({
      id: String(group.id),
      name: group.name,
      value: String(group.id),
      group: ItemType.GROUP,
    })) || [];

  const studentSelectorItems =
    students?.map((student) => ({
      id: String(student.id),
      name: `${student.firstName} ${student.lastName}`,
      value: String(student.id),
      group: ItemType.STUDENT,
    })) || [];

  return (
    <MultiSelectorWithSearch
      data-test="student-and-group-multi-selector-input"
      selectorItems={[...groupSelectorItems, ...studentSelectorItems]}
      values={[...selectedGroupIds, ...selectedStudentIds]}
      onChange={(_, value) => {
        let groups: string[] = [];
        let students: string[] = currentStudent?.id ? [String(currentStudent.id)] : [];
        value.forEach((v) => {
          if (v.group === ItemType.GROUP) {
            groups.push(v.id);
          } else if (v.group === ItemType.STUDENT) {
            students.push(v.id);
          }
        });

        const groupDiff = ArrayUtils.getDifference(selectedGroupIds, groups);
        const studentDiff = ArrayUtils.getDifference(selectedStudentIds, students);
        if (groupDiff && groupDiff.length > 0) {
          const remainGroups = groups.map((r) => studentGroupData?.find((s) => s.id === Number(r)));
          students = remainGroups.flatMap((g) => g?.students || []).map((s) => String(s));
        } else {
          const studentId = studentDiff[0];
          if (studentId) {
            const effectedGroupIds = studentGroupData?.filter((sg) => sg.students.includes(Number(studentId))).map((g) => g.id);
            groups = groups.filter((group) => !effectedGroupIds?.includes(Number(group)));
            // const groupIds = groups?.map((group) => group.id);
          }
        }

        setSelectedGroupIds(groups);
        setSelectedStudentIds(students);
        // Reset error message
        if (!activitiesAssignmentValidationResult?.success && activitiesAssignmentValidationResult) {
          validateActivitiesAssignment();
        }
      }}
      limitTags={8}
      // placeholderWhenEmpty={placeholderWhenEmpty}
      showSelectedValueAtTheTop
      isError={Boolean(getErrorText())}
      helperText={getErrorText()}
      textFieldMode
      onTextChange={(value) => {
        setFilterStudentsAndGroupText(value);
      }}
    />
  );
}
