import { useStore } from 'zustand';
import SaveIcon from '@mui/icons-material/Save';
import { useMutation } from '@tanstack/react-query';
import appUiStore from '../../stores/AppUiStore';
import { Lesson } from '../../types/lesson/LessonType';
import { useUserStore } from '../../stores/UserStore';
import LessonService from '../../services/LessonService';
import { SNACKBAR_SEVERITY } from '../../types/ui/VizzleSnackbar';
import { ItemRowMenuItemType } from '../../types/ui/ItemRowMenuItem';

export const useSaveToMyResourceAction = () => {
  const user = useStore(useUserStore, (state) => state.user);
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);
  const saveToMyResourceMutation = useMutation({
    mutationKey: ['save-to-my-resource-instruction'],
    mutationFn: async (lessons: Lesson[]) => {
      const lessonIds = lessons.map((lesson) => lesson.id);
      const userId = user!.id;
      await LessonService.assignLesson(lessonIds, userId);
      openSnackbar({
        severity: SNACKBAR_SEVERITY.SUCCESS,
        open: true,
        message: `The ${lessonIds.length > 1 ? 'activities have' : 'activity has'} been saved to My Resources`,
        duration: 3000,
      });
    },
  });

  const getSaveToMyResourceAction = (lessons: Lesson[]): ItemRowMenuItemType => {
    return {
      id: 'saved-to-my-resource-action',
      label: 'Save to My Resources',
      icon: <SaveIcon />,
      onClick: async (closeDropdownFunction) => {
        closeDropdownFunction();
        await saveToMyResourceMutation.mutate(lessons);
      },
    };
  };

  return {
    getSaveToMyResourceAction,
  };
};
