import { Button } from '@mui/material';
import { useContext } from 'react';
import { useStore } from 'zustand';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';
// import { useUpdateLessonSearchCriteriaByUser } from '../../../hooks/LessonSearchCriteriaByUserHooks';

export default function ClearAllCriteriaAction() {
  const searchLessonsContext = useContext(SearchLessonsContext);

  const grades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.grades);
  const courseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.courseIds);
  const verticalAlignmentIds = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.verticalAlignmentIds,
  );
  const standardCodes = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.standardCodes);

  const setGrades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setGrades);
  const setVerticalAlignmentIds = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setVerticalAlignmentIds,
  );
  const setStandardCodes = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setStandardCodes,
  );
  const setCourseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setCourseIds);

  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  if (
    (!grades || grades.length === 0) &&
    (!courseIds || courseIds.length === 0) &&
    (!verticalAlignmentIds || verticalAlignmentIds.length === 0) &&
    (!standardCodes || verticalAlignmentIds.length === 0)
  ) {
    return null;
  }
  return (
    <Button
      sx={{ marginLeft: 1 }}
      onClick={() => {
        setGrades([]);
        setStandardCodes([]);
        setVerticalAlignmentIds([]);
        setCourseIds([]);
        resetSearchData();
      }}
    >
      Clear All
    </Button>
  );
}
