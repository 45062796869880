import { useContext, useEffect } from 'react';
import { useStore } from 'zustand';
import { SearchLessonsContext } from '../SearchLessonsPageHooks';
import { LessonSearchCriteriaByUser } from '../../../types/user/LessonSearchCriteriaByUserType';
import { useMutation } from '@tanstack/react-query';
import SearchService from '../../../services/SearchService';
import Logger from '../../../utils/Logger';

export const useUpdateLessonSearchCriteriaByUser = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const lessonSearchCriteriaByUser: LessonSearchCriteriaByUser = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => ({
      subject: state.selectedSubjectId,
      grades: state.grades,
      libraries: state.contentLibraries,
    })
  );

  const updateLessonSearchCriteriaByUserMutation = useMutation({
    mutationKey: ['update-search-criteria-for-user'],
    mutationFn: async (data: LessonSearchCriteriaByUser) => {
      const updatedData = { ...lessonSearchCriteriaByUser, ...data };
      await SearchService.updateSearchCriteriaForUser(updatedData);
    },
    onError: (e) => {
      Logger.logError(e);
    },
  });

  return updateLessonSearchCriteriaByUserMutation;
};

export const useAutoSaveLessonSearchCriteriaByUser = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const filterData = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => ({
    subject: state.selectedSubjectId,
    libraries: state.contentLibraries,
    grades: state.grades,
  }));
  const updateLessonSearchCriteriaByUserMutation = useUpdateLessonSearchCriteriaByUser();
  useEffect(() => {
    updateLessonSearchCriteriaByUserMutation.mutate({ ...filterData });
  }, [filterData.grades, filterData.libraries, filterData.subject]);
};
