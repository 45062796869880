import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ActivitiessAssignmentType } from '../../../components/activities-assignment/models/ActivitiesAssigmentType';
import ActivitiesAssignment from '../../../components/activities-assignment/ActivitiesAssignment';
import WindowParentUtil from '../../../utils/WindowParentUtil';

export default function ActivitiesAssignmentInterimPage() {
  const location = useLocation();
  const [data] = useState(() => {
    const { data } = location.state || location;
    return data;
  });

  const dataPayload = data as ActivitiessAssignmentType;
  return (
    <ActivitiesAssignment
      activitiesAssignment={dataPayload}
      onClose={() => {
        WindowParentUtil.postMessage('close-dialog');
      }}
      onSaveActivitiesAssignmentSuccess={() => {
        WindowParentUtil.postMessage('save-activities-assigment-success');
      }}
      onSaveTagsAssignmentSuccess={() => {
        WindowParentUtil.postMessage('save-tags-assigment-success');
      }}
    />
  );
}
