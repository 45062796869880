import ThemeSettingPage from '../theme-setting/ThemeSettingPage';
import ReinforcesSettingPage from '../reinforces-setting/ReinforcesSettingPage';
import VoiceSettingPage from '../voices-setting/VoiceSettingPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { studentSettingsUrl } from '../constants/StudentSettingsConst';
import { useShouldEnableVoicesMenu } from './hooks/StudentTtsHooks';

export default function SelectedSettingView() {
  const shouldEnableVoiceMenu = useShouldEnableVoicesMenu();

  return (
    <>
      <Routes>
        <Route path={studentSettingsUrl.reinforcers} element={<ReinforcesSettingPage />} />
        <Route index path={studentSettingsUrl.themes} element={<ThemeSettingPage />} />
        {shouldEnableVoiceMenu && <Route index path={studentSettingsUrl.voices} element={<VoiceSettingPage />} />}
        <Route path="*" element={<Navigate to={studentSettingsUrl.themes} />} />
      </Routes>
    </>
  );
}
