import { useContext } from 'react';
import { FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { RETAKE_OPTIONS } from '../../../../../../types/activity-assignment/ActivityAssignmentType';
import { ActivitiesAssignmentContext } from '../../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';

export default function RetakeOption() {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const retakeOption = useStore(activitiesAssignmentContext!, (state) => state.retakeOption);
  const setRetakeOption = useStore(activitiesAssignmentContext!, (state) => state.setRetakeOption);

  return (
    <Stack data-test="retake-option-container">
      <Typography data-test="title" variant="subtitle1" gutterBottom>
        Retake Options
      </Typography>
      <FormControl fullWidth data-test="form-control">
        <Select
          value={retakeOption}
          onChange={(e) => {
            const selectedOption =
              Number(e.target.value) === RETAKE_OPTIONS.RETAKE_SINGLE_PLAY
                ? RETAKE_OPTIONS.RETAKE_SINGLE_PLAY
                : RETAKE_OPTIONS.RETAKE_MULTIPLE_PLAY;
            setRetakeOption(selectedOption);
          }}
          data-test="select-component"
        >
          <MenuItem data-test="no-single-play-option" value={RETAKE_OPTIONS.RETAKE_SINGLE_PLAY}>
            No - Single Play
          </MenuItem>
          <MenuItem data-test="yes-multiple-play-option" value={RETAKE_OPTIONS.RETAKE_MULTIPLE_PLAY}>
            Yes - Multi-Play
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}
