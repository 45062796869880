import ItemRowCard from '../../../../ui/cards/item-row-card/ItemRowCard';
import { CardActionArea } from '@mui/material';
import WindowParentUtil from '../../../../utils/WindowParentUtil';
import { Lesson } from '../../../../types/lesson/LessonType';
import { getLessonChips } from './StudentSelftSelectedLessonChips';
import { FilterLessonStatus } from '../../model/FilterLessonStatus';
import NoAssignmentLabel from '../../ui/NoAssignmentLabel';
import { useGetLessonsData } from './StudentSelfSelectedLessonListHooks';
import { useStudentLessonsSummary } from '../hooks/StudentLessonsSummaryHooks';
import DateUtils from '../../../../utils/DateUtils';

const getDueDateString = (lastAssignedDate?: string) => {
  if (!lastAssignedDate) {
    return undefined;
  }
  const dateLeft = new Date(lastAssignedDate);
  const days = DateUtils.getDifferenceInDays(dateLeft);

  let message = '';
  if (days === 0) {
    message = 'Due today';
  } else if (days === 1) {
    message = 'Due tomorrow';
  } else if (days > 1) {
    message = `Due in ${days} days`;
  }
  return message;
};

export default function StudentSelfSelectedLessonList() {
  const { userProfile, lessons, lessonStatus, subjectId } = useGetLessonsData();
  const { fetchStudentLessonsSummary, studentLessonSummary } = useStudentLessonsSummary();
  if (!lessons || lessons.length === 0) {
    return null;
  }

  /**
   * if the backend sends convenience as true,
   * that means there are in reality no current assignments...
   * so the backend sends previous lessons for that subject if they exist
   *
   * convenience flag = true + there are some lessons -> show message
   * convenience flag = false + there is no lessons - show message
   */
  return (
    <>
      {((lessons.length > 0 && userProfile!.convenience) ||
        (!userProfile!.convenience && (!lessons || lessons.length === 0))) &&
        lessonStatus === FilterLessonStatus.currentAssignments && (
          <NoAssignmentLabel
            sx={{ backgroundColor: 'white', width: 'fit-content', paddingX: 2, paddingY: 1, borderRadius: 1 }}
          />
        )}

      {lessons.map((lesson) => (
        <CardActionArea
          key={`lesson-${lesson.id}-card-action`}
          data-test={`lesson-${lesson.id}-card-action`}
          sx={{
            width: '95%',
          }}
          onClick={() => {
            const lessonToPlay = {
              id: String(lesson.id),
              attributes: {
                uuid: lesson.uuid,
              },
            };
            WindowParentUtil.playLesson(lessonToPlay as Lesson, () => {
              fetchStudentLessonsSummary();
            });
          }}
        >
          <ItemRowCard
            key={`lesson-item-${lesson.id}`}
            data-test={`lesson-item-${lesson.id}`}
            title={lesson.name}
            id={String(lesson.id)}
            image={lesson.thumbnailUrl}
            sx={{
              backgroundColor: 'white',
            }}
            imageHeight="101px"
            imageWidth="153px"
            chips={getLessonChips({ lesson, subjectId: subjectId!, studentLessonSummary: studentLessonSummary.data })}
            topRightTitle={getDueDateString(lesson.lastAssignedDate)}
          />
        </CardActionArea>
      ))}
    </>
  );
}
