import {
  DndContext,
  DragEndEvent,
  Modifiers,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableItemDataType } from './SortableItem';

type DragContainerType = {
  children: JSX.Element;
  onDragEnd: (oldIndex: number, newIndex: number) => void;
  modifiers: Modifiers;
};

export default function DragContainer({ children, onDragEnd, ...props }: DragContainerType) {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 0 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the handler toolbar component.
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 0ms, with tolerance of 5px of movement.
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    const oldIndex = active.data.current as SortableItemDataType;
    const newIndex = over?.data.current as SortableItemDataType;

    onDragEnd(oldIndex.sortable.index, newIndex.sortable.index);
  }

  return (
    <DndContext
      sensors={sensors}
      autoScroll={false}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      {...props}
    >
      {children}
    </DndContext>
  );
}
