import { Stack } from '@mui/system';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextToSpeechService from '../../../services/tts/TextToSpeechService';
import { Tts } from '../../../types/tts/TtsType';
import { Card, CardActionArea, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type VoiceSelectorType = {
  ttsConfig: Tts;
  onChange: (newValue: number) => void;
};

const sampleText = 'Welcome to Vizzle! I can help read activities out loud.';

export default function VoiceSelector({ ttsConfig, onChange }: VoiceSelectorType) {
  const textToSpeechService = new TextToSpeechService(ttsConfig.ttsMode);
  const theme = useTheme();
  const { readText, VOICE_TYPES } = textToSpeechService.getTtsService();
  return (
    <Stack gap={2}>
      {VOICE_TYPES.map((type) => (
        <Card
          key={type.key}
          sx={{
            width: 400,
            border: '1px solid #E0E0E0',
            borderRadius: 1,
            borderColor: type.value === ttsConfig.voice ? theme.palette.secondary.light : undefined,
          }}
          elevation={0}
        >
          <CardActionArea
            onClick={() => {
              readText(sampleText, { ...ttsConfig, voice: type.value });
              onChange(type.value);
            }}
          >
            <Stack direction="row" gap={2} alignItems="center" paddingY={2} paddingX={2}>
              <PlayCircleOutlineIcon
                sx={{
                  color: theme.palette.grey[700],
                }}
              />
              <Typography variant="body1">{type.name}</Typography>
              {type.value === ttsConfig.voice ? (
                <CheckCircleIcon
                  sx={{
                    color: theme.palette.secondary.light,
                    marginLeft: 'auto',
                  }}
                />
              ) : null}
            </Stack>
          </CardActionArea>
        </Card>
      ))}
    </Stack>
  );
}
