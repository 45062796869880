/**
 * Utility for Object
 */
export default {
  setTimeout: (f: () => void, duration: number) => {
    if (!f) {
      return null;
    }
    const timer = setTimeout(() => {
      clearTimeout(timer);
      f();
    }, duration);
    return timer;
  },

  delay: (duration: number) => {
    if (!duration) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve) => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        resolve();
      }, duration);
    });
  },
};
