import { Button, ButtonProps } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { APP_URL } from '../../../../../routes/RouteConstants';

export default function BackToSchoolsAction(props: ButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(APP_URL.searchLessons);
      }}
      variant="text"
      startIcon={<ArrowBackIcon />}
      {...props}
    >
      Back To Lessons
    </Button>
  );
}
