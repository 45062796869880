import { useMemo } from 'react';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from '@mui/material/styles';
import PlayPreferencesConstants from '../../../../constants/PlayPreferencesConstants';

export default function SimpleReinforcer() {
  const message = useMemo(() => PlayPreferencesConstants.getRandomSimpleMessage(), []);
  const theme = useTheme();

  return (
    <Stack
      data-test="simple-reinforcer-style"
      marginTop={2}
      marginLeft={2}
      height="155px"
      width="180px"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.successSecondary.main,
        borderRadius: 2,
        gap: 2,
        color: 'white',
      }}
    >
      <TaskAltIcon htmlColor="white" fill="white" />
      <Typography color="white" fontWeight={500}>
        {message}
      </Typography>
    </Stack>
  );
}
