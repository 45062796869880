import { createStore } from 'zustand';
import { VizzleSnackbarType } from '../types/ui/VizzleSnackbar';
import ObjectUtils from '../utils/ObjectUtils';

type AppUi = {
  snackbar: VizzleSnackbarType;
  openSnackbar: (snackbar: VizzleSnackbarType) => void;
  closeSnackbar: () => void;
};

const initialState = {
  snackbar: {
    severity: undefined,
    open: false,
    title: undefined,
    message: undefined,
    duration: undefined,
  },
};

const createAppUiStore = () => {
  return createStore<AppUi>((set, get) => ({
    ...initialState,
    openSnackbar: (snackbar: VizzleSnackbarType) => {
      const currentSnack = get().snackbar;
      set({ snackbar: { ...currentSnack, open: false } });
      ObjectUtils.setTimeout(() => {
        set({ snackbar });
      }, 100);
    },
    closeSnackbar: () => {
      const currentSnack = get().snackbar;
      return set({ snackbar: { ...currentSnack, open: false } });
    },
  }));
};

const appUiStore = createAppUiStore();
export default appUiStore;
