import { useStore } from 'zustand';
import {
  ActivitiesAssignmentContext,
  TagsAssignmentContext,
  useDetermineMovingToCustomTagStep,
  useInitilzeActivitiesAssignment,
} from './ActivitiesAssignmentHooks';
import { AssignmentStepEnum } from './stores/ActivitiesAssignmentStore';
import { ActivitiessAssignmentType } from './models/ActivitiesAssigmentType';
import CustomTagForm from './steps/custom-tag/CustomTagForm';
import AssignActivitiesForm from './steps/assign-actitivities/AssignActivitiesForm';
import SaveAssignmentSuccess from './steps/save-success/SaveAssignmentSuccess';
import AssignActivitiesSaveSuccessPanel from './steps/assign-activities-save-success/AssignActivitiesSaveSuccessPanel';

type ActivitiesAssignmentProps = {
  activitiesAssignment: ActivitiessAssignmentType;
  onClose: () => void;
  onSaveActivitiesAssignmentSuccess?: () => void;
  onSaveTagsAssignmentSuccess?: () => void;
  onCustomTagCancel?: () => void;
};

export default function ActivitiesAssignment({
  activitiesAssignment,
  onClose,
  onSaveActivitiesAssignmentSuccess,
  onSaveTagsAssignmentSuccess,
  onCustomTagCancel,
}: ActivitiesAssignmentProps) {
  const { activitiesAssignmentStoreRef, tagsAssignmentStoreRef } = useInitilzeActivitiesAssignment({
    activitiesAssignment,
    onClose,
  });

  const currentStep = useStore(activitiesAssignmentStoreRef.current, (state) => state.step);
  const setStep = useStore(activitiesAssignmentStoreRef.current, (state) => state.setStep);
  const { determineMovingToCustomTagStep } = useDetermineMovingToCustomTagStep({ activitiesAssignmentStoreRef });

  return (
    <ActivitiesAssignmentContext.Provider value={activitiesAssignmentStoreRef.current}>
      {currentStep === AssignmentStepEnum.ASSIGN_ACTIVTIES && (
        <AssignActivitiesForm
          onSaveSuccess={() => {
            if (onSaveActivitiesAssignmentSuccess) {
              onSaveActivitiesAssignmentSuccess();
            }

            const shouldMoveToCustomTag = determineMovingToCustomTagStep();
            if (shouldMoveToCustomTag) {
              setStep(AssignmentStepEnum.CUSTOM_TAG);
            } else {
              setStep(AssignmentStepEnum.ASSIGN_ACTIVITIES_SAVE_SUCEESS);
            }
          }}
        />
      )}

      {currentStep === AssignmentStepEnum.ASSIGN_ACTIVITIES_SAVE_SUCEESS && (
        <TagsAssignmentContext.Provider value={tagsAssignmentStoreRef.current}>
          <AssignActivitiesSaveSuccessPanel onClose={onSaveTagsAssignmentSuccess} />
        </TagsAssignmentContext.Provider>
      )}

      {currentStep === AssignmentStepEnum.CUSTOM_TAG && (
        <TagsAssignmentContext.Provider value={tagsAssignmentStoreRef.current}>
          <CustomTagForm
            onSaveSuccess={() => {
              if (onSaveTagsAssignmentSuccess) {
                onSaveTagsAssignmentSuccess();
              }
            }}
            onCustomTagCancel={onCustomTagCancel}
          >
            <SaveAssignmentSuccess />
          </CustomTagForm>
        </TagsAssignmentContext.Provider>
      )}
    </ActivitiesAssignmentContext.Provider>
  );
}
