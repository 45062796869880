import { Howl } from 'howler';

let sound: Howl | null = null;
let cancelFunction = () => {};

type AudioPromiseType = {
  resolve: () => void;
  reject: (param: { isCancel: true }) => void;
};
let currentPromise: AudioPromiseType | null = null;

export default {
  playAudio: (
    audioUrl: string | string[],
    onStart = () => {},
    { rate, volume } = { rate: 1, volume: 1 },
    onCancel = () => {},
  ): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
      currentPromise = {
        resolve,
        reject,
      };

      let timeout: NodeJS.Timeout;
      cancelFunction = onCancel;
      try {
        sound = new Howl({
          //@ts-ignore
          src: [audioUrl],
          autoplay: true,
          pool: 1,
          format: ['wav', 'mp3'],
          onend: () => {
            clearTimeout(timeout);
            resolve(undefined);
          },
          onload: () => {
            clearTimeout(timeout);
            onStart();
          },
          onloaderror: (_, e) => {
            console.log(e);
          },
          onplayerror: (e) => {
            console.log(e);
          },
          rate,
          volume,
        });

        // If the audio cannot be start within 5 seconds.
        timeout = setTimeout(() => {
          if (!sound?.playing()) {
            clearTimeout(timeout);
            resolve(undefined);
          }
        }, 5000);
      } catch (e) {
        resolve();
      }
    });
    return promise;
  },

  stopAudio: () => {
    if (sound) {
      sound.stop();
    }
    if (currentPromise) {
      currentPromise.reject({
        isCancel: true,
      });
    }

    sound?.stop();
    if (cancelFunction) {
      cancelFunction();
    }
  },

  isAudioPlaying: () => sound && sound.playing(),
};
