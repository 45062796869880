import { Fade, Typography, TypographyProps } from '@mui/material';

export default function NoAssignmentLabel(props: TypographyProps) {
  return (
    <Fade in>
      <Typography variant="h6" fontWeight={400} color="secondary" {...props} data-test="no-assignments-label">
        No assignments today, but you can work on these previous lessons.
      </Typography>
    </Fade>
  );
}
