import { Stack, StackProps, Typography } from '@mui/material';
import noBitesImage from '../../../assets/images/no-results-penguin.svg';

type NoResultCardType = {
  message: string;
};

export default function NoResultCard({ message, ...rest }: NoResultCardType & StackProps) {
  return (
    <Stack alignItems="center" gap={3} data-test="no-result-card" {...rest}>
      <Typography>{message}</Typography>
      <img
        src={noBitesImage}
        alt={message}
        data-test="no-result-image"
        style={{
          width: '600px',
        }}
      />
    </Stack>
  );
}
