import { z } from 'zod';
import { DATE_SELECTOR_MODE } from '../../../types/ui/DateSelector';

export const ActivitiesAssignmentValidation = z
  .object({
    selectedStudentsAndGroupIds: z.array(z.string()).min(1, { message: 'Select at least one student to assign' }),
    selectedDates: z.array(z.string()),
    selectedDateMode: z.nativeEnum(DATE_SELECTOR_MODE),
    selectedLessonsByType: z.array(z.any()),
  })
  .superRefine((data, ctx) => {
    if (data.selectedLessonsByType && data.selectedLessonsByType.length > 0) {
      const hasEmptyArray = data.selectedLessonsByType.findIndex((array) => array.length === 0) >= 0;
      if (hasEmptyArray) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select at least one item',
          path: ['selectedLessonsByType'],
        });
        return;
      }

    }
    if (data.selectedDateMode === DATE_SELECTOR_MODE.DATE_RANGE) {
      if (data.selectedDates.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Select start date and end date',
          path: ['selectedDates'],
        });
        return;
      }
    }

    if (data.selectedDateMode === DATE_SELECTOR_MODE.MULTIPLE_DATES) {
      if (data.selectedDates.length < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Select at least one date to assign',
          path: ['selectedDates'],
        });
        return;
      }
    }
  });

export type ActivitiesAssignmentValidationType = z.infer<typeof ActivitiesAssignmentValidation>;
