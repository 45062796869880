import { Howl } from 'howler';
import { useState, useEffect } from 'react';
import { Reinforcer } from '../../../../types/user/TerminologiesType';
import MediaService from '../../../../services/MediaService';
import { useMutation } from '@tanstack/react-query';

type ReinforcerStateType = {
  open: boolean;
  reinforceId: string;
  promise: { resolve: () => void; reject: () => void } | null;
};

type ReinforcerObjectType = {
  reinforceState?: ReinforcerStateType;
  setReinforceState?: (reinforceState: ReinforcerStateType) => void;
};

export const reinforcerObject: ReinforcerObjectType = {};

export const useInitReinforcer = () => {
  const [reinforceState, setReinforceState] = useState<ReinforcerStateType>({
    open: false,
    reinforceId: '',
    promise: null,
  });

  reinforcerObject.reinforceState = reinforceState;
  reinforcerObject.setReinforceState = setReinforceState;

  return {
    reinforceState,
    setReinforceState,
  };
};

export const useGetLottieAnimationData = () => {
  const [animationData, setAnimationData] = useState<unknown | null>(null);
  const getJsonDataMutaion = useMutation({
    mutationKey: ['get-json-data'],
    mutationFn: async (url: string) => {
      const result = await MediaService.getJsonData(url);
      setAnimationData(result);
    },
  });

  return {
    animationData,
    setAnimationData,
    getJsonDataMutaion,
  };
};

export const usePreloadReinforcer = ({
  reinforcerList,
  enableAudio,
}: {
  reinforcerList: Reinforcer[];
  enableAudio: boolean;
}) => {
  useEffect(() => {
    reinforcerList.forEach((reinforcer) => {
      if (!reinforcer) {
        return;
      }
      const { manifest } = reinforcer;
      if (enableAudio) {
        const sounds = manifest.filter((m) => m.id === 'sound');
        sounds.forEach((s) => {
          if (s && s.src) {
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            const sound = new Howl({
              src: s.src,
              preload: true,
            });
          }
        });
      }

      const spritesheetData = manifest.find((m) => m.id === 'spritesheetData');
      if (spritesheetData) {
        MediaService.getJsonData(spritesheetData.src);
      }
    });
  }, [reinforcerList]);
};
