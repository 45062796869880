import { useStore } from 'zustand';
import { useUserStore } from '../../stores/UserStore';
import LandingStudentSelfSelected from './student-self-selected/LandingStudentSelfSelected';
import LandingStudentTile from './student-tile/LandingStudentTile';
import { Fade, Stack } from '@mui/material';
import StudentToolbar from './toolbar/StudentToolbar';
import { PlayMode } from '../../types/user/UserProfileType';
import NoActivityLabel from './ui/NoActivityLabel';
import { Route, Routes } from 'react-router-dom';
import LandingStudentSettings from '../student-settings/LandingStudentSettings';
import { landingStudentUrl } from '../student-settings/constants/StudentSettingsConst';

export default function LandingStudent() {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const terminologies = useStore(useUserStore, (state) => state.terminologies);
  const { playMode, playerWorld, lessonIds } = userProfile!;

  const isSelftSelected = playMode === PlayMode.selfSelected;
  const themes = terminologies?.themes;
  const theme = themes ? themes.find((t) => t.code === playerWorld) : null;
  const hasLesson = lessonIds && lessonIds.length > 0;

  const isColorBackground = theme?.manifest[0] && theme?.manifest[0]?.id === 'color';

  const backgroundStyle = isColorBackground
    ? {
        backgroundColor: theme?.manifest[0]?.src,
      }
    : {
        backgroundImage: `url(${theme?.manifest[0]?.src})`,
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

  return (
    <Routes>
      <Route
        index
        path="/*"
        element={
          <Fade in timeout={1000}>
            <Stack sx={{ height: '100%' }} data-test="landing-student-page">
              <StudentToolbar />
              <Stack overflow="hidden" flexGrow={1} sx={backgroundStyle} position="relative">
                {!hasLesson && <NoActivityLabel />}

                {isSelftSelected ? <LandingStudentSelfSelected /> : <LandingStudentTile />}
              </Stack>
            </Stack>
          </Fade>
        }
      />
      {userProfile?.studentSettings && (
        <Route path={`${landingStudentUrl.settings}/*`} element={<LandingStudentSettings />} />
      )}
    </Routes>
  );
}
