import { useContext } from 'react';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { Chip } from '@mui/material';

export default function CoursesSummaryPanel() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const courseList = useStore(searchLessonsContext!.searchLessonsMasterDataStore!, (state) => state.courseList);

  const courseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.courseIds);
  const setCourseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setCourseIds);
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);


  return <>
      {courseIds.map((courseId) => {
        const course = (courseList || []).find((c) => c.id === courseId);
        return (
          <Chip
            key={`course-${courseId}`}
            data-test={`course-${courseId}`}
            label={course?.name}
            onDelete={() => {
              const updatedCourses = courseIds.filter((c) =>  c !== courseId);
              resetSearchData();
              setCourseIds(updatedCourses);
            }}
          />
        );
      })}
    </>;
}
