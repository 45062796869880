import { useStore } from 'zustand';
import { useUserStore } from '../stores/UserStore';
import { SUBJECT_CONSTANTS, SubjectObjectDetailType } from '../constants/LessonConstant';
import ArrayUtils from '../utils/ArrayUtils';
import { UserComponent } from '../types/user/UserProfileType';

export const useGetSubjectsByUserComponents = (includeMisc: boolean): SubjectObjectDetailType[] => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const { components } = userProfile!;
  const componentIds = (components || []).map((c) => String(c.identifier));

  const subjects: SubjectObjectDetailType[] = [];
  if (componentIds.includes(UserComponent.academic)) {
    subjects.push(SUBJECT_CONSTANTS.english);
    subjects.push(SUBJECT_CONSTANTS.math);
    subjects.push(SUBJECT_CONSTANTS.science);
    subjects.push(SUBJECT_CONSTANTS.social);
  }

  if (componentIds.includes(UserComponent.skillSuite)) {
    subjects.push(SUBJECT_CONSTANTS.speech);
    subjects.push(SUBJECT_CONSTANTS.arts);
    subjects.push(SUBJECT_CONSTANTS.life);
    subjects.push(SUBJECT_CONSTANTS.socialSkills);
    subjects.push(SUBJECT_CONSTANTS.transitionalSkills);
  }
  if (includeMisc) {
    subjects.push(SUBJECT_CONSTANTS.misc);
  }

  return ArrayUtils.unique(subjects, 'subjectId');
};
