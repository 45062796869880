import { Grid, GridProps, Stack } from '@mui/material';
import ItemInformation from './item-information/ItemInformation';
import { ItemInformationType } from './types/ItemInformationType';
import ResourceMaterial from './resource-material/ResourceMaterial';
import { ResourceMaterialType } from './types/ResourceMaterialType';
import { StateStandardsType } from './types/StateStandardsType';
import StateStandards from './state-standards/StateStandards';
import { ExtensionActivityType } from './types/ExtensionActivityType';
import ExtensionActivity from './extension-activities/ExtensionActivity';
import OtherSection from './others-section/OtherSection';
import { OtherSectionType } from './types/OtherSectionType';
import { LessonListType } from './types/LessonList';
import LessonList from './lessons-list/LessonList';
import { useStore } from 'zustand';
import { useContext, useEffect } from 'react';
import { SearchLessonsContext } from '../../SearchLessonsPageHooks';
import { TopicListType } from './types/TopicList';
import TopicList from './topic-list/TopicList';
import { useHandleExternalLinkClick } from './ItemDetailsHooks';
import ItemDetailsAction from './actions/ItemDetailsAction';
import { ItemDetailsActionType } from './types/ItemDetailsActionType';
import BackToSchoolsAction from './actions/BackToSchoolsAction';

type ItemDetailsType = {
  itemInformation: ItemInformationType;
  resourceMaterial: ResourceMaterialType;
  stateStandards?: StateStandardsType;
  extensionActivity: ExtensionActivityType;
  othersSection: OtherSectionType;
  lessonList?: LessonListType;
  topicList?: TopicListType;
  itemDetailsAction: ItemDetailsActionType;
  'data-test': string;
};

function ItemDetails({
  itemInformation,
  resourceMaterial,
  stateStandards,
  extensionActivity,
  othersSection,
  lessonList,
  topicList,
  itemDetailsAction,
  ...rest
}: ItemDetailsType & GridProps) {
  /**
   * To handle the on click of "Lessons" menu button from the parent window
   */
  useHandleExternalLinkClick();

  const searchLessonsContext = useContext(SearchLessonsContext);
  const setLessonLevel = useStore(searchLessonsContext!.searchLessonsSelectionStore!, (state) => state.setLessonLevel);

  useEffect(() => {
    return () => {
      setLessonLevel();
    };
  }, []);
  return (
    <Grid
      paddingX="5%"
      paddingTop={3}
      minWidth="1000px"
      maxWidth="1400px"
      marginLeft="auto"
      marginRight="auto"
      boxSizing="border-box"
      container
      columnSpacing={4}
      height="100%"
      data-test={`${rest['data-test']}-item-details`}
    >
      <Grid item xs={6} data-test="left-container" paddingBottom={3}>
        <BackToSchoolsAction sx={{ paddingX: 0, marginBottom: 1, fontWeight: 500 }} />
        <Stack gap={5} data-test="item-information-container">
          <ItemInformation {...itemInformation} />
          <ResourceMaterial {...resourceMaterial} />
          <ExtensionActivity {...extensionActivity} />
          {stateStandards && <StateStandards {...stateStandards} />}
          <OtherSection {...othersSection} />
        </Stack>
      </Grid>
      <Grid item xs={6} data-test="right-container" paddingBottom={3}>
        <Stack gap={9} mt={2} width="100%">
          <ItemDetailsAction {...itemDetailsAction} />
          {/* <Stack alignSelf="flex-end" direction="row" gap={1}>
            <ItemRowActions
              actions={[
                ...(additionalActions || []),
                {
                  variant: 'rounded',
                  'data-test': 'assign-action',
                  onClick: () => {
                    const data = getSelectedData();
                    setSelectedAssignmentItem(data);
                    setOpenActivitiesAssignmentDialog(true);
                  },
                  children: 'Assign',
                },
              ]}
            />
            <ItemRowMenuItems dropdownActions={dropdownActions} />
          </Stack> */}
          {lessonList && <LessonList {...lessonList} />}
          {topicList && <TopicList {...topicList} />}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ItemDetails;
