import { useTheme } from '@mui/material/styles';
import { CircularProgress, Stack, Typography } from '@mui/material';
import {
  useDataForUpdate,
  useGetCurrentSelectedStudents,
  useGetLessonTagsForAutoComplete,
  usePrepareTagsData,
} from './TagsAssignmentHook';
import LessonTagAutocompleteForm from './LessonTagAutocompleteForm';
import StudentsTagsList from './StudentsTagsList';

export default function TagsAssignment() {
  const theme = useTheme();

  const tagsQuery = usePrepareTagsData();

  const {
    studentsTagsMap,
    tagsAddedInThisSession,
    handleOnStudentSelect,
    handleOnTagAdded,
    handleOnTagSelected,
    handleOnTagDelete,
  } = useDataForUpdate(tagsQuery.data);

  const { tagsNameExisitingInAllStudents, tagsForAutoComplete } = useGetLessonTagsForAutoComplete(
    tagsQuery?.data?.data || [],
    tagsAddedInThisSession
  );

  const currentSelectedStudents = useGetCurrentSelectedStudents();

  const studentWithTags = Object.values(studentsTagsMap);
  const hasTagsAssiged = studentWithTags.findIndex((s) => s.addedTags.length > 0 || s.studentTags.length > 0) >= 0;

  return (
    <Stack
      width={780}
      data-test="save-assignment-success"
      paddingX={1.5}
      paddingY={2}
      // border={1}
      // borderColor={theme.palette.divider}
      sx={{ boxShadow: '0px 0px 0px 1px #E0E0E0' }}
      borderRadius={1}
    >
      <Typography variant="h6" gutterBottom data-test="title">
        Add Custom Goals for Enhanced Data Tracking
      </Typography>
      <Typography data-test="description">
        Custom Goals enable advanced data tracking and real-time notifications.{' '}
        <Typography
          component="a"
          color={theme.palette.primary.main}
          href="https://support.govizzle.com/search-assign-and-tag-activities-with-iep-goalsobjectives/"
          target="_blank"
          sx={{ textDecoration: 'underline' }}
          data-test="learn-more-link"
        >
          Learn More
        </Typography>
      </Typography>
      {(tagsQuery.isLoading || tagsQuery.isFetching) && (
        <Stack alignItems="center" paddingY={5}>
          <CircularProgress data-test="loading" />
        </Stack>
      )}

      {!tagsQuery.isLoading && !tagsQuery.isFetching && (
        <Stack alignItems="center" data-test="custom-tag-form" paddingTop={2} paddingBottom={5} gap={3}>
          <LessonTagAutocompleteForm
            allLessonTags={tagsForAutoComplete || []}
            skipAddingTagNames={tagsNameExisitingInAllStudents}
            placeholder="Type to add/search Goals & Objectives"
            onTagAdded={handleOnTagAdded}
            onTagSelected={handleOnTagSelected}
          />
          <StudentsTagsList
            students={currentSelectedStudents}
            studentsTagsMap={studentsTagsMap}
            onStudentSelect={handleOnStudentSelect}
            onTagDelete={handleOnTagDelete}
          />
        </Stack>
      )}

      {!tagsQuery.isLoading && !hasTagsAssiged && (
        <Typography color="error" fontWeight={500}>
          Add goals to enable mastery tracking for this assignment.
        </Typography>
      )}
    </Stack>
  );
}
