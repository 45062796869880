import { forwardRef } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import './UnitGuidePage.scss';
import VizzleLogo from '../../../../assets/images/logo/VizzleLogo.svg';
import UnitGuideSection from './UnitGuideSection';
import { Standard, UnitGuide } from '../../../../types/unit/UnitGuideType';
import ArrayUtils from '../../../../utils/ArrayUtils';
import { useStore } from 'zustand';
import { useUserStore } from '../../../../stores/UserStore';

type UnitGuidePageType = {
  unitGuideData: UnitGuide;
  courseName: string;
};

// eslint-disable-next-line react/display-name
const UnitGuidePage = forwardRef(({ unitGuideData, courseName }: UnitGuidePageType, ref) => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);

  return (
    <Box ref={ref} className="unit-guide-page">
      {unitGuideData.unitGuideInfo &&
        unitGuideData.unitGuideInfo.map((unitGuideInfo) => {
          let displayedGroupedStandard: ({ title: string; standardList: Standard[] } | null)[] = [];
          if (unitGuideInfo.standards) {
            const groupedStandard: { [key: string]: Standard[] } = {};
            unitGuideInfo.standards.forEach((s: Standard) => {
              let group = groupedStandard[s.standard_applied_scope];
              if (!group) {
                group = [];
              }
              group.push(s);
              groupedStandard[s.standard_applied_scope] = group;
            });

            const { standardScopes } = userProfile!;
            displayedGroupedStandard = Object.keys(groupedStandard)
              .map((g) => {
                const scope = standardScopes.find((s) => s.scopeCode === g);
                if (!scope) {
                  return null;
                }
                return {
                  title: scope.scopeDisplayName,
                  standardList: groupedStandard[g],
                };
              })
              .filter((d) => d !== null);
          }

          return (
            <Paper
              elevation={3}
              className="unit-guide-page-container"
              key={`unit-guide-of-topic-${unitGuideInfo.topicId}`}
            >
              <div className="unit-guide-header">
                <div className="unit-guide-pdf-logo">
                  <img src={VizzleLogo} alt="Vizzle Logo" />
                </div>
                <div className="unit-info">
                  <Typography className="header-text" variant="h5">
                    {courseName}
                  </Typography>
                  <Typography className="header-text unit-header" variant="h5">
                    UNIT: {unitGuideData.name}
                  </Typography>
                </div>
              </div>
              <div className="unit-guide-body">
                <UnitGuideSection
                  title={`Topic ${unitGuideInfo.topicNumber > 0 ? `${unitGuideInfo.topicNumber} ` : ''}- ${
                    unitGuideInfo.topicName
                  }`}
                >
                  {displayedGroupedStandard && displayedGroupedStandard.length > 0 ? (
                    displayedGroupedStandard.map((gStandard, i) => (
                      <div className="standard-group" key={`group-stnadard-${i}`}>
                        <Typography variant="subtitle1" className="unit-guide-body-sub-title">
                          {gStandard?.title}
                        </Typography>
                        {gStandard?.standardList &&
                          gStandard.standardList.length > 0 &&
                          ArrayUtils.sort(
                            ArrayUtils.unique(gStandard.standardList, 'standard_code'),
                            'standard_code',
                          ).map((standard: Standard, standardIndex: number) => (
                            <div className="standard-entry" key={`standard-${standardIndex}`}>
                              <Typography>
                                <strong>{standard.standard_code}</strong> {standard.standard_description}
                              </Typography>
                            </div>
                          ))}
                      </div>
                    ))
                  ) : (
                    <div className="standard-in-progress">Standards in Progress</div>
                  )}
                </UnitGuideSection>
                <UnitGuideSection
                  className="section-margin-top"
                  title="KEY VOCABULARY"
                  content={unitGuideInfo.keyVocabulary}
                />
                <UnitGuideSection
                  className="section-margin-top"
                  title="ESSENTIAL QUESTIONS/BIG IDEAS"
                  content={unitGuideInfo.essentialQuestions}
                />
                <UnitGuideSection
                  className="section-margin-top"
                  title="ACTIVITIES"
                  content={unitGuideInfo.activities}
                />
              </div>
            </Paper>
          );
        })}
    </Box>
  );
});

export default UnitGuidePage;
