import { LessonSearchCriteriaByUser } from '../types/user/LessonSearchCriteriaByUserType';
import vizzleAxios from './service';

export default {
  getSavedSearchCriteriaForUser: async (): Promise<LessonSearchCriteriaByUser> => {
    const url = '/search/get';
    const result = await vizzleAxios.get(url);
    return result.data;
  },

  addSearchCriteriaForUser: async(data: LessonSearchCriteriaByUser) => {
    const url = '/search/add';
    const result = await vizzleAxios.post(url, data);
    return result.data;
  },

  updateSearchCriteriaForUser: async(data: LessonSearchCriteriaByUser) => {
    const url = '/search/edit';
    const result = await vizzleAxios.put(url, data);
    return result.data;
  },
};
