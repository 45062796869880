import { User } from '../types/user/UserType';

export default {
  isTexasTeacher: (user?: User) => user && user.state === 'TX',
  isFloridaTeacher: (user?: User) => user && user.state === 'FL',
  isTeacher: (user?: User) => user && user.userType === 'Teacher',
  isStudent: (user?: User) => user && user.userType === 'Student',
  isMfaUser: (user?: User) => user && (user.userSuperAdmin || user.userModerator || user.userModeratorWithDirectAccess),
  isPaidAccount: (user: User) => user && user.isDistrictPaidCustomer,
};

export const USER_COMPONENT = {
  academic: 35,
  skillSuite: 36,
};
