import { Lesson } from './LessonType';

export type LessonResult = {
  data: Lesson[];
  included: LessonIncluded[];
};

export type SearchLessonResult = LessonResult & {
  meta?: SearchLessonResultMeta;
};

export type LessonIncluded = {
  type: string;
  id: string;
  attributes: IncludedAttributes;
};

export type IncludedAttributes = {
  standardId?: number;
  standardCode?: string;
  standardGrade?: string;
  standardDescription?: string;
  lessonLevel?: number;
  scope?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  type?: string;
};

export type SearchLessonResultMeta = {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
};

export enum ContentLibraryScenary {
  COURSES = 'courses',
  COURSES_STANDARD_BASED_SEARCH = 'courses-standards-based-search',
  STANDARD_BASED_SEARCH = 'standard-based-search',
  GENERAL = 'general',
}
