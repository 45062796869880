import { Button, Stack, Typography } from '@mui/material';
import { switchAccessBodyElements } from '../../../components/switch-access/SwitchAccessUtils';

type SubjectActivityCompleteType = {
  onClose: () => void;
};
export default function SubjectActivityComplete({ onClose }: SubjectActivityCompleteType) {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center" gap={1} marginTop={5} mx="auto" data-test="activity-complete">
      <Typography fontWeight={400} fontSize="2em" marginTop={1} data-test="activity-complete-label">
        Congratulations!
      </Typography>
      <Typography fontWeight={400} fontSize="2em" marginTop={1} data-test="activity-complete-label">
        Activities completed.
      </Typography>

      <Button
        variant="rounded"
        onClick={onClose}
        fullWidth
        sx={{ height: 40, marginTop: 1 }}
        {...switchAccessBodyElements}
        data-test="activity-complete-close-action"
      >
        Close
      </Button>
    </Stack>
  );
}
