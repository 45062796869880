/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { ColorSystemOptions, SupportedColorScheme } from '@mui/material';

export const palleteTheme: Partial<Record<SupportedColorScheme, ColorSystemOptions>> = {
  light: {
    palette: {
      primary: {
        main: '#1565C0',
      },
      secondary: {
        main: '#ee7b22',
        contrastText: '#fff',
      },
      black: {
        main: '#000000',
        contrastText: '#fff',
      },
      textSecondary: {
        main: 'rgba(0, 0, 0, 0.6)',
        contrastText: 'rgba(0, 0, 0, 0.6)',
        light: 'rgba(0, 0, 0, 0.6)',
        dark: 'rgba(0, 0, 0, 0.6)',
      },
      successSecondary: {
        main: '#39bd39',
        contrastText: '#fff',
        light: '#39bd39',
        dark: '#39bd39',
      }
    },
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    textSecondary: Palette['primary'];
    successSecondary: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    textSecondary?: Palette['primary'];
    successSecondary?: Palette['primary'];
  }
}
