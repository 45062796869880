import { createContext, useContext, useRef } from 'react';
import { SearchLessonsCriteriaStore, createSearchLessonsCriteriaStore } from './stores/SearchLessonsCriteriaStore';
import { SearchLessonsResultStore, createSearchLessonsResultStore } from './stores/SearchLessonsResultStore';
import { useQuery } from '@tanstack/react-query';
import StudentService from '../../services/StudentService';
import { StudentGroup } from '../../types/student/StudentGroupType';
import { SearchLessonsSelectionStore, createSearchLessonsSelectionStore } from './stores/SearchLessonsSelectionStore';
import { useStore } from 'zustand';
import {
  SearchLessonsResultUiControlStore,
  createSearchLessonsResultUiControlStore,
} from './stores/SearchLessonsResultUiControlStore';
import { TopicTypeString } from '../../types/topic/SearchTopicResultType';
import { UnitTypeString } from '../../types/unit/UnitType';
import { LessonTypeString } from '../../types/lesson/LessonType';
import { SUBJECT_ID } from '../../constants/LessonConstant';
import { SearchLessonsMasterDataStore, createSearchLessonsMasterDataStore } from './stores/SearchLessonsMasterDataStore';

type SearchLessonContextType = {
  searchLessonsCriteriaStore: SearchLessonsCriteriaStore;
  searchLessonsResultStore: SearchLessonsResultStore;
  searchLessonsSelectionStore: SearchLessonsSelectionStore;
  searchLessonsResultUiControlStore: SearchLessonsResultUiControlStore;
  searchLessonsMasterDataStore: SearchLessonsMasterDataStore;
  studentGroupData: StudentGroup[];
} | null;
export const SearchLessonsContext = createContext<SearchLessonContextType>(null);

export const useInitilzeSearchLessons = () => {
  const searchLessonsCriteriaStoreRef = useRef(createSearchLessonsCriteriaStore());
  const searchLessonsResultStoreRef = useRef(createSearchLessonsResultStore());
  const searchLessonsSelectionStoreRef = useRef(createSearchLessonsSelectionStore());
  const searchLessonsMasterDataStoreRef = useRef(createSearchLessonsMasterDataStore());
  const searchLessonsResultUiControlStoreRef = useRef(createSearchLessonsResultUiControlStore());

  return {
    searchLessonsCriteriaStoreRef,
    searchLessonsResultStoreRef,
    searchLessonsSelectionStoreRef,
    searchLessonsMasterDataStoreRef,
    searchLessonsResultUiControlStoreRef,
  };
};

export const useGetStudentGroupdata = () => {
  const studentGroupDataQuery = useQuery({
    queryKey: ['get-student-group-data'],
    queryFn: async () => {
      const studentGroups = await StudentService.getStudentGroups();
      return studentGroups.data;
    },
  });
  return studentGroupDataQuery;
};

export const useGetRecordType = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const selectedSubjectId = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.selectedSubjectId
  );

  /**
   * Topic and Unit View
   *   If subject is ELA/Math/Science/Social Studies, display ‘Academic Curriculum
   *   If subject is Transitional Skills/Social Skills/Life Skills, display ‘Skills Suite’
   * Activity View
   *   If subject is ELA/Math/Science/Social Studies and returned lesson has a topic id associated with it, then display ‘Academic Curriculum’, otherwise display ‘Vizzle Classic’
   *   If subject is Transitional Skills/Social Skills/Life Skills and returned lesson has a topic id associated with it, then display ‘Skills Suite’, otherwise display ‘Vizzle Classic’
   *   For ‘Misc’, ‘Arts’ and ‘Speech Therapy, always display ‘Vizzle Classic’
   */
  const getRecordType = (type: string, topicId?: number) => {
    if (type === TopicTypeString || type === UnitTypeString) {
      if (
        [
          SUBJECT_ID.SUBJECT_ID_ELA,
          SUBJECT_ID.SUBJECT_ID_MATH,
          SUBJECT_ID.SUBJECT_ID_SCIENCE,
          SUBJECT_ID.SUBJECT_ID_SOCIAL_STUDY,
        ].includes(selectedSubjectId!)
      ) {
        return 'Academic Curriculum';
      } else if (
        [
          SUBJECT_ID.SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS,
          SUBJECT_ID.SUBJECT_ID_SOCIAL_SKILLS,
          SUBJECT_ID.SUBJECT_ID_LIFE_SKILL,
        ].includes(selectedSubjectId!)
      ) {
        return 'Skills Suite';
      }
    } else if (type === LessonTypeString) {
      if (!topicId) {
        return 'Vizzle Classic';
      }
      if (
        [
          SUBJECT_ID.SUBJECT_ID_ELA,
          SUBJECT_ID.SUBJECT_ID_MATH,
          SUBJECT_ID.SUBJECT_ID_SCIENCE,
          SUBJECT_ID.SUBJECT_ID_SOCIAL_STUDY,
        ].includes(selectedSubjectId!) && topicId
      ) {
          return 'Academic Curriculum';
  
      } else if (
        [
          SUBJECT_ID.SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS,
          SUBJECT_ID.SUBJECT_ID_SOCIAL_SKILLS,
          SUBJECT_ID.SUBJECT_ID_LIFE_SKILL,
        ].includes(selectedSubjectId!) && topicId
      ) {
        return 'Skills Suite';
      } else {
        return 'Vizzle Classic';
      }
    }

    return '';
  };

  return { getRecordType };
};
