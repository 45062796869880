import { useState } from 'react';
import { Stack, Slide, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LottieComponentProps } from 'lottie-react';
import { useTheme } from '@mui/material/styles';
import SubjectRowCard from '../../ui/SubjectRowCard';
import { iconsMap } from '../../constants/SubjectIconMap';
import WindowParentUtil from '../../../../utils/WindowParentUtil';
import { Lesson } from '../../../../types/lesson/LessonType';
import {
  useGetElementsForSwitchAccess,
  useHandleBackAction,
  useHandleOnLessonCompleteClose,
  usePrepareLessonToStart,
} from './LandingStudentTileLessonSelectionHooks';
import SubjectActivityComplete from '../../ui/SubjectActivityComplete';
import PlayLessonIcon from '../../ui/PlayLessonIcon';

const transitionTimeout = 800;

export default function LandingStudentTileLessonSelection() {
  const theme = useTheme();
  const [showComponent, setShowComponent] = useState(true);
  const { handleBackAction } = useHandleBackAction({ setShowComponent, transitionTimeout });
  const { lesson, subjectObject, completed } = usePrepareLessonToStart();
  const { handleOnLessonCompleteClose } = useHandleOnLessonCompleteClose({ subjectObject });
  const { updateElements, resetElementsToScan } = useGetElementsForSwitchAccess();

  if (completed) {
    return (
      <SubjectActivityComplete
        onClose={() => {
          handleOnLessonCompleteClose();
        }}
      />
    );
  }
  return (
    <Slide direction={showComponent ? 'left' : 'right'} in={showComponent} timeout={transitionTimeout}>
      <Stack data-test="landing-student-tile-lesson-selector-page" paddingTop={5} gap={5} alignItems="center">
        <SubjectRowCard
          subjectName={subjectObject?.name || ''}
          lottieIcon={iconsMap.get(subjectObject?.subjectId)}
          onClick={handleBackAction}
          startAdornment={
            <IconButton data-switch-access-scan="true">
              <ArrowBackIcon />
            </IconButton>
          }
          width="528px"
          height="80px"
          contentProps={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          subjectNameProps={{
            color: theme.palette.primary.main,
            fontSize: '1.3em',
            marginRight: 'auto',
          }}
          lottieProps={
            {
              loop: true,
              style: { width: '48px', height: '48px', opacity: 1, marginLeft: 'auto' },
            } as LottieComponentProps
          }
        />

        <PlayLessonIcon
          onClick={async () => {
            const lessonToPlay = {
              id: String(lesson.id),
              attributes: {
                uuid: lesson.uuid,
              },
            };
            await resetElementsToScan();
            WindowParentUtil.playLesson(lessonToPlay as Lesson, () => {
              updateElements();
              window.focus();
            });
          }}
        />
      </Stack>
    </Slide>
  );
}
