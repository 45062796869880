import { Box, Typography } from '@mui/material';
import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';
import ReinforcesSettingStyle from './reinforces-setting-style/ReinforcesSettingStyle';
import ReinforcesSettingList from './reinforces-setting-list/ReinforcesSettingList';
import { ReinforcerStyle, UserProfile } from '../../../types/user/UserProfileType';
import { useUpdateProfile } from '../LandingStudentSettingsHooks';
import SimpleReinforcer from '../../../components/terminologies/reinforcers/simple-reinforcer/SimpleReinforcer';

export default function ReinforcesSettingPage() {
  const userProfile = useStore(useUserStore, (state) => state.userProfile!);
  const terminologies = useStore(useUserStore, (state) => state.terminologies);

  const internalReinforcerStyle = useStore(useUserStore, (state) => state.userProfile!.reinforcerStyle!);
  const { updateUserProfileMutation } = useUpdateProfile('Reinforcers have been updated.');

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h5" paragraph>
        Reinforcers
      </Typography>
      <Box sx={{ m: 1, mt: 3 }}>
        <ReinforcesSettingStyle
          profile={userProfile}
          terminologies={terminologies}
          onChange={(value) => {
            updateUserProfileMutation.mutate(value);
          }}
        ></ReinforcesSettingStyle>
      </Box>
      {internalReinforcerStyle === ReinforcerStyle.simple && <SimpleReinforcer />}
      {internalReinforcerStyle === ReinforcerStyle.animated && (
        <ReinforcesSettingList
          profile={userProfile}
          terminologies={terminologies!}
          reinforcersListChange={(reinforcers: number[]) => {
            updateUserProfileMutation.mutate({ reinforcers: reinforcers } as UserProfile);
          }}
        ></ReinforcesSettingList>
      )}
    </Box>
  );
}
