/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { darken, experimental_extendTheme as extendTheme } from '@mui/material';

const theme = extendTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (ownerState.color === 'confirm') {
            return {
              bottom: 0,
              color: theme.palette.primary.contrastText,
              backgroundColor: darken(theme.palette.primary.main, 0.2),
            };
          }
        },
      },
    },
  },
});

export const alertTheme = theme.components?.MuiAlert;

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    confirm: true;
  }
}
