import { Box, IconButton } from '@mui/material';
import playIcon from '../../../assets/images/icons/play.svg';
import './PlayLessonIcon.scss';
import { switchAccessBodyElements } from '../../../components/switch-access/SwitchAccessUtils';

type PlayLessonIconType = {
  onClick: () => void;
};
export default function PlayLessonIcon({ onClick }: PlayLessonIconType) {
  return (
    <IconButton className="play-lesson-icon" onClick={onClick} data-test="play-lesson" {...switchAccessBodyElements}>
      <Box data-test="play-icon" className="play-icon" component="img" src={playIcon} alt="Play" sx={{ width: 380 }} />
    </IconButton>
  );
}
