import { useContext } from 'react';
import { useStore } from 'zustand';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useGetGradeByContentLibraries } from '../../../../hooks/GradeHooks';

export default function GradeSelector() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const grades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.grades);
  const setGrades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setGrades);
  const setCourseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setCourseIds);
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const gradeList = useGetGradeByContentLibraries();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let values = [...grades];
    if (grades.includes(name)) {
      values = grades.filter((grade) => grade !== name);
    } else {
      values.push(name);
    }

    resetSearchData();
    setGrades(values);
    setCourseIds([]);
  };

  return (
    <FormControl component="fieldset" variant="standard" data-test="grade-selector-container">
      <Typography variant="subtitle1" data-test="title">
        Grade
      </Typography>
      <FormGroup data-test="form-group">
        {gradeList.map((option) => (
          <FormControlLabel
            key={`grade-option-${option.value}`}
            data-test={`grade-option-${option.value}`}
            control={<Checkbox onChange={handleChange} name={option.value} />}
            label={option.label}
            name={option.value}
            checked={grades.includes(option.value)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
