import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Box, BoxProps, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WindowParentUtil from '../../../../utils/WindowParentUtil';
import { UnitGuide } from '../../../../types/unit/UnitGuideType';
import UnitGuidePage from './UnitGuidePage';

type ViewUnitGuideDialogType = {
  unitGuideData: UnitGuide;
  title: string;
  courseName: string;
  actionComponent: JSX.Element;
};
export default function ViewUnitGuideDialog({ unitGuideData, title, actionComponent, courseName, ...rest }: ViewUnitGuideDialogType & BoxProps) {
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    WindowParentUtil.showChatIcon(true);
    WindowParentUtil.showToolbar();
    setOpen(false);
  };

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    onBeforePrint: () => {
      window.history.pushState({}, '', 'Ⓒ RethinkEd. All rights reserved');
    },
    onAfterPrint: () => {
      window.history.back();
    },
    content: () => componentRef.current,
  });

  useEffect(() => {
    return () => {
      WindowParentUtil.showChatIcon(true);
      WindowParentUtil.showToolbar();
    };
  }, []);

  return (
    <>
      <Box
        data-test="view-unit-guide-dialog-action-container"
        {...rest}
        onClick={() => {
          WindowParentUtil.showChatIcon(false);
          WindowParentUtil.hideToolbar();
          setOpen(true);
        }}
      >
        {actionComponent}
      </Box>
      <Dialog
        open={open}
        onClose={handleOnClose}
        maxWidth="xl"
        PaperProps={{
          sx: { width: '100%', height: '100%' },
        }}
        fullScreen
        data-test="view-unit-guide-dialog"
      >
        <DialogTitle variant="h6" sx={{ paddingY: 1 }} data-test="title-container">
          <Stack direction="row" data-test="title" alignItems="center" justifyContent="space-between">
            {title}
            <Stack direction="row" data-test="action-container" gap={2} alignItems="center">
              <Button
                variant="contained"
                data-test="unit-guide-export-pdf"
                onClick={() => {
                  handlePrint();
                }}
              >
                PDF
              </Button>
              <IconButton component="label" onClick={handleOnClose} data-test="close-dialog-action">
                <CloseIcon data-test="close-icon" />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent data-test="content">
          <Box sx={{ maxWidth: '1000px', marginX: 'auto' }}>
            <UnitGuidePage ref={componentRef} courseName={courseName} unitGuideData={unitGuideData} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
