import { BrowserRouter as Router } from 'react-router-dom';
import RouterConfiguration from './routes/RouterConfiguration';
import { Box } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { useApp, useRegisterMedia } from './AppHooks';
import VizzleMainContainer from './ui/container/VizzleMainContainer';

function App() {
  const { queryClientRef } = useApp();
  useRegisterMedia();
  return (
    <Router>
      <QueryClientProvider client={queryClientRef.current}>
        <Box height="100vh">
          <RouterConfiguration />
          <VizzleMainContainer />
        </Box>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
