import { useContext } from 'react';
import { useStore } from 'zustand';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import {
  convertSelectedSubjectToSearchScenario,
} from '../../../../models/ContentLibraryType';
import { useQuery } from '@tanstack/react-query';
import UnitService from '../../../../../../services/UnitService';
import SearchUtils from '../../../../utils/SearchUtils';

export const useSearchUnit = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const searchUnitResultQueryKey = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => ({
    filterView: state.filterView,
    subjectId: state.selectedSubjectId,
    contentLibraries: state.contentLibraries,
    grades: state.grades,
    courseIds: state.courseIds,
    standardCodes: state.standardCodes,
    verticalAlignmentIds: state.verticalAlignmentIds,
    searchText: state.searchText,
    exactMatch: state.exactMatch,
  }));

  const unitSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.unitSearchPageNumber
  );

  const unitSearchResult = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.unitSearchResult);

  const setUnitSearchResult = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.setUnitSearchResult
  );

  const scenario = convertSelectedSubjectToSearchScenario(searchUnitResultQueryKey.subjectId!, searchUnitResultQueryKey.searchText);

  const searchUnitResultQuery = useQuery({
    queryKey: [searchUnitResultQueryKey, unitSearchPageNumber],
    queryFn: async () => {
      if (unitSearchPageNumber === unitSearchResult.meta?.pageNumber) {
        return null;
      }
      const result = await UnitService.searchUnitsByScenario({
        pageNumber: unitSearchPageNumber || 1,
        subjectId: searchUnitResultQueryKey.subjectId!,
        grades: searchUnitResultQueryKey.verticalAlignmentIds && searchUnitResultQueryKey.verticalAlignmentIds.length > 0 ? [] : searchUnitResultQueryKey.grades,
        standardCodes: searchUnitResultQueryKey.standardCodes,
        verticalAlignmentIds: searchUnitResultQueryKey.verticalAlignmentIds,
        courseIds: searchUnitResultQueryKey.verticalAlignmentIds && searchUnitResultQueryKey.verticalAlignmentIds.length > 0 ? [] : searchUnitResultQueryKey.courseIds,
        keywords: SearchUtils.getSearchTextKeyword(searchUnitResultQueryKey.searchText, searchUnitResultQueryKey.exactMatch),
        scenario,
      });
      return result;
    },
    onSuccess: (result) => {
      if (!result) {
        return;
      }
      const newData =
        (result.meta?.pageNumber || 1) > 1
          ? [...unitSearchResult.data, ...(result.data || [])]
          : result.data || [];
      const updatedUnitSearchResult = {
        data: newData,
        included: [...unitSearchResult.included, ...(result.included || [])],
        meta: result.meta,
      };
      setUnitSearchResult(updatedUnitSearchResult);
    },
  });

  return searchUnitResultQuery;
};
