import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

export default function NoActivityLabel() {
  const theme = useTheme();

  return (
    <Stack
      data-test="noactivity-label-container"
      position="fixed"
      top={0}
      left="50%"
      right="50%"
      alignItems="center"
      zIndex={100}
    >
      <Stack
        data-test="noactivity-label-inner-container"
        borderRadius={1}
        sx={{ backgroundColor: theme.palette.secondary.main, paddingY: '10px', paddingX: '20px' }}
      >
        <Typography data-test="noactivity-label-text" fontSize="1.5em" color="white" whiteSpace="nowrap">
          Tell your teacher there are no assignments today
        </Typography>
      </Stack>
    </Stack>
  );
}
