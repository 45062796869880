import { createStore } from 'zustand';
import { FilterLessonStatus } from '../../model/FilterLessonStatus';

const initialState = {
  subjectId: undefined,
  lessonStatus: undefined,
};

type StoreType = {
  subjectId?: number;
  setSubjectId: (subjectId: number) => void;
  lessonStatus?: FilterLessonStatus;
  setLessonStatus: (lessonStatus: FilterLessonStatus) => void;
  reset: () => void;
};

export const createStudentSelfSelectedFilterStore = () => {
  return createStore<StoreType>((set) => ({
    ...initialState,
    setSubjectId: (subjectId: number) => set({ subjectId }),
    setLessonStatus: (lessonStatus: FilterLessonStatus) => set({ lessonStatus }),
    reset: () => set({ ...initialState }),
  }));
};

export type StudentSelfSelectedFilterStore = ReturnType<typeof createStudentSelfSelectedFilterStore> | null;
