import { Box, CircularProgress, Stack, StackProps } from '@mui/material';

type LoadingSpinnerType = {
  message?: string;
  ['data-test']?: string;
  iconSize?: number;
};

const LoadingSpinner = ({ message, iconSize, ...rest }: LoadingSpinnerType & StackProps) => (
  <Stack {...rest} margin="auto" alignItems="center" justifyItems="center">
    <CircularProgress size={iconSize || 40} />
    <Box data-private>{message}</Box>
  </Stack>
);

LoadingSpinner.defaultProps = {
  message: '',
  iconSize: 40,
};

export default LoadingSpinner;
