import { RelationshipData } from '../relationships/Relationship';
import { Standards } from '../standard/StandardType';

export const LessonTypeString = 'lessons';

export type Lesson = {
  meta: Meta;
  type: string;
  id: string;
  attributes: Attributes;
  relationships: Relationships;
  specialtyContent: SpecialtyContent;
};

type Meta = {
  archived?: boolean;
  assigned?: boolean;
  playable?: boolean;
  assignedDate?: number;
  groupPlay?: boolean;
  editable?: boolean;
  associatedLessons?: AssociatedLesson[];
};

type AssociatedLesson = {
  lessonId: number
  uuid: string
  thumbnailUrl: string
  name: string
  submitted: boolean
  lessonLevel: number
  vizzleGold: boolean
}

type Attributes = {
  uuid: string;
  name: string;
  courseId?: number;
  courseName?: string;
  description: string;
  keywords: string;
  gradeLevel: string;
  appName: string;
  appId: number;
  subjects: string[];
  categories: string[];
  subcategories: string[];
  specialtyContentCode: string;
  userName: string;
  createdDate: string;
  updatedDate: string;
  thumbnailUrl: string;
  activities: string[];
  pages: Pages;
  submitted: boolean;
  approvalStatus: string;
  teacherId: number;
  lessonLevel: number;
  topicId?: number;
  topicName?: string;
  unitId?: number;
  unitName?: string;
  libraryLessonFlag: boolean;
  feedbackLoopValue: boolean;
  feedbackLoopEnabled: boolean;
  sp: boolean;
  vizzleGold: boolean;
};

type Pages = {
  Matching: number;
  Game: number;
  Book: number;
  Sorting: number;
};

type Relationships = {
  students: Students;
  standards?: Standards;
  myListTags: MyListTags;
};

type Students = {
  data: RelationshipData[];
};

type MyListTags = {
  data: RelationshipData[];
};

type SpecialtyContent = {
  cardImage: string;
  description: string;
  url: string;
};
