import { useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogActions, DialogProps } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import WindowParentUtil from '../../utils/WindowParentUtil';

type CustomDialogType = {
  open: boolean;
  onClose: () => void;
  title: JSX.Element | string;
  dialogContent: JSX.Element | string;
  displayCloseIcon?: boolean;
  actions?: LoadingButtonProps[];
  onEntered?: () => void;
  fullScreen?: boolean;
};
/**
 * Dialog component.
 */
export default function CustomDialog({
  open,
  onClose,
  title,
  dialogContent,
  displayCloseIcon,
  actions,
  onEntered,
  fullScreen,
  ...dialogProps
}: CustomDialogType & DialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    WindowParentUtil.showChatIcon(!open);
    return () => {
      WindowParentUtil.showChatIcon(true);
    };
  }, [open]);

  return (
    <Dialog
      {...dialogProps}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      fullWidth={false}
      maxWidth="xl"
      fullScreen={fullScreen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      ref={dialogRef}
      TransitionProps={{
        onEntered: () => {
          /* Fix the issues when there are multiple popups on the screen, the input fields
           * on the subsequenc dialogs does not work
           */
          dialogRef?.current?.querySelector('.MuiDialog-container')?.removeAttribute('tabindex');
          if (onEntered) {
            onEntered();
          }
        },
      }}
    >
      {title || displayCloseIcon ? (
        <DialogTitle
          data-test="custom-dialog-title"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          {title}
          {displayCloseIcon ? (
            <IconButton data-test="custom-dialog-close-icon" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : (
            ''
          )}
        </DialogTitle>
      ) : null}

      <DialogContent data-test="custom-dialog-content">{dialogContent}</DialogContent>
      {actions && actions.length > 0 ? (
        <DialogActions>
          {actions.map((action, index) => (
            <LoadingButton key={`action-${index}`} {...action} />
          ))}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
