import { useContext } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useStore } from 'zustand';
import { ContentLibraryType, contentLibraryOptions } from '../../../../models/ContentLibraryType';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { LessonTypeString } from '../../../../../../types/lesson/LessonType';
import { useGetSubjectsGroupByUserComponents } from '../../../../hooks/SubjectGroupByUserComponentsHooks';
import { useRemoveGradesNotInList } from '../../../../hooks/GradeHooks';

export default function ContentLibrarySelector() {
  const contentLibraryOptionsForUserComponents = useGetSubjectsGroupByUserComponents({
    includeMisc: false,
    includeStandardSubjectInClassic: true,
  });
  const contentLibraryOptionsKey = contentLibraryOptionsForUserComponents.map((c) => c.contentLibrary);
  const contentLibraryOptionsForUser = contentLibraryOptionsKey.map((key) => ({
    value: key,
    ...contentLibraryOptions[key],
  }));

  const searchLessonsContext = useContext(SearchLessonsContext);
  const contentLibraries = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.contentLibraries,
  );
  const setContentLibraries = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setContentLibraries,
  );

  const setSelectedSubjectId = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setSelectedSubjectId,
  );

  const setCourseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setCourseIds);

  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const removeGradeNotInList = useRemoveGradesNotInList();

  const selectedAssignmentItems = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.selectedAssignmentItems,
  );

  const setSelectedAssignmentItems = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setSelectedAssignmentItems,
  );

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const valueNumber = Number(value);
    if (valueNumber === ContentLibraryType.vizzleClassic) {
      const updatedAssignmentItems = selectedAssignmentItems.filter((s) => s.type === LessonTypeString);
      setSelectedAssignmentItems(updatedAssignmentItems);
    }

    removeGradeNotInList(valueNumber);

    const values = [valueNumber];
    resetSearchData();
    setContentLibraries(values);
    setCourseIds([]);
    const subjectGroup = contentLibraryOptionsForUserComponents.find((c) => c.contentLibrary === values[0]);
    if (subjectGroup?.subjects[0].subjectId) {
      setSelectedSubjectId(subjectGroup?.subjects[0].subjectId);
    }
  };

  return (
    <FormControl component="fieldset" variant="standard" data-test="content-library-container">
      <Typography variant="subtitle1" data-test="title">
        Content Library
      </Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={contentLibraries[0]}
        onChange={handleChange}
      >
        {contentLibraryOptionsForUser.map((option) => (
          <FormControlLabel
            key={`conent-library-option-${option.fieldName}`}
            data-test={`conent-library-option-${option.fieldName}`}
            control={<Radio />}
            label={option.label}
            value={option.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
