import { Button, Stack, Typography } from '@mui/material';
import { SubjectObjectDetailType } from '../../../../constants/LessonConstant';
import { useContext } from 'react';
import { SearchLessonsContext } from '../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { useMutation } from '@tanstack/react-query';
import SearchService from '../../../../services/SearchService';

type SubjectSelectorSectionType = {
  title: string;
  description: string;
  subjects: SubjectObjectDetailType[];
  onSubjectSelect?: (subject: SubjectObjectDetailType) => void;
};

export default function SubjectSelectorSection({ title, description, subjects, onSubjectSelect }: SubjectSelectorSectionType) {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const setSelectedSubjectId = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setSelectedSubjectId
  );

  const addSearchCriteriaForUserMutation = useMutation({
    mutationKey: ['add-search-criteria-for-user'],
    mutationFn: async (subjectId: number) => {
      await SearchService.addSearchCriteriaForUser({
        subject: subjectId,
        grades: [],
        libraries: [],
      });
    },
  });

  return (
    <Stack data-test='subject-selector-section' width="689px" gap={2} padding={3} style={{ borderRadius: 4, boxShadow: '0px 0px 0px 1px #E0E0E0' }}>
      <Typography data-test='title' variant="h5" fontWeight={500}>
        {title}
      </Typography>
      <Typography data-test='sub-title'>{description}</Typography>

      <Stack data-test='subject-list' direction="row" flexWrap="wrap" gap={3}>
        {subjects.map((subject) => (
          <Button
            variant="roundedBorder"
            color="black"
            data-test={`subject-${subject.subjectId}`}
            key={`subject-${subject.subjectId}`}
            onClick={async () => {
              await addSearchCriteriaForUserMutation.mutateAsync(subject.subjectId);
              setSelectedSubjectId(subject.subjectId);
              if (onSubjectSelect) {
                onSubjectSelect(subject);
              }
            }}
            sx={{
              fontWeight: 400,
            }}
            disabled={addSearchCriteriaForUserMutation.isLoading}
          >
            {subject.name}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}
