import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { studentSettingsUrl } from '../constants/StudentSettingsConst';
import './StudentSettingSidePanel.scss';
import { useShouldEnableVoicesMenu } from '../selected-setting-view/hooks/StudentTtsHooks';

export default function StudentSettingSidePanel() {
  const theme = useTheme();
  const shouldEnableVoiceMenu = useShouldEnableVoicesMenu();
  const menus = [
    {
      label: 'Theme',
      url: studentSettingsUrl.themes,
    },
    {
      label: 'Reinforcers',
      url: studentSettingsUrl.reinforcers,
    },
  ];

  if (shouldEnableVoiceMenu) {
    menus.push({
      label: 'Voices',
      url: studentSettingsUrl.voices,
    });
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 310,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: { width: 310, boxSizing: 'border-box', position: 'inherit' },
      }}
      className="student-setting-side-panel"
    >
      <Box sx={{ overflow: 'auto', paddingBottom: '100%' }}>
        <Toolbar>
          <Typography variant="h6" component="div">
            Settings
          </Typography>
        </Toolbar>
        <List sx={{ marginLeft: '2%', marginRight: '1%', paddingTop: '0px' }}>
          {menus.map((menu, index) => (
            <ListItem key={`menu-${index}`} disablePadding>
              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  color: theme.palette.text.primary,
                }}
                to={menu.url}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <ListItemButton>
                  <ListItemText className="list-item-text" primary={menu.label} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
