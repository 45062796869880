import { Skeleton, Stack } from '@mui/material';
import ItemRowActions from '../../../../../ui/cards/item-row-card/ItemRowActions';
import ItemRowMenuItems from '../../../../../ui/cards/item-row-card/ItemRowMenuItems';
import { useContext } from 'react';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { ItemDetailsActionType } from '../types/ItemDetailsActionType';

function ItemDetailsAction({
  getSelectedData,
  dropdownActions,
  additionalActions,
  isLoading,
  isAbleToAssign,
}: ItemDetailsActionType) {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const setSelectedAssignmentItem = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setSelectedAssignmentItem
  );

  const setOpenActivitiesAssignmentDialog = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setOpenActivitiesAssignmentDialog
  );

  const actions = [...(additionalActions || [])];

  if (isAbleToAssign) {
    actions.push({
      variant: 'rounded',
      'data-test': 'assign-action',
      onClick: () => {
        const data = getSelectedData();
        setSelectedAssignmentItem(data);
        setOpenActivitiesAssignmentDialog(true);
      },
      children: 'Assign',
    });
  }

  if (isLoading) {
    return (
      <Stack direction="row" gap={1} justifyContent="flex-end">
        {actions.map((action, index) => (
          <Skeleton key={`${action['data-test']}-${index}`} variant="rounded" width="100px" height={40} />
        ))}
      </Stack>
    );
  }

  return (
    <Stack alignSelf="flex-end" direction="row" gap={1} data-test="detail-page-action">
      <ItemRowActions actions={actions} />
      <ItemRowMenuItems dropdownActions={dropdownActions} />
    </Stack>
  );
}

ItemDetailsAction.defaultProps = {
  isAbleToAssign: true,
};

export default ItemDetailsAction;
