export enum RETAKE_OPTIONS {
  RETAKE_SINGLE_PLAY = 0,
  RETAKE_MULTIPLE_PLAY = 1,
}

export type ActivityAssignment = {
  userIds: string[];
  lessonIds: string[];
  singlePlay?: boolean;
};

export type ActivityAssignmentDay = {
  userIds: string[];
  startDate?: string | undefined | null;
  endDate?: string | undefined | null;
  days: string[] | undefined | null;
  lessonAssignments: LessonAssignment[];
};

export type LessonAssignment = {
  subjectId: number;
  lessonInfos: LessonInfo[];
};

export type LessonInfo = {
  lId: string;
  status: number;
  seq: number;
};
