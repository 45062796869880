import { Checkbox, Chip, FormControlLabel, Divider } from '@mui/material';
import './StudentTagsForm.scss';
import { Student } from '../../../../../types/student/StudentType';
import { Tag } from '../../../../../types/tag-assignment/TagAssignmentType';

type StudentTagsFormType = {
  student: Student;
  addedTags: Tag[];
  tags: Tag[];
  isSelected: boolean;
  onStudentSelect: (student: Student, isSelected: boolean) => void;
  onTagDelete: (student: Student, tag: Tag) => void;
};

const StudentTagsForm = ({
  student,
  addedTags,
  tags,
  isSelected,
  onStudentSelect,
  onTagDelete,
}: StudentTagsFormType) => {
  const handleOnStudentSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    onStudentSelect(student, e.target.checked);
  };

  return (
    <div className="students-tags-form" data-test={`student-tags-form-${student.id}`}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isSelected}
            onChange={handleOnStudentSelect}
            name={`Select student ${student.firstName} ${student.lastName}`}
            data-test={`student-${student.id}-checkbox`}
          />
        }
        label={
          <span className="student-name-label" data-private>
            {student.firstName} {student.lastName}
          </span>
        }
        data-test="select-student-checkbox-form"
      />
      <div className="assigned-tags" data-test="assigned-tags">
        {addedTags.map((tag) => (
          <Chip
            className="new-tag"
            label={tag.attributes.name}
            key={`new-tags-${student.id}-${tag.attributes.name}`}
            onDelete={() => {
              onTagDelete(student, tag);
            }}
            data-test={`new-tag-${tag.attributes.name}`}
          />
        ))}
        {tags.map((tag) => (
          <Chip
            className="existing-assigned-tag"
            label={tag.attributes.name}
            key={`assign-tags-${student.id}-${tag.attributes.name}`}
            data-test={`assign-tag-${tag.attributes.name}`}
          />
        ))}
      </div>
      <Divider data-test="divider" />
    </div>
  );
};

export default StudentTagsForm;
