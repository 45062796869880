import { Reinforcer } from '../../../../types/user/TerminologiesType';
import { reinforcerObject } from './ReinforcerHooks';

export default {
  playReinforcer: (reinforceId: string) => {
    if (reinforcerObject && reinforcerObject.setReinforceState) {
      reinforcerObject.setReinforceState({
        open: true,
        reinforceId,
        promise: null,
      });
    }
  },
  playRandomReinforcer: (userReinforcers: Reinforcer[] = []) => {
    if (!userReinforcers || userReinforcers.length === 0) {
      return Promise.resolve();
    }
    const reinforcer = userReinforcers[Math.floor(Math.random() * userReinforcers.length)];
    const promise = new Promise<void>((resolve, reject) => {
      if (reinforcerObject && reinforcerObject.setReinforceState) {
        reinforcerObject.setReinforceState({
          open: true,
          reinforceId: reinforcer.id,
          promise: {
            resolve,
            reject,
          },
        });
      }
    });
    return promise;
  },
};
