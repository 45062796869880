import React, { useContext } from 'react';
import { useStore } from 'zustand';
import { Chip } from '@mui/material';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';

export default function StandardSummaryPanel() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const standardCodes = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.standardCodes,
  );
  const setStandardCodes = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setStandardCodes,
  );
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  if (!standardCodes || standardCodes.length === 0) {
    return null;
  }

  return (
    <>
      {standardCodes.map((standardCode) => {
        return (
          <Chip
            key={`standard-code-${standardCode}`}
            data-test={`standard-code-${standardCode}`}
            label={standardCode}
            onDelete={() => {
              const updatedData = standardCodes.filter((s) => s !== standardCode);
              resetSearchData();
              setStandardCodes(updatedData);
            }}
          />
        );
      })}
    </>
  );
}
