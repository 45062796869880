import { TtsModeEnum } from '../../types/tts/TtsType';
import * as RV from './TextToSpeechServiceV1';
import * as AZURE from './TextToSpeechServiceV2';


export const HIGHLIGHT_COLORS = [
  { key: 'Orange', value: '#ef7c22', name: 'Orange' },
  { key: 'Blue', value: '#509fd6', name: 'Blue' },
  { key: 'Green', value: '#78b543', name: 'Green' },
  { key: 'Yellow', value: '#f4f442', name: 'Yellow' },
];

export const TTS_DEFAULT_VALUE = {
  rate: 10,
  pitch: 10,
  volume: 10,
  voice: 0,
  highlightColor: HIGHLIGHT_COLORS[0].value,
  ttsMode: 'azure',
};

export const reigsterTextToSpeechAutoPlay = () => {
  RV.reigsterTextToSpeechAutoPlay();
  AZURE.reigsterTextToSpeechAutoPlay();
};

export default class {

  ttsAssets: typeof RV | typeof AZURE;

  constructor(ttsMode: TtsModeEnum) {
    this.ttsAssets = TtsModeEnum.AZURE === ttsMode ? AZURE : RV;
  }

  getTtsService() {
    return {
      VOICE_TYPES: this.ttsAssets.VOICE_TYPES,
      HIGHLIGHT_COLORS,
      TTS_DEFAULT_VALUE,
      playTextToSpeech: this.ttsAssets.playTextToSpeech,
      cancelTts: this.ttsAssets.cancelTts,
      readText: this.ttsAssets.readText,
      isTtsPlaying: this.ttsAssets.isTtsPlaying,
      reigsterTextToSpeechAutoPlay: this.ttsAssets.reigsterTextToSpeechAutoPlay,
    };
  }
}
