import { StudentSummary } from '../types/student/StudentSummary';
import vizzleAxios from './service';

export default {
  getStudentGroups: async () => {
    const url = '/groups/students';
    const result = await vizzleAxios.get(url);
    return result;
  },

  getStudentLessonsSummary: async (): Promise<StudentSummary> => {
    const url = '/users/lessons/summary/current';
    const result = await vizzleAxios.get(url);
    return result.data;
  },
};
