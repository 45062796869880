/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppBar, Box, Divider, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

type FormPanelType = {
  title?: string | JSX.Element;
  content: string | JSX.Element;
  bottomBar?: JSX.Element;
  displayCloseIcon: boolean;
  onClose: () => void;
  disableClose?: boolean;
  'data-test': string;
};

export default function FormPanel({
  onClose,
  title,
  content,
  displayCloseIcon,
  bottomBar,
  disableClose,
  ...rest
}: FormPanelType) {
  const theme = useTheme();
  return (
    <Stack direction="column" height="100%" data-test={`form-container-${rest['data-test']}`}>
      {title && (
        <AppBar
          color="primary"
          sx={{
            boxShadow: '0px 0px 0px 1px #E0E0E0',
          }}
          data-test="title-bar"
          position="relative"
        >
          <Box paddingX={2} paddingY={1} position="relative">
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            {displayCloseIcon ? (
              <Box position="absolute" right={2} top={2}>
                <IconButton data-test="close-button" aria-label="close" onClick={onClose} disabled={disableClose}>
                  <CloseIcon htmlColor={theme.palette.primary.contrastText} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </AppBar>
      )}
      <Box data-test="body" flexGrow={1} overflow="auto">
        {content}
      </Box>
      {bottomBar && (
        <>
          <Divider></Divider>
          <Box data-test="bottom-bar" minHeight={40} justifySelf="center" padding={1}>
            {bottomBar}
          </Box>
        </>
      )}
    </Stack>
  );
}
