import { Chip, Skeleton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ItemInformationType } from '../types/ItemInformationType';
import { Link } from 'react-router-dom';

export default function ItemInformation({
  isLoading,
  breadcrumbs,
  title,
  titleChips,
  description,
}: ItemInformationType) {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack gap={1}>
        <Skeleton variant="rounded" width="100%" sx={{ marginBottom: 2 }} />
        <Skeleton variant="rounded" width="100%" />
        <Skeleton variant="rounded" width="100%" height={120} sx={{ marginTop: 3 }} />
      </Stack>
    );
  }
  return (
    <Stack data-test="item-information-container" gap={0.5}>
      <Stack gap={1} data-test="breadcrumb-title-container">
        {breadcrumbs && breadcrumbs.length > 0 ? (
          <Stack direction="row" alignItems="center" gap={1} data-test="breadcrumb-title-inner-container">
            {breadcrumbs.map((breadcrumb, index) => (
              <Stack
                direction="row"
                alignItems="center"
                key={`${breadcrumb}-${index}`}
                sx={{
                  cursor: 'pointer',
                  flexWrap: 'nowrap',
                }}
                data-test={`breadcrumb-${index}`}
              >
                <Link
                  className="MuiTypography-root MuiTypography-body2"
                  to={breadcrumb.href}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography
                    variant="body2"
                    data-test={`${breadcrumb.text}-${index}`}
                    color={theme.palette.textSecondary.main}
                    whiteSpace="nowrap"
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {breadcrumb.text}
                  </Typography>
                </Link>
                {index <= breadcrumbs.length - 2 ? (
                  <Typography
                    data-test={`${breadcrumb}-separator-${index}`}
                    color={theme.palette.textSecondary.main}
                    marginLeft={1}
                  >
                    {'>'}
                  </Typography>
                ) : null}
              </Stack>
            ))}
          </Stack>
        ) : null}
        <Typography variant="h5" data-test="title" sx={{ marginBottom: 1 }}>
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" data-test="chips-container" gap={0.5}>
        {titleChips
          .filter((t) => Boolean(t))
          .map((titleChip, index) => (
            <Chip
              key={`chip-${index}`}
              sx={{ fontWeight: 400, color: theme.palette.textSecondary.main }}
              label={titleChip}
              variant="outlined"
            />
          ))}
      </Stack>
      {description ? (
        <Typography data-test="description" sx={{ marginTop: 2, lineHeight: '1.5rem' }}>
          {description}
        </Typography>
      ) : null}
    </Stack>
  );
}
