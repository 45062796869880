import { useState } from 'react';
import { TextField } from '@mui/material';
import { Lesson } from '../../../types/lesson/LessonType';
import CustomDialog from '../../../ui/dialog/CustomDialog';
import { useMutation } from '@tanstack/react-query';
import LessonService from '../../../services/LessonService';
import { SNACKBAR_SEVERITY } from '../../../types/ui/VizzleSnackbar';
import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';
import appUiStore from '../../../stores/AppUiStore';

type CopyToEditDialogType = {
  lesson: Lesson;
  open: boolean;
  onClose: () => void;
};

export default function CopyToEditDialog({ open, onClose, lesson }: CopyToEditDialogType) {
  const [lessonName, setLessonName] = useState(lesson.attributes.name);
  const user = useStore(useUserStore, (state) => state.user);
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  const copyLessonMutation = useMutation({
    mutationKey: ['copy-lesson-mutation'],
    mutationFn: async () => {
      LessonService.copyLesson(lessonName, lesson.attributes.uuid, user!.id);
      openSnackbar({
        severity: SNACKBAR_SEVERITY.SUCCESS,
        open: true,
        title: 'Copy Complete!',
        message: 'The copied activity is available under My Resources for editing',
        duration: 3000,
      });
    },
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <CustomDialog
      data-test="copy-to-edit-dialog"
      open={open}
      onClose={onClose}
      title="Enter a new title for your lesson"
      displayCloseIcon={!copyLessonMutation.isLoading}
      dialogContent={
        <TextField
          onChange={(e) => {
            setLessonName(e.target.value);
          }}
          label="Activity Title"
          fullWidth
          value={lessonName}
          inputProps={{
            maxLength: 255,
          }}
          sx={{
            width: '470px',
            marginTop: 1,
            marginBottom: 2,
          }}
        />
      }
      actions={[
        {
          children: 'Close',
          size: 'large',
          variant: 'roundedBorder',
          color: 'primary',
          sx: {
            width: '100px',
            marginRight: 1,
          },
          onClick: () => {
            onClose();
          },
          loading: copyLessonMutation.isLoading,
        },
        {
          children: 'OK',
          size: 'large',
          variant: 'rounded',
          color: 'primary',
          sx: {
            width: '100px',
          },
          onClick: () => {
            copyLessonMutation.mutate();
          },
          loading: copyLessonMutation.isLoading,
        },
      ]}
    />
  );
}
