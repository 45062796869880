import { Standard } from '../types/standard/StandardType';
import vizzleAxios from './service';

export default {
  getStandardListByCriteria: async (subject: number, grades: string[], courseIds: number[]) => {
    const url = '/standards';
    const result = await vizzleAxios.get(url, {
      params: {
        subject: subject,
        grades: grades.join(','),
        courseIds: courseIds.join(','),
      },
    });
    return result.data.data;
  },

  getStandardByTopicAndState: async (topicIds: string[], state: string): Promise<Standard[]> => {
    const url = '/topics/standards';
    const result = await vizzleAxios.get(url, {
      params: {
        ids: topicIds.join(','),
        state,
      },
    });
    return result.data.data;
  },

  getStandardByUnitAndState: async (unitIds: string[], state: string): Promise<Standard[]> => {
    const url = '/topics/standards';
    const result = await vizzleAxios.get(url, {
      params: {
        ids: unitIds.join(','),
        state,
      },
    });
    return result.data.data;
  },
  getVerticalAlignmentByStandardCode: async (standardCode: string, subjectId: number): Promise<Standard[]>=> {
    const url = `/standards/${standardCode}/vertical?subject=${subjectId}`;
    const result = await vizzleAxios.get(url);
    return result.data.data;
  }
};
