import React, { useContext } from 'react';
import { useStore } from 'zustand';
import { Chip } from '@mui/material';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';

export default function VerticalAlignmentSummaryPanel() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const verticalAlignmentIds = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.verticalAlignmentIds,
  );
  const setVerticalAlignmentIds = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setVerticalAlignmentIds,
  );
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  if (!verticalAlignmentIds || verticalAlignmentIds.length === 0) {
    return null;
  }

  return (
    <>
      {verticalAlignmentIds.map((verticalAlignmentId) => {
        return (
          <Chip
            key={`vertical-alignment-${verticalAlignmentId}`}
            data-test={`vertical-alignment-${verticalAlignmentId}`}
            label={verticalAlignmentId}
            onDelete={() => {
              const updatedData = verticalAlignmentIds.filter((v) => v !== verticalAlignmentId);
              resetSearchData();
              setVerticalAlignmentIds(updatedData);
            }}
          />
        );
      })}
    </>
  );
}
