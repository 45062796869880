import { palleteTheme } from './components/PalleteTheme';
import { buttonTheme } from './components/ButtonTheme';
import { experimental_extendTheme } from '@mui/material';
import { outlinedInputTheme } from './components/InputTheme';
import { toggleButtonGroupTheme, toggleButtonTheme } from './components/ToggleButtonTheme';
import { alertTheme } from './components/AlertTheme';
import '../../styles/fonts.scss';
import { dialogTheme } from './components/DialogTheme';
import { formControlLabelTheme } from './components/FormControlLabel';

export const defaultTheme = experimental_extendTheme({
  colorSchemes: palleteTheme,
  typography: {
    fontFamily: 'Rubik',
    allVariants: {
      lineHeight: undefined,
    },
    subtitle1: {
      fontSize: '1em',
      fontWeight: 500,
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
  },

  components: {
    MuiButton: buttonTheme,
    MuiOutlinedInput: outlinedInputTheme,
    MuiToggleButton: toggleButtonTheme,
    MuiToggleButtonGroup: toggleButtonGroupTheme,
    MuiAlert: alertTheme,
    MuiDialog: dialogTheme,
    MuiFormControlLabel: formControlLabelTheme,
  },
});
