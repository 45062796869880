import { useEffect } from 'react';
import LandingStudent from '../../student/LandingStudent';
import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';

export default function LandingStudentInterimPage() {
  const setUserProfile = useStore(useUserStore, (state) => state.setUserProfile);

  useEffect(() => {
    const register = (e: MessageEvent) => {
      // Get the sent data
      const data = e.data;
      if (data.event === 'update-user-profile' && data.data && setUserProfile) {
        setUserProfile(data.data);
      }
    };

    window.addEventListener('message', register);

    return () => {
      window.removeEventListener('message', register);
    };
  }, []);

  return <LandingStudent />;
}
