import { Dialog, DialogProps } from '@mui/material';
import { ActivitiessAssignmentType } from './models/ActivitiesAssigmentType';
import ActivitiesAssignment from './ActivitiesAssignment';
import WindowParentUtil from '../../utils/WindowParentUtil';
import { useEffect } from 'react';

type ActivitiesAssignmenDialog = {
  open: boolean;
  onDialogClose: () => void;
  onSaveTagsAssignmentSuccess: () => void;
  activitiesAssignment: ActivitiessAssignmentType;
  onCustomTagCancel?: () => void;
};
export default function ActivitiesAssignmenDialog({
  open,
  onDialogClose,
  activitiesAssignment,
  onSaveTagsAssignmentSuccess,
  onCustomTagCancel,
  ...rest
}: ActivitiesAssignmenDialog & DialogProps) {

  useEffect(() => {
    WindowParentUtil.showChatIcon(!open);
    return () => {
      WindowParentUtil.showChatIcon(true);
    };
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      maxWidth="xl"
      PaperProps={{
        sx: { width: '100%', height: '100%' },
      }}
      fullScreen
      TransitionProps={{
        onEntered: () => {
          WindowParentUtil.showChatIcon(false);
        },
        onExited: () => {
          WindowParentUtil.showChatIcon(true);
        },
      }}
    >
      <ActivitiesAssignment
        activitiesAssignment={activitiesAssignment}
        onClose={onDialogClose}
        onSaveTagsAssignmentSuccess={onSaveTagsAssignmentSuccess}
        onCustomTagCancel={onCustomTagCancel}
      />
    </Dialog>
  );
}
