import { UserProfile } from '../types/user/UserProfileType';
import vizzleAxios from './service';

export default {
  updateUserProfile: async (id: number, userProfile: UserProfile) => {
    const url = `/user/profile/${id}`;
    const result = await vizzleAxios.post(url, userProfile);
    return result;
  },
};
