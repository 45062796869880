import { Stack } from '@mui/material';
import GradesSummaryPanel from './GradesSummaryPanel';
import ClearAllCriteriaAction from './ClearAllCriteriaAction';
import CoursesSummaryPanel from './CoursesSummaryPanel';
import VerticalAlignmentSummaryPanel from './VerticalAlignmentSummaryPanel';
import StandardSummaryPanel from './StandardSummaryPanel';

export default function CriteriaSummaryPanel() {
  return (
    <Stack marginTop={2} marginBottom={1} direction="row" gap={1} flexWrap="wrap" data-test="criteria-summary-panel">
      <GradesSummaryPanel />
      <CoursesSummaryPanel />
      <StandardSummaryPanel />
      <VerticalAlignmentSummaryPanel />
      <ClearAllCriteriaAction />
    </Stack>
  );
}
