import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import TopicService from '../../../../services/TopicService';
import ItemDetails from '../../ui/item-details/ItemDetails';
import { SearchLessonsContext, useGetRecordType } from '../../SearchLessonsPageHooks';
import LessonStandardService from '../../../../services/LessonStandardService';
import { useStore } from 'zustand';
import { useUserStore } from '../../../../stores/UserStore';
import { FULL_SORTED_SUBJECT_CONSTANTS } from '../../../../constants/LessonConstant';
import { SEARCH_LESSONS_SUB_URL } from '../../SearchLessonsConstant';
import { APP_URL } from '../../../../routes/RouteConstants';
import LessonService from '../../../../services/LessonService';
import { Topic } from '../../../../types/topic/TopicType';
import { TopicTypeString } from '../../../../types/topic/SearchTopicResultType';
import { useGetTopicsDropdownMenu } from '../../common-actions/TopicsActions';
import { useContext, useState } from 'react';
import { Lesson } from '../../../../types/lesson/LessonType';
import { getSubjectOrCourseNameForTopicAndUnit } from '../../hooks/SubjectGroupByUserComponentsHooks';

export default function TopicDetails() {
  const { topicId } = useParams();
  const user = useStore(useUserStore, (state) => state.user);

  const topicDetailQuery = useQuery({
    queryKey: ['topic-detail', topicId],
    queryFn: async () => {
      if (!topicId) {
        return;
      }
      const result = await TopicService.getTopicDetail(topicId);
      return result;
    },
  });

  const topicStandardQuery = useQuery({
    queryKey: ['topic-standard', topicId],
    queryFn: async () => {
      if (!topicId) {
        return;
      }
      const result = await LessonStandardService.getStandardByTopicAndState([topicId], user!.state);
      return result;
    },
  });

  const topicOthersQuery = useQuery({
    queryKey: ['topic-others', topicId],
    queryFn: async () => {
      if (!topicId) {
        return;
      }
      const result = await TopicService.getOtherTopics(topicId);
      return result;
    },
  });

  const searchLessonsContext = useContext(SearchLessonsContext);

  const setLessonLevel = useStore(searchLessonsContext!.searchLessonsSelectionStore!, (state) => state.setLessonLevel);

  const navigate = useNavigate();

  const { getRecordType } = useGetRecordType();

  const [lessonList, setLessonList] = useState<{
    level: string;
    lessons: Lesson[];
  }>();

  const { getTopicsDropdownMenu } = useGetTopicsDropdownMenu();

  const topicDetail = topicDetailQuery.data;

  const subject = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.subjectId === topicDetail?.subjectId);

  return (
    <ItemDetails 
      data-test="topic-details"
      itemInformation={{
        isLoading: topicDetailQuery.isLoading,
        breadcrumbs: topicDetail?.unitName
          ? [
              {
                text: `Unit ${topicDetail?.unitName}`,
                href: `${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.unitDetails}/${topicDetail.unitId}`,
              },
            ]
          : undefined,
        title: `Topic ${topicDetail?.name}`,
        titleChips: [
          `${topicDetail?.grade} Grade`, 
          getSubjectOrCourseNameForTopicAndUnit(
            {attributes: {gradeLevel: topicDetail?.grade, courseName: topicDetail?.courseName, subject: subject?.name}}
          ),
          getRecordType(TopicTypeString)
        ],
        description: topicDetail?.description,
      }}
      resourceMaterial={{
        isLoading: topicDetailQuery.isLoading,
        courseName: topicDetail?.courseName || '',
        lessonPlan: topicDetail?.lessonPlan,
        unitGuide: topicDetail?.unitGuide,
        observationalScoring: 'observational_scoring/Observational Scoring Worksheet.pdf',
      }}
      stateStandards={{
        isLoading: topicStandardQuery.isLoading,
        standards: topicStandardQuery.data || [],
      }}
      extensionActivity={{
        isLoading: topicDetailQuery.isLoading,
        extensionActivities: topicDetail?.extensionActivities || [],
      }}
      othersSection={{
        isLoading: topicOthersQuery.isLoading,
        sectionTitle: 'Other Topics From This Unit',
        data:
          topicOthersQuery?.data?.map((d) => ({
            id: d.id || '',
            title: `Topic ${d.name}`,
            subTitles: [[`Unit: ${d.unitName || ''}`], [subject?.name || '', `${d.gradeBand || ''} Grade`]],
            image: d.thumbnailUrl,
          })) || [],
        onDetailClick: (id) => {
          navigate(`${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.topicDetails}/${id}`);
        },
      }}
      lessonList={{
        id: topicId!,
        displayLevelSelector: true,
        goToLessonDetail: true,
        getLessonsFunction: async (level: string) => {
          if (!topicId || !level) {
            return [];
          }
          const result = await LessonService.getLessonsByTopicIdAndLevel({ topicId, level });
          setLessonList({
            level,
            lessons: result.data,
          });
          setLessonLevel(level);
          return result.data;
        },
      }}
      itemDetailsAction={{
        isLoading: topicDetailQuery.isLoading,
        getSelectedData: () => {
          const topic: Topic = {
            id: String(topicDetail!.id!),
            type: TopicTypeString,
            attributes: {
              name: topicDetail!.name,
              unitName: topicDetail!.unitName,
              gradeLevel: topicDetail!.grade,
              subject: subject?.name,
            },
          };

          return topic;
        },
        dropdownActions: getTopicsDropdownMenu(lessonList?.lessons || []),
      }}
    />
  );
}
