import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { ActivitiesAssignmentContext } from '../../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import { useTheme } from '@mui/material/styles';
import { useStudentStore } from '../../../../../../stores/StudentStore';
import StudentAndGroupMultiSelectorInput from './StudentAndGroupMultiSelectorInput';

export default function StudentAndGroupSelector() {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);

  const studentGroupData = activitiesAssignment?.studentGroupData;
  const students = useStore(useStudentStore, (state) => state.students);

  const allStudentIds = useMemo(() => {
    const ids = students?.map((student) => String(student.id));
    return [...new Set(ids)];
  }, [students]);

  const theme = useTheme();

  const selectedGroupIds = useStore(activitiesAssignmentContext!, (state) => state.selectedGroupIds);
  const selectedStudentIds = useStore(activitiesAssignmentContext!, (state) => state.selectedStudentIds);
  const setSelectedGroupIds = useStore(activitiesAssignmentContext!, (state) => state.setSelectedGroupIds);
  const setSelectedStudentIds = useStore(activitiesAssignmentContext!, (state) => state.setSelectedStudentIds);
  const filterStudentsAndGroupText = useStore(
    activitiesAssignmentContext!,
    (state) => state.filterStudentsAndGroupText
  );

  const filteredStudentGroupData = studentGroupData?.filter((group) => group.name.toLocaleLowerCase().indexOf(filterStudentsAndGroupText.toLocaleLowerCase()) >= 0) || [];
  const filteredStudent = students?.filter((student) => `${student.firstName} ${student.lastName}`.toLocaleLowerCase().indexOf(filterStudentsAndGroupText.toLocaleLowerCase()) >= 0) || [];
  const currentStudent = activitiesAssignment?.currentStudent;

  const handleSelectChange = (id: string, list: string[]) => {
    let updatedListIds = [...list];
    if (updatedListIds.includes(id)) {
      updatedListIds = updatedListIds.filter((s) => s !== id);
    } else {
      updatedListIds.push(id);
    }
    return updatedListIds;
  };

  const getSelectAllStudentFlag = () => {
    const uniqueSelectedStudentIds = [...new Set(selectedStudentIds)];
    return uniqueSelectedStudentIds.length === allStudentIds.length;
  };

  const handleOnAllStudentChange = () => {
    if (selectedStudentIds.length >= 0 && selectedStudentIds.length < allStudentIds.length) {
      setSelectedStudentIds([...allStudentIds]);
    } else {
      setSelectedStudentIds([]);
    }
  };

  return (
    <Stack
      data-test="student-and-group-selector"
      direction="column"
      paddingTop={2}
      paddingBottom={1}
      boxSizing="border-box"
      height="100%"
      gap={1}
    >
      <Typography data-test="title" variant="subtitle1">
        Select Students
      </Typography>
      <StudentAndGroupMultiSelectorInput />

      <Stack
        // border={1}
        padding={1}
        // borderColor={theme.palette.divider}
        borderRadius={1}
        gap={2}
        direction="column"
        position="relative"
        height="100%"
        overflow="auto"
        style={{ overflowX: 'hidden', boxShadow: '0px 0px 0px 1px #E0E0E0' }}
        data-test="student-and-group-outer-container"
      >
        <Stack position="absolute" gap={1} width="100%" data-test="student-and-group-container">
          {studentGroupData && studentGroupData.length ? (
            <Stack data-test="group-selector-container">
              <Typography data-test="title" variant="subtitle1" color={theme.palette.text.disabled}>
                GROUPS
              </Typography>
              <Box data-test="student-group-container">
                <FormGroup data-test="student-group-form-group">
                  {filteredStudentGroupData?.map((group) => (
                    <FormControlLabel
                      key={`group-${group.id}`}
                      data-test={`form-control-label-group-${group.id}`}
                      control={
                        <Checkbox
                          data-test={`form-control-label-checkbox-${group.id}`}
                          checked={selectedGroupIds.includes(String(group.id))}
                          onChange={(e) => {
                            const result = handleSelectChange(String(group.id), selectedGroupIds);
                            setSelectedGroupIds(result);

                            if (e.target.checked) {
                              const updatedSelectedStudentIds = [
                                ...selectedStudentIds,
                                ...group.students.map((s) => String(s)),
                              ];
                              setSelectedStudentIds([...new Set(updatedSelectedStudentIds)]);
                            } else {
                              const groups = result.map((r) => studentGroupData.find((s) => s.id === Number(r)));
                              const studentsInGroup = groups.flatMap((g) => g?.students);
                              const updatedSelectedStudentIds = selectedStudentIds.filter(
                                (s) => !group.students.includes(Number(s)) || studentsInGroup.includes(Number(s))
                              );
                              setSelectedStudentIds([...new Set(updatedSelectedStudentIds)]);
                            }
                          }}
                        />
                      }
                      label={group.name}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Stack>
          ) : null}

          {students && students.length ? (
            <Stack data-test="student-selector-container">
              <Typography data-test="title" variant="subtitle1" color={theme.palette.text.disabled}>
                STUDENTS
              </Typography>

              <FormGroup data-test="student-select-all-form-group">
                <FormControlLabel
                  data-test="student-select-all-control-label"
                  control={<Checkbox checked={getSelectAllStudentFlag()} onChange={handleOnAllStudentChange} />}
                  label="Select All Students"
                />
              </FormGroup>
              <FormGroup data-test="student-form-group">
                {filteredStudent.map((student) => (
                  <FormControlLabel
                    key={`student-${student.id}`}
                    data-test={`form-control-label-student-${student.id}`}
                    disabled={currentStudent?.id === student.id}
                    control={
                      <Checkbox
                        data-test={`form-control-label-checkbox-${student.id}`}
                        checked={selectedStudentIds.includes(String(student.id))}
                        onChange={(e) => {
                          const result = handleSelectChange(String(student.id), selectedStudentIds);
                          setSelectedStudentIds(result);

                          if (e.target.checked) {
                            const groups = studentGroupData?.filter((group) => group.students.every(student => result.includes(String(student))));
                            const groupIds = groups?.map((g) => String(g.id));
                            setSelectedGroupIds(groupIds || []);
                          } else {
                            const groups = studentGroupData?.filter((group) => group.students.includes(student.id));
                            const groupIds = groups?.map((group) => group.id);
                            const updatedSelectedGroupIds = selectedGroupIds.filter(
                              (s) => !groupIds?.includes(Number(s))
                            );
                            setSelectedGroupIds(updatedSelectedGroupIds);
                          }
                        }}
                      />
                    }
                    label={`${student.firstName} ${student.lastName}`}
                  />
                ))}
              </FormGroup>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}
