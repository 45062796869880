import { useState } from 'react';
import PdfService from '../services/PdfService';
import { AxiosError } from 'axios';

export const useCheckPdf = (pdfKey: string) => {
  const [state, setState] = useState<{
    loading: boolean;
    signedPdfUrl: string | null;
    errorMessage: string | null;
  }>({
    loading: false,
    signedPdfUrl: '',
    errorMessage: '',
  });

  const checkPdfExistings = async () => {
    try {
      setState({
        loading: true,
        signedPdfUrl: null,
        errorMessage: null,
      });

      const result = await PdfService.checkPdfLink(pdfKey);
      if (result.data) {
        setState({
          loading: false,
          signedPdfUrl: result.data,
          errorMessage: null,
        });
      }
    } catch (error) {
      let message = 'An error occur while reading the pdf.';
      if (error instanceof AxiosError) {
        message = error?.response?.data;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setState({
        loading: false,
        signedPdfUrl: null,
        errorMessage: message,
      });
    }
  };

  // useEffect(() => {
  //   if (pdfKey) {
  //     checkPdfExistings();
  //   } else {
  //     setState({
  //       loading: false,
  //       signedPdfUrl: '',
  //     });
  //   }
  // }, [pdfKey]);

  return { state, checkPdfExistings };
};
