import { LessonDetail } from '../types/lesson/LessonDetailType';
import { ContentLibraryScenary, LessonResult, SearchLessonResult } from '../types/lesson/SearchLessonResultType';
import StandardUtils from '../utils/StandardUtils';
import vizzleAxios from './service';

export default {
  assignLesson: async (lessonIds: string[], userId: number, fromStudentId?: number) => {
    let url = `/users/${userId}/lessons?operation=add`;
    if (fromStudentId) {
      url = `${url}&fromStudent=${fromStudentId}`;
    }
    const result = await vizzleAxios.post(url, { lessonIds });
    return result;
  },

  searchLessonsByScenario: async ({
    pageNumber,
    searchNode,
    subjectId,
    grades,
    standardCodes,
    verticalAlignmentIds,
    courseIds,
    keywords,
    scenario,
    ignoreTopicLessons,
  }: {
    pageNumber: number;
    searchNode: string;
    subjectId: number | string;
    grades: string[];
    standardCodes: string[];
    verticalAlignmentIds: string[];
    courseIds: number[],
    keywords: string;
    scenario?: ContentLibraryScenary;
    ignoreTopicLessons?: boolean;
  }): Promise<SearchLessonResult> => {
    const url = '/lessons';
    const standards = StandardUtils.getStandardCodeOrVerticalAlignmentIds(standardCodes, verticalAlignmentIds);
    const result = await vizzleAxios.get(url, {
      params: {
        'page[number]': pageNumber || 1,
        scenario,
        'filter[subjectId]': subjectId || undefined,
        'filter[gradeLevel]': grades && grades.length > 0 ? grades.join(',') : null,
        'filter[standardCode]': standards && standards.length > 0 ? standards.join(',') : null,
        'filter[courseId]': courseIds && courseIds.length > 0 ? courseIds.join(',') : null,
        'filter[ignoreTopicLessons]': ignoreTopicLessons,
        keywords,
        searchNode,
      },
    });
    return result.data;
  },

  getLessonDetail: async (lessonId: string): Promise<LessonDetail> => {
    const url = `/lessons/${lessonId}/details`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },

  getLessonsByTopicIdAndLevel: async ({
    topicId,
    level,
  }: {
    topicId: string;
    level: string;
  }): Promise<LessonResult> => {
    const url = `/topics/${topicId}/lessons/${level}`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },

  getLessonsByUnitIdAndLevel: async ({ unitId, level }: { unitId: string; level: string }): Promise<LessonResult> => {
    const url = `/units/${unitId}/lessons/${level}`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },

  copyLesson: async (lessonTitle: string, lessonUuid: string, fromStudentId?: string | number) => {
    let url = `/lessons/${lessonUuid}/copy/authoring`;
    if (fromStudentId) {
      url = `${url}?fromStudent=${fromStudentId}`;
    }
    const result = await vizzleAxios.post(url, { title: lessonTitle });
    return result;
  },
};
