import vizzleAxios from './service';

let lastGetTokenTime: Date | null = null;
let tokenCache: string = '';

export default {
  getSpeechToken: async () => {
    const endTime = new Date();

    if (tokenCache && lastGetTokenTime) {
      const difference = endTime.getTime() - lastGetTokenTime.getTime(); // This will give difference in milliseconds
      const resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes < 10) {
        return tokenCache;
      }
    }
    const url = '/terminologies/speechtoken';
    const result = await vizzleAxios.get(url);
    tokenCache = result.data;
    lastGetTokenTime = new Date();
    return tokenCache;
  },
};
