import { createStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Student } from '../types/student/StudentType';

// define the initial state
const initialState = {
  students: undefined,
};

const studentStore = persist<{
  students?: Student[];
  setStudents?: (students: Student[]) => void;
  resetStore?: () => void;
}>(
  (set) => ({
    ...initialState,
    setStudents: (students: Student[]) =>
      set({
        students,
      }),
    resetStore: () => set(initialState),
  }),
  {
    name: 'students-store', // name of item in the storage (must be unique)
    partialize: (state) => ({
      students: state.students,
    }),
    storage: createJSONStorage(() => sessionStorage), // (optional) by default the 'localStorage' is used
  }
);

export const useStudentStore = createStore(studentStore);
