import { Topic } from './TopicType';

export const TopicTypeString = 'topics';

export type SearchTopicResult = {
  data: Topic[];
  included: Included[];
  meta?: Meta;
};

type Included = {
  type: string;
  id: string;
  attributes: Attributes;
};

type Attributes = {
  standardId: number;
  standardCode: string;
  standardGrade: string;
  standardDescription: string;
  scope: string;
};

type Meta = {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
};
