import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';

type DateRangeTextFieldType = {
  title: string;
  value: string;
  isError?: boolean;
  helperText?: string;
  onClick: () => void;
  'data-test': string;
  textFieldProps?: TextFieldProps;
};
export default function DateRangeTextField({
  title,
  value,
  isError,
  helperText,
  onClick,
  textFieldProps,
  ...rest
}: DateRangeTextFieldType) {
  const theme = useTheme();
  return (
    <Stack gap={1} width="100%">
      <Typography data-test={`title-${rest['data-test']}`} variant="subtitle1">
        {title}
      </Typography>
      <TextField
        value={value}
        onClick={onClick}
        inputProps={{
          readOnly: true,
        }}
        variant="outlined"
        InputProps={{
          endAdornment: <ArrowDropDownIcon htmlColor={theme.palette.grey[600]} />,
        }}
        error={isError}
        helperText={helperText}
        data-test={`input-${rest['data-test']}`}
        {...textFieldProps}
      />
    </Stack>
  );
}
