import { CardActionArea, Skeleton, Stack } from '@mui/material';
// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { LessonListType } from '../types/LessonList';
import ItemRowCard from '../../../../../ui/cards/item-row-card/ItemRowCard';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import DropdownInput from '../../../../../ui/selector/DropdownInput';
import { LEVEL_OPTIONS } from '../../../../../constants/LevelConstant';
import WindowParentUtil from '../../../../../utils/WindowParentUtil';
import { useNavigate } from 'react-router-dom';
import { SEARCH_LESSONS_SUB_URL } from '../../../SearchLessonsConstant';
import { APP_URL } from '../../../../../routes/RouteConstants';
import SelectLessonLevelDialog from './SelectLessonLevelDialog';
import mockedLessonData from '../../../../../mock-data/mocked-lessons-data.json';
import { Lesson } from '../../../../../types/lesson/LessonType';

export default function LessonList({
  id,
  displayLevelSelector,
  getLessonsFunction,
  goToLessonDetail,
}: LessonListType) {
  const [level, setLevel] = useState('');
  const [hasSelected, setHasSelected] = useState(false);

  const lessonsInTopicQuery = useQuery({
    queryKey: ['lesson-details-lesson-list', level, id],
    queryFn: async () => {
      const result = await getLessonsFunction(level);
      return result;
    },
  });

  const lessons = lessonsInTopicQuery.data;

  const navigate = useNavigate();

  // if (displayLevelSelector && !hasSelected) {
  //   return (
  //     <SelectLessonLevelDialog setLevel={(level: string) => {
  //       setLevel(level);
  //       setHasSelected(true);
  //     }} />
  //   );
  // }

  const displaySelectorPopup = displayLevelSelector && !hasSelected;

  const getLessonList = (lessonsData?: Lesson[]) => {
    return (lessonsData || []).map((lesson) => (
      <CardActionArea
        sx={{ filter: `blur(${!displayLevelSelector || hasSelected ? 0 : '2px'})` }}
        component="div"
        key={`row-${lesson.id}`}
        disabled={displaySelectorPopup}
        onClick={() => {
          if (goToLessonDetail) {
            navigate(`${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.activitiesDetails}/${lesson.id}`);
          }
        }}
      >
        <ItemRowCard
          key={`row-${lesson.id}`}
          id={String(lesson.id)}
          title={lesson.attributes.name}
          image={lesson.attributes.thumbnailUrl}
          imageHeight="55px"
          imageWidth="82px"
          actions={[
            {
              variant: 'roundedBorder',
              'data-test': 'activity-play-action',
              onClick: (e) => {
                e.stopPropagation();
                WindowParentUtil.playLesson(lesson!, () => {});
              },
              children: 'Teach',
            },
            {
              variant: 'roundedBorder',
              'data-test': 'activity-preview-action',
              onClick: (e) => {
                e.stopPropagation();
                WindowParentUtil.previewLesson(lesson!);
              },
              children: 'Preview',
            },
            // {
            //   isIconButton: true,
            //   'data-test': `lesson-review-lesson-id${lesson.id}`,
            //   children: <PlayCircleOutlineIcon />,
            //   onClick: (e) => {
            //     e.stopPropagation();
            //     WindowParentUtil.previewLesson(lesson);
            //   },
            // },
          ]}
        />
      </CardActionArea>
    ));
  };

  return (
    <Stack direction="column" position="relative" gap={1} data-test="lesson-list-item-row-container">
      {displayLevelSelector && hasSelected && (
        <DropdownInput
          labelProps={{ variant: 'subtitle1' }}
          inlineLabel="Select a level to view/assign"
          dropdownValues={LEVEL_OPTIONS}
          value={level}
          onChange={(e) => {
            const { value } = e.target;
            setLevel(value);
          }}
          data-test="level-selector"
        />
      )}

      {lessonsInTopicQuery.isLoading && (
        <Stack direction="column" gap={1}>
          <Skeleton variant="rounded" width="100%" height={60} />
          <Skeleton variant="rounded" width="100%" height={60} />
          <Skeleton variant="rounded" width="100%" height={60} />
          <Skeleton variant="rounded" width="100%" height={60} />
          <Skeleton variant="rounded" width="100%" height={60} />
        </Stack>
      )}

      {displaySelectorPopup && (
        <>
          <SelectLessonLevelDialog
            setLevel={(level: string) => {
              setLevel(level);
              setHasSelected(true);
            }}
          />
          {getLessonList(mockedLessonData as unknown as Lesson[])}
        </>
      )}

      {!lessonsInTopicQuery.isLoading && getLessonList(lessons)}
    </Stack>
  );
}
