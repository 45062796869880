import { IconButton, Stack, StackProps, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import EllipsisTypography from '../../../../../../ui/text/EllipsisTypography';
import './LessonCardListItem.scss';

type LessonCardListItemType = {
  title: string;
  subtitle?: string;
  grade: string;
  subject: string;
  onDeleteClick: () => void;
};
export default function LessonCardListItem({
  title,
  subtitle,
  grade,
  subject,
  onDeleteClick,
  ...rest
}: LessonCardListItemType & StackProps) {
  const theme = useTheme();

  return (
    <Stack
      className="lesson-card-list-item"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      {...rest}
    >
      <Stack direction="column" justifyContent="center" gap={1} width="100%" height={65}>
        <EllipsisTypography variant="body2" fontWeight={400} row={1} data-test="title">
          {title}
        </EllipsisTypography>

        {subtitle ? (
          <EllipsisTypography row={1} data-test="grade" variant="body2">
            {subtitle}
          </EllipsisTypography>
        ) : null}

        <Stack direction="row" data-test="item-detail" gap={2} color={theme.palette.text.disabled}>
          {grade ? (
            <Typography data-test="grade" variant="body2">
              {grade} Grade
            </Typography>
          ) : null}

          <Typography data-test="subject" variant="body2">
            {subject}
          </Typography>
        </Stack>
      </Stack>
      <IconButton
        sx={{ marginRight: 1 }}
        aria-label="Delete"
        className="delete-action"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick();
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
}
