/* eslint-disable no-use-before-define */
import { useEffect, useState, useRef } from 'react';
import {
  Popper,
  Button,
  InputBase,
  CircularProgress,
  ClickAwayListener,
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { v1 as uuidv1 } from 'uuid';
import { useTheme } from '@mui/material/styles';
import ObjectUtils from '../../utils/ObjectUtils';
import './FilterableMultiSelector.scss';

type OptionType = {
  id: string | number;
  label: string;
  hidden?: boolean;
  group?: string | number;
};

type FilterableMultiSelectorType = {
  title?: string;
  label: string;
  selectorTitle?: string;
  valueIds: (string | number)[];
  options: OptionType[] | undefined;
  onApply: (data: (string | number)[] | string | number) => void;
  filterable?: boolean;
  disabled?: boolean;
  noOptionsText?: string;
  isMultiple?: boolean;
  loading?: boolean;
  ableToUnselectedInSingleMode?: boolean;
  helperText?: string;
  ['data-test']?: string;
};
const FilterableMultiSelector = ({
  title,
  label,
  selectorTitle,
  valueIds,
  options,
  onApply,
  filterable,
  disabled,
  noOptionsText,
  isMultiple,
  loading,
  ableToUnselectedInSingleMode,
  helperText,
  ...props
}: FilterableMultiSelectorType) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [value, setValue] = useState<(OptionType | undefined)[] | OptionType | null | undefined>(
    isMultiple ? [] : null
  );
  const [pendingValue, setPendingValue] = useState<(OptionType | undefined)[] | OptionType | null | undefined>(
    isMultiple ? [] : null
  );
  const filterText = useRef('');
  useEffect(() => {
    if (valueIds && valueIds.length > 0) {
      const selectedOptions = valueIds
        .map((id) => options?.find((option) => option.id === id))
        .filter((o) => o !== undefined);

      setPendingValue(selectedOptions);
      setValue(selectedOptions);
    } else {
      setPendingValue(null);
      setValue(null);
    }
  }, [valueIds]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_event: unknown, reason?: string) => {
    if (reason === 'toggleInput') {
      return;
    }

    if (anchorEl) {
      anchorEl.focus();
    }
    filterText.current = '';
    setAnchorEl(null);
  };

  const handleOnApply = () => {
    setValue(pendingValue);
    handleClose(null, '');
    onApply(
      !isMultiple && Array.isArray(pendingValue) && pendingValue.length > 0
        ? pendingValue[0]!.id
        : (pendingValue as OptionType[]).map((o) => o.id)
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? uuidv1() : undefined;

  const renderButtonLabel = () => {
    if (loading) {
      return (
        <div className="loading-filter">
          <CircularProgress className="loading-icon" />
        </div>
      );
    }

    if (!value || (Array.isArray(value) && value.length === 0)) {
      return (
        <Typography color={theme.palette.grey[600]} textAlign="left" width="100%">
          {label}
        </Typography>
      );
    }

    if (Array.isArray(value) && value && value.length > 0) {
      return (
        <Typography textAlign="left" noWrap width="100%" data-private>
          {value.map((v) => v?.label).join(', ')}
        </Typography>
      );
    } else {
      return (
        <Typography textAlign="left" noWrap width="100%" data-private>
          {(value as OptionType).label}
        </Typography>
      );
    }
  };
  return (
    <>
      <div className="filterable-multi-selector" data-test={props['data-test']}>
        {title && <Typography variant="subtitle1">{title}</Typography>}
        <Button
          disableRipple
          className="filter-button"
          aria-describedby={id}
          onClick={handleClick}
          variant="outlined"
          color="black"
          disabled={disabled}
          fullWidth
          sx={{ textAlign: 'left' }}
        >
          {renderButtonLabel()}

          <KeyboardArrowDownIcon className="filter-button-icon" />
        </Button>
        {helperText && (
          <Typography variant="body2" color={theme.palette.grey[600]}>
            {helperText}
          </Typography>
        )}
      </div>

      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          className="filterable-multi-select-popper"
        >
          {selectorTitle ? <div className="header">{selectorTitle}</div> : null}
          <Autocomplete
            className="autocomplete-container"
            open
            clearOnBlur={false}
            onClose={handleClose}
            multiple={isMultiple}
            value={pendingValue || []}
            onChange={(event, newValue) => {
              setPendingValue(newValue);
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText={noOptionsText}
            filterOptions={(optionsFilter) => {
              const filtered = optionsFilter.filter(
                (option) =>
                  !option?.hidden &&
                  option?.label &&
                  option?.label?.toLowerCase().indexOf(filterText.current.toLowerCase()) >= 0
              );
              return filtered;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={`option-${option?.id}`}>
                <div
                  className="row-entry"
                  onClick={() => {
                    if (ableToUnselectedInSingleMode && !isMultiple && selected) {
                      // Need to be in timeout
                      ObjectUtils.setTimeout(() => {
                        setPendingValue(null);
                      }, 100);
                    }
                  }}
                  key={`option-${option?.id}`}
                >
                  <Checkbox checked={selected} />
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      WebkitBoxOrient: 'vertical',
                      maxWidth: '400px',
                    }}
                    data-private
                  >
                    {option?.label}
                  </Typography>
                </div>
              </li>
            )}
            options={options || []}
            groupBy={(option) => String(option?.group || '')}
            getOptionLabel={(option) => (Array.isArray(option) && option.length > 0 ? option[0].name : '')}
            isOptionEqualToValue={(option, v) =>
              Array.isArray(v) && v.length > 0 ? option?.id === v[0].key : option?.id === v?.id
            }
            renderInput={(params) => {
              // Fix error from the library "Uncaught TypeError: Cannot read properties of null (reading 'length')"
              if (params.inputProps.value) {
                // eslint-disable-next-line no-param-reassign
                params.inputProps.value = filterText.current;
              } else {
                // eslint-disable-next-line no-param-reassign
                params.inputProps.value = '';
              }

              return (
                <>
                  <InputBase
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    className={`input-base ${!filterable ? 'hidden' : ''}`}
                    data-private
                    onChange={(e) => {
                      filterText.current = e.target.value;
                    }}
                    name="filter-text-input"
                  />
                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      size="large"
                      onClick={handleClose}
                      data-test="filterable-multi-selector-cancel-button"
                      sx={{ color: theme.palette.grey[700] }}
                    >
                      Cancel
                    </Button>
                    <Button size="large" onClick={handleOnApply} data-test="filterable-multi-selector-apply-button">
                      Apply
                    </Button>
                  </Stack>
                </>
              );
            }}
          />
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default FilterableMultiSelector;
