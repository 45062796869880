import { LottieComponentProps } from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Slide } from '@mui/material';
import SubjectList from '../../ui/SubjectList';
import { LANDING_STUDENT_SUB_URL } from '../LandingStudentTileConstants';
import { useState } from 'react';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { FULL_SORTED_SUBJECT_CONSTANTS } from '../../../../constants/LessonConstant';
import { useGetElementsForSwitchAccess } from './LandingStudentTileLessonSelectionHooks';

const transitionTimeout = 800;
export default function LandingStudentTileSubjectSelector() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showComponent, setShowComponent] = useState(true);

  useGetElementsForSwitchAccess();

  return (
    <Slide direction={showComponent ? 'left' : 'right'} in={showComponent} timeout={transitionTimeout}>
      <Box data-test="landing-student-tile-subject-selector-page">
        <SubjectList
          containerProps={{
            gap: 2,
            width: '100%',
            direction: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '5em',
          }}
          includeExpired={false}
          cardWidth="430px"
          cardHeight="112px"
          autoSelectFirstAvailable={false}
          onSubjectSelected={(selectedSubjectId: number) => {
            setShowComponent(false);
            ObjectUtils.setTimeout(() => {
              const subjectObject = FULL_SORTED_SUBJECT_CONSTANTS.find(
                (subject) => subject.subjectId === selectedSubjectId
              );
              if (subjectObject) {
                navigate(`${LANDING_STUDENT_SUB_URL.lessonSelection}/${subjectObject?.tag}`);
              }
            }, transitionTimeout + 100);
          }}
          subjectNameProps={{
            color: theme.palette.primary.main,
            fontSize: '1.3em',
          }}
          lottieProps={
            {
              style: { width: '64px', height: '64px', opacity: 1 },
            } as LottieComponentProps
          }
        />
      </Box>
    </Slide>
  );
}
