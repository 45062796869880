import { useEffect, useState } from 'react';
import { useCheckPdf } from '../../../../hooks/CheckPdfLinkHooks';
import LoadingSpinner from '../../../../ui/loader/LoadingSpinner';
import { Stack } from '@mui/material';

type PdfContainerType = {
  pdfKey: string;
  shouldCheckPdf?: boolean;
};
export default function PdfContainer({ pdfKey, shouldCheckPdf }: PdfContainerType) {
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const { state, checkPdfExistings } = useCheckPdf(pdfKey);

  useEffect(() => {
    if (shouldCheckPdf) {
      checkPdfExistings();
    }
  }, []);

  return (
    <>
      {(state.loading || isPdfLoading) && !state.errorMessage ? (
        <LoadingSpinner
          message="Loading the pdf file."
          gap={2}
          sx={{
            margin: 'auto',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
          }}
        />
      ) : null}

      {!state.loading && state.errorMessage ? (
        <Stack margin="auto" height="100%" alignItems="center" justifyContent="center" data-test="error-message">
          {state.errorMessage}
        </Stack>
      ) : null}

      {(shouldCheckPdf && !state.loading && !state.errorMessage && state.signedPdfUrl) ||
      (!shouldCheckPdf && pdfKey) ? (
        <iframe
          data-test="pdf-iframe-container"
          width="100%"
          height="100%"
          src={shouldCheckPdf ? state.signedPdfUrl || '' : pdfKey}
          style={{ border: 'none' }}
          onLoad={() => {
            setIsPdfLoading(false);
          }}
        />
      ) : null}
    </>
  );
}
