import { Fade, Stack } from '@mui/material';
import LoadingSpinner from '../../../ui/loader/LoadingSpinner';
import { useInitialPage } from './InternalLoginHooks';

export default function InternalLoginPage() {
  useInitialPage();

  return (
    <Fade in timeout={{
      enter: 500,
      exit: 500,
    }}>
      <Stack height="100%" justifyContent="center">
        <LoadingSpinner />
      </Stack>
    </Fade>
  );
}
