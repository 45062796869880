import { useRef } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { useTheme } from '@mui/material/styles';

type SwitchAccessDialogType = {
  open: boolean;
};

export default function SwitchAccessDialog({ open }: SwitchAccessDialogType) {
  const focusElement = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  return open ? (
    <Dialog
      open={open}
      className="switch-access-dialog"
      TransitionProps={{
        onEntered: () => {
          focusElement?.current?.focus();
        },
      }}
      data-test="switch-access-dialog"
    >
      <DialogContent
        sx={{
          padding: '40px 40px 30px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TouchAppIcon
          sx={{
            fontSize: '5em',
            marginBottom: '10px',
            fill: theme.palette.primary.main,
          }}
        />
        <Typography
          fontSize="1.5em"
          textAlign="center"
          fontWeight="bold"
          gutterBottom
          data-test="switch-access-dialog-title"
        >
          Scan
        </Typography>
        <Typography
          sx={{
            outline: 'none',
          }}
          textAlign="center"
          ref={focusElement}
          tabIndex={1}
          data-test="switch-access-dialog-message"
        >
          Press Spacebar Key/Switch to <br /> Start Scanning
        </Typography>
      </DialogContent>
    </Dialog>
  ) : null;
}
