import React from 'react';
import { StateStandardsType } from '../types/StateStandardsType';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useGroupStandardByStandardScope } from '../../../../../hooks/StandardsHooks';
import StandardGroup from './StandardGroup';

export default function StateStandards({ standards, isLoading }: StateStandardsType) {
  const standardsByState = useGroupStandardByStandardScope(standards);
  
  if (isLoading) {
    return (
      <Stack direction="column" gap={1}>
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </Stack>
    );
  }

  if (!standards || standards.length === 0) {
    return null;
  }

  return (
    <Stack gap={1} data-test="state-standards">
      <Typography variant="h5" data-test="title" gutterBottom>
        State Standards
      </Typography>
      <Stack gap={1} data-test="group-container">
        {standardsByState.map((s, index) => (
          <StandardGroup key={`standard-group-${index}`} standardGroup={s} />
        ))}
      </Stack>
    </Stack>
  );
}
