import { createStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { ElementObjectType } from '../types/ElementObjectType';

// define the initial state
const initialState = {
  hasDialogShown: false,
  isDialogShowing: false,
  elementsForSwitch: {
    topBar: [],
    body: [],
    bottomBar: [],
  },
};

const switchAccessStore = persist<{
  hasDialogShown?: boolean;
  isDialogShowing?: boolean;
  elementsForSwitch?: ElementObjectType;
  updateShowDialog?: ({
    hasDialogShown,
    isDialogShowing,
  }: {
    hasDialogShown?: boolean;
    isDialogShowing?: boolean;
  }) => void;
  updateElementToScan?: (elementsForSwitch: ElementObjectType) => void;
}>(
  (set) => ({
    ...initialState,
    updateShowDialog: ({ hasDialogShown, isDialogShowing }) =>
      set({
        hasDialogShown: hasDialogShown || false,
        isDialogShowing: isDialogShowing || false,
      }),
    updateElementToScan: (elementsForSwitch: ElementObjectType) => set({ elementsForSwitch }),
    resetStore: () => set(initialState),
  }),
  {
    name: 'switch-access-store', // name of item in the storage (must be unique)
    partialize: (state) => ({
      hasDialogShown: state.hasDialogShown,
      isDialogShowing: state.isDialogShowing,
    }),
    storage: createJSONStorage(() => sessionStorage), // (optional) by default the 'localStorage' is used
  }
);

export const useSwitchAccessStore = createStore(switchAccessStore);
