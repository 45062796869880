import { createStore } from 'zustand';
import { ViewFilterType } from '../models/ViewType';
import { ContentLibraryType } from '../models/ContentLibraryType';
import { LessonSearchCriteriaByUser } from '../../../types/user/LessonSearchCriteriaByUserType';

const initialState = {
  selectedSubjectId: undefined,
  filterView: ViewFilterType.topicView,
  contentLibraries: [],
  grades: [],
  standardCodes: [],
  searchText: '',
  exactMatch: false,
  courseIds: [],
  verticalAlignmentIds: [],
};

type StoreType = {
  selectedSubjectId?: number;
  setSelectedSubjectId: (selectedSubjectId: number) => void;
  filterView: ViewFilterType;
  setFilterView: (filterView: ViewFilterType) => void;
  contentLibraries: ContentLibraryType[];
  setContentLibraries: (contentLibraries: ContentLibraryType[]) => void;
  grades: string[];
  setGrades: (grades: string[]) => void;
  standardCodes: string[];
  setStandardCodes: (standardCodes: string[]) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  exactMatch: boolean;
  setExactMatch: (exactMatch: boolean) => void;
  courseIds: number[];
  setCourseIds: (courseIds: number[]) => void;
  verticalAlignmentIds: string[];
  setVerticalAlignmentIds: (verticalAlignmentIds: string[]) => void;
  setSearchCriteriaFromLessonSearchCriteriaByUser: (criteria: LessonSearchCriteriaByUser) => void;
};

export const createSearchLessonsCriteriaStore = () => {
  return createStore<StoreType>((set) => ({
    ...initialState,
    setSelectedSubjectId: (selectedSubjectId: number) => set({ selectedSubjectId }),
    setFilterView: (filterView: ViewFilterType) => set({ filterView }),
    setContentLibraries: (contentLibraries: ContentLibraryType[]) => set({ contentLibraries }),
    setGrades: (grades: string[]) => set({ grades }),
    setStandardCodes: (standardCodes: string[]) => set({ standardCodes }),
    setCourseIds: (courseIds: number[]) => set({ courseIds }),
    setVerticalAlignmentIds: (verticalAlignmentIds: string[]) => set({ verticalAlignmentIds }),
    setSearchText: (searchText: string) => set({ searchText }),
    setExactMatch: (exactMatch: boolean) => set({ exactMatch }),
    setSearchCriteriaFromLessonSearchCriteriaByUser: (criteria: LessonSearchCriteriaByUser) => {
      set({
        selectedSubjectId: criteria.subject,
        contentLibraries: criteria.libraries,
        grades: criteria.grades,
      });
    },
  }));
};

export type SearchLessonsCriteriaStore = ReturnType<typeof createSearchLessonsCriteriaStore> | null;
