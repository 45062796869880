import { OtherSectionType } from '../types/OtherSectionType';
import { Skeleton, Stack, Typography } from '@mui/material';
import ItemRowCard from '../../../../../ui/cards/item-row-card/ItemRowCard';

export default function OtherSection({ isLoading, sectionTitle, data, onDetailClick }: OtherSectionType) {
  if (isLoading) {
    return (
      <Stack direction="column" gap={1}>
        <Skeleton variant="rounded" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60} />
      </Stack>
    );
  }

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Stack gap={1} data-test="extension-activities">
      <Typography variant="h5" data-test="title" gutterBottom>
        {sectionTitle}
      </Typography>
      <Stack direction="column" gap={1} data-test='other-section-item-row-container'>
        {data.map((row) => (
          <ItemRowCard
            key={`row-${row.id}`}
            id={String(row.id)}
            title={row.title}
            subTitles={row.subTitles}
            image={row.image}
            actions={[
              {
                variant: 'roundedBorder',
                'data-test': 'detail-action',
                onClick: () => {
                  onDetailClick(row.id);
                },
                children: 'Detail',
              },
            ]}
          />
        ))}
      </Stack>
    </Stack>
  );
}
