import { Grid, Stack } from '@mui/material';
import StudentLessonFilter from './components/StudentLessonFilter';
import {
  LandingStudentSelfSelectedContext,
  useInitilzeLandingStudentSelfSelected,
} from './LandingStudentSelfSelectedHooks';
import StudentSelfSelectedLessonList from './components/StudentSelfSelectedLessonList';
import SubjectListSelector from './components/SubjectListSelector';

export default function LandingStudentSelfSelected() {
  const { studentSelfSelectedFilterStoreRef } = useInitilzeLandingStudentSelfSelected();
  return (
    <LandingStudentSelfSelectedContext.Provider
      value={{
        studentSelfSelectedFilterStore: studentSelfSelectedFilterStoreRef.current,
      }}
    >
      <Grid
        container
        sx={{ height: '100%', width: '100%' }}
        spacing={2}
        rowSpacing={1}
        data-test="landing-student-self-selected-container"
        marginTop={0}
        paddingBottom={2}
        paddingTop={1}
      >
        <Grid
          height="100%"
          item
          xs={4}
          data-test="subject-selector-container"
          overflow="auto"
          boxSizing="border-box"
          sx={{
            paddingX: '35px !important',
            paddingTop: '20px !important',
          }}
        >
          <SubjectListSelector />
        </Grid>
        <Grid
          item
          height="100%"
          xs={8}
          data-test="lesson-list-main-container"
          sx={{
            paddingTop: '20px !important',
          }}
        >
          <Stack gap={2} width="100%" height="100%" data-test="filter-and-lesson-list-container">
            <StudentLessonFilter />
            <Stack flexGrow={1} position="relative" data-test="lesson-list-container">
              <Stack
                overflow="auto"
                position="absolute"
                width="100%"
                height="100%"
                data-test="lesson-list-inner-container"
                gap={1}
              >
                <StudentSelfSelectedLessonList />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </LandingStudentSelfSelectedContext.Provider>
  );
}
