import { useContext } from 'react';
import { useStore } from 'zustand';
import {
  ActivitiesAssignmentContext,
  TagsAssignmentContext,
  useGetAssignmentLessons,
} from '../../../ActivitiesAssignmentHooks';
import { useMutation } from '@tanstack/react-query';
import LessonTagService from '../../../../../services/LessonTagService';
import { StudentCourseAssignment } from '../../../../../types/activity-assignment/StudentCourseAssignmentType';

export const useSaveData = ({ onSaveSuccess }: { onSaveSuccess: () => void }) => {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const tagsAssignmentContext = useContext(TagsAssignmentContext);

  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);

  const saveTagAssignmentKey = useStore(tagsAssignmentContext!, (state) => state.saveTagAssignmentKey);
  const handleCloseForm = useStore(tagsAssignmentContext!, (state) => state.handleCloseForm);
  const studentsTagsMap = useStore(tagsAssignmentContext!, (state) => state.studentsTagsMap);

  const lessons = useGetAssignmentLessons();
  const lessonIds = lessons?.map((lesson) => lesson.id) || [];

  const mutation = useMutation({
    mutationKey: saveTagAssignmentKey,
    mutationFn: async () => {
      const tagRelationships: StudentCourseAssignment[] = [];
      const studentIds = Object.keys(studentsTagsMap);
      studentIds.forEach((studentId) => {
        const data = studentsTagsMap[studentId];
        const { addedTags } = data;
        addedTags.forEach((addedTag) => {
          let matched = tagRelationships.find((tr) => tr.attributes.name === addedTag.attributes.name);
          if (!matched) {
            matched = {
              type: 'tags',
              attributes: {
                name: addedTag.attributes.name,
              },
              relationships: {
                students: {
                  data: [],
                },
                lessons: {
                  data: lessonIds.map((lessonId) => ({
                    id: lessonId,
                    type: 'lessons',
                  })),
                },
              },
            };
            tagRelationships.push(matched);
          }
          matched.relationships.students.data.push({
            id: studentId,
            type: 'users',
          });
        });
      });

      if (tagRelationships.length > 0) {
        await LessonTagService.addTagsForStudentAssignments(
          tagRelationships,
          activitiesAssignment!.objectiveType
        );
      }
    },
    onSuccess() {
      onSaveSuccess();
      handleCloseForm();
    },
  });
  return mutation;
};
