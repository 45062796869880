import { DateObject } from 'react-multi-date-picker';

export const DATE_FORMAT = 'MM/DD/YYYY';
const currentDate = new Date();

export default {
  getStartDateEndDate: (days: number) => {
    const defaultStartDate = new DateObject();
    const defaultEndDate = new DateObject();
    defaultEndDate.setDay(defaultEndDate.day + days);
    const defaultStartDateString = defaultStartDate.format(DATE_FORMAT);
    const defaultEndDateString = defaultEndDate.format(DATE_FORMAT);
    return {
      defaultStartDateString,
      defaultEndDateString,
    };
  },
  getMaxDateFromCurrentDate: (days: number) => {
    const date = new Date();
    // add a day
    date.setDate(date.getDate() + days);
    return date;
  },
  getDifferenceInDays: (dateLeft: Date, dateRight: Date = currentDate) => {
    const difference = dateLeft.getTime() - dateRight.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
  },

  getCurrentDate: () => currentDate,
};
