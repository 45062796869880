import { useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { Lesson } from '../../../types/lesson/LessonType';
import { ItemRowMenuItemType } from '../../../types/ui/ItemRowMenuItem';
import ShareLinkLessonDialog from './ShareLinkLessonDialog';

export const useShareLinkLessonAction = () => {
  const [open, setOpen] = useState(false);
  const getShareLinkLessonAction = (lesson: Lesson): ItemRowMenuItemType => {
    return {
      id: 'share-link-lesson-action',
      label: 'Share Link',
      icon: <LinkIcon />,
      onClick: async () => {
        setOpen(true);
      },
      childrenComponentFunction: (closeDropdownFunction) => {
        return (
          <ShareLinkLessonDialog
            lesson={lesson}
            open={open}
            onClose={() => {
              setOpen(false);
              closeDropdownFunction();
            }}
          />
        );
      },
    };
  };

  return { getShareLinkLessonAction };
};
