import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, BoxProps, IconButton, Stack } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTheme } from '@mui/material/styles';
import './SortableItem.scss';

type SortableItemType = {
  id: string | number;
  isError: boolean;
  children?: JSX.Element;
};

export type SortableItemDataType = { sortable: { index: 0 } };

export function SortableItem({ id, children, isError, ...rest }: SortableItemType & BoxProps) {
  const theme = useTheme();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      {...rest}
      paddingY={0.3}
      borderRadius={2}
      style={style}
      sx={{
        marginTop: 0.05,
        marginBottom: 1,
        background: 'white',
        boxShadow: `0px 0px 0px 1px ${isError ? theme.palette.error.main : '#E0E0E0'}`,
      }}
      className={`sortable-item ${isDragging ? 'dragging' : ''}`}
    >
      <Stack direction="row" alignItems="center">
        <IconButton {...attributes} {...listeners} aria-label="delete" size="small">
          <DragIndicatorIcon />
        </IconButton>
        {children}
      </Stack>
    </Box>
  );
}
