import { useContext } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useTheme } from '@mui/material/styles';
import { useStore } from 'zustand';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useHandlePageNumber, useSearchActivities } from './ActivitiesViewSearchResultHooks';
import SearchResultContainer from '../../../../ui/SearchResultContainer';
import WindowParentUtil from '../../../../../../utils/WindowParentUtil';
import { SEARCH_LESSONS_SUB_URL } from '../../../../SearchLessonsConstant';
import { useNavigate } from 'react-router-dom';
import { MaxCardSelection } from '../../../../models/MaxCardSelectionConstants';
import { getSubjectOrCourseName } from '../../../../hooks/SubjectGroupByUserComponentsHooks';

export default function ActivitiesViewSearchResult() {
  const theme = useTheme();
  const searchLessonResultQuery = useSearchActivities();
  const { activitiesSearchPageNumber, setActivitiesSearchPageNumber } = useHandlePageNumber();
  const searchLessonsContext = useContext(SearchLessonsContext);
  const activitiesSearchResult = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.activitiesSearchResult
  );

  const navigate = useNavigate();

  return (
    <SearchResultContainer
      data-test="activities-view-search-result"
      isLoading={activitiesSearchPageNumber === 1 && searchLessonResultQuery.isLoading}
      isLoadingMore={searchLessonResultQuery.isLoading && activitiesSearchPageNumber > 1}
      hasMore={activitiesSearchResult.data.length < (activitiesSearchResult.meta?.totalCount || 0)}
      onLoadMore={() => {
        setActivitiesSearchPageNumber(activitiesSearchPageNumber + 1);
      }}
      dataList={activitiesSearchResult.data.map((d) => ({
        id: d.id,
        type: d.type,
        title: d.attributes.name,
        subTitles: [
          [d.attributes.topicName ? `Topic ${d.attributes.topicName || ''}` : null],
          [
            getSubjectOrCourseName(d),
            `${d.attributes.gradeLevel} Grade`,
          ],
        ],
        image: d.attributes.thumbnailUrl,
        imageOverlay: {
          icon: <PlayCircleIcon fontSize="large" htmlColor={theme.palette.primary.main} />,
          action: () => {
            WindowParentUtil.previewLesson(d);
          },
        },
        topicId: d.attributes.topicId,
      }))}
      getSelectedData={(selectedIndex: number) => {
        if (selectedIndex >= 0) {
          return activitiesSearchResult.data[selectedIndex];
        }
      }}
      onDetailClick={(selectedIndex) => {
        navigate(`${SEARCH_LESSONS_SUB_URL.activitiesDetails}/${activitiesSearchResult.data[selectedIndex].id}`);
      }}
      maxCardsSelected={MaxCardSelection.activities}
    />
  );
}
