import { Stack } from '@mui/system';
import { Tts } from '../../../types/tts/TtsType';
import VoiceSelector from './VoiceSelector';

type TextToSpeechSettingsFormType = {
  ttsConfig: Tts;
  onChange: (field: string, newValue: string | number) => void;
};

export default function TextToSpeechSettingsForm({ ttsConfig, onChange }: TextToSpeechSettingsFormType) {
  return (
    <Stack gap={2}>
      <VoiceSelector
        ttsConfig={ttsConfig}
        onChange={(value: number) => {
          onChange('voice', value);
        }}
      />
    </Stack>
  );
}
