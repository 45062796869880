export enum SNACKBAR_SEVERITY {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
export type VizzleSnackbarType = {
  severity?: SNACKBAR_SEVERITY;
  open: boolean;
  title?: string;
  message?: string;
  duration?: number;
};