import { useContext, useEffect, useState } from 'react';
import SearchInputField from '../../../../../../ui/input/SearchInputField';
import useDebounce from '../../../../../../hooks/useDebounce';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { useIsMount } from '../../../../../../hooks/useIsMount';

export default function TextFilter() {
  const isMount = useIsMount();
  const searchLessonsContext = useContext(SearchLessonsContext);
  const searchText = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.searchText);
  const setSearchText = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setSearchText);

  const [value, setValue] = useState(searchText);
  const handleClearKeywordClick = () => {
    setValue('');
  };

  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const debouncedValue = useDebounce(value, 600) as string;

  useEffect(() => {
    if (isMount) {
      resetSearchData();
      setSearchText(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <SearchInputField
      placeholder="Search"
      value={value}
      onChange={(value) => {
        setValue(value as string);
      }}
      sx={{ width: '50%', fontWeight: 300 }}
      onKeywordClear={handleClearKeywordClick}
      data-test="search-text-field"
    />
  );
}
