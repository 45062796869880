import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useContext } from 'react';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import TooltipWithIndicator from '../../../../../../ui/tooltips/TooltipWithIndicator';
import { exactMatchTooltipText } from '../../../../../../texts/TooltipText';

export default function ExactTextFilter() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const exactMatch = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.exactMatch);
  const setExactMatch = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setExactMatch);
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExactMatch(event.target.checked);
    resetSearchData();
  };

  return (
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='start'>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleCheckbox}
            name="exactMatch"
            color="primary"
            checked={exactMatch}
            data-test="exact-match-checkbox"
          />
        }
        label="Exact Match"
      />
      <TooltipWithIndicator
        {...exactMatchTooltipText}
      />
    </Box>
  );
}
