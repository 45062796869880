const messages = [
  'Correct!',
  'Well done!',
  'Nice work!',
  'Excellent!',
  'Marvelous!',
  'Great job!',
  'Fantastic!',
  'Superb!',
  'Tremendous!',
  'Awesome!',
  'Yes!',
];

export default {
  getRandomSimpleMessage: () => messages[Math.floor(Math.random() * messages.length)],
};
