export const APP_URL = {
  index: '/',
  login: {
    internalLogin: '/internal-login',
  },
  interim: {
    assignLesson: '/interim-assign-lesson',
    student: '/interim-student'
  },
  searchLessons: '/search-lessons',
  student: '/student',
};
