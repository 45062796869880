import { useMutation } from '@tanstack/react-query';
import StudentService from '../../../../services/StudentService';
import ObjectUtils from '../../../../utils/ObjectUtils';
import Logger from '../../../../utils/Logger';
import { useEffect } from 'react';

export const useStudentLessonsSummary = () => {
  const studentLessonSummary = useMutation({
    mutationKey: ['student-lesson-summary'],
    mutationFn: async () => {
      const result = await StudentService.getStudentLessonsSummary();
      return result;
    },
  });

  const fetchStudentLessonsSummary = (timeout = 2000) => {
    /*
      Delay the call for 2 seconds
      There will be some users who will not have any celebrations enabled for
      them which means the moment they finish the lesson, they will come back to
      theme page or self select page and the data about their lesson completion
      will still be in progress on the server
    */
    const timer = ObjectUtils.setTimeout(async () => {
      try {
        await studentLessonSummary.mutateAsync();
      } catch (e) {
        Logger.logError(e);
      }
    }, timeout);

    return timer;
  };

  useEffect(() => {
    const timer = fetchStudentLessonsSummary();
    return (() => { clearTimeout(timer || 0); });
  }, []);

  return {
    fetchStudentLessonsSummary,
    studentLessonSummary,
  };
};
