import { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { ActivitiesAssignmentContext, createKeyMapForLessonSelectionType } from '../../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import LessonCardListItem from './LessonCardListItem';
import { Lesson, LessonTypeString } from '../../../../../../types/lesson/LessonType';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from '../../../../../../ui/drag-and-drop/SortableItem';
import DragContainer from '../../../../../../ui/drag-and-drop/DragContainer';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { ActivitiessAssignmentType, AssignmentItem, TopicLesson } from '../../../../models/ActivitiesAssigmentType';
import { TopicTypeString } from '../../../../../../types/topic/SearchTopicResultType';
import LessonByLevelListItem from './LessonByLevelListItem';
import { Topic } from '../../../../../../types/topic/TopicType';
import LessonService from '../../../../../../services/LessonService';
import { Unit, UnitTypeString } from '../../../../../../types/unit/UnitType';
import {
  getSubjectOrCourseName,
  getSubjectOrCourseNameForTopicAndUnit,
} from '../../../../../../pages/search-lessons/hooks/SubjectGroupByUserComponentsHooks';

export default function ItemsToAssignList() {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const selectedItems = useStore(activitiesAssignmentContext!, (state) => state.selectedItems);
  const setSelectedItems = useStore(activitiesAssignmentContext!, (state) => state.setSelectedItems);
  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);
  const setActivitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.setActivitiesAssignment);

  const selectedLessonsByType = useStore(activitiesAssignmentContext!, (state) => state.selectedLessonsByType);
  const setSelectedLessonsByType = useStore(activitiesAssignmentContext!, (state) => state.setSelectedLessonsByType);

  function handleDragEnd(oldIndex: number, newIndex: number) {
    const updatedSelectedLessons = arrayMove(selectedItems!, oldIndex, newIndex);
    setSelectedItems(updatedSelectedLessons);
  }

  const handleOnItemDelete = (id: string, type: string) => {
    const updatedActivitiesAssignment = { ...activitiesAssignment };
    updatedActivitiesAssignment.assignmentItems =
      activitiesAssignment?.assignmentItems?.filter((a) => !(a.id === id && a.type === type)) || [];
    setActivitiesAssignment(updatedActivitiesAssignment as ActivitiessAssignmentType);

    const updatedSelectedItems = selectedItems.filter((s) => !(s.id === id && s.type === type));
    setSelectedItems(updatedSelectedItems);
    const keyMap = createKeyMapForLessonSelectionType(id, type);
    selectedLessonsByType.delete(keyMap);
    setSelectedLessonsByType(selectedLessonsByType);
  };

  const getComponent = (item: AssignmentItem) => {
    if (item.type === LessonTypeString) {
      const lesson = item as Lesson;
      return (
        <LessonCardListItem
          title={lesson.attributes.name}
          grade={lesson.attributes.gradeLevel}
          subject={getSubjectOrCourseName(lesson)}
          data-test={`item-to-assign-container-lesson-${lesson.id}`}
          onDeleteClick={() => handleOnItemDelete(lesson.id, lesson.type)}
        />
      );
    } else if (item.type === TopicTypeString) {
      const topic = item as Topic;
      return (
        <LessonByLevelListItem
          id={topic.id}
          type={topic.type}
          title={`Topic ${topic.attributes.name}`}
          subtitle={`Unit ${topic.attributes.unitName}`}
          lessonLevel={activitiesAssignment?.lessonLevel}
          grade={topic.attributes.gradeLevel || ''}
          subject={getSubjectOrCourseNameForTopicAndUnit(topic)}
          data-test={`item-to-assign-container-topic-${topic.id}`}
          getDataFunction={async (id: string, level: string) => {
            if (!level) {
              return {
                data: [],
                included: [],
              };
            }
            const lessons = await LessonService.getLessonsByTopicIdAndLevel({ topicId: id, level });
            return lessons;
          }}
          onDeleteClick={() => handleOnItemDelete(topic.id, topic.type)}
        />
      );
    } else if (item.type === UnitTypeString) {
      const unit = item as Unit;
      return (
        <LessonByLevelListItem
          id={unit.id}
          type={unit.type}
          title={`Unit ${unit.attributes.name}`}
          lessonLevel={activitiesAssignment?.lessonLevel}
          grade={unit.attributes.gradeLevel || ''}
          subject={getSubjectOrCourseNameForTopicAndUnit(unit)}
          data-test={`item-to-assign-container-unit-${unit.id}`}
          getDataFunction={async (id: string, level: string) => {
            if (!level) {
              return {
                data: [],
                included: [],
              };
            }
            const lessons = await LessonService.getLessonsByUnitIdAndLevel({ unitId: id, level });
            const lessonsTopicMap: TopicLesson[] = [];
            lessons.data.forEach((lesson) => {
              let lessonTopic = lessonsTopicMap.find((l) => l.id === lesson.attributes.topicId);
              if (!lessonTopic) {
                lessonTopic = {
                  id: lesson.attributes.topicId!,
                  attributes: { name: `Topic ${lesson.attributes.topicName}` },
                  type: UnitTypeString,
                  lessons: [],
                };
                lessonsTopicMap.push(lessonTopic);
              }
              lessonTopic.lessons.push(lesson);
            });

            return {
              data: lessonsTopicMap,
              included: [],
            };
          }}
          onDeleteClick={() => handleOnItemDelete(unit.id, unit.type)}
        />
      );
    }
  };

  return (
    <Stack
      data-test="items-to-assign"
      direction="column"
      paddingY={2}
      paddingLeft={2}
      paddingRight={0}
      boxSizing="border-box"
      height="100%"
      gap={1}
    >
      <Typography data-test="title" variant="subtitle1">
        Items to be assigned
      </Typography>
      <DragContainer onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
        <Stack
          position="relative"
          paddingLeft={-1}
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          height="100%"
          data-test="list-outer-container"
        >
          <Stack
            position="absolute"
            gap={1}
            width="100%"
            paddingTop={0.5}
            paddingLeft={1}
            paddingRight={2}
            data-test="list-container"
          >
            <SortableContext items={selectedItems || []} strategy={verticalListSortingStrategy}>
              {selectedItems?.map((item: AssignmentItem) => {
                const keyMap = createKeyMapForLessonSelectionType(item.id, item.type);
                const lessons = selectedLessonsByType.get(keyMap);
                return (
                  <SortableItem
                    isError={item.type !== LessonTypeString && lessons?.length === 0}
                    key={`${item.id}`}
                    id={item.id}
                    data-test={`sortable-item-${item.id}`}
                  >
                    {getComponent(item)}
                  </SortableItem>
                );
              })}
            </SortableContext>
          </Stack>
        </Stack>
      </DragContainer>
    </Stack>
  );
}
