export const SUBJECT_ID = {
  SUBJECT_ID_ELA: 4,
  SUBJECT_ID_MATH: 1,
  SUBJECT_ID_SCIENCE: 3,
  SUBJECT_ID_SOCIAL_STUDY: 2,
  SUBJECT_ID_SPEECH_THERAPY: 5,
  SUBJECT_ID_LIFE_SKILL: 13,
  SUBJECT_ID_LIFE_ART_MUSIC: 7,
  SUBJECT_ID_MISC: -1,
  SUBJECT_ID_READING: 41,
  SUBJECT_ID_WRITING: 42,

  SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS: 8,
  SUBJECT_ID_SOCIAL_SKILLS: 9,
};

export type SubjectObjectType = {
  [key: string]: SubjectObjectDetailType;
};

export type SubjectObjectDetailType = {
  tag: string;
  name: string;
  shortName: string;
  subjectId: number;
};

/** Subject constants */
export const SUBJECT_CONSTANTS: SubjectObjectType = {
  english: { tag: 'english', name: 'English Language Arts', shortName: 'ELA', subjectId: SUBJECT_ID.SUBJECT_ID_ELA },
  math: { tag: 'math', name: 'Math', shortName: 'Math', subjectId: SUBJECT_ID.SUBJECT_ID_MATH },
  science: { tag: 'science', name: 'Science', shortName: 'Science', subjectId: SUBJECT_ID.SUBJECT_ID_SCIENCE },
  social: {
    tag: 'social',
    name: 'Social Studies',
    shortName: 'Social Studies',
    subjectId: SUBJECT_ID.SUBJECT_ID_SOCIAL_STUDY,
  },

  speech: {
    tag: 'speech',
    name: 'Speech Therapy',
    shortName: 'Speech Therapy',
    subjectId: SUBJECT_ID.SUBJECT_ID_SPEECH_THERAPY,
  },
  arts: {
    tag: 'arts',
    name: 'Arts & Music',
    shortName: 'Arts & Music',
    subjectId: SUBJECT_ID.SUBJECT_ID_LIFE_ART_MUSIC,
  },
  life: { tag: 'life', name: 'Life Skills', shortName: 'Life Skills', subjectId: SUBJECT_ID.SUBJECT_ID_LIFE_SKILL },

  socialSkills: {
    tag: 'social-skills',
    name: 'Social Skills',
    shortName: 'Social Skills',
    subjectId: SUBJECT_ID.SUBJECT_ID_SOCIAL_SKILLS,
  },
  transitionalSkills: {
    tag: 'transition',
    name: 'Transition/Vocational Skills',
    shortName: 'Transition/Vocational Skills',
    subjectId: SUBJECT_ID.SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS,
  },
  misc: { tag: 'misc', name: 'Misc.', shortName: 'Misc.', subjectId: SUBJECT_ID.SUBJECT_ID_MISC },
};

export const FULL_SORTED_SUBJECT_CONSTANTS: SubjectObjectDetailType[] = [
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.social,
  SUBJECT_CONSTANTS.speech,
  SUBJECT_CONSTANTS.arts,
  SUBJECT_CONSTANTS.life,
  SUBJECT_CONSTANTS.socialSkills,
  SUBJECT_CONSTANTS.transitionalSkills,
  SUBJECT_CONSTANTS.misc,
];

export const FULL_SORTED_SUBJECT_CONSTANTS_WITHOUT_MISC = [
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.social,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.life,
  SUBJECT_CONSTANTS.transitionalSkills,
  SUBJECT_CONSTANTS.socialSkills,
  SUBJECT_CONSTANTS.speech,
  SUBJECT_CONSTANTS.arts,
];
