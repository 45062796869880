/* eslint-disable @typescript-eslint/no-explicit-any */
export const flatten: any = (arr: []) =>
  arr.reduce((flat: any, toFlatten: any) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);

export default {
  flatten: flatten,
  sort: <T>(arr: T[], attr: any) =>
    arr.sort((a: any, b: any) =>
      a[attr].toString().localeCompare(b[attr], undefined, { numeric: true, sensitivity: 'base' })
    ),
  sortByAttributes: <T>(arr: T[], attr: string) =>
    arr.sort((a, b) =>
      // @ts-ignore
      a.attributes[attr].toString().localeCompare(b.attributes[attr], undefined, { numeric: true, sensitivity: 'base' })
    ),
  containAll: (arr: any, target: any) => target.every((v: any) => arr.includes(v)),

  getDifference: <T>(a: T[], b: T[]) => {
    return a.filter((element) => {
      return !b.includes(element);
    });
  },

  insert: <T>(arr: T[], index: number, newItem: T) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ],
  unique: <T>(arr: T[], attr: string) => {
    if (!arr || arr.length === 0) {
      return [];
    }

    return (
      arr
        .map((e: any) => e[attr])
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter((e: any) => arr[e])
        .map((e: any) => arr[e])
    );
  },
  uniqueByAttributes: <T>(arr: T[], attr: string) => {
    if (!arr || arr.length === 0) {
      return [];
    }

    return (
      arr
        .map((e: any) => e.attributes[attr])
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter((e: any) => arr[e])
        .map((e: any) => arr[e])
    );
  },

  isArrayEqual: <T>(arr1: T[], arr2: T[]) => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
  },
};
