import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSaveData } from './AssignActivitiesFormHook';
import { useContext } from 'react';
import { ActivitiesAssignmentContext } from '../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import { useIsFetching } from '@tanstack/react-query';

type AssignActivitiesFormActionType = {
  onSaveSuccess: () => void;
};

export default function AssignActivitiesFormAction({ onSaveSuccess }: AssignActivitiesFormActionType) {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const handleCloseForm = useStore(activitiesAssignmentContext!, (state) => state.handleCloseForm);
  const selectedItems = useStore(activitiesAssignmentContext!, (state) => state.selectedItems);

  const validateActivitiesAssignment = useStore(
    activitiesAssignmentContext!,
    (state) => state.validateActivitiesAssignment
  );

  const mutation = useSaveData({ onSaveSuccess });

  const isFetching = useIsFetching();

  return (
    <Stack
      direction="row"
      data-test="assign-activities-form-action"
      justifyContent="flex-end"
      alignItems="center"
      gap={2}
      height="100%"
    >
      <LoadingButton
        data-test="cancel-button"
        variant="roundedBorder"
        onClick={() => {
          handleCloseForm();
        }}
        loading={mutation.isLoading}
      >
        Cancel
      </LoadingButton>
      <LoadingButton
        variant="rounded"
        data-test="assign-button"
        onClick={async () => {
          const result = validateActivitiesAssignment();
          if (result.success) {
            mutation.mutate();
          }
        }}
        disabled={Boolean(isFetching) || selectedItems.length === 0}
        loading={mutation.isLoading}
      >
        Assign
      </LoadingButton>
    </Stack>
  );
}
