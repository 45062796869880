import { Route, Navigate, Routes } from 'react-router-dom';
import { APP_URL } from './RouteConstants';
import IndexPage from '../pages/index/IndexPage';
import InternalLoginPage from '../pages/login/internal-login/InternalLoginPage';
import { useStore } from 'zustand';
import { useUserStore } from '../stores/UserStore';
import User from '../utils/User';
import getTeacherRoutes from './config/TeacherRoutes';
import getStudentRoutes from './config/StudentRoutes';
import PrivateRoute from './router/PrivateRoute';
import { generateMfaRoutes } from './config/MfaRoutes';
/**
 * Create routers of the application
 */
export const RouterConfiguration = () => {
  const user = useStore(useUserStore, (state) => state.user);

  const availableRoutes = User.isTeacher(user)
    ? getTeacherRoutes(user)
    : getStudentRoutes();

  const isMfa = User.isMfaUser(user);
  return (
    <Routes>
      (
      <>
        <Route path={APP_URL.index} element={<IndexPage />} index />
        <Route
          path={APP_URL.login.internalLogin}
          element={<InternalLoginPage />}
        />
        {isMfa
          ? generateMfaRoutes(availableRoutes, user)
          : availableRoutes.map((route) => (
              <Route
                key={`route-${route.path}`}
                path={route.path}
                element={<PrivateRoute element={route.element} />}
              />
            ))}

        <Route
          path="*"
          caseSensitive={false}
          element={<Navigate to={APP_URL.index} replace />}
        />
      </>
      )
    </Routes>
  );
};

export default RouterConfiguration;
