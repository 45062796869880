import { useState, useEffect } from 'react';
import { UserProfile } from '../../types/user/UserProfileType';
import { Tts } from '../../types/tts/TtsType';
import TextToSpeechSettingsForm from './components/TextToSpeechSettingsForm';

type TextToSpeech = {
  profile: UserProfile;
  onChange: (tts: Tts) => void;
};

const TextToSpeech = ({ profile, onChange }: TextToSpeech) => {
  const [ttsConfig, setTtsConfig] = useState(profile.tts);
  const [toggle, setToggle] = useState(profile.tts.enabled);
  const isTtsEnabled = profile.tts.enabled;

  // When mounting and changing a profile.
  useEffect(() => {
    setToggle(isTtsEnabled);
    setTtsConfig(profile.tts);
  }, [profile.id, profile.tts.enabled]);
  const handleTTSOnchange = (field: string, newValue: string | number) => {
    if (newValue === null) return;
    const newTts = {
      ...profile.tts,
      enabled: toggle,
      [field]: newValue,
    };
    setTtsConfig(newTts);
    onChange(newTts);
  };

  return (
    <>
      <TextToSpeechSettingsForm ttsConfig={ttsConfig} onChange={handleTTSOnchange} />
    </>
  );
};

export default TextToSpeech;
