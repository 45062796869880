import { Box, Typography } from '@mui/material';
import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';
import ImageList from '@mui/material/ImageList';
import { useState } from 'react';
import { UserProfile } from '../../../types/user/UserProfileType';
import { useUpdateProfile } from '../LandingStudentSettingsHooks';
import SettingsListItem from '../list-item/SettingsListItem';

export default function ThemeSettingPage() {
  const themes = useStore(useUserStore, (state) => state.terminologies?.themes);
  const userProfile = useStore(useUserStore, (state) => state.userProfile!);
  const [selectedTheme, setSelectedTheme] = useState(userProfile.playerWorld);

  const { updateUserProfileMutation } = useUpdateProfile('Theme has been updated.');

  const themeSettingChange = (index: number) => {
    updateUserProfileMutation.mutate({ playerWorld: index } as UserProfile);
  };

  const onCardSelect = (index: number) => {
    setSelectedTheme(index);
    themeSettingChange(index);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }} data-test="theme-mystudent-setting-container">
      <Typography variant="h5" paragraph>
        Theme
      </Typography>
      <ImageList sx={{}} cols={4} rowHeight={164}>
        {themes!.map((item) => (
          <Box key={item.thumbnail}>
            <SettingsListItem
              item={item}
              onCardSelect={() => onCardSelect(item.code)}
              checked={item.code === selectedTheme}
              thumbnailAsColor={item && item.manifest[0] && item.manifest[0].id === 'color'}
            ></SettingsListItem>
          </Box>
        ))}
      </ImageList>
    </Box>
  );
}
