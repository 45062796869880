import { Box, CardMedia, Chip, ChipProps, IconButton, Stack, StackProps, Typography } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import DefaultThumbnail from '../../../assets/images/default_thumbnail.png';
import './ItemRowCard.scss';
import { ItemRowAction, ItemRowMenuItemType } from '../../../types/ui/ItemRowMenuItem';
import ItemRowMenuItems from './ItemRowMenuItems';
import ItemRowActions from './ItemRowActions';

type ItemRowCardType = {
  id: string | number;
  title: string;
  subTitles?: (string | null)[][];
  topRightTitle?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  imageOverlay?: {
    icon: JSX.Element;
    action: () => void;
  };
  onTitleClick?: () => void;
  recordType?: string;
  startAdornmentComponent?: JSX.Element;
  isSelected?: boolean;
  actions?: ItemRowAction[];
  dropdownActions?: ItemRowMenuItemType[];
  chips?: ChipProps[];
};
export default function ItemRowCard({
  id,
  title,
  subTitles,
  topRightTitle,
  image,
  imageWidth,
  imageHeight,
  imageOverlay,
  recordType,
  onTitleClick,
  startAdornmentComponent,
  isSelected,
  actions,
  dropdownActions,
  chips,
  ...rest
}: ItemRowCardType & StackProps) {
  const theme = useTheme();

  const getImageElement = () => (
    <CardMedia
      sx={{
        minWidth: imageWidth,
        height: imageHeight,
        borderRadius: 1,
        '&:first-of-type': {
          marginLeft: 1,
        },
      }}
      image={image || DefaultThumbnail}
      title={title}
      data-test="card-media"
    />
  );

  const getTitleElement = () => {
    const titleEl = (
      <Typography data-test="title" variant="body1" fontWeight={400}>
        {title}
      </Typography>
    );

    if (onTitleClick) {
      return (
        <Box sx={{ cursor: 'pointer' }} onClick={onTitleClick}>
          {titleEl}
        </Box>
      );
    } else {
      return titleEl;
    }
  };
  return (
    <Stack
      gap={2}
      display="flex"
      direction="row"
      alignItems="center"
      data-test={`item-row-card-${id}`}
      position="relative"
      width="100%"
      paddingY={1}
      paddingRight={2}
      // marginTop="2px"
      borderRadius="4px"
      // marginLeft="2px"
      boxShadow={`box-shadow: 0px 0px 0px 1px ${theme.palette.grey[200]};`}
      sx={{
        backgroundColor: isSelected ? lighten(theme.palette.primary.main, 0.96) : '',
        boxShadow: isSelected
          ? `0px 0px 0px 2px ${theme.palette.primary.main}`
          : `0px 0px 0px 1px ${theme.palette.grey[200]}`,
      }}
      className="item-row-card"
      {...rest}
    >
      {startAdornmentComponent}

      {imageOverlay ? (
        <Box className="image-with-overlay" onClick={imageOverlay.action}>
          {getImageElement()}
          <Stack className="overlay">
            <IconButton>{imageOverlay.icon}</IconButton>
          </Stack>
        </Box>
      ) : (
        getImageElement()
      )}

      <Stack data-test="title-panel" flexGrow={1} gap={0.5}>
        {getTitleElement()}
        {subTitles && subTitles.length > 0 && (
          <Stack data-test="sub-title" gap={0.5}>
            {subTitles
              .filter((s) => s)
              .map((subTitle, index) => (
                <Stack direction="row" gap={2} data-test={`subtitle-${index}`} key={`subtitle-${index}`}>
                  {subTitle
                    .filter((s) => s)
                    .map((s, index2) => (
                      <Typography
                        variant="body2"
                        fontSize="0.85rem"
                        color={theme.palette.textSecondary.main}
                        data-test={`subtitle-${index}-${index2}`}
                        key={`subtitle-${index}-${index2}`}
                      >
                        {s}
                      </Typography>
                    ))}
                </Stack>
              ))}
          </Stack>
        )}
        {chips && chips.length > 0 && (
          <Stack data-test="chip-list" direction="row" alignContent="center" gap={0.5} mt={0.5}>
            {chips
              .filter((chip) => Boolean(chip))
              .map((chip, index) => (
                <Chip key={`chip-${id}-${index}`} data-test={`chip-${id}`} sx={{ paddingLeft: 1 }} {...chip} />
              ))}
          </Stack>
        )}
      </Stack>

      {topRightTitle ? (
        <Typography
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
          }}
          color={theme.palette.textSecondary.main}
          fontSize="0.95rem"
        >
          {topRightTitle}
        </Typography>
      ) : null}

      {recordType ? (
        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }} justifySelf="flex-end" data-test="record-type">
          {recordType}
        </Typography>
      ) : null}
      <ItemRowActions actions={actions} />
      <ItemRowMenuItems dropdownActions={dropdownActions} />
    </Stack>
  );
}

ItemRowCard.defaultProps = {
  imageWidth: '117px',
  imageHeight: '78px',
};
