import { useEffect, useState } from 'react';
import { useStore } from 'zustand';
import { Box } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import { UserProfile } from '../../../../types/user/UserProfileType';
import SettingsListItem from '../../list-item/SettingsListItem';
import { Reinforcer, Terminologies } from '../../../../types/user/TerminologiesType';
import ReinforcerService from '../../../../components/terminologies/reinforcers/reinforcer/ReinforcerService';
import ReinforcerElement from '../../../../components/terminologies/reinforcers/reinforcer/Reinforcer';
import appUiStore from '../../../../stores/AppUiStore';
import { SNACKBAR_SEVERITY } from '../../../../types/ui/VizzleSnackbar';

type ReinforcesSettingListType = {
  profile: UserProfile;
  terminologies: Terminologies;
  reinforcersListChange: (reinforcersList: number[]) => void;
};

export default function ReinforcesSettingList({
  profile,
  terminologies,
  reinforcersListChange,
}: ReinforcesSettingListType) {
  const [selectedReinforcers, setSelectedReinforcers] = useState(profile.reinforcers);
  const reinforcers = terminologies!.reinforcers;
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  useEffect(() => {
    setSelectedReinforcers(profile.reinforcers);
  }, [profile.reinforcers]);

  const onCardSelect = (id: string) => {
    const value = reinforcers.find((x) => x.id === id)?.code ?? 0;
    let reinforcersList = [];
    if (selectedReinforcers.includes(value)) {
      reinforcersList = selectedReinforcers.filter((item) => item !== value);
      if (reinforcersList.length === 0) {
        openSnackbar({
          severity: SNACKBAR_SEVERITY.WARNING,
          open: true,
          title: 'At least 1 reinforcer must be selected.',
          message: 'Change the style to “None” to turn off reinforcers',
          duration: 6000,
        });
        return;
      }
      setSelectedReinforcers(reinforcersList);
    } else {
      selectedReinforcers.push(value);
      reinforcersList = selectedReinforcers;
      setSelectedReinforcers(selectedReinforcers);
    }

    reinforcersListChange(reinforcersList);
  };

  const onMediaClick = (reinforcerItem: Reinforcer) => {
    ReinforcerService.playReinforcer(reinforcerItem.id);
  };

  return (
    <>
      <Box data-test="reinforcer-style-settings-list">
        <ImageList sx={{}} cols={4} rowHeight={164}>
          {reinforcers!.map((item) => (
            <Box key={item.thumbnail}>
              <SettingsListItem
                item={item}
                checked={selectedReinforcers.includes(item.code)}
                onCardSelect={onCardSelect}
                onMediaClick={() => {
                  onMediaClick(item);
                }}
              ></SettingsListItem>
            </Box>
          ))}
        </ImageList>
      </Box>
      <ReinforcerElement reinforcerList={terminologies.reinforcers || []} enableAudio={profile.reinforcerSound} />
    </>
  );
}
