import { AlertTitle, Button, Portal, Snackbar, SnackbarProps, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

type VizzleConfirmationSnackbarType = {
  open?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  message?: string;
  duration?: number;
  confirmLabel?: string;
  ['data-test']: string;
};

export default function VizzleConfirmationSnackbar({
  open,
  onConfirm,
  onCancel,
  title,
  message,
  confirmLabel,
  ...rest
}: VizzleConfirmationSnackbarType & SnackbarProps) {
  return (
    <Portal container={document.body}>
      <Snackbar
        {...rest}
        data-test={rest['data-test']}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        sx={{ ...rest.sx, width: '100%', bottom: '0px !important' }}
      >
        <Alert
          variant="filled"
          color="confirm"
          data-test="alert-container"
          icon={false}
          sx={{
            width: '100%',
            '.MuiAlert-message': {
              width: '100%',
            },
          }}
        >
          {title && <AlertTitle data-test="title">{title}</AlertTitle>}
          <Stack
            direction="row"
            data-test="snackbar-body"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography data-test="message" variant="body2" component="p" flexGrow={1}>
              {message}
            </Typography>
            <Stack direction="row" gap={1} data-test="action-container">
              <Button variant="roundedBorderInvert" data-test="cancel-action" color="primary" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="roundedInvert" data-test="confirm-action" color="primary" onClick={onConfirm}>
                <Typography fontWeight={500} variant="body2" data-test="confirm-label">
                  {confirmLabel}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Alert>
      </Snackbar>
    </Portal>
  );
}

VizzleConfirmationSnackbar.defaultProps = {
  confirmLabel: 'Confirm',
};
