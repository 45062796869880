import { useEffect, useState } from 'react';
import { Box, BoxProps, Dialog, DialogTitle, Grid, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WindowParentUtil from '../../../../utils/WindowParentUtil';
import PdfContainer from './PdfContainer';

type ViewPdfDialogType = {
  title: string;
  pdfKeys: string[];
  actionComponent: JSX.Element;
  shouldCheckPdf?: boolean;
};
export default function ViewPdfDialog({
  title,
  pdfKeys,
  shouldCheckPdf,
  actionComponent,
  ...rest
}: ViewPdfDialogType & BoxProps) {
  const [open, setOpen] = useState(false);
  const handleOnClose = () => {
    WindowParentUtil.showChatIcon(true);
    WindowParentUtil.showToolbar();
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      WindowParentUtil.showChatIcon(true);
      WindowParentUtil.showToolbar();
    };
  }, []);
  
  return (
    <>
      <Box
        data-test="view-pdf-dialog-action-container"
        {...rest}
        onClick={() => {
          WindowParentUtil.showChatIcon(false);
          WindowParentUtil.hideToolbar();
          setOpen(true);
        }}
      >
        {actionComponent}
      </Box>
      <Dialog
        open={open}
        onClose={handleOnClose}
        maxWidth="xl"
        PaperProps={{
          sx: { width: '100%', height: '100%' },
        }}
        sx={{
          '.MuiPaper-root': { overflowY: pdfKeys.length > 1 ? 'auto' : 'hidden' },
        }}
        fullScreen
        data-test="view-pdf-dialog"
      >
        <DialogTitle variant="h6" sx={{ paddingY: 1 }} data-test="title-container">
          <Stack data-test="title" direction="row" alignItems="center" justifyContent="space-between">
            {title}
            <IconButton data-test="close-action" component="label" onClick={handleOnClose}>
              <CloseIcon data-test="close-icon" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Grid container height="100%" columnSpacing={0} gap={2} data-test="pdfs-container">
          {pdfKeys.map((pdfKey, index) => (
            <Grid key={`pdf-container-${pdfKey}-${index}`} item height="100%" xs={12} data-test="pdf-container">
              <PdfContainer key={`pdf-container-${pdfKey}-${index}`} pdfKey={pdfKey} shouldCheckPdf={shouldCheckPdf} />
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </>
  );
}
