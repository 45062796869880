import { createStore /* useStore */ } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { User } from '../types/user/UserType';
import { UserProfile } from '../types/user/UserProfileType';
import { Terminologies } from '../types/user/TerminologiesType';

// define the initial state
const initialState = {
  user: undefined,
  userProfile: undefined,
  terminologies: undefined,
};

const userStore = persist<{
  user?: User;
  userProfile?: UserProfile;
  terminologies?: Terminologies;
  setUserData?: (params: { user: User; userProfile: UserProfile; terminologies: Terminologies }) => void;
  setUserProfile?: (userProfile: UserProfile) => void;
  resetStore?: () => void;
}>(
  (set) => ({
    ...initialState,
    setUserData: ({ user, userProfile, terminologies }) =>
      set({
        user,
        userProfile,
        terminologies,
      }),
    setUserProfile: (userProfile: UserProfile) =>
      set({
        userProfile,
      }),
    resetStore: () => set(initialState),
  }),
  {
    name: 'user-store', // name of item in the storage (must be unique)
    partialize: (state) => ({
      user: state.user,
      userProfile: state.userProfile,
      terminologies: state.terminologies,
    }),
    storage: createJSONStorage(() => sessionStorage), // (optional) by default the 'localStorage' is used
  }
);

export const useUserStore = createStore(userStore);
