import { useContext } from 'react';
import { useStore } from 'zustand';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useQuery } from '@tanstack/react-query';
import TopicService from '../../../../../../services/TopicService';
import { convertSelectedSubjectToSearchScenario } from '../../../../models/ContentLibraryType';
import SearchUtils from '../../../../utils/SearchUtils';

export const useSearchTopic = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const searchTopicResultQueryKey = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => ({
    filterView: state.filterView,
    subjectId: state.selectedSubjectId,
    contentLibraries: state.contentLibraries,
    grades: state.grades,
    standardCodes: state.standardCodes,
    verticalAlignmentIds: state.verticalAlignmentIds,
    courseIds: state.courseIds,
    searchText: state.searchText,
    exactMatch: state.exactMatch,
  }));

  const topicSearchResult = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.topicSearchResult
  );

  const setTopicSearchResult = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.setTopicSearchResult
  );

  const topicSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.topicSearchPageNumber
  );
  const scenario = convertSelectedSubjectToSearchScenario(searchTopicResultQueryKey.subjectId!, searchTopicResultQueryKey.searchText);

  const searchTopicResultQuery = useQuery({
    queryKey: [searchTopicResultQueryKey, topicSearchPageNumber],
    queryFn: async () => {
      if (topicSearchPageNumber === topicSearchResult.meta?.pageNumber) {
        return null;
      }
      const result = await TopicService.searchTopicsByScenario({
        pageNumber: topicSearchPageNumber || 1,
        subjectId: searchTopicResultQueryKey.subjectId!,
        grades: searchTopicResultQueryKey.verticalAlignmentIds && searchTopicResultQueryKey.verticalAlignmentIds.length > 0 ? [] : searchTopicResultQueryKey.grades,
        standardCodes: searchTopicResultQueryKey.standardCodes,
        verticalAlignmentIds: searchTopicResultQueryKey.verticalAlignmentIds,
        courseIds: searchTopicResultQueryKey.verticalAlignmentIds && searchTopicResultQueryKey.verticalAlignmentIds.length > 0 ? [] : searchTopicResultQueryKey.courseIds,
        keywords: SearchUtils.getSearchTextKeyword(searchTopicResultQueryKey.searchText, searchTopicResultQueryKey.exactMatch),
        scenario,
      });
      return result;
    },
    onSuccess: (result) => {
      if (!result) {
        return;
      }
      const newData =
        (result.meta?.pageNumber || 1) > 1
          ? [...topicSearchResult.data, ...(result.data || [])]
          : result.data || [];

      const updatedTopicSearchResult = {
        data: newData,
        included: [...topicSearchResult.included, ...(result.included || [])],
        meta: result.meta,
      };
      setTopicSearchResult(updatedTopicSearchResult);
    },
  });

  return searchTopicResultQuery;
};
