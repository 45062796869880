import { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { useStore } from 'zustand';
import SubjectSelectorSection from './SubjectSelectorSection';
import { useGetSubjectsGroupByUserComponents } from '../../hooks/SubjectGroupByUserComponentsHooks';
import { contentLibraryOptions } from '../../models/ContentLibraryType';
import { SearchLessonsContext } from '../../SearchLessonsPageHooks';
import { useUpdateLessonSearchCriteriaByUser } from '../../hooks/LessonSearchCriteriaByUserHooks';
import { SubjectObjectDetailType } from '../../../../constants/LessonConstant';

export default function SubjectSelectorPage() {
  const subjectByUserComponents = useGetSubjectsGroupByUserComponents({
    includeMisc: false,
    includeStandardSubjectInClassic: true,
  });

  const searchLessonsContext = useContext(SearchLessonsContext);
  const setContentLibraries = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setContentLibraries
  );

  const updateLessonSearchCriteriaByUserMutation = useUpdateLessonSearchCriteriaByUser();

  return (
    <Stack alignItems="center" gap={4} paddingTop={7} paddingBottom={5} data-test="subject-selector-page">
      <Typography variant="h4" data-test="title">
        Choose a subject
      </Typography>
      {subjectByUserComponents.map((s, index) =>
        s.subjects.length > 0 ? (
          <SubjectSelectorSection
            key={`subject-group-${index}`}
            title={contentLibraryOptions[s.contentLibrary].label}
            description={s.description}
            subjects={s.subjects}
            onSubjectSelect={(subject: SubjectObjectDetailType) => {
              setContentLibraries([s.contentLibrary]);
              updateLessonSearchCriteriaByUserMutation.mutateAsync({ subject: subject.subjectId, libraries: [s.contentLibrary] });
            }}
          />
        ) : null
      )}
    </Stack>
  );
}
