import { useContext } from 'react';
import { SearchLessonsContext } from './SearchLessonsPageHooks';
import { useStore } from 'zustand';
import SubjectSelectorPage from './components/subject-selector/SubjectSelectorPage';
import SearchLessonsContainer from './components/search-lessons-container/SearchLessonsContainer';
import { useQuery } from '@tanstack/react-query';
import { useUserStore } from '../../stores/UserStore';
import SearchService from '../../services/SearchService';
import { Stack } from '@mui/material';
import LoadingSpinner from '../../ui/loader/LoadingSpinner';
import Logger from '../../utils/Logger';

export default function SearchLessonsMain() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const user = useStore(useUserStore, (state) => state.user);

  const selectedSubjectId = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.selectedSubjectId
  );

  const setSearchCriteriaFromLessonSearchCriteriaByUser = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setSearchCriteriaFromLessonSearchCriteriaByUser
  );

  const criteriaForUserQuery = useQuery({
    queryKey: ['get-search-criteria-for-user', user!.id],
    queryFn: async () => {
      try {
        const criteriaForUser = await SearchService.getSavedSearchCriteriaForUser();
        if (criteriaForUser) {
          setSearchCriteriaFromLessonSearchCriteriaByUser(criteriaForUser);
        }
        return criteriaForUser;
      } catch (e) {
        Logger.logError(e);
        return null;
      }
    },
  });

  if (criteriaForUserQuery.isLoading) {
    return (
      <Stack height="100%" justifyContent="center">
        <LoadingSpinner />
      </Stack>
    );
  }
  if (!selectedSubjectId) {
    return <SubjectSelectorPage />;
  }
  return <SearchLessonsContainer />;
}
