import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useContext } from 'react';
import { ActivitiesAssignmentContext /*, TagsAssignmentContext*/ } from '../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import { useSaveData } from './components/TagsAssignmentSaveHooks';
// import appUiStore from '../../../../stores/AppUiStore';
// import { SNACKBAR_SEVERITY } from '../../../../types/ui/VizzleSnackbar';

type CustomTagStepActionType = {
  onSaveSuccess: () => void;
  onCustomTagCancel?: () => void;
};

export default function CustomTagStepAction({ onSaveSuccess, onCustomTagCancel }: CustomTagStepActionType) {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const handleCloseForm = useStore(activitiesAssignmentContext!, (state) => state.handleCloseForm);

  const saveTagMutation = useSaveData({
    onSaveSuccess,
  });

  return (
    <Stack
      data-test="custom-tag-step-actions-container"
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      gap={2}
      height="100%"
    >
      <LoadingButton
        variant="roundedBorder"
        onClick={() => {
          handleCloseForm();
          if (onCustomTagCancel) {
            onCustomTagCancel();
          }
        }}
        loading={saveTagMutation.isLoading}
        data-test="cancel-button"
      >
        Skip Custom Goals
      </LoadingButton>
      <LoadingButton
        variant="rounded"
        onClick={async () => {
          await saveTagMutation.mutateAsync();
          // const isChanged = hasDataChanged();
          // if (isChanged) {
          //   await saveTagMutation.mutateAsync();
          // } else {
          //   openSnackbar({
          //     severity: SNACKBAR_SEVERITY.INFO,
          //     open: true,
          //     message: 'No data changed',
          //     duration: 3000,
          //   });
          // }
        }}
        loading={saveTagMutation.isLoading}
        data-test="save-button"
      >
        Save & Close
      </LoadingButton>
    </Stack>
  );
}
