import { createStore } from 'zustand';
import { ActivitiessAssignmentType, AssignmentItem, SelectedLessonByType } from '../models/ActivitiesAssigmentType';
import { DATE_SELECTOR_MODE } from '../../../types/ui/DateSelector';
import { RETAKE_OPTIONS } from '../../../types/activity-assignment/ActivityAssignmentType';
import { SafeParseReturnType } from 'zod';
import {
  ActivitiesAssignmentValidation,
  ActivitiesAssignmentValidationType,
} from '../models/ActivitiesAssignmentValidation';
import DateUtils from '../../../utils/DateUtils';

export enum AssignmentStepEnum {
  ASSIGN_ACTIVTIES = 'ASSIGN_ACTIVTIES',
  ASSIGN_ACTIVITIES_SAVE_SUCEESS = 'ASSIGN_ACTIVITIES_SAVE_SUCEESS',
  CUSTOM_TAG = 'CUSTOM_TAG',
}

// 7 days
export const defaultDate = DateUtils.getStartDateEndDate(6);

const initialState = {
  activitiesAssignment: undefined,
  selectedItems: [],
  selectedLessonsByType: new Map(),
  step: AssignmentStepEnum.ASSIGN_ACTIVTIES,
  filterStudentsAndGroupText: '',
  selectedGroupIds: [],
  selectedStudentIds: [],
  selectedDates: [defaultDate.defaultStartDateString, defaultDate.defaultEndDateString],
  selectedDateMode: DATE_SELECTOR_MODE.DATE_RANGE,
  retakeOption: RETAKE_OPTIONS.RETAKE_SINGLE_PLAY,
  addToMyResourceFlag: false,
  activitiesAssignmentValidationResult: undefined,
};

type StoreType = {
  saveAssignmentKey: string[];
  activitiesAssignment?: ActivitiessAssignmentType;
  step: AssignmentStepEnum;
  setStep: (step: AssignmentStepEnum) => void;
  setActivitiesAssignment: (activitiesAssignment: ActivitiessAssignmentType) => void;
  selectedItems: AssignmentItem[];
  setSelectedItems: (items: AssignmentItem[]) => void;
  selectedLessonsByType: Map<string, SelectedLessonByType[]>;
  setSelectedLessonsByType: (selectedLessonsByType: Map<string, SelectedLessonByType[]>) => void;
  handleCloseForm: () => void;
  filterStudentsAndGroupText: string;
  setFilterStudentsAndGroupText: (filterStudentsAndGroupText: string) => void;
  selectedGroupIds: string[];
  setSelectedGroupIds: (ids: string[]) => void;
  selectedStudentIds: string[];
  setSelectedStudentIds: (ids: string[]) => void;
  selectedDates: string[] | null | undefined;
  setSelectedDates: (dates: string[] | null) => void;
  selectedDateMode: DATE_SELECTOR_MODE;
  setSelectedDateMode: (mode: DATE_SELECTOR_MODE) => void;
  retakeOption: RETAKE_OPTIONS;
  setRetakeOption: (option: RETAKE_OPTIONS) => void;
  addToMyResourceFlag: boolean;
  setAddToMyResourceFlag: (flag: boolean) => void;
  activitiesAssignmentValidationResult?: SafeParseReturnType<
    ActivitiesAssignmentValidationType,
    ActivitiesAssignmentValidationType
  >;
  setActivitiesAssignmentValidationResult: (
    result: SafeParseReturnType<ActivitiesAssignmentValidationType, ActivitiesAssignmentValidationType>
  ) => void;
  validateActivitiesAssignment: () => SafeParseReturnType<
    ActivitiesAssignmentValidationType,
    ActivitiesAssignmentValidationType
  >;
};

export const createActivitiesAssignmentStore = ({ onClose }: { onClose: () => void }) => {
  return createStore<StoreType>((set, get) => ({
    ...initialState,
    saveAssignmentKey: ['saveAssignmentKey'],
    setStep: (step: AssignmentStepEnum) => set({ step }),
    setActivitiesAssignment: (activitiesAssignment: ActivitiessAssignmentType) => {
      return set({
        activitiesAssignment,
        selectedItems: activitiesAssignment.assignmentItems,
        selectedStudentIds: activitiesAssignment.currentStudent ? [String(activitiesAssignment.currentStudent.id)] : [],
      });
    },
    setSelectedItems: (selectedItems: AssignmentItem[]) => set({ selectedItems }),
    setSelectedLessonsByType: (selectedLessonsByType: Map<string, SelectedLessonByType[]>) =>
      set({ selectedLessonsByType }),
    handleCloseForm: () => {
      onClose();
    },
    setFilterStudentsAndGroupText: (filterStudentsAndGroupText: string) => set({ filterStudentsAndGroupText }),
    setSelectedGroupIds: (ids: string[]) => set({ selectedGroupIds: ids }),
    setSelectedStudentIds: (ids: string[]) => set({ selectedStudentIds: ids }),
    setSelectedDates: (dates: string[] | null) => set({ selectedDates: dates }),
    setSelectedDateMode: (mode: DATE_SELECTOR_MODE) => set({ selectedDateMode: mode }),
    setRetakeOption: (option: RETAKE_OPTIONS) => set({ retakeOption: option }),
    setAddToMyResourceFlag: (flag: boolean) => set({ addToMyResourceFlag: flag }),
    validateActivitiesAssignment: () => {
      const result = ActivitiesAssignmentValidation.safeParse({
        selectedStudentsAndGroupIds: [...get().selectedGroupIds, ...get().selectedStudentIds],
        selectedDates: get().selectedDates,
        selectedDateMode: get().selectedDateMode,
        selectedLessonsByType: Array.from(get().selectedLessonsByType.values()),
      });
      set({
        activitiesAssignmentValidationResult: result,
      });
      return result;
    },
    setActivitiesAssignmentValidationResult: (
      result: SafeParseReturnType<ActivitiesAssignmentValidationType, ActivitiesAssignmentValidationType>
    ) => set({ activitiesAssignmentValidationResult: result }),
  }));
};

export type ActivitiesAssignmentStore = ReturnType<typeof createActivitiesAssignmentStore> | null;
