import { useContext } from 'react';
import { useStore } from 'zustand';
import { Chip } from '@mui/material';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';
import { useGetGradeByContentLibraries } from '../../../hooks/GradeHooks';

export default function GradesSummaryPanel() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const grades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.grades);
  const setGrades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setGrades);
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const gradeList = useGetGradeByContentLibraries();
  return (
    <>
      {grades.map((grade) => {
        const gradeData = gradeList.find((g) => g.value === grade);
        return (
          <Chip
            key={`grade-${grade}`}
            data-test={`grade-${grade}`}
            label={gradeData?.label}
            onDelete={() => {
              const updatedGrades = grades.filter((g) => g !== grade);
              resetSearchData();
              setGrades(updatedGrades);
            }}
          />
        );
      })}
    </>
  );
}
