import { Box } from '@mui/material';
import { ReinforcerStyle, UserProfile } from '../../../../types/user/UserProfileType';
import SoundEffectSetting from '../../settings/SoundEffectSetting';
import AnimationStyleSetting from '../../settings/AnimationStyleSetting';
import SelectAllSettings from '../../settings/SelectAllSettings';
import { Terminologies } from '../../../../types/user/TerminologiesType';
import { Stack } from '@mui/system';

const defaultReinforcers = [61, 62, 67];

type ReinforcesSettingStyleType = {
  profile: UserProfile | undefined;
  terminologies: Terminologies | undefined;
  onChange: (profile: UserProfile) => void;
};

export default function ReinforcesSettingStyle({ profile, terminologies, onChange }: ReinforcesSettingStyleType) {
  const showReinforcerDetail = [ReinforcerStyle.animated].includes(profile!.reinforcerStyle);

  return (
    <Stack gap={1} sx={{ m: 1, mt: 3 }} data-test="reinforcer-setting-style">
      <Box sx={{ display: 'inline-flex' }} data-test="inner-reinforcer-setting-style">
        <AnimationStyleSetting
          style={profile!.reinforcerStyle}
          onChange={(value) => {
            const objectToUpdate = { reinforcerStyle: value } as UserProfile;

            if (value === ReinforcerStyle.animated && profile?.reinforcers.length === 0) {
              objectToUpdate.reinforcers = [...defaultReinforcers];
            }

            onChange(objectToUpdate);
          }}
        />
        {showReinforcerDetail && (
          <SoundEffectSetting
            soundEffect={profile!.reinforcerSound}
            onChange={(value) => {
              onChange({ reinforcerSound: value } as UserProfile);
            }}
          />
        )}
      </Box>
      {showReinforcerDetail && (
        <SelectAllSettings
          attributeName="reinforcers"
          defaultValue={[...defaultReinforcers]}
          profile={profile!}
          terminologies={terminologies!}
          onChangeCallback={(newValue) => {
            onChange({ reinforcers: [...newValue] } as UserProfile);
          }}
        />
      )}
    </Stack>
  );
}
