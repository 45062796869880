import { useContext, useEffect } from 'react';
import LoadableMoreContainer from '../../../ui/container/LoadableMoreContainer';
import { SearchLessonsContext, useGetRecordType } from '../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { AssignmentItem } from '../../../components/activities-assignment/models/ActivitiesAssigmentType';
import ItemRowCard from '../../../ui/cards/item-row-card/ItemRowCard';
import { Checkbox } from '@mui/material';
import appUiStore from '../../../stores/AppUiStore';
import { SNACKBAR_SEVERITY } from '../../../types/ui/VizzleSnackbar';
import { ItemRowMenuItemType } from '../../../types/ui/ItemRowMenuItem';
import { MaxCardSelection } from '../models/MaxCardSelectionConstants';
import NoResultCard from '../../../ui/cards/no-result-card/NoResultCard';

type SearchResultContainerType = {
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  dataList: {
    id: string;
    type: string;
    title: string;
    subTitles?: (string | null)[][];
    image?: string;
    imageOverlay?: { icon: JSX.Element; action: () => void };
    dropdownActions?: ItemRowMenuItemType[];
    topicId?: number;
  }[];
  getSelectedData: (selectedIndexes: number) => AssignmentItem | undefined;
  onDetailClick: (selectedIndex: number) => void;
  maxCardsSelected: number;
  ['data-test']: string;
  isAbleToAssign?: boolean;
  isSelectable?: boolean;
};

export default function SearchResultContainer({
  isLoading,
  isLoadingMore,
  hasMore,
  onLoadMore,
  dataList,
  getSelectedData,
  onDetailClick,
  maxCardsSelected,
  isAbleToAssign,
  isSelectable,
  ...rest
}: SearchResultContainerType) {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  const setSelectedAssignmentItem = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setSelectedAssignmentItem
  );
  const setOpenActivitiesAssignmentDialog = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setOpenActivitiesAssignmentDialog
  );
  const selectedAssignmentItems = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.selectedAssignmentItems
  );
  const setSelectedAssignmentItems = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setSelectedAssignmentItems
  );
  const setOpenConfirmSnackBar = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setOpenConfirmSnackBar
  );

  const searchResultContainerScrollTop = useStore(
    searchLessonsContext!.searchLessonsResultUiControlStore!,
    (state) => state.searchResultContainerScrollTop
  );

  const setSearchResultContainerScrollTop = useStore(
    searchLessonsContext!.searchLessonsResultUiControlStore!,
    (state) => state.setSearchResultContainerScrollTop
  );

  const { getRecordType } = useGetRecordType();

  useEffect(() => {
    if (searchResultContainerScrollTop > 0) {
      setTimeout(() => {
        const element = document.getElementById('loadable-more-container');
        if (element) {
          element.scrollTo(0, Number(searchResultContainerScrollTop));
        }
      }, 100);
    }
  }, [searchResultContainerScrollTop]);

  const handleGoToDetail = (index: number) => {
    const element = document.getElementById('loadable-more-container');
    if (element) {
      setSearchResultContainerScrollTop(element.scrollTop);
    }

    onDetailClick(index);
  };

  const getActions = (index: number) => {
    const result = [
      {
        variant: 'roundedBorder',
        'data-test': 'detail-action',
        onClick: () => {
          handleGoToDetail(index);
        },
        children: 'Detail',
      },
    ];

    if (isAbleToAssign) {
      result.push({
        variant: 'rounded',
        'data-test': 'assign-action',
        onClick: () => {
          const data = getSelectedData(index);
          setSelectedAssignmentItem(data);
          setOpenActivitiesAssignmentDialog(true);
        },
        children: 'Assign',
      });
    }
    return result;
  };
  return (
    <LoadableMoreContainer
      id="loadable-more-container"
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
      data-test={rest['data-test']}
    >
      {dataList.length > 0 ? (
        <>
          {dataList.map((data, index: number) => {
            const isSelected =
              selectedAssignmentItems.findIndex((item) => item.type === data.type && item.id === data.id) >= 0;
            const recordType = getRecordType(data.type, data.topicId);
            return (
              <ItemRowCard
                key={`activities-card-${data.id}`}
                data-test={`activities-card-${data.id}`}
                id={data.id}
                title={data.title}
                subTitles={data.subTitles}
                image={data.image}
                imageOverlay={data.imageOverlay}
                recordType={recordType}
                marginX={0.5}
                marginTop={0.5}
                width='99%'
                
                startAdornmentComponent={
                  isSelectable ? (
                    <Checkbox
                      data-test="item-row-card-checkbox"
                      checked={isSelected}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        let updatedSelectedData: AssignmentItem[] = [];
                        if (checked) {
                          const currentItems = selectedAssignmentItems.filter((s) => s.type === data.type);
                          if (currentItems.length >= maxCardsSelected) {
                            openSnackbar({
                              severity: SNACKBAR_SEVERITY.ERROR,
                              open: true,
                              message: `No more than ${MaxCardSelection.topic} topics / ${MaxCardSelection.activities} activities can be selected at once`,
                              duration: 500000,
                            });
                            return;
                          }
                          const selectedData = getSelectedData(index);
                          if (selectedData) {
                            updatedSelectedData = [...selectedAssignmentItems, selectedData];
                          }
                        } else {
                          updatedSelectedData = selectedAssignmentItems.filter(
                            (s) => s.type === data.type && s.id !== data.id
                          );
                        }
                        setSelectedAssignmentItems(updatedSelectedData);
                        if (updatedSelectedData.length > 0) {
                          setOpenConfirmSnackBar(true);
                        } else {
                          setOpenConfirmSnackBar(false);
                        }
                      }}
                    />
                  ) : undefined
                }
                isSelected={isSelected}
                onTitleClick={() => {
                  handleGoToDetail(index);
                }}
                actions={getActions(index)}
                dropdownActions={data.dropdownActions}
              />
            );
          })}
        </>
      ) : (
        <NoResultCard width='90%' message="No matching results. Try adjusting your search term or filters." />
      )}
    </LoadableMoreContainer>
  );
}

SearchResultContainer.defaultProps = {
  isAbleToAssign: true,
  isSelectable: true,
};
