import { useContext } from 'react';
import { useStore } from 'zustand';
import DropdownInput from '../../../../../../ui/selector/DropdownInput';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useGetSubjectsGroupByUserComponents } from '../../../../hooks/SubjectGroupByUserComponentsHooks';

export default function SubjectDropdownSelector() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const selectedSubjectId = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.selectedSubjectId
  );
  const setSelectedSubjectId = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setSelectedSubjectId
  );
  const setCourseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setCourseIds);
  const setStandardCodes = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setStandardCodes
  );
  const contentLibraries = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.contentLibraries
  );

  const contentLibraryOptionsForUserComponents = useGetSubjectsGroupByUserComponents({
    includeMisc: false,
    includeStandardSubjectInClassic: true,
  });
  const subjectByUserComponents = contentLibraryOptionsForUserComponents.find(
    (c) => c.contentLibrary === contentLibraries[0]
  );
  const subjectDropdownOptions = subjectByUserComponents?.subjects.map((subject) => ({
    value: subject.subjectId,
    label: subject.name,
  }));

  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);
  return (
    <DropdownInput
      dropdownValues={subjectDropdownOptions}
      label="Subject"
      labelProps={{ variant: 'subtitle1', marginBottom: '4px' }}
      data-test="subject-selector"
      value={selectedSubjectId!}
      onChange={async (e) => {
        const subjectId = Number(e.target.value);
        setStandardCodes([]);
        resetSearchData();
        setSelectedSubjectId(subjectId);
        setCourseIds([]);
      }}
    />
  );
}
