import { Standard } from '../types/standard/StandardType';

export default {
  uniqueStandardsByAttributes: (standardList: Standard[] = []): Standard[] => {
    const result: Standard[] = [];
    standardList.forEach((s) => {
      if (s.attributes.standardCode.length > 0) {
        const index = result.findIndex(
          (r) =>
            r.attributes.standardCode === s.attributes.standardCode &&
            r.attributes.standardDescription === s.attributes.standardDescription,
        );
        if (index < 0) {
          result.push(s);
        }
      }
    });

    return result;
  },

  getStandardCodeOrVerticalAlignmentIds: (standardCodes: string[], verticalAlignmentIds: string[]): string[] => {
    if (standardCodes && standardCodes.length === 1 && verticalAlignmentIds && verticalAlignmentIds.length > 0) {
      return verticalAlignmentIds;
    }
    return standardCodes;
  },
};
