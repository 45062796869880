import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  CircularProgress,
  TypographyProps,
  TextFieldProps,
} from '@mui/material';
import './DropdownInput.scss';

type DropdownInputType = {
  label: string;
  dropdownValues: { value: number | string; label: string; disabled?: boolean }[];
  value: number | string | undefined;
  loading: boolean;
  inlineLabel: string;
  selectorProps?: TextFieldProps;
  labelProps: TypographyProps;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ['data-test']?: string;
};

export default function DropdownInput({
  label,
  dropdownValues,
  loading,
  inlineLabel,
  selectorProps,
  labelProps,
  value,
  onChange,
  ...rest
}: DropdownInputType) {
  return (
    <Stack width="100%" position="relative" className="dropdown-input" data-test={rest['data-test']}>
      {loading && <CircularProgress size={25} className={`spinner ${label ? '' : 'no-label'}`} data-test="loading" />}
      {label && <Typography {...labelProps}>{label}</Typography>}
      <TextField
        select
        {...selectorProps}
        variant="outlined"
        fullWidth
        label={inlineLabel}
        data-private
        value={value}
        onChange={onChange}
        data-test={'dropdown-selector'}
      >
        {dropdownValues ? (
          dropdownValues.map((l) => (
            <MenuItem
              key={l.value}
              value={l.value}
              data-test={`selector-option-${l.value}`}
              disabled={l.disabled}
              data-private
            >
              {l.label}
            </MenuItem>
          ))
        ) : (
          <div />
        )}
      </TextField>
    </Stack>
  );
}

DropdownInput.defaultProps = {
  label: '',
  inlineLabel: undefined,
  dropdownValues: [],
  loading: false,
  labelProps: { variant: 'h6' },
};
