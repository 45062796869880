import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';
import TextToSpeech from '../../../components/tts/TextToSpeech';
import { Tts } from '../../../types/tts/TtsType';
import { Stack } from '@mui/system';
import { useUpdateProfile } from '../LandingStudentSettingsHooks';
import { UserProfile } from '../../../types/user/UserProfileType';
import { Typography } from '@mui/material';

export default function VoiceSettingPage() {
  const userProfile = useStore(useUserStore, (state) => state.userProfile!);
  const { updateUserProfileMutation } = useUpdateProfile('Text to speech has been updated.');

  const handleOnChange = (tts: Tts) => {
    updateUserProfileMutation.mutate({ tts } as UserProfile);
  };

  return (
    <Stack gap={2} padding={2} data-test="voices-page">
      <Stack data-test="title" gap={1}>
        <Typography variant="h5">Voices</Typography>
        <Typography fontWeight={400}>Select Text-to-Speech Voice</Typography>
      </Stack>

      <TextToSpeech
        profile={userProfile}
        onChange={(tts: Tts) => {
          handleOnChange(tts);
        }}
      />
    </Stack>
  );
}
