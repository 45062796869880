import { createContext, useContext, useEffect, useRef } from 'react';
import { useStore } from 'zustand';
import { ActivitiesAssignmentStore, createActivitiesAssignmentStore } from './stores/ActivitiesAssignmentStore';
import {
  ActivitiessAssignmentType,
  AssignmentItem,
  SelectedLessonByType,
  TopicLesson,
} from './models/ActivitiesAssigmentType';
import { TagsAssignmentStore, createTagsAssignmentStore } from './stores/TagsAssignmentStore';
import { Lesson, LessonTypeString } from '../../types/lesson/LessonType';
import { TopicTypeString } from '../../types/topic/SearchTopicResultType';
import { UnitTypeString } from '../../types/unit/UnitType';

export const ActivitiesAssignmentContext = createContext<ActivitiesAssignmentStore | null>(null);
export const TagsAssignmentContext = createContext<TagsAssignmentStore | null>(null);

export const useInitilzeActivitiesAssignment = ({
  activitiesAssignment,
  onClose,
}: {
  activitiesAssignment: ActivitiessAssignmentType;
  onClose: () => void;
}) => {
  const activitiesAssignmentStoreRef = useRef(createActivitiesAssignmentStore({ onClose }));
  const tagsAssignmentStoreRef = useRef(createTagsAssignmentStore({ onClose }));

  const setActivitiesAssignment = useStore(
    activitiesAssignmentStoreRef.current,
    (state) => state.setActivitiesAssignment
  );

  useEffect(() => {
    setActivitiesAssignment(activitiesAssignment);
  }, [activitiesAssignment]);
  return { activitiesAssignmentStoreRef, tagsAssignmentStoreRef };
};

export const getUserIds = (
  activitiesAssignment: ActivitiessAssignmentType,
  selectedGroupIds: string[],
  selectedStudentIds: string[]
) => {
  const groups = activitiesAssignment?.studentGroupData.filter((group) => selectedGroupIds.includes(String(group.id)));
  const studentIdsInGroup = groups?.flatMap((g) => g.students).map((student) => String(student)) || [];
  const userIds = [...new Set([...studentIdsInGroup, ...selectedStudentIds])];
  return userIds;
};

export const useDetermineMovingToCustomTagStep = ({
  activitiesAssignmentStoreRef,
}: {
  activitiesAssignmentStoreRef: { current: ActivitiesAssignmentStore };
}) => {
  const selectedItems = useStore(activitiesAssignmentStoreRef.current!, (state) => state.selectedItems);
  const selectedLessonsByType = useStore(activitiesAssignmentStoreRef.current!, (state) => state.selectedLessonsByType);
  const determineMovingToCustomTagStep = (): boolean => {
    /**
     * - if all activities are under the same topic, then display the custom tag with auto-tag
     * - all selected items to be assigned are activities, then we show the custom tag screen with no auto-tag
     * - other scenarios, we don't show the custom tag
     */
    const assignedLessons = getAssignmentLessons({ selectedItems, selectedLessonsByType });
    const isSameTopic = assignedLessons.every((val, _i, arr) => val.attributes.topicId === arr[0].attributes.topicId);
    const isAllActivities = selectedItems.every((val) => val.type === LessonTypeString);

    let shouldMoveToCustomTag = false;
    if (isSameTopic || isAllActivities) {
      shouldMoveToCustomTag = true;
    }

    return shouldMoveToCustomTag;
  };
  return { determineMovingToCustomTagStep };
};

export const useGetAssignmentLessons = () => {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const selectedItems = useStore(activitiesAssignmentContext!, (state) => state.selectedItems);
  const selectedLessonsByType = useStore(activitiesAssignmentContext!, (state) => state.selectedLessonsByType);
  return getAssignmentLessons({ selectedItems, selectedLessonsByType });
};

const getAssignmentLessons = ({
  selectedItems,
  selectedLessonsByType,
}: {
  selectedItems: AssignmentItem[];
  selectedLessonsByType: Map<string, SelectedLessonByType[]>;
}) => {
  let lessons: Lesson[] = [];
  selectedItems.forEach((item) => {
    if (item.type === LessonTypeString) {
      lessons.push(item as Lesson);
    } else if (item.type === UnitTypeString) {
      const keyMap = createKeyMapForLessonSelectionType(item.id, item.type);
      const lessonsByType = selectedLessonsByType.get(keyMap) || [];
      const unitTopics = lessonsByType as TopicLesson[];
      unitTopics.forEach((unit) => {
        lessons = lessons.concat(unit.lessons);
      });
    } else if (item.type === TopicTypeString) {
      const keyMap = createKeyMapForLessonSelectionType(item.id, item.type);
      const lessonsByType = selectedLessonsByType.get(keyMap) || [];
      lessons = lessons.concat(lessonsByType as Lesson[]);
    }
  });

  return lessons;
};

export const createKeyMapForLessonSelectionType = (id: string, type: string) => {
  return `${id}|${type}`;
};
