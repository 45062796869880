import { useRef } from 'react';
import Lottie, { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';

type LottieIconType = {
  animateOnHover?: boolean;
};

export default function LottieIcon({ animateOnHover, ...rest }: LottieIconType & LottieComponentProps) {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  return (
    <Lottie
      {...rest}
      lottieRef={lottieRef}
      autoplay={!animateOnHover}
      onMouseEnter={animateOnHover ? () => lottieRef.current && lottieRef.current.play() : undefined}
      onMouseLeave={animateOnHover ? () => lottieRef.current && lottieRef.current.stop() : undefined}
    />
  );
}
