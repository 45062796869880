
import elaIcon from '../../../assets/images/icons/animated-icons/ela.json';
import artAndMusicIcon from '../../../assets/images/icons/animated-icons/artsandmusic.json';
import speechIcon from '../../../assets/images/icons/animated-icons/speech.json';
import liftSkillIcon from '../../../assets/images/icons/animated-icons/life-skills.json';
import mathIcon from '../../../assets/images/icons/animated-icons/math.json';
import scienceIcon from '../../../assets/images/icons/animated-icons/science.json';
import scocialStudiesIcon from '../../../assets/images/icons/animated-icons/socialstudies.json';
import socialSkillIcon from '../../../assets/images/icons/animated-icons/socialskills.json';
import transitionskillIcon from '../../../assets/images/icons/animated-icons/transitionskill.json';
import miscIcon from '../../../assets/images/icons/animated-icons/misc.json';


import { SUBJECT_CONSTANTS } from '../../../constants/LessonConstant';


export const iconsMap = new Map();
iconsMap.set(SUBJECT_CONSTANTS.english.subjectId, elaIcon);
iconsMap.set(SUBJECT_CONSTANTS.math.subjectId, mathIcon);
iconsMap.set(SUBJECT_CONSTANTS.science.subjectId, scienceIcon);
iconsMap.set(SUBJECT_CONSTANTS.social.subjectId, scocialStudiesIcon);
iconsMap.set(SUBJECT_CONSTANTS.speech.subjectId, speechIcon);
iconsMap.set(SUBJECT_CONSTANTS.arts.subjectId, artAndMusicIcon);
iconsMap.set(SUBJECT_CONSTANTS.life.subjectId, liftSkillIcon);
iconsMap.set(SUBJECT_CONSTANTS.socialSkills.subjectId, socialSkillIcon);
iconsMap.set(SUBJECT_CONSTANTS.transitionalSkills.subjectId, transitionskillIcon);
iconsMap.set(SUBJECT_CONSTANTS.misc.subjectId, miscIcon);