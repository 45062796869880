import { useContext } from 'react';
import { useStore } from 'zustand';
import { LandingStudentSelfSelectedContext } from '../LandingStudentSelfSelectedHooks';
import SubjectList from '../../ui/SubjectList';

export default function SubjectListSelector() {
  const landingStudentSelfSelectedContext = useContext(LandingStudentSelfSelectedContext);

  const subjectId = useStore(
    landingStudentSelfSelectedContext!.studentSelfSelectedFilterStore!,
    (state) => state.subjectId
  );
  const setSubjectId = useStore(
    landingStudentSelfSelectedContext!.studentSelfSelectedFilterStore!,
    (state) => state.setSubjectId
  );
  
  return (
    <SubjectList
      containerProps={{
        gap: 2,
        width: '100%',
      }}
      autoSelectFirstAvailable
      selectedSubjectId={subjectId}
      onSubjectSelected={(selectedSubjectId: number) => {
        setSubjectId(selectedSubjectId);
      }}
      includeExpired
    />
  );
}
