import { useEffect } from 'react';
import { Stack, StackProps, TypographyProps } from '@mui/material';
import SubjectRowCard from './SubjectRowCard';
import { useGetSubjectListForStudent } from '../hooks/SubjectListHooks';
import { iconsMap } from '../constants/SubjectIconMap';
import { LottieComponentProps } from 'lottie-react';

type SubjectListType = {
  containerProps?: StackProps;
  cardWidth: number | string;
  cardHeight: number | string;
  autoSelectFirstAvailable?: boolean;
  includeExpired: boolean;
  onSubjectSelected: (subjectId: number) => void;
  selectedSubjectId?: number;
  subjectNameProps?: TypographyProps;
  lottieProps?: LottieComponentProps;
};

export default function SubjectList({
  selectedSubjectId,
  onSubjectSelected,
  autoSelectFirstAvailable,
  containerProps,
  cardWidth,
  cardHeight,
  includeExpired,
  subjectNameProps,
  lottieProps,
}: SubjectListType) {
  const subjectListToDisplay = useGetSubjectListForStudent({ includeExpired });
  useEffect(() => {
    if (autoSelectFirstAvailable && subjectListToDisplay.length > 0) {
      const matched = subjectListToDisplay.findIndex((s) => s.subjectDetail.subjectId === selectedSubjectId) >= 0;
      if (!matched) {
        onSubjectSelected(subjectListToDisplay[0].subjectDetail.subjectId);
      }
    }
  }, [subjectListToDisplay]);

  return (
    <Stack {...containerProps} data-test="subject-list">
      {subjectListToDisplay.map((option, index) => {
        return (
          <SubjectRowCard
            key={`subject-${index}`}
            subjectName={option.subjectDetail.name}
            lottieIcon={iconsMap.get(option.subjectDetail.subjectId)}
            onClick={() => {
              onSubjectSelected(option.subjectDetail.subjectId);
            }}
            isSelected={selectedSubjectId === option.subjectDetail.subjectId}
            width={cardWidth}
            height={cardHeight}
            subjectNameProps={subjectNameProps}
            lottieProps={lottieProps}
          />
        );
      })}
    </Stack>
  );
}

SubjectList.defaultProps = {
  cardWidth: undefined,
  cardHeight: 64,
};
