import { useGetToGroupInstructionAction } from '../../../actions/drop-down-actions/AddToGroupInstructionAction';
import { useSaveToMyResourceAction } from '../../../actions/drop-down-actions/AddToMyResourceAction';
import { Lesson } from '../../../types/lesson/LessonType';

export const useGetTopicsDropdownMenu = () => {
  const { getAddToGroupInstructionAction } = useGetToGroupInstructionAction({});
  const { getSaveToMyResourceAction } = useSaveToMyResourceAction();

  const getTopicsDropdownMenu = (lessons: Lesson[]) => {
    if (lessons.length === 0) {
      return [];
    }
    return [getAddToGroupInstructionAction(lessons), getSaveToMyResourceAction(lessons)];
  };

  return {
    getTopicsDropdownMenu,
  };
};
