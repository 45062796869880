import { useRef, useState } from 'react';
import { useStore } from 'zustand';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useUserStore } from '../../../stores/UserStore';
import appUiStore from '../../../stores/AppUiStore';
import { Lesson } from '../../../types/lesson/LessonType';
import WindowParentUtil from '../../../utils/WindowParentUtil';
import { SNACKBAR_SEVERITY } from '../../../types/ui/VizzleSnackbar';
import CustomDialog from '../../../ui/dialog/CustomDialog';
import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material';

type ShareLinkLessonDialogType = {
  lesson: Lesson;
  open: boolean;
  onClose: () => void;
};

export default function ShareLinkLessonDialog({ open, onClose, lesson }: ShareLinkLessonDialogType) {
  const user = useStore(useUserStore, (state) => state.user);
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  const areaToCopy = useRef<HTMLTextAreaElement>(null);
  const googleShareLinkRef = useRef(null);
  const link = `${WindowParentUtil.getParentUrl()}lesson/${lesson.attributes.uuid}`;
  const [sharedLink, setSharedLink] = useState(link);
  const [checkboxValues, setCheckboxValue] = useState({
    requiredLogin: false,
    schoolLink: false,
  });

  const copyToClipboard = async () => {
    areaToCopy?.current?.select();
    document.execCommand('copy');
    openSnackbar({
      severity: SNACKBAR_SEVERITY.INFO,
      open: true,
      message: 'The url has been copied to the clipboard',
      duration: 30000,
    });
  };

  const handleCheckboxRequiedLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckboxValue({
        requiredLogin: true,
        schoolLink: false,
      });
      setSharedLink(`${link}?auth=Y`);
    } else {
      setSharedLink(link);
      setCheckboxValue({
        ...checkboxValues,
        requiredLogin: false,
      });
    }
  };

  const handleCheckboxSchoolLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckboxValue({
        requiredLogin: false,
        schoolLink: true,
      });
      setSharedLink(`${import.meta.env.VITE_APP_API_URL}/users/lti_launch/${lesson.attributes.uuid}`);
    } else {
      setSharedLink(link);
      setCheckboxValue({
        ...checkboxValues,
        schoolLink: false,
      });
    }
  };

  return (
    <CustomDialog
      data-test="share-link-dialog"
      open={open}
      onClose={onClose}
      title="Share Link"
      displayCloseIcon
      onEntered={() => {
        if (googleShareLinkRef.current) {
          // @ts-ignore
          gapi.sharetoclassroom.render(googleShareLinkRef.current, {
            size: '50',
            theme: 'classic',
            url: `${link}?auth=Y`,
          });
        }
      }}
      dialogContent={
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" paddingBottom={2}>
          <Stack>
            <textarea
              ref={areaToCopy}
              style={{
                width: '550px',
                border: 'none',
                fontSize: '15px',
                outline: 'none',
                paddingRight: 10,
                resize: 'none',
                fontFamily: 'inherit',
              }}
              data-test="lesson-link"
              value={sharedLink}
              readOnly={true}
            />
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckboxRequiedLoginChange}
                    name="requiredLogin"
                    color="primary"
                    checked={checkboxValues.requiredLogin}
                    data-test="required-login-checkbox"
                  />
                }
                label="Require Student Login"
              />
            </Box>
            {user!.schoologyFlag && (
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxSchoolLinkChange}
                      name="schoolLink"
                      color="primary"
                      checked={checkboxValues.schoolLink}
                      data-test="schoology-checkbox"
                    />
                  }
                  label="Schoology Link"
                />
              </Box>
            )}
          </Stack>
          <Stack alignItems="center" gap={2}>
            <Button
              aria-label="close"
              onClick={copyToClipboard}
              data-test="copy-link-action"
              color="secondary"
              variant="contained"
              sx={{
                paddingY: 1.5,
                paddingX: 1,
              }}
            >
              <FileCopyIcon fontSize="medium" />
            </Button>
            <Box
              ref={googleShareLinkRef}
              className="g-sharetoclassroom share-to-classroom"
              data-test="google-share-to-classroom-action"
              data-size="32"
              data-url={`${link}?auth=Y`}
            />
          </Stack>
        </Stack>
      }
    />
  );
}
