import { Stack } from '@mui/system';
import VerticalAlignmentSummary from './VerticalAlignmentSummary';

export default function NotificationSummary() {
  return (
    <Stack data-test='notificaton-summary'>
      <VerticalAlignmentSummary />
    </Stack>
  );
}
