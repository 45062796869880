import { useGetToGroupInstructionAction } from '../../../actions/drop-down-actions/AddToGroupInstructionAction';
import { useSaveToMyResourceAction } from '../../../actions/drop-down-actions/AddToMyResourceAction';
import { useCopyToEditAction } from '../../../actions/drop-down-actions/copy-to-edit-action/CopytoEditAction';
import { useGetPrintLessonAction } from '../../../actions/drop-down-actions/PrintLessonAction';
import { useShareLinkLessonAction } from '../../../actions/drop-down-actions/share-link-lesson-action/ShareLinkLessonAction';
import { Lesson } from '../../../types/lesson/LessonType';

export const useGetActivitiesDropdownMenu = ({ updateData }: { updateData?: () => void }) => {
  const { getAddToGroupInstructionAction, getRemoveFromGroupInstructionAction } = useGetToGroupInstructionAction({
    onSaveSuccess: () => {
      if (updateData) {
        updateData();
      }
    },
  });
  const { getPrintLessonAction } = useGetPrintLessonAction();

  const { getSaveToMyResourceAction } = useSaveToMyResourceAction();

  const { getShareLinkLessonAction } = useShareLinkLessonAction();
  const { getCopyToEditAction } = useCopyToEditAction();

  const getActivitiesDropdownMenu = (lesson?: Lesson) => {
    if (!lesson) {
      return [];
    }
    let groupPlay;
    const { meta } = lesson;
    if (meta) {
      groupPlay = meta.groupPlay;
    }

    return [
      groupPlay ? getRemoveFromGroupInstructionAction([lesson]) : getAddToGroupInstructionAction([lesson]),
      getPrintLessonAction(lesson),
      getSaveToMyResourceAction([lesson]),
      getCopyToEditAction(lesson),
      getShareLinkLessonAction(lesson),
    ];
  };

  return {
    getActivitiesDropdownMenu,
  };
};
