import { Navigate, Route, Routes } from 'react-router-dom';
import SearchLessonsMain from './SearchLessonsMain';
import { SearchLessonsContext, useGetStudentGroupdata, useInitilzeSearchLessons } from './SearchLessonsPageHooks';
import { APP_URL } from '../../routes/RouteConstants';
import { SEARCH_LESSONS_SUB_URL } from './SearchLessonsConstant';
import TopicDetails from './components/item-details/TopicDetails';
import UnitDetails from './components/item-details/UnitDetails';
import ActivityDetails from './components/item-details/ActivityDetails';
import ActivitiesAssignmentDialogContainer from './ui/items-assignment/ActivitiesAssignmentDialogContainer';

export default function SearchLessonsPage() {
  const {
    searchLessonsCriteriaStoreRef,
    searchLessonsResultStoreRef,
    searchLessonsSelectionStoreRef,
    searchLessonsResultUiControlStoreRef,
    searchLessonsMasterDataStoreRef,
  } = useInitilzeSearchLessons();
  const studentGroupDataQuery = useGetStudentGroupdata();

  return (
    <>
      <SearchLessonsContext.Provider
        value={{
          searchLessonsCriteriaStore: searchLessonsCriteriaStoreRef.current,
          searchLessonsResultStore: searchLessonsResultStoreRef.current,
          searchLessonsSelectionStore: searchLessonsSelectionStoreRef.current,
          searchLessonsResultUiControlStore: searchLessonsResultUiControlStoreRef.current,
          searchLessonsMasterDataStore: searchLessonsMasterDataStoreRef.current,
          studentGroupData: studentGroupDataQuery.data,
        }}
      >
        <Routes>
          <Route index element={<SearchLessonsMain />} />
          <Route path={`${SEARCH_LESSONS_SUB_URL.topicDetails}/:topicId`} element={<TopicDetails />} />
          <Route path={`${SEARCH_LESSONS_SUB_URL.unitDetails}/:unitId`} element={<UnitDetails />} />
          <Route path={`${SEARCH_LESSONS_SUB_URL.activitiesDetails}/:lessonId`} element={<ActivityDetails />} />
          <Route path="*" element={<Navigate to={`${APP_URL.searchLessons}`} />} />
        </Routes>
        <ActivitiesAssignmentDialogContainer studentGroup={studentGroupDataQuery.data || []} />
      </SearchLessonsContext.Provider>
    </>
  );
}
