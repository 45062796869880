import { Stack, Typography } from '@mui/material';
import { StudentsTagsMapType } from '../models/StudentTagAssignmentType';
import { Tag } from '../../../../../types/tag-assignment/TagAssignmentType';
import { Student } from '../../../../../types/student/StudentType';
import StudentTagsForm from './StudentTagsForm';

type StudentsTagsListType = {
  students: Student[];
  studentsTagsMap: StudentsTagsMapType;
  onStudentSelect: (student: Student, isSelected: boolean) => void;
  onTagDelete: (student: Student, deletedTag: Tag) => void;
};
const StudentsTagsList = ({ students, studentsTagsMap, onStudentSelect, onTagDelete }: StudentsTagsListType) => (
  <Stack data-test='student-list' width='100%'>
    <Typography variant="subtitle2" data-test='title'>STUDENT LIST</Typography>
    <Stack data-test="student-tag-list">
      {students.map((student: Student) => {
        const addedTags = (studentsTagsMap[student.id] && studentsTagsMap[String(student.id)].addedTags) || [];
        const tags = (studentsTagsMap[student.id] && studentsTagsMap[String(student.id)].studentTags) || [];
        const isSelected = (studentsTagsMap[student.id] && studentsTagsMap[String(student.id)].isSelected) || false;
        return (
          <StudentTagsForm
            key={`assign-tag-for-student-${student.id}`}
            student={student}
            addedTags={addedTags}
            tags={tags}
            isSelected={isSelected}
            onStudentSelect={onStudentSelect}
            onTagDelete={onTagDelete}
          />
        );
      })}
    </Stack>
  </Stack>
);

export default StudentsTagsList;
