import { Button, ButtonProps, IconButton, IconButtonProps, Stack } from '@mui/material';
import { ItemRowAction } from '../../../types/ui/ItemRowMenuItem';
import CustomTooltip from '../tooltip/CustomTooltip';

type ItemRowActionsType = {
  actions?: ItemRowAction[];
};
export default function ItemRowActions({ actions }: ItemRowActionsType) {
  if (!actions || actions.length === 0) {
    return null;
  }
  return (
    <Stack direction="row" gap={1} data-test="action-container">
      {actions.map((action, index) => {
        const { isIconButton, ...rest } = action;
        if (isIconButton) {
          const iconButtonProps = rest as IconButtonProps;
          return (
            <CustomTooltip key={`${index}`} title={action.tooltipMessage} aria-label={action.tooltipMessage}>
              <IconButton key={`${index}`} {...iconButtonProps}>
                {action.children}
              </IconButton>
            </CustomTooltip>
          );
        }
        const buttonProps = rest as ButtonProps;
        return (
          <CustomTooltip key={`${index}`} title={action.tooltipMessage} aria-label={action.tooltipMessage}>
            <Button sx={{ paddingX: 1.5, width: '86px', ...buttonProps.sx }} {...buttonProps}>
              {action.children}
            </Button>
          </CustomTooltip>
        );
      })}
    </Stack>
  );
}
