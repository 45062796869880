import { User } from '../../types/user/UserType';
import { APP_URL } from '../RouteConstants';
import ActivitiesAssignmentInterimPage from '../../pages/interim/activities-assignment/ActivitiesAssignmentInterimPage';
import { IRoute } from './MfaRoutes';
import SearchLessonsPage from '../../pages/search-lessons/SearchLessonsPage';

const getTeacherRoutes = (user?: User): IRoute[] => {
  if (user?.tosFlag) {
    const result = [
      {
        path: APP_URL.interim.assignLesson,
        element: <ActivitiesAssignmentInterimPage />,
      },
      {
        path: `${APP_URL.searchLessons}/*`,
        element: <SearchLessonsPage />,
      },
    ];
    return result;
  } else {
    return [];
  }
};

export default getTeacherRoutes;
