import { createStore } from 'zustand';
import { AssignmentItem } from '../../../components/activities-assignment/models/ActivitiesAssigmentType';
import WindowParentUtil from '../../../utils/WindowParentUtil';

const initialState = {
  selectedAssignmentItems: [],
  selectedAssignmentItem: undefined,
  openActivitiesAssignmentDialog: false,
  openConfirmSnackBar: false,
  lessonLevel: undefined,
};
type StoreType = {
  selectedAssignmentItems: AssignmentItem[];
  setSelectedAssignmentItems: (selectedAssignmentItems: AssignmentItem[]) => void;
  selectedAssignmentItem: AssignmentItem | undefined;
  setSelectedAssignmentItem: (selectedAssignmentItem: AssignmentItem | undefined) => void;
  lessonLevel?: string;
  setLessonLevel: (lessonLevel?: string) => void;
  openActivitiesAssignmentDialog: boolean;
  setOpenActivitiesAssignmentDialog: (openActivitiesAssignmentDialog: boolean) => void;
  openConfirmSnackBar: boolean;
  setOpenConfirmSnackBar: (openConfirmSnackBar: boolean) => void;
  resetSelectionData: () => void;
};

export const createSearchLessonsSelectionStore = () => {
  return createStore<StoreType>((set) => ({
    ...initialState,
    setSelectedAssignmentItems: (selectedAssignmentItems: AssignmentItem[]) => set({ selectedAssignmentItems }),
    setSelectedAssignmentItem: (selectedAssignmentItem: AssignmentItem | undefined) => set({ selectedAssignmentItem }),
    setOpenActivitiesAssignmentDialog: (openActivitiesAssignmentDialog: boolean) => {
      if (openActivitiesAssignmentDialog) {
        WindowParentUtil.hideToolbar();
      } else {
        WindowParentUtil.showToolbar();
      }

      return set({ openActivitiesAssignmentDialog });
    },
    setLessonLevel: (lessonLevel?: string) => set({ lessonLevel }),
    setOpenConfirmSnackBar: (openConfirmSnackBar: boolean) => set({ openConfirmSnackBar }),
    resetSelectionData: () => set({ ...initialState }),
  }));
};

export type SearchLessonsSelectionStore = ReturnType<typeof createSearchLessonsSelectionStore> | null;
