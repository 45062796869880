import vizzleAxios from './service';
import { ActivityAssignment, ActivityAssignmentDay } from '../types/activity-assignment/ActivityAssignmentType';
import { StudentCourseAssignment } from '../types/activity-assignment/StudentCourseAssignmentType';

export default {
  assignAllStudents: async (activityAssignment: ActivityAssignment, lastAssignmentDate: string) => {
    let url = '/users/me/lessons?operation=add';
    if (lastAssignmentDate) {
      url = `${url}&latestDate=${lastAssignmentDate}`;
    }
    const result = await vizzleAxios.post(url, activityAssignment);
    return result;
  },

  assignAllStudentsForCourses: async (activityAssignmentDay: ActivityAssignmentDay, studentId?: number) => {
    let url = '/users/lessons/days';
    if (studentId) {
      url = `${url}?fromStudent=${studentId}`;
    }
    const result = await vizzleAxios.post(url, activityAssignmentDay);
    return result;
  },

  addTagsForStudentAssignments: async (
    tagRelationsips: StudentCourseAssignment[],
    objectiveType?: string
  ) => {
    let url = '/tags/?operation=createAndOrAssign';
    if (objectiveType) {
      url = `${url}&type=${objectiveType}`;
    }
    const result = await vizzleAxios.post(url, { data: tagRelationsips });
    return result;
  },

  getTagsByUserIds: async (userIds: string[], lessonIds: string[]) => {
    const url = `/tags/?filter[userIds]=${userIds}&filter[activeTagsOnly]=true&includeTagAssignmentsForLessonsWithIds=${lessonIds}`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },
};
