import { useContext } from 'react';
import { useStore } from 'zustand';
import SearchResultContainer from '../../../../ui/SearchResultContainer';
import { useSearchTopic } from './TopicViewSearchResultHooks';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useNavigate } from 'react-router-dom';
import { SEARCH_LESSONS_SUB_URL } from '../../../../SearchLessonsConstant';
import { MaxCardSelection } from '../../../../models/MaxCardSelectionConstants';
import { getSubjectOrCourseNameForTopicAndUnit } from '../../../../hooks/SubjectGroupByUserComponentsHooks';

export default function TopicViewSearchResult() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const topicSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.topicSearchPageNumber
  );
  const setTopicSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.setTopicSearchPageNumber
  );

  const searchTopicResultQuery = useSearchTopic();
  const topicSearchResult = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.topicSearchResult
  );

  const navigate = useNavigate();
  
  return (
    <SearchResultContainer
      data-test="topic-view-search-result"
      isLoading={topicSearchPageNumber === 1 && searchTopicResultQuery.isLoading}
      isLoadingMore={searchTopicResultQuery.isLoading && topicSearchPageNumber > 1}
      hasMore={topicSearchResult.data.length < (topicSearchResult.meta?.totalCount || 0)}
      onLoadMore={() => {
        setTopicSearchPageNumber(topicSearchPageNumber + 1);
      }}
      dataList={topicSearchResult.data.map((d) => ({
        id: d.id,
        type: d.type,
        title: `Topic: ${d.attributes.name}`,
        subTitles: [[`Unit: ${d.attributes.unitName}`], [
          getSubjectOrCourseNameForTopicAndUnit(d), 
          `${d.attributes.gradeLevel} Grade`]],
        image: d.attributes.thumbnailUrl,
      }))}
      getSelectedData={(selectedIndex: number) => {
        if (selectedIndex >= 0) {
          return topicSearchResult.data[selectedIndex];
        }
      }}
      onDetailClick={(selectedIndex) => {
        navigate(`${SEARCH_LESSONS_SUB_URL.topicDetails}/${topicSearchResult.data[selectedIndex].id}`);
      }}
      maxCardsSelected={MaxCardSelection.topic}
    />
  );
}
