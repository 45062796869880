export const landingStudentUrl = {
  settings: 'settings',
};

export const studentSettingsUrl = {
  themes: 'themes',
  reinforcers: 'reinforcers',
  celebrations: 'celebrations',
  voices: 'voices',
};

