import { useContext, useEffect } from 'react';
import FilterableMultiSelector from '../../../../../../ui/selector/FilterableMultiSelector';
import { useStore } from 'zustand';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import ArrayUtils from '../../../../../../utils/ArrayUtils';
import { useQuery } from '@tanstack/react-query';
import LessonStandardService from '../../../../../../services/LessonStandardService';
import { Standard } from '../../../../../../types/standard/StandardType';
import StandardUtils from '../../../../../../utils/StandardUtils';
import { useUserStore } from '../../../../../../stores/UserStore';
import { SUBJECT_ID } from '../../../../../../constants/LessonConstant';

export default function VerticalAlignmentSelector() {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const { standardScopes } = userProfile!;
  const searchLessonsContext = useContext(SearchLessonsContext);
  const selectedSubjectId = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.selectedSubjectId);
  const standardCodes = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.standardCodes);
  const verticalAlignmentIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.verticalAlignmentIds);
  const setVerticalAlignmentIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setVerticalAlignmentIds);
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const disabled = !standardCodes || standardCodes.length != 1;
  const shouldShow = selectedSubjectId && [SUBJECT_ID.SUBJECT_ID_MATH, SUBJECT_ID.SUBJECT_ID_ELA, SUBJECT_ID.SUBJECT_ID_SCIENCE].includes(selectedSubjectId);

  useEffect(() => {
    if (disabled) {
      setVerticalAlignmentIds([]);
    }
  }, [standardCodes]);

  useEffect(() => {
    if (!shouldShow) {
      setVerticalAlignmentIds([]);
    }
  }, [selectedSubjectId]);

  const verticalAlignmentQuery = useQuery({
    queryKey: ['vertical-alignment', standardCodes],
    queryFn: async () => {
      if (
        !standardCodes || standardCodes.length !== 1 || !shouldShow
      ) {
        return [];
      }
      
      const verticalAlignmentList: Standard[] = await LessonStandardService.getVerticalAlignmentByStandardCode(standardCodes[0], selectedSubjectId!);
      const uniqueVerticalAlignmentList = StandardUtils.uniqueStandardsByAttributes(verticalAlignmentList);

      const standardDropdownList = uniqueVerticalAlignmentList
        ? uniqueVerticalAlignmentList.map((s) => {
            const standardScope = standardScopes.find((ss) => ss.scopeCode === s.attributes.scope);
            return {
              id: s.attributes.standardCode,
              label: `(Grade ${s.attributes.standardGrade}) - ${s.attributes.standardCode} - ${s.attributes.standardDescription}`,
              group: standardScope ? standardScope.scopeDisplayName || '' : '',
            };
          })
        : [];
      const result = ArrayUtils.sort(ArrayUtils.sort(standardDropdownList, 'id'), 'group');
      return result;
    },
    onSuccess(data) {
      // Filter out standards not in the list
      const optionData = data as { id: string }[];
      const uniqueVerticalAlignmentCode = optionData.map((standard) => standard.id);
      const verticalIdsInList = verticalAlignmentIds.filter((s) => uniqueVerticalAlignmentCode.includes(s));
      setVerticalAlignmentIds(verticalIdsInList);
    },
  });

  if (!selectedSubjectId || ![SUBJECT_ID.SUBJECT_ID_MATH, SUBJECT_ID.SUBJECT_ID_ELA, SUBJECT_ID.SUBJECT_ID_SCIENCE].includes(selectedSubjectId)) {
    return null;
  }

  return (
    <FilterableMultiSelector
      title="Vertical Alignment"
      label="Select"
      valueIds={verticalAlignmentIds}
      isMultiple
      filterable
      loading={verticalAlignmentQuery.isLoading}
      options={verticalAlignmentQuery.data}
      onApply={(data) => {
        if (!ArrayUtils.isArrayEqual(data as string[], verticalAlignmentIds)) {
          resetSearchData();
          setVerticalAlignmentIds(data as string[]);
        }
      }}
      data-test="vertical-alignment"
      disabled={disabled}
      helperText={disabled ? 'Available with ONLY ONE standard selected' : ''}
    />
  );
}
