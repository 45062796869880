import { useStore } from 'zustand';
import VizzleSnackbar from '../snackbar/VizzleSnackbar';
import appUiStore from '../../stores/AppUiStore';

export default function VizzleMainContainer() {
  const snackbar = useStore(appUiStore, (state) => state.snackbar);
  const closeSnackbar = useStore(appUiStore, (state) => state.closeSnackbar);
  return (
    <>
      <VizzleSnackbar {...snackbar} onClose={closeSnackbar} />
    </>
  );
}
