import { useStore } from 'zustand';
import { Standard, StandardWithStateGroup, Standards } from '../types/standard/StandardType';
import { useUserStore } from '../stores/UserStore';
import ArrayUtils from '../utils/ArrayUtils';
import { StandardScope } from '../types/user/UserProfileType';

export const mapStandardData = (standards: Standards | undefined, included: Standard[]) => {
  if (!standards) {
    return [];
  }
  const standardListWithDetail = standards.data.map((s) => included.find((i) => i.type === s.type && i.id === s.id));
  const uniqueStandards = [
    // @ts-ignore
    ...new Map(standardListWithDetail.map((item: Standard) => [item.attributes.standardCode, item])).values(),
  ];
  const sortedUniqueStandards = ArrayUtils.sortByAttributes(uniqueStandards, 'standardCode');
  return sortedUniqueStandards;
};

export const useGroupStandardByStandardScope = (standards: Standard[]): StandardWithStateGroup[] => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const groupedStandard: { [key: string]: Standard[] } = {};
  standards.forEach((s) => {
    let group = groupedStandard[s.attributes.scope];
    if (!group) {
      group = [];
    }
    group.push(s);
    groupedStandard[s.attributes.scope] = group;
  });

  const { standardScopes } = userProfile!;
  const displayedGroupedStandard = Object.keys(groupedStandard)
    .map((g) => {
      const scope = standardScopes.find((s: StandardScope) => s.scopeCode === g);
      if (!scope) {
        return null;
      }
      const standardList = ArrayUtils.uniqueByAttributes(groupedStandard[g], 'standardCode');
      const sortedStandardList = ArrayUtils.sortByAttributes(standardList, 'standardCode');
      return {
        title: scope.scopeDisplayName,
        standardList: sortedStandardList,
      };
    });
    
  const filteredDisplayedGroupedStandard = displayedGroupedStandard.filter((d) => d !== null) as unknown as StandardWithStateGroup[];
  return ArrayUtils.sort(filteredDisplayedGroupedStandard, 'title');
};
