import { AlertTitle, Portal, Snackbar, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { SNACKBAR_SEVERITY } from '../../types/ui/VizzleSnackbar';

type VizzleSnackbarType = {
  severity?: SNACKBAR_SEVERITY;
  open?: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  duration?: number;
};

export default function VizzleSnackbar({ severity, open, onClose, title, message, duration }: VizzleSnackbarType) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Portal container={document.body}>
      <Snackbar data-test='snack-bar' anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} autoHideDuration={duration} onClose={handleClose} sx={{ width: '100%' }}>
        <Alert data-test='alert-container' onClose={handleClose} severity={severity} sx={{ maxWidth: '90%', minWidth: '40%' }}>
          {title && <AlertTitle data-test='title'>{title}</AlertTitle>}
          <Typography data-test='message' variant="body2" component="p" flexGrow={1}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </Portal>
  );
}
