import { createContext, useRef } from 'react';
import { StudentSelfSelectedFilterStore, createStudentSelfSelectedFilterStore } from './stores/StudentSelfSelectedFilterStore';

type LandingStudentSelfSelectedContextType = {
  studentSelfSelectedFilterStore: StudentSelfSelectedFilterStore;
} | null;
export const LandingStudentSelfSelectedContext = createContext<LandingStudentSelfSelectedContextType>(null);

export const useInitilzeLandingStudentSelfSelected = () => {
  const studentSelfSelectedFilterStoreRef = useRef(createStudentSelfSelectedFilterStore());

  return {
    studentSelfSelectedFilterStoreRef,
  };
};
