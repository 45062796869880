import { useNavigate, useParams } from 'react-router-dom';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useStore } from 'zustand';
import { useUserStore } from '../../../../stores/UserStore';
import { getSubjectObjectByName } from '../../hooks/SubjectListHooks';
import { FULL_SORTED_SUBJECT_CONSTANTS, SubjectObjectDetailType } from '../../../../constants/LessonConstant';
import { Subject } from '../../../../types/user/UserProfileType';
import WindowParentUtil from '../../../../utils/WindowParentUtil';
import { useSwitchAccessStore } from '../../../../components/switch-access/stores/SwitchAccessStore';
import { useEffect } from 'react';
import { useUpdateElementsToScan } from '../../../../components/switch-access/SwitchAccessHooks';
import SwitchAccessUtils from '../../../../components/switch-access/SwitchAccessUtils';

export const useHandleBackAction = ({
  transitionTimeout,
  setShowComponent,
}: {
  transitionTimeout: number;
  setShowComponent: (showComponent: boolean) => void;
}) => {
  const navigate = useNavigate();
  const handleBackAction = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowComponent(false);
    ObjectUtils.setTimeout(() => {
      navigate(-1);
    }, transitionTimeout + 100);
  };

  return {
    handleBackAction,
  };
};

export const useHandleOnLessonCompleteClose = ({ subjectObject }: { subjectObject?: SubjectObjectDetailType }) => {
  const navigate = useNavigate();
  const userProfile = useStore(useUserStore, (state) => state.userProfile!);
  const setUserProfile = useStore(useUserStore, (state) => state.setUserProfile!);
  const { subjects } = userProfile!;

  const handleOnLessonCompleteClose = () => {
    if (subjectObject) {
      const subjectFromProfile = getSubjectObjectByName(subjects, subjectObject?.name) as Subject;
      subjectFromProfile.completed = false;
      const udpatedUserProfile = { ...userProfile };
      setUserProfile(udpatedUserProfile);
      WindowParentUtil.updateUserProfile(udpatedUserProfile);
    }
    navigate(-1);
  };
  return { handleOnLessonCompleteClose };
};

export const usePrepareLessonToStart = () => {
  const { subjectTag } = useParams();
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const { subjects } = userProfile!;
  const subjectObject = FULL_SORTED_SUBJECT_CONSTANTS.find((subject) => subject.tag === subjectTag);

  const subjectFromProfile = getSubjectObjectByName(subjects, subjectObject?.name);

  const subjectFromProfileObject = subjectFromProfile as Subject;
  const { lessons, startIndex, completed } = subjectFromProfileObject;
  const lesson = lessons[startIndex];

  return {
    lesson,
    completed,
    subjectObject,
  };
};

export const useGetElementsForSwitchAccess = () => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const { switchAccessibility } = userProfile!;

  const switchAccess = useStore(useSwitchAccessStore, (switchAccess) => switchAccess);
  const { updateElementsToScan, resetElementsToScan } = useUpdateElementsToScan();

  const updateElements = () => {
    updateElementsToScan({
      topBar: SwitchAccessUtils.getActionElementsInToolbar(),
      body: SwitchAccessUtils.getBodyElementsInToolbar(),
      bottomBar: [],
    });
  };

  useEffect(() => {
    if (!switchAccessibility || switchAccess.isDialogShowing) {
      return;
    }

    updateElementsToScan({
      topBar: SwitchAccessUtils.getActionElementsInToolbar(),
      body: SwitchAccessUtils.getBodyElementsInToolbar(),
      bottomBar: [],
    });

    return () => {
      resetElementsToScan();
    };
  }, [switchAccess.isDialogShowing]);

  return {
    updateElements,
    resetElementsToScan,
  };
};
