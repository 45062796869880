import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './components.scss';
import {
  CssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
} from '@mui/material';
import { defaultTheme } from './theme/default/DefaultTheme';
import AppErrorBoundary from './ui/error-handler/AppErrorBoundary';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <CssVarsProvider theme={defaultTheme}>
      <CssBaseline />
      <AppErrorBoundary>
        <App />
      </AppErrorBoundary>
    </CssVarsProvider>
  </React.StrictMode>
);
