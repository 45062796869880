import { Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchLessonsFilter from './search-lessons-filters/SearchLessonsFilter';
import TextFilter from './search-lessons-filters/filters/TextFilter';
import CriteriaSummaryPanel from './criteria-summary/CriteriaSummaryPanel';
import SearchLessonResult from './search-lesson-result/SearchLessonResult';
import NotificationSummary from './notification-summary/NotificationSummary';
import ExactTextFilter from './search-lessons-filters/filters/ExactTextFilter';
import { Box } from '@mui/system';

export default function SearchLessonsContainer() {
  const theme = useTheme();

  return (
    <Grid container columns={24} height="100%" data-test="search-lesson-container">
      <Grid
        item
        xs={6}
        data-test="search-lesson-filter-container"
        boxShadow={`0px 0px 0px 1px ${theme.palette.grey[200]}`}
        height="100%"
        overflow="auto"
      >
        <SearchLessonsFilter />
      </Grid>
      <Grid item xs={18} data-test="search-lesson-result-container" paddingTop={2} paddingBottom={1} paddingX={2}>
        <Stack gap={0} height="100%">
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <TextFilter />
            <ExactTextFilter />
          </Box>
          <CriteriaSummaryPanel />
          <NotificationSummary />
          <SearchLessonResult />
        </Stack>
      </Grid>
    </Grid>
  );
}
