import { useMemo } from 'react';
import { useStore } from 'zustand';
import { FULL_SORTED_SUBJECT_CONSTANTS, SubjectObjectDetailType } from '../../../constants/LessonConstant';
import { useUserStore } from '../../../stores/UserStore';
import { ExpiredSubject, ReviewStatus, Subject, UserLesson, UserProfile } from '../../../types/user/UserProfileType';
import { FilterLessonStatus } from '../model/FilterLessonStatus';

export const getSubjectObjectByName = (
  subjects: (Subject | ExpiredSubject)[],
  subjectName?: string
): Subject | ExpiredSubject | undefined => {
  if (!subjectName) {
    return undefined;
  }
  return subjects.find((s) => s.id.replaceAll('.', '') === subjectName.replaceAll('.', ''));
};

export const getSubjectDetailBySubjectId = (subjectId: number | undefined): SubjectObjectDetailType | undefined => {
  if (!subjectId) {
    return undefined;
  }
  const subject = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.subjectId === subjectId);
  return subject;
};

export const hasChatNotification = (lessons: UserLesson[]) => lessons.findIndex((l) => l.unreadChat) >= 0;

export const filterLessonsFromProfile = (
  subjectId?: number,
  lessonStatus?: FilterLessonStatus,
  userProfile?: UserProfile
): UserLesson[] => {
  if (!subjectId || !userProfile) {
    return [];
  }
  const subject = getSubjectDetailBySubjectId(subjectId);

  let subjectFromProfileToUse: (Subject | ExpiredSubject)[] = userProfile?.subjects || [];
  if (lessonStatus === FilterLessonStatus.lateAssignments) {
    subjectFromProfileToUse = userProfile?.expiredSubjects || [];
  }

  const subjectFromProfile = getSubjectObjectByName(subjectFromProfileToUse, subject?.name);
  let lessons = subjectFromProfile?.lessons || [];
  lessons =
    subjectFromProfile?.lessons.filter((l) => {
      if (FilterLessonStatus.lateAssignments === lessonStatus) {
        return true;
      }
      if (FilterLessonStatus.currentAssignments === lessonStatus) {
        if (l.reviewStatus === ReviewStatus.initialPlayId) {
          return true;
        } else {
          return false;
        }
      }
      if (FilterLessonStatus.revision === lessonStatus) {
        if (l.reviewStatus === ReviewStatus.underRevision) {
          return true;
        } else {
          return false;
        }
      }
    }) || [];

  const filteredLessons = filterPlayableLessons(lessons);
  return filteredLessons;
};

const filterPlayableLessons = (lessons: UserLesson[]) => {
  const filteredLessons = lessons.filter((l) => {
    if (!l.played) {
      return true;
    }
    if (l.singlePlay || l.preAssessment) {
      return false;
    }
    return true;
  });

  return filteredLessons;
};

export const useGetSubjectListForStudent = ({ includeExpired }: { includeExpired: boolean }) => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const { expiredSubjects, subjects } = userProfile!;

  const subjectListToDisplay = useMemo(() => {
    const result: {
      subjectDetail: SubjectObjectDetailType;
      expiredSubjectLessons: UserLesson[];
      subjectLessons: UserLesson[];
    }[] = [];
    FULL_SORTED_SUBJECT_CONSTANTS.forEach((option) => {
      const subjectName = option.name;
      const subjectLessons = getSubjectObjectByName(subjects, subjectName)?.lessons || [];
      const expiredSubjectLessons = includeExpired
        ? getSubjectObjectByName(expiredSubjects, subjectName)?.lessons || []
        : [];

      const playableLessons = filterPlayableLessons([...subjectLessons, ...expiredSubjectLessons]);

      if (playableLessons.length > 0) {
        result.push({
          subjectDetail: option,
          subjectLessons,
          expiredSubjectLessons,
        });
      }
    });
    return result;
  }, [expiredSubjects, subjects]);

  return subjectListToDisplay;
};
