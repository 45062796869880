import { Box } from '@mui/system';
import { ReinforcerStyle } from '../../../types/user/UserProfileType';
import { FormControl } from '@mui/material';
import DropdownInput from '../../../ui/selector/DropdownInput';
import { useState } from 'react';

type AnimationStyleSettingType = {
  style: ReinforcerStyle;
  onChange: (style: ReinforcerStyle) => void;
};

const reinforcerOptions = [
  {
    value: ReinforcerStyle.animated,
    label: 'Animated',
  },
  {
    value: ReinforcerStyle.simple,
    label: 'Simple',
  },
  {
    value: ReinforcerStyle.none,
    label: 'None',
  },
];

export default function AnimationStyleSetting({ style, onChange }: AnimationStyleSettingType) {
  const [reinforcerStyle, setReinforcerStyle] = useState(style);

  return (
    <Box sx={{ mr: 3, display: 'flex' }} data-test="reinforcer-style-settings-container">
      <FormControl variant="outlined" sx={{ width: 250 }}>
        <DropdownInput
          dropdownValues={reinforcerOptions}
          label="Select Style"
          value={reinforcerStyle}
          onChange={(e) => {
            const value = Number(e.target.value);
            setReinforcerStyle(value);
            onChange(value);
          }}
          data-test="style-settings-selector"
        />
      </FormControl>
    </Box>
  );
}
