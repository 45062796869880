import { Lesson } from '../types/lesson/LessonType';
import { UserProfile } from '../types/user/UserProfileType';
import Logger from './Logger';

const WindowParentUtil = {
  postMessage: (event: string, data?: object) => {
    if (window && window.parent) {
      window.parent.postMessage({ event, data }, '*');
    }
  },
  hideToolbar: () => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'hide-toolbar' }, '*');
    }
  },
  showToolbar: () => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'show-toolbar' }, '*');
    }
  },
  previewLesson: (lesson: Lesson) => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'preview-lesson', data: lesson }, '*');
    }
  },
  playLesson: (lesson: Lesson, onLessonFinish?: () => void) => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'play-lesson', data: lesson }, '*');
    }
    if (onLessonFinish) {
      const register = (e: MessageEvent) => {
        try {
          // Get the sent data
          const data = e.data;
          if (data.event === 'lesson-finished') {
            onLessonFinish();
            window.removeEventListener('message', register);
          }
        } catch (e) {
          window.removeEventListener('message', register);
          Logger.logError(e);
        }
      };

      window.addEventListener('message', register);
    }
  },
  printLesson: (lesson: Lesson) => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'print-lesson', data: lesson }, '*');
    }
  },
  updateUserProfile: (userProfile: UserProfile) => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'update-user-profile', data: userProfile }, '*');
    }
  },
  logout: ({ showDialog }: { showDialog: boolean }) => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'logout', data: { showDialog } }, '*');
    }
  },
  updateHasDialogShown: (hasDialogShown: boolean) => {
    window.parent.postMessage({ event: 'has-dialog-shown', data: { hasDialogShown } }, '*');
  },

  showChatIcon: (show: boolean) => {
    window.parent.postMessage({ event: 'show-chat-icon', data: { show } }, '*');
  },

  getParentUrl: () => {
    // return window.parent.location.origin;
    return document.referrer;
  },

  registerMedia: () => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'register-media' }, '*');
    }
  },

  redirectTo: (path: string) => {
    if (window && window.parent) {
      window.parent.postMessage({ event: 'redirect-to', data: { path } }, '*');
    }
  },
};

export default WindowParentUtil;
