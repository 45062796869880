import { Lesson } from '../../types/lesson/LessonType';
import PrintIcon from '@mui/icons-material/Print';
import { ItemRowMenuItemType } from '../../types/ui/ItemRowMenuItem';
import WindowParentUtil from '../../utils/WindowParentUtil';

export const useGetPrintLessonAction = () => {
  const getPrintLessonAction = (lesson: Lesson): ItemRowMenuItemType => {
    return {
      id: `print-lesson-${lesson.id}`,
      label: 'Print',
      icon: <PrintIcon />,
      onClick: async (closeDropdownFunction) => {
        closeDropdownFunction();
        WindowParentUtil.printLesson(lesson);
      },
    };
  };

  return { getPrintLessonAction };
};
