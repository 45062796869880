import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SEARCH_LESSONS_SUB_URL } from '../../SearchLessonsConstant';
import { APP_URL } from '../../../../routes/RouteConstants';

export const useHandleExternalLinkClick = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleSearchLessonLink = (e: MessageEvent) => {
      if (e.data.event === 'search-lessons') {
        const detailsPath = Object.values(SEARCH_LESSONS_SUB_URL);
        const isOnDetailPage = detailsPath.findIndex((path) => location.pathname.includes(path)) >= 0;
        if (isOnDetailPage) {
          navigate(APP_URL.searchLessons);
        }
      }
    };

    window.addEventListener('message', handleSearchLessonLink);

    return () => {
      window.removeEventListener('message', handleSearchLessonLink);
    };
  }, []);
};
