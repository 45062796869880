import { useRef, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './MultiSelectorWithSearch.scss';

export type SelectorItemType = {
  id: string;
  name: string;
  value: string;
  group?: string;
};

type MultiSelectorWithSearchType = {
  selectorItems: SelectorItemType[];
  values: string[];
  className?: string;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: SelectorItemType[]) => void;
  disabled?: boolean;
  placeholderWhenEmpty?: string;
  limitTags: number;
  showSelectedValueAtTheTop?: boolean;
  isError?: boolean;
  helperText?: string;
  ['data-test']: string;
  textFieldMode?: boolean;
  onTextChange?: (value: string) => void;
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectorWithSearch({
  selectorItems,
  values,
  className,
  onChange,
  disabled,
  placeholderWhenEmpty,
  limitTags,
  showSelectedValueAtTheTop,
  isError,
  helperText,
  textFieldMode,
  onTextChange,
  ...rest
}: MultiSelectorWithSearchType) {
  const v = selectorItems.filter((s: SelectorItemType) => (values as string[]).includes(String(s.value)));

  const filterText = useRef('');
  const [open, setOpen] = useState(false);

  const [initValues, setInitValues] = useState<string[] | number[]>(values);

  let optionToUse = selectorItems;
  if (showSelectedValueAtTheTop) {
    const selectedOptions = selectorItems.filter((s: SelectorItemType) => (initValues as string[]).includes(s.value));
    const notSelectedOptions = selectorItems.filter(
      (s: SelectorItemType) => !(initValues as string[]).includes(s.value)
    );
    optionToUse = [...selectedOptions, ...notSelectedOptions];
  }

  return (
    <Autocomplete
      {...rest}
      multiple
      options={optionToUse}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      limitTags={limitTags}
      value={v}
      className={`multi-selector-with-search ${textFieldMode ? 'text-field-mode' : ''} ${className}`}
      openOnFocus
      // disableClearable
      open={open}
      groupBy={(option) => option.group || ''}
      onOpen={() => {
        if (showSelectedValueAtTheTop) {
          setInitValues(values);
        }
      }}
      onClose={(event, reason) => {
        if (reason === 'toggleInput') {
          return;
        }
        filterText.current = '';
        setOpen(false);
      }}
      onChange={(event: React.SyntheticEvent<Element, Event>, value: SelectorItemType[]) => {
        if (event.type === 'keydown') {
          return;
        }
        if (onChange) {
          onChange(event, value);
        }
      }}
      disabled={disabled}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={`option-${option.value}`} data-test={`option-${option.value}`}>
          <Checkbox
            data-test="checkbox"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      filterOptions={(optionsFilter) => {
        const filtered = optionsFilter.filter(
          (option) => option.name.toLowerCase().indexOf(filterText.current.toLowerCase()) >= 0
        );
        return filtered;
      }}
      renderInput={(params) => {
        // eslint-disable-next-line no-param-reassign
        params.inputProps.value = filterText.current;

        return (
          <TextField
            {...params}
            data-test="text-field"
            onFocus={() => {
              setOpen(Boolean(!textFieldMode) && true);
            }}
            variant="outlined"
            label={placeholderWhenEmpty}
            placeholder={placeholderWhenEmpty}
            onChange={(e) => {
              filterText.current = e.target.value;
              if (onTextChange) {
                onTextChange(e.target.value);
              }
            }}
            error={isError}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        );
      }}
    />
  );
}
