import { useContext } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStore } from 'zustand';
import SaveSuccessIcon from '../../../../assets/images/icons/SaveSuccess.svg';
import { ActivitiesAssignmentContext } from '../../ActivitiesAssignmentHooks';
import { DATE_SELECTOR_MODE } from '../../../../types/ui/DateSelector';
import EllipsisTypography from '../../../../ui/text/EllipsisTypography';
import { AssignmentItem } from '../../models/ActivitiesAssigmentType';
import { UnitTypeString } from '../../../../types/unit/UnitType';
import { TopicTypeString } from '../../../../types/topic/SearchTopicResultType';

const getPrefix = (item: AssignmentItem) => {
  if (item.type === UnitTypeString) {
    return 'Unit';
  } else if (item.type === TopicTypeString) {
    return 'Topic';
  }
  return '';
};
export default function SaveAssignmentSuccess() {
  const theme = useTheme();
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const selectedDates = useStore(activitiesAssignmentContext!, (state) => state.selectedDates);
  const selectedDateMode = useStore(activitiesAssignmentContext!, (state) => state.selectedDateMode);
  const selectedItems = useStore(activitiesAssignmentContext!, (state) => state.selectedItems);

  return (
    <Stack
      direction="row"
      gap={3}
      width={780}
      data-test="save-assignment-success"
      paddingRight={3}
      paddingBottom={3}
      // border={1}
      // borderColor={theme.palette.divider}
      // borderRadius={1}
      sx={{ boxShadow: '0px 0px 0px 1px #E0E0E0' }}
    >
      <img src={SaveSuccessIcon} alt="Save success" data-test="logo" width={200} />
      <Stack marginTop={3} gap={3} data-test="message">
        <Typography variant="h6" data-test="message-title">
          Assignment Sent!
        </Typography>
        <Grid container columnSpacing={0} height="100%" rowGap={2} data-test="grid-container">
          <Grid item xs={3} data-test="date-title-grid-container">
            <Typography data-test="date-title" color={theme.palette.grey[700]}>
              Dates
            </Typography>
          </Grid>
          <Grid item xs={9} data-test="date-selected-grid-container">
            <EllipsisTypography row={2} data-test="date-value">
              {selectedDateMode === DATE_SELECTOR_MODE.MULTIPLE_DATES
                ? selectedDates!.join(', ')
                : `${selectedDates![0]}-${selectedDates![1]}`}
            </EllipsisTypography>
          </Grid>

          <Grid item xs={3} data-test="items-grid-container">
            <Typography data-test="items-title" color={theme.palette.grey[700]}>
              Items
            </Typography>
          </Grid>
          <Grid item xs={9} data-test="selected-items-container">
            <EllipsisTypography row={2} data-test="items-title">
              {selectedItems?.map((item) => `${getPrefix(item)} ${item.attributes.name}`).join(', ')}
            </EllipsisTypography>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
