import { useContext } from 'react';
import { useStore } from 'zustand';
import { useMutation } from '@tanstack/react-query';
import { ActivitiesAssignmentContext, getUserIds, useGetAssignmentLessons } from '../../ActivitiesAssignmentHooks';
import { useUserStore } from '../../../../stores/UserStore';
import LessonService from '../../../../services/LessonService';
import LessonTagService from '../../../../services/LessonTagService';
import {
  ActivityAssignment,
  ActivityAssignmentDay,
  LessonAssignment,
  LessonInfo,
  RETAKE_OPTIONS,
} from '../../../../types/activity-assignment/ActivityAssignmentType';
import { DATE_SELECTOR_MODE } from '../../../../types/ui/DateSelector';
import LessonUtils from '../../../../utils/LessonUtils';
import { ActivitiessAssignmentType } from '../../models/ActivitiesAssigmentType';
import { User } from '../../../../types/user/UserType';
import { Lesson } from '../../../../types/lesson/LessonType';

const saveAssignment = async (
  activitiesAssignment: ActivitiessAssignmentType,
  selectedLessons: Lesson[],
  addToMyResourceFlag: boolean,
  user: User,
  userIds: string[],
  retakeOption: RETAKE_OPTIONS,
  selectedDates: string[] | null | undefined
) => {
  const promiseList = [];
  const selectedLessonIds = selectedLessons.map((lesson) => lesson.id);
  if (activitiesAssignment?.showAddToMyList && addToMyResourceFlag) {
    const currentStudent = activitiesAssignment?.currentStudent;
    const assignLessonsPromise = LessonService.assignLesson(
      selectedLessonIds,
      user!.id,
      currentStudent && currentStudent.id
    );
    promiseList.push(assignLessonsPromise);
  }

  const teacherId = activitiesAssignment?.showAddToMyList && addToMyResourceFlag ? user?.id : null;

  const userIdsToUse = [...userIds];
  if (teacherId) {
    userIdsToUse.push(String(teacherId));
  }

  const activityAssignment: ActivityAssignment = {
    userIds: userIdsToUse,
    lessonIds: selectedLessonIds,
    singlePlay: retakeOption === RETAKE_OPTIONS.RETAKE_SINGLE_PLAY,
  };
  const assignAllStudentPromise = LessonTagService.assignAllStudents(
    activityAssignment,
    selectedDates![selectedDates!.length - 1]
  );
  promiseList.push(assignAllStudentPromise);

  await Promise.all(promiseList);
};

const assignStudentForCourse = async (
  activitiesAssignment: ActivitiessAssignmentType,
  selectedLessons: Lesson[],
  userIds: string[],
  selectedDateMode: DATE_SELECTOR_MODE,
  selectedDates: string[] | null | undefined
) => {
  const lessonAssignments: LessonAssignment[] = [];
  selectedLessons?.forEach((l, index) => {
    const subjectId = LessonUtils.getSubjectId(l);
    const lessonAssignment = lessonAssignments.find((la) => la.subjectId === subjectId);
    const lessonObject: LessonInfo = {
      lId: l.id,
      status: 0,
      seq: index,
    };

    if (lessonAssignment) {
      lessonAssignment.lessonInfos.push(lessonObject);
    } else {
      const newLessonAssignment = {
        subjectId,
        lessonInfos: [lessonObject],
      };
      lessonAssignments.push(newLessonAssignment);
    }
  });

  const activityAssignmentDay: ActivityAssignmentDay = {
    userIds,
    days: selectedDateMode! === DATE_SELECTOR_MODE.MULTIPLE_DATES ? selectedDates : undefined,
    startDate: selectedDateMode! === DATE_SELECTOR_MODE.DATE_RANGE ? selectedDates![0] : undefined,
    endDate:
      selectedDateMode! === DATE_SELECTOR_MODE.DATE_RANGE ? selectedDates![selectedDates!.length - 1] : undefined,
    lessonAssignments,
  };

  await LessonTagService.assignAllStudentsForCourses(
    activityAssignmentDay,
    activitiesAssignment!.currentStudent && activitiesAssignment!.currentStudent?.id
  );
};

const saveAutoTag = async (
  activitiesAssignment: ActivitiessAssignmentType,
  selectedLessons: Lesson[],
  selectedStudentIds: string[]
) => {
  // Auto tag
  const { tagNameForAutoSaving: tagNameForAutoSavingData } = activitiesAssignment;
  const tagsLessonsMap: { [key: string]: string[] } = {};
  if (!tagNameForAutoSavingData || tagNameForAutoSavingData.length === 0) {
    // build custom tag string
    selectedLessons.forEach((lesson) => {
      if (
        lesson.attributes.topicId &&
        lesson.attributes.courseName &&
        lesson.attributes.unitName &&
        lesson.attributes.topicName
      ) {
        const autoTag = `${lesson.attributes.courseName}, ${lesson.attributes.unitName}, ${lesson.attributes.topicName}, Level ${lesson.attributes.lessonLevel}`;
        let lessonsForTag = tagsLessonsMap[autoTag];
        if (!lessonsForTag) {
          lessonsForTag = [];
          tagsLessonsMap[autoTag] = [];
        }
        if (autoTag) {
          lessonsForTag.push(lesson.id);
          tagsLessonsMap[autoTag] = [...lessonsForTag];
        }
      }
    });
  }

  if (tagsLessonsMap && Object.keys(tagsLessonsMap).length > 0 && selectedStudentIds && selectedStudentIds.length > 0) {
    const tagRelationships = Object.keys(tagsLessonsMap).map((tagName) => {
      const lessonIds = tagsLessonsMap[tagName];
      return {
        type: 'tags',
        attributes: {
          name: tagName,
        },
        relationships: {
          students: {
            data: selectedStudentIds.map((studentId) => ({
              id: studentId,
              type: 'users',
            })),
          },
          lessons: {
            data: lessonIds.map((lessonId) => ({
              id: lessonId,
              type: 'lessons',
            })),
          },
        },
      };
    });

    await LessonTagService.addTagsForStudentAssignments(tagRelationships, activitiesAssignment.objectiveType);
  }
};

export const useSaveData = ({ onSaveSuccess }: { onSaveSuccess: () => void }) => {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const state = useStore(activitiesAssignmentContext!, (state) => state);
  const user = useStore(useUserStore, (state) => state.user);

  const saveAssignmentKey = useStore(activitiesAssignmentContext!, (state) => state.saveAssignmentKey);
  const lessons = useGetAssignmentLessons();

  const mutation = useMutation({
    mutationKey: saveAssignmentKey,
    mutationFn: async () => {
      const {
        activitiesAssignment,
        selectedGroupIds,
        selectedStudentIds,
        selectedDates,
        selectedDateMode,
        retakeOption,
        addToMyResourceFlag,
      } = state;

      const userIds = getUserIds(activitiesAssignment!, selectedGroupIds, selectedStudentIds);
      await saveAssignment(
        activitiesAssignment!,
        lessons,
        addToMyResourceFlag,
        user!,
        userIds,
        retakeOption,
        selectedDates
      );
      await assignStudentForCourse(activitiesAssignment!, lessons!, userIds, selectedDateMode, selectedDates);
      await saveAutoTag(activitiesAssignment!, lessons, selectedStudentIds);
    },
    onSuccess() {
      onSaveSuccess();
    },
  });
  return mutation;
};
