import ChatIcon from '@mui/icons-material/Chat';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { ReviewStatus, UserLesson } from '../../../../types/user/UserProfileType';
import { ChipProps } from '@mui/material';
import { StudentSummary } from '../../../../types/student/StudentSummary';

export const getLessonChips = ({
  lesson,
  subjectId,
  studentLessonSummary,
}: {
  lesson: UserLesson;
  subjectId: number;
  studentLessonSummary?: StudentSummary;
}): ChipProps[] => {
  const chips: ChipProps[] = [];
  if (lesson.unreadChat) {
    chips.push({
      id: `chip-${lesson.id}-unread-chip`,
      icon: <ChatIcon />,
      label: 'Unread Message',
      color: 'secondary',
    } as ChipProps);
  }

  const summaryValue = (studentLessonSummary && studentLessonSummary[subjectId].key) || [];

  if (lesson.played || summaryValue.includes(lesson.id)) {
    chips.push({
      id: `chip-${lesson.id}-played-chip`,
      icon: <TaskAltIcon />,
      label: 'Played',
      color: 'success',
    } as ChipProps);
  }
  if (lesson.feedbackLoopValue && (ReviewStatus.underReview === lesson.reviewStatus || lesson.played)) {
    chips.push({
      id: `chip-${lesson.id}-teahcer-review-chip`,
      icon: <ChecklistIcon />,
      label: 'Teacher Review',
      color: 'primary',
    } as ChipProps);
  }
  return chips;
};
