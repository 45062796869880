const TOOLBAR_ACTION = 'data-switch-access-toolbar';
export const switchAccessToolbarElements = {
  [TOOLBAR_ACTION]: 'true',
};

const BODY_ELEMENTS = 'data-switch-access-body';
export const switchAccessBodyElements = {
  [BODY_ELEMENTS]: 'true',
};

export default {
  getActionElementsInToolbar: () => {
    const el = document.querySelectorAll(`[${TOOLBAR_ACTION}='true']`);
    return [...el] as HTMLElement[];
  },

  getBodyElementsInToolbar: () => {
    const el = document.querySelectorAll(`[${BODY_ELEMENTS}='true']`);
    return [...el] as HTMLElement[];
  },
};
