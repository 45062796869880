import { useState } from 'react';
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { ExpandMore as ExpandMoreIcon, Settings as SettingsIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { landingStudentUrl } from '../../student-settings/constants/StudentSettingsConst';
import { useNavigate } from 'react-router-dom';
import WindowParentUtil from '../../../utils/WindowParentUtil';
import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';


export default function StudentMenuDropdown() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const user = useStore(useUserStore, (state) => state.user);
  const { switchAccessibility } = userProfile!;

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const options = userProfile?.studentSettings ? [
    {
      title: 'Settings',
      icon: <SettingsIcon />,
      navigate: landingStudentUrl.settings,
    },
  ] : [];

  return (
    <Box data-test='student-menu-dropdown'>
      <Button
        color="textSecondary"
        size="large"
        sx={{ fontWeight: 500 }}
        onClick={handleClickListItem}
        endIcon={<ExpandMoreIcon />}
        data-test='welcome-first-name'
      >
        Welcome {user?.firstName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu',
          role: 'listbox',
        }}
        data-test='menu'
      >
        {options.map((option) => (
          <MenuItem
            key={option.title}
            onClick={() => {
              handleMenuItemClick();
              navigate(option.navigate);
            }}
            sx={{ minWidth: 181, height: 56, marginBottom: 1 }}
            data-test={`menu-item-${option.title}`}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            {option.title}
          </MenuItem>
        ))}

        <MenuItem
          onClick={() => {
            handleMenuItemClick();
            WindowParentUtil.logout({ showDialog: switchAccessibility === 0 });
          }}
          sx={{ minWidth: 181, height: 56 }}
          data-test='logout-button'
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
