import { useStore } from 'zustand';
import UserProfileService from '../../services/UserProfileService';
import appUiStore from '../../stores/AppUiStore';
import { useUserStore } from '../../stores/UserStore';
import { SNACKBAR_SEVERITY } from '../../types/ui/VizzleSnackbar';
import { UserProfile } from '../../types/user/UserProfileType';
import WindowParentUtil from '../../utils/WindowParentUtil';
import { useMutation } from '@tanstack/react-query';

export const useUpdateProfile = (saveSuccessMessage: string) => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile!);
  const setUserProfile = useStore(useUserStore, (state) => state.setUserProfile!);
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  const updateUserProfileMutation = useMutation({
    mutationKey: ['update-user-profile'],
    mutationFn: async (newData: UserProfile) => {
      const updatedUserProfile = { ...userProfile, ...newData } as UserProfile;
      setUserProfile(updatedUserProfile);
      WindowParentUtil.updateUserProfile(updatedUserProfile);
      await UserProfileService.updateUserProfile(userProfile.id, newData);
      openSnackbar({
        severity: SNACKBAR_SEVERITY.SUCCESS,
        open: true,
        message: saveSuccessMessage,
        duration: 3000,
      });
    },
  });

  return { updateUserProfileMutation };
  // const userProfile = useStore(useUserStore, (state) => state.userProfile!);
  // const setUserProfile = useStore(useUserStore, (state) => state.setUserProfile!);
  // const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  // const updateProfileSettingsOnChange = async (newData: UserProfile, snackBarText?: string) => {
  //   const udpatedUserProfile = { ...userProfile, ...newData } as UserProfile;
  //   setUserProfile(udpatedUserProfile);
  //   WindowParentUtil.updateUserProfile(udpatedUserProfile);

  //   const res = await UserProfileService.updateUserProfile(userProfile.id, newData);
  //   if (res.status === 204) {
  //     openSnackbar({
  //       severity: SNACKBAR_SEVERITY.SUCCESS,
  //       open: true,
  //       message: `${snackBarText ?? 'Settings has been updated.'}`,
  //       duration: 3000,
  //     });
  //   }
  // };

  // return { updateProfileSettingsOnChange };
};
