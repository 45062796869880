import { useStore } from 'zustand';
import { useUserStore } from '../../stores/UserStore';
import { getUserToken } from '../../utils/VizzleSessionStorage';
import { APP_URL } from '../RouteConstants';

export const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const user = useStore(useUserStore, (state) => state.user);
  const userToken = getUserToken();
  if (userToken && user) {
    return element;
  }
  // eslint-disable-next-line no-unused-expressions
  // force the url to change to login screen
  window.location.href = APP_URL.index;
  return null;
};

export default PrivateRoute;
