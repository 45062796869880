import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import './HoverTooltip.scss';

type HtmlTooltipType = {
  className?: string;
} & TooltipProps;

const HtmlTooltip = styled(({ className, ...props }: HtmlTooltipType) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));

/**
 * Tooltip dialog component.
 * Dialogs inform users about a task and can contain critical information, require decisions
 */
type HoverTooltipType = {
  className: string;
  title: JSX.Element | string;
  messages: JSX.Element[] | string[];
  onClick: () => void;
};
export default function HoverTooltip({
  className,
  title,
  // eslint-disable-next-line no-unused-vars
  messages,
  onClick,
}: HoverTooltipType) {
  return (
    <div className={`hover-tooltip ${className}`}>
      <HtmlTooltip
        title={
          <>
            <Typography>
              <strong>{title}</strong>
            </Typography>
            {messages.map((m, index) => (
              <p key={`tooltip-message-${index}`}>{m}</p>
            ))}
          </>
        }
      >
        <HelpIcon className="hover-tooltip-icon" onClick={onClick} />
      </HtmlTooltip>
    </div>
  );
}

HoverTooltip.defaultProps = {
  title: 'title',
  messages: ['text line 1', 'text line 2', 'text line 3'],
  className: '',
  onClick: () => null,
};

HoverTooltip.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onClick: PropTypes.func,
};
