import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import DropdownInput from '../../../../../../ui/selector/DropdownInput';
import { LEVEL_OPTIONS } from '../../../../../../constants/LevelConstant';
import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Lesson } from '../../../../../../types/lesson/LessonType';
import { LessonResult } from '../../../../../../types/lesson/SearchLessonResultType';
import { ActivitiesAssignmentContext, createKeyMapForLessonSelectionType } from '../../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import ArrayUtils from '../../../../../../utils/ArrayUtils';
import { SelectedLessonByType, TopicLesson } from '../../../../models/ActivitiesAssigmentType';
import LessonCardListItem from './LessonCardListItem';

type LessonByLevelListItem = {
  id: string;
  type: string;
  title: string;
  lessonLevel?: string;
  subtitle?: string;
  grade: string;
  subject: string;
  getDataFunction: (id: string, level: string) => Promise<LessonResult | { data: TopicLesson[] }>;
  'data-test': string;
  onDeleteClick: () => void;
};

export default function LessonByLevelListItem({
  id,
  type,
  title,
  subtitle,
  lessonLevel,
  grade,
  subject,
  getDataFunction,
  onDeleteClick,
  ...rest
}: LessonByLevelListItem & StackProps) {
  const theme = useTheme();
  const [level, setLevel] = useState(lessonLevel || '');
  const keyMap = createKeyMapForLessonSelectionType(id, type);

  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const selectedLessonsByType = useStore(activitiesAssignmentContext!, (state) => state.selectedLessonsByType);
  const setSelectedLessonsByType = useStore(activitiesAssignmentContext!, (state) => state.setSelectedLessonsByType);

  const lessonQuery = useQuery({
    queryKey: [id, type, level],
    queryFn: async () => {
      if (level) {
        const result = await getDataFunction(id, level);
        return result;
      }
      return {
        data: undefined,
        included: [],
      };
    },
    onSuccess: async (data) => {
      if (data && data.data) {
        const newMap = new Map(selectedLessonsByType);
        newMap.set(keyMap, data.data);
        setSelectedLessonsByType(newMap);
      }
    },
  });

  const lessons = selectedLessonsByType.get(keyMap) || [];
  const lessonsIds = lessons.map((lesson) => lesson.id);

  const handleSelectLessonChange = (lesson: Lesson | TopicLesson, checked: boolean) => {
    const newMap = new Map(selectedLessonsByType);
    let newLessons: SelectedLessonByType[] = [];
    if (checked) {
      const indexToInsert = lessonQuery?.data?.data?.findIndex((l) => l.id === lesson.id);
      if (indexToInsert === undefined) {
        return;
      }
      newLessons = ArrayUtils.insert(lessons, indexToInsert, lesson);
    } else {
      newLessons = lessons.filter((l) => l.id !== lesson.id);
    }
    newMap.set(keyMap, newLessons);
    setSelectedLessonsByType(newMap);
  };

  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        width: '90%',
        '&.MuiPaper-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      }}
      disableGutters
      defaultExpanded
      data-test={rest['data-test']}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '.MuiAccordionSummary-content': {
            margin: 0,
          },
          paddingLeft: 0,
          paddingY: 0.5,
        }}
      >
        <LessonCardListItem
          title={title}
          subtitle={subtitle}
          grade={grade}
          subject={subject}
          data-test={`item-to-assign-container-lesson-${id}`}
          onDeleteClick={onDeleteClick}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingBottom: 0, paddingLeft: 0 }}>
        <DropdownInput
          labelProps={{ variant: 'subtitle1' }}
          loading={lessonQuery.isLoading}
          dropdownValues={LEVEL_OPTIONS}
          value={level}
          onChange={(e) => {
            const { value } = e.target;
            setLevel(value);
          }}
          data-test="level-selector"
          inlineLabel={!level ? 'Select a level to view/assign' : ''}
          selectorProps={{
            error: !level,
          }}
        />

        <Stack data-test="lesson-container" gap={2} marginTop={2}>
          {lessonQuery.data?.data?.map((lesson: Lesson | TopicLesson) => (
            <FormControlLabel
              key={`lesson-${lesson.id}`}
              data-test={`conent-library-option-${lesson.id}`}
              control={
                <Checkbox
                  checked={lessonsIds.includes(lesson.id)}
                  onChange={(e) => {
                    handleSelectLessonChange(lesson, e.target.checked);
                  }}
                />
              }
              label={lesson.attributes.name}
            />
          ))}
        </Stack>
      </AccordionDetails>

      {!lessonQuery.isLoading && level && lessons.length === 0 ? (
        <Typography paddingTop={1} color={theme.palette.error.main}>
          Select at least one item above
        </Typography>
      ) : null}
    </Accordion>
  );
}
