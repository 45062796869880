import { Route } from 'react-router-dom';
import PrivateRoute from '../router/PrivateRoute';
import TwoFactorPrivateRoute from '../router/TwoFactorPrivateRoute';
import { User } from '../../types/user/UserType';

export type IRoute = { path: string; element: JSX.Element };

const getMfaRoutes = (): IRoute[] => [
  // { path: URL_SETUP_TWO_FACTOR_AUTH, element: <SetupTwoFactorAuthentication /> },
  // { path: URL_ENTER_TWO_FACTOR_AUTH, element: <EnterTwoFactorAuthentication /> },
  // { path: URL_TERMS_OF_USE, element: <TermsOfUse /> },
];

export const generateMfaRoutes = (availableRoutes: IRoute[], user?: User) => {
  const routes = getMfaRoutes();
  const mfaRoutes = routes.map((route) => (
    <Route
      key={`route-${route.path}`}
      path={route.path}
      element={<PrivateRoute element={route.element} />}
    />
  ));
  
  const InternalRoute =
    import.meta.env.VITE_SKIP_MFA === 'true'
      ? PrivateRoute
      : TwoFactorPrivateRoute;

  const appRoutes = availableRoutes
    // .filter((a) => a.path !== URL_TERMS_OF_USE)
    .map((route: IRoute) => (
      <Route
        key={`route-${route.path}`}
        path={route.path}
        // @ts-ignore
        element={<InternalRoute element={route.element} user={user} />}
      />
    ));
  return [...mfaRoutes, ...appRoutes];
};

export default getMfaRoutes;
