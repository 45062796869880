import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { v1 as uuidv1 } from 'uuid';
import { Stack, TextField, Typography } from '@mui/material';
import { AddCircle as AddCircleIcon, NearMe as NearMeIcon } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './LessonTagAutocompleteForm.scss';
import { Tag } from '../../../../../types/tag-assignment/TagAssignmentType';

const filter = createFilterOptions();
const emptyValue = {
  attributes: {
    name: '',
  },
};

const LessonTagAutocompleteForm = ({
  allLessonTags,
  skipAddingTagNames,
  placeholder,
  onTagAdded,
  onTagSelected,
}: {
  allLessonTags: Tag[];
  skipAddingTagNames: string[];
  placeholder: string;
  onTagAdded: (newTag: Tag) => void;
  onTagSelected: (selectedTag: Tag) => void;
}) => {
  const [value, setValue] = useState({ ...emptyValue });
  const theme = useTheme();

  const resetValue = () => {
    // Reset value
    setValue({ ...emptyValue });
  };

  const handleSelect = (selectedTag: Tag) => {
    onTagSelected(selectedTag);
    resetValue();
  };

  const handleAdd = (tag: string) => {
    onTagAdded({
      id: uuidv1(),
      attributes: {
        name: tag,
      },
    });
    resetValue();
  };

  return (
    <Autocomplete
      value={value}
      fullWidth
      className="lesson-tag-autocomplete"
      data-test="lesson-tag-autocomplete"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(event, newValue: any) => {
        console.log(newValue, typeof newValue);
        if (typeof newValue === 'string') {
          const matched = allLessonTags.find((t: Tag) => t.attributes.name.toLowerCase() === newValue.toLowerCase());
          if (matched) {
            // console.log('call add existing tag (press Enter)', matched);
            handleSelect(matched);
          } else {
            // console.log('call add new tag (press Enter)', newValue);
            handleAdd(newValue);
          }
        } else if (newValue && newValue.inputValue) {
          // console.log('call add new tag (press add)', newValue.inputValue);
          handleAdd(newValue.inputValue);
        } else if (newValue) {
          // console.log('call add existing tag (from list)', newValue);
          handleSelect(newValue as Tag);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          const matched = filtered.findIndex(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (f: any) => f.attributes.name.toLowerCase() === params.inputValue.toLowerCase()
          );
          if (matched < 0 && !skipAddingTagNames.includes(params.inputValue.toLowerCase())) {
            filtered.unshift({
              inputValue: params.inputValue,
              attributes: {
                name: (
                  <Stack justifyContent="center" direction="row" gap={1} data-test="new-tag-container">
                    <AddCircleIcon
                      htmlColor={theme.palette.primary.main}
                      className="new-tag-icon"
                      data-test="new-tag-icon"
                    />
                    <Typography data-test="add-action">
                      Add &quot;<b>{params.inputValue}</b>&quot;
                    </Typography>
                  </Stack>
                ),
              },
            });
          }
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      blurOnSelect
      handleHomeEndKeys
      options={allLessonTags}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.attributes ? option.attributes.name : '';
      }}
      renderOption={(props, option) => {
        if (!option.attributes) {
          return '';
        }
        const { name } = option.attributes;
        if (typeof name === 'string') {
          return (
            <li {...props} key={`option-${option.id}`} data-test={`option-${option.id}`}>
              <Stack direction="row" alignItems="center" data-test="tag-name-container">
                <NearMeIcon className="tag-icon" htmlColor={theme.palette.success.main} data-test="tag-name-icon"/>
                {name}
              </Stack>
            </li>
          );
        }
        return (
          <li {...props} key={`option-${option.id}`} data-test={`option-${option.id}`}>
            {name}
          </li>
        );
      }}
      isOptionEqualToValue={(option, v) => {
        if (v && v.attributes && v.attributes.name === '') {
          return emptyValue;
        }
        return option;
      }}
      renderInput={(params) => (
        <TextField {...params} className="auto-complete-textfield" data-test="auto-complete-textfield" placeholder={placeholder} variant="outlined" />
      )}
    />
  );
};

export default LessonTagAutocompleteForm;
