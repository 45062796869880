import { Box, Stack, Typography } from '@mui/material';
import VizzleLogo from '../../assets/images/logo/VizzleLogo.svg';

export default function IndexPage() {
  return (
    <Stack direction="column" flexGrow={1} height='100vh' alignItems='center'>
      <Box width='30%' marginTop={10}>
        <img src={VizzleLogo} />
      </Box>
      <Typography variant='h5' mt={5}>
        Welcome to Vizzle NextGen
      </Typography>
    </Stack>
  );
}
