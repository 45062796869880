import { createStore } from 'zustand';

const initialState = {
  searchResultContainerScrollTop: 0,
};

type StoreType = {
  searchResultContainerScrollTop: number;
  setSearchResultContainerScrollTop: (searchResultContainerScrollTop: number) => void;
  reset: () => void;
};

export const createSearchLessonsResultUiControlStore = () => {
  return createStore<StoreType>((set) => ({
    ...initialState,
    setSearchResultContainerScrollTop: (searchResultContainerScrollTop: number) =>
      set({ searchResultContainerScrollTop }),
    reset: () => set({ ...initialState }),
  }));
};

export type SearchLessonsResultUiControlStore = ReturnType<typeof createSearchLessonsResultUiControlStore> | null;
