import { useContext } from 'react';
import { useStore } from 'zustand';
import { Grid } from '@mui/material';
import FormPanel from '../../../../ui/panel/FormPanel';
import { ActivitiesAssignmentContext } from '../../ActivitiesAssignmentHooks';
import ItemsToAssignList from './components/items-to-assign/ItemsToAssignList';
import StudentAndGroupSelector from './components/student-selector/StudentAndGroupSelector';
import AssignmentSettings from './components/settings/AssignmentSettings';
import AssignActivitiesFormAction from './AssignActivitiesFormAction';
import { useIsMutating } from '@tanstack/react-query';

type AssignActivitiesFormType = {
  onSaveSuccess: () => void;
};
export default function AssignActivitiesForm({ onSaveSuccess }: AssignActivitiesFormType) {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const handleCloseForm = useStore(activitiesAssignmentContext!, (state) => state.handleCloseForm);

  const saveAssignmentKey = useStore(activitiesAssignmentContext!, (state) => state.saveAssignmentKey);
  const isMutating = useIsMutating({ mutationKey: saveAssignmentKey });
  return (
    <FormPanel
      title="Assign"
      data-test='activity-assignment-form'
      content={
        <Grid container columnSpacing={0} height="100%" data-test='activity-assignment-grid-container'>
          <Grid item xs={4} data-test='item-to-assign'>
            <ItemsToAssignList />
          </Grid>
          <Grid item xs={4} data-test='student-selector'>
            <StudentAndGroupSelector />
          </Grid>
          <Grid item xs={4} data-test='assignment-settings'>
            <AssignmentSettings />
          </Grid>
        </Grid>
      }
      bottomBar={<AssignActivitiesFormAction onSaveSuccess={onSaveSuccess} />}
      displayCloseIcon
      onClose={() => {
        handleCloseForm();
      }}
      disableClose={isMutating > 0}
    />
  );
}
