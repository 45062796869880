import { Course } from '../types/course/CourseType';
import vizzleAxios from './service';

export default {
  getLessonCourses: async (primaryOnly?: boolean): Promise<Course[]> => {
    let url = '/courses';
    if (primaryOnly !== undefined) {
      url = `${url}?primary_only=${primaryOnly}`;
    }
    const result = await vizzleAxios.get(url);
    return result.data;
  },
};
