import { useEffect, useRef } from 'react';
import { User } from '../../../types/user/UserType';
import { UserProfile } from '../../../types/user/UserProfileType';
import { Terminologies } from '../../../types/user/TerminologiesType';
import { Student } from '../../../types/student/StudentType';
import { useUserStore } from '../../../stores/UserStore';
import { useStore } from 'zustand';
import { saveTokenToMemory } from '../../../utils/VizzleSessionStorage';
import { useNavigate } from 'react-router-dom';
import { useStudentStore } from '../../../stores/StudentStore';
// import GainsightService from '../../../services/third-party/GainsightService';
// import UserUtil from '../../../utils/User';

const EVENT = {
  pageReady: 'page-ready',
  initialization: 'initialization',
};

type MessageData = {
  path: string;
  payload: unknown;
  userToken: string;
  userInfo: {
    user: User;
    userProfile: UserProfile;
    terminologies: Terminologies;
    students: Student[];
  };
};
export const useInitialPage = () => {
  const isSent = useRef(false);

  const setUserData = useStore(useUserStore, (state) => state.setUserData);
  const setStudents = useStore(useStudentStore, (state) => state.setStudents);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSent.current) {
      isSent.current = true;
      window.parent.postMessage({ event: EVENT.pageReady }, '*');
    }

    const register = (e: MessageEvent) => {
      // Get the sent data
      const data = e.data;
      if (data.event === EVENT.initialization) {
        const messageData: MessageData = data.data;
        setUserData!({
          user: messageData.userInfo.user,
          userProfile: messageData.userInfo.userProfile,
          terminologies: messageData.userInfo.terminologies,
        });
        setStudents!(messageData.userInfo.students);
        saveTokenToMemory(messageData.userToken);
        navigate(messageData.path, {
          state: {
            data: messageData.payload,
          },
        });

        // if (UserUtil.isTeacher(messageData.userInfo.user)) {
        //   GainsightService.trackTeacher(messageData.userInfo.user);
        // } else {
        //   GainsightService.trackStudent(messageData.userInfo.user);
        // }
      }
    };

    window.addEventListener('message', register);

    return () => {
      window.removeEventListener('message', register);
    };
  }, []);
};
