import { CardActionArea, Skeleton, Stack } from '@mui/material';
import { TopicListType } from '../types/TopicList';
import ItemRowCard from '../../../../../ui/cards/item-row-card/ItemRowCard';
import WindowParentUtil from '../../../../../utils/WindowParentUtil';
import { useNavigate } from 'react-router-dom';
import { APP_URL } from '../../../../../routes/RouteConstants';
import { SEARCH_LESSONS_SUB_URL } from '../../../SearchLessonsConstant';

export default function TopicList({ topics, isLoading, lesson }: TopicListType) {
  const navigate = useNavigate();

  if (isLoading) {
    <Stack direction="column" gap={1}>
      <Skeleton variant="rounded" width="100%" height={60} />
      <Skeleton variant="rounded" width="100%" height={60} />
      <Skeleton variant="rounded" width="100%" height={60} />
      <Skeleton variant="rounded" width="100%" height={60} />
      <Skeleton variant="rounded" width="100%" height={60} />
    </Stack>;
  }

  if (!topics || topics.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" gap={1}>
      {topics.map((topic, index) => (
        <CardActionArea
          key={`row-${topic.id}-${index}`}
          onClick={() => {
            navigate(`${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.topicDetails}/${topic.id}`);
          }}
        >
          <ItemRowCard
            id={String(topic.id)}
            title={`Topic ${topic.name}`}
            image={topic.thumbnailUrl}
            imageHeight="55px"
          />
        </CardActionArea>
      ))}

      {lesson && (
        <CardActionArea
          key={`row-${lesson.id}`}
          onClick={() => {
            navigate(`${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.activitiesDetails}/${lesson.id}`);
          }}
        >
          <ItemRowCard
            id={String(lesson?.id)}
            title={lesson?.attributes.name || ''}
            image={lesson?.attributes?.thumbnailUrl || ''}
            imageHeight="55px"
            actions={[
              {
                variant: 'roundedBorder',
                'data-test': 'activity-play-action',
                onClick: (e) => {
                  e.stopPropagation();
                  WindowParentUtil.playLesson(lesson!, () => {});
                },
                children: 'Play',
              },
              {
                variant: 'roundedBorder',
                'data-test': 'activity-preview-action',
                onClick: (e) => {
                  e.stopPropagation();
                  WindowParentUtil.previewLesson(lesson!);
                },
                children: 'Preview',
              },
            ]}
          />
        </CardActionArea>
      )}
    </Stack>
  );
}
