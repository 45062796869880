import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

type SearchInputFieldType = TextFieldProps & {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onKeywordClear?: () => void;
};
export default function SearchInputField({
  placeholder,
  value,
  onChange,
  onKeywordClear,
  ...rest
}: SearchInputFieldType) {
  return (
    <TextField
      {...rest}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton aria-label="Clear keyword" onClick={onKeywordClear}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      variant="outlined"
    />
  );
}
