import { useRef } from 'react';
import { Box, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import Lottie, { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';
import { useTheme } from '@mui/material/styles';
import { switchAccessBodyElements } from '../../../components/switch-access/SwitchAccessUtils';

type SubjectRowCardType = {
  subjectName: string;
  lottieIcon: unknown;
  isSelected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  width?: number | string;
  height?: number | string;
  startAdornment?: JSX.Element | null;
  contentProps?: StackProps;
  subjectNameProps?: TypographyProps;
  lottieProps?: LottieComponentProps;
};

type ModifiedStackProps = Omit<StackProps, 'sx' | 'onMouseEnter' | 'onMouseLeave' | 'onClick'>;
export default function SubjectRowCard({
  subjectName,
  lottieIcon,
  isSelected,
  onClick,
  startAdornment,
  contentProps,
  subjectNameProps,
  lottieProps,
  ...props
}: SubjectRowCardType & ModifiedStackProps) {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      display="flex"
      sx={{
        backgroundColor: 'white',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        borderRadius: 2,
        '&:hover': onClick
          ? {
              cursor: 'pointer',
              backgroundColor: theme.palette.grey[200],
            }
          : undefined,
      }}
      paddingLeft={0.5}
      gap={1}
      onMouseEnter={() => lottieRef.current && lottieRef.current.play()}
      onMouseLeave={() => lottieRef.current && lottieRef.current.stop()}
      onClick={onClick}
      data-test={`subject-row-card-${subjectName}`}
      {...switchAccessBodyElements}
      {...props}
    >
      {startAdornment && <Box>{startAdornment}</Box>}
      <Stack direction="row" alignItems="center" {...contentProps} gap={3} paddingLeft={1}>
        <Lottie
          loop
          lottieRef={lottieRef}
          autoplay={false}
          animationData={lottieIcon}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          style={{ width: '40px', height: '40px', opacity: isSelected ? 1 : 0.5 }}
          data-test={`lottie-${subjectName}`}
          {...lottieProps}
        />
        <Typography
          variant="subtitle1"
          color={isSelected ? theme.palette.primary.main : theme.palette.grey[500]}
          {...subjectNameProps}
        >
          {subjectName}
        </Typography>
      </Stack>
    </Stack>
  );
}

SubjectRowCard.defaultProps = {
  width: undefined,
  height: 64,
};
