import { Box, Fade, Stack } from '@mui/material';
import StudentToolbar from '../student/toolbar/StudentToolbar';
import StudentSettingSidePanel from './side-panel/StudentSettingSidePanel';
import SelectedSettingView from './selected-setting-view/SelectedSettingView';

export default function LandingStudentSettings() {
  return (
    <Fade in timeout={1000}>
      <Stack sx={{ height: '100%' }} data-test="landing-student-settings-page">
        <StudentToolbar />
        <Box sx={{ display: 'inline-flex' }}>
          <StudentSettingSidePanel />
          <SelectedSettingView />
        </Box>
      </Stack>
    </Fade>
  );
}
