import { Button, Stack, Typography } from '@mui/material';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

type IAppErrorBoundary = {
  children: JSX.Element;
};

function AppErrorBoundary({ children }: IAppErrorBoundary) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => {
            return (
              <Stack alignItems="center" paddingTop={20}>
                <Typography variant="h3" gutterBottom>
                  There was an error!
                </Typography>

                <Typography gutterBottom mb={4}>
                  {error.message}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => resetErrorBoundary()}
                >
                  Try again
                </Button>
              </Stack>
            );
          }}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

export default AppErrorBoundary;
