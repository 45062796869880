import { createStore } from 'zustand';
import { StudentsTagsMapType } from '../steps/custom-tag/models/StudentTagAssignmentType';

const initialState = {
  studentsTagsMap: {},
};

type StoreType = {
  tagByUserIdKey: string[];
  saveTagAssignmentKey: string[];
  studentsTagsMap: StudentsTagsMapType;
  setStudentsTagsMap: (studentsTagsMap: StudentsTagsMapType) => void;
  handleCloseForm: () => void;
};

export const createTagsAssignmentStore = ({ onClose }: { onClose: () => void }) => {
  return createStore<StoreType>((set) => ({
    ...initialState,
    tagByUserIdKey: ['tagByUserIdKey'],
    saveTagAssignmentKey: ['saveTagAssignmentKey'],
    setStudentsTagsMap: (studentsTagsMap: StudentsTagsMapType) => set({ studentsTagsMap }),
    handleCloseForm: () => {
      onClose();
    },
  }));
};

export type TagsAssignmentStore = ReturnType<typeof createTagsAssignmentStore> | null;
