import { useContext, useEffect } from 'react';
import { useStore } from 'zustand';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { TOGGLE_BUTTON_VIZZLE_CUSTOM } from '../../../../../../theme/default/components/ToggleButtonTheme';
import { ViewFilterType } from '../../../../models/ViewType';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { ContentLibraryType } from '../../../../models/ContentLibraryType';

const filterViewOptions = [
  {
    label: 'Topic View',
    data: ViewFilterType.topicView,
  },
  {
    label: 'Unit View',
    data: ViewFilterType.unitView,
  },
  {
    label: 'Activity View',
    data: ViewFilterType.activityView,
  },
];

const shouldHideViewFilter = (contentLibraries: ContentLibraryType[]) => {
  const isVizzleClassicSelected =
    contentLibraries && contentLibraries.length === 1 && contentLibraries.includes(ContentLibraryType.vizzleClassic);
  return isVizzleClassicSelected;
};

export default function ViewFilterSelector() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const filterView = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.filterView);
  const setFilterView = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setFilterView);

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: number) => {
    if (newAlignment === null) {
      return;
    }
    setFilterView(newAlignment);
  };
  const control = {
    value: filterView,
    onChange: handleChange,
    exclusive: true,
  };

  const contentLibraries = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.contentLibraries,
  );

  const isVizzleClassicSelected = shouldHideViewFilter(contentLibraries);

  useEffect(() => {
    if (isVizzleClassicSelected) {
      setFilterView(ViewFilterType.activityView);
    }
  }, [contentLibraries]);

  return (
    <Stack alignItems="flex-start" data-test="view-filter-container">
      <Typography variant="subtitle1" fontWeight={400} fontSize="1.25em" gutterBottom data-test="title">
        Filters
      </Typography>
      {!isVizzleClassicSelected && (
        <ToggleButtonGroup
          fullWidth
          role={TOGGLE_BUTTON_VIZZLE_CUSTOM}
          size="small"
          {...control}
          aria-label="Small sizes"
          data-test="toggle-container"
        >
          {filterViewOptions.map((option) => (
            <ToggleButton
              value={option.data}
              key={`view-filter-${option.data}`}
              role={TOGGLE_BUTTON_VIZZLE_CUSTOM}
              data-test={`view-filter-${option.data}`}
            >
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </Stack>
  );
}
