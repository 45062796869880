import { useStore } from 'zustand';
import { useUserStore } from '../../../../stores/UserStore';
import { useContext } from 'react';
import { LandingStudentSelfSelectedContext } from '../LandingStudentSelfSelectedHooks';
import { filterLessonsFromProfile } from '../../hooks/SubjectListHooks';

export const useGetLessonsData = () => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const landingStudentSelfSelectedContext = useContext(LandingStudentSelfSelectedContext);

  const lessonStatus = useStore(
    landingStudentSelfSelectedContext!.studentSelfSelectedFilterStore!,
    (state) => state.lessonStatus
  );

  const subjectId = useStore(
    landingStudentSelfSelectedContext!.studentSelfSelectedFilterStore!,
    (state) => state.subjectId
  );
  const lessons = filterLessonsFromProfile(subjectId, lessonStatus, userProfile);

  return {
    userProfile,
    lessons,
    lessonStatus,
    subjectId,
  };
};
