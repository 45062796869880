import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { ActivitiesAssignmentContext } from '../../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';

export default function OptionalSetting() {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);

  const addToMyResourceFlag = useStore(activitiesAssignmentContext!, (state) => state.addToMyResourceFlag);
  const setAddToMyResourceFlag = useStore(activitiesAssignmentContext!, (state) => state.setAddToMyResourceFlag);

  if (!activitiesAssignment?.showAddToMyList) {
    return null;
  }
  return (
    <Stack
      direction="column"
      data-test="optional-settings-container"
      paddingTop={1}
      boxSizing="border-box"
      height="100%"
    >
      <Typography variant="subtitle1" data-test="title">
        Optional Settings
      </Typography>
      <Stack position="relative" overflow="auto" height="100%" data-test="form-container">
        {activitiesAssignment?.showAddToMyList && (
          <FormGroup data-test="form-group">
            <FormControlLabel
              data-test="form-control-label"
              control={
                <Checkbox
                  data-test="checkbox"
                  checked={addToMyResourceFlag}
                  onChange={(e) => {
                    setAddToMyResourceFlag(e.target.checked);
                  }}
                />
              }
              label="Add to My Resources"
            />
          </FormGroup>
        )}
      </Stack>
    </Stack>
  );
}
