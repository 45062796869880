import PeopleIcon from '@mui/icons-material/People';
import { Lesson } from '../../types/lesson/LessonType';
import { ItemRowMenuItemType } from '../../types/ui/ItemRowMenuItem';
import { useMutation } from '@tanstack/react-query';
import { useUserStore } from '../../stores/UserStore';
import { useStore } from 'zustand';
import GroupPlayService from '../../services/GroupPlayService';
import appUiStore from '../../stores/AppUiStore';
import { SNACKBAR_SEVERITY } from '../../types/ui/VizzleSnackbar';

export const useGetToGroupInstructionAction = ({ onSaveSuccess }: { onSaveSuccess?: () => void }) => {
  const user = useStore(useUserStore, (state) => state.user);
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  const addToGroupInstructionMutation = useMutation({
    mutationKey: ['add-to-group-instruction'],
    mutationFn: async (lessons: Lesson[]) => {
      const lessonIds = lessons.map((lesson) => lesson.id);
      const userId = user!.id;
      await GroupPlayService.addToGroupPlay(userId, lessonIds);
      lessons.forEach((lesson) => {
        if (!lesson.meta) {
          lesson.meta = {};
        }
        lesson.meta.groupPlay = true;
      });
      openSnackbar({
        severity: SNACKBAR_SEVERITY.SUCCESS,
        open: true,
        message: `The ${lessonIds.length > 1 ? 'activities have' : 'activity has'} been saved to Group Instruction.`,
        duration: 3000,
      });
    },
    onSuccess: () => {
      if (onSaveSuccess) {
        onSaveSuccess();
      }
    },
  });

  const removeFromGroupInstructionMutation = useMutation({
    mutationKey: ['remove-from-group-instruction'],
    mutationFn: async (lessons: Lesson[]) => {
      const lessonIds = lessons.map((lesson) => lesson.id);
      const userId = user!.id;
      await GroupPlayService.removeFromGroupPlay(userId, lessonIds);
      lessons.forEach((lesson) => {
        if (!lesson.meta) {
          lesson.meta = {};
        }
        lesson.meta.groupPlay = false;
      });
      openSnackbar({
        severity: SNACKBAR_SEVERITY.SUCCESS,
        open: true,
        message: `The ${lessonIds.length > 1 ? 'activities have' : 'activity has'} been removed from Group Instruction.`,
        duration: 3000,
      });
    },
    onSuccess: () => {
      if (onSaveSuccess) {
        onSaveSuccess();
      }
    },
  });

  const getAddToGroupInstructionAction = (lessons: Lesson[]): ItemRowMenuItemType => {
    return {
      id: 'add-to-group-instruction',
      label: 'Add to Group Instruction',
      icon: <PeopleIcon />,
      onClick: async (closeDropdownFunction) => {
        closeDropdownFunction();
        await addToGroupInstructionMutation.mutate(lessons);
      },
    };
  };

  const getRemoveFromGroupInstructionAction = (lessons: Lesson[]): ItemRowMenuItemType => {
    return {
      id: 'remove-from-group-instruction',
      label: 'Remove from Group Instruction',
      icon: <PeopleIcon />,
      onClick: async (closeDropdownFunction) => {
        closeDropdownFunction();
        await removeFromGroupInstructionMutation.mutate(lessons);
      },
    };
  };

  return { getAddToGroupInstructionAction, getRemoveFromGroupInstructionAction };
};
