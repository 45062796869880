/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { experimental_extendTheme as extendTheme } from '@mui/material';

const theme = extendTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: () => {
          return {
            '.MuiDialog-paper': {
              maxHeight: 'calc(100% - 10px)',
            },
            '.MuiDialog-paperFullScreen': {
              maxHeight: '100%',
            },
          };
        },
      },
    },
  },
});

export const dialogTheme = theme.components?.MuiDialog;
