import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useStore } from 'zustand';
import { useUserStore } from '../../../../../../stores/UserStore';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { ContentLibraryType, convertContentLibraryToSearchScenario } from '../../../../models/ContentLibraryType';
import LessonService from '../../../../../../services/LessonService';
import { SearchLessonResult } from '../../../../../../types/lesson/SearchLessonResultType';
import SearchUtils from '../../../../utils/SearchUtils';

export const useSearchActivities = () => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const searchLessonsContext = useContext(SearchLessonsContext);
  const activitiesSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.activitiesSearchPageNumber
  );

  const searchLessonResultQueryKey = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => ({
    filterView: state.filterView,
    subjectId: state.selectedSubjectId,
    contentLibraries: state.contentLibraries,
    grades: state.grades,
    standardCodes: state.standardCodes,
    verticalAlignmentIds: state.verticalAlignmentIds,
    courseIds: state.courseIds,
    searchText: state.searchText,
    exactMatch: state.exactMatch,
  }));

  const activitiesSearchResult = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.activitiesSearchResult
  );

  const setActivitiesSearchResult = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.setActivitiesSearchResult
  );

  const searchLessonResultQuery = useQuery({
    queryKey: [searchLessonResultQueryKey, activitiesSearchPageNumber],
    queryFn: async () => {
      if (activitiesSearchPageNumber === activitiesSearchResult.meta?.pageNumber) {
        return null;
      }

      const scenario = convertContentLibraryToSearchScenario(
        searchLessonResultQueryKey.contentLibraries,
        searchLessonResultQueryKey.standardCodes,
        searchLessonResultQueryKey.searchText,
      );

      let ignoreTopicLessons;
      if (
        searchLessonResultQueryKey.contentLibraries.length === 1 &&
        searchLessonResultQueryKey.contentLibraries[0] === ContentLibraryType.vizzleClassic
      ) {
        ignoreTopicLessons = true;
      }

      const result: SearchLessonResult = await LessonService.searchLessonsByScenario({
        pageNumber: activitiesSearchPageNumber || 1,
        searchNode: userProfile!.searchNode,
        subjectId: searchLessonResultQueryKey.subjectId!,
        grades: searchLessonResultQueryKey.verticalAlignmentIds && searchLessonResultQueryKey.verticalAlignmentIds.length > 0 ? [] : searchLessonResultQueryKey.grades,
        standardCodes: searchLessonResultQueryKey.standardCodes,
        verticalAlignmentIds: searchLessonResultQueryKey.verticalAlignmentIds,
        keywords: SearchUtils.getSearchTextKeyword(searchLessonResultQueryKey.searchText, searchLessonResultQueryKey.exactMatch),
        courseIds: searchLessonResultQueryKey.verticalAlignmentIds && searchLessonResultQueryKey.verticalAlignmentIds.length > 0 ? [] : searchLessonResultQueryKey.courseIds,
        scenario,
        ignoreTopicLessons,
      });
      return result;
    },
    onSuccess: (result) => {
      if (!result) {
        return;
      }
      const newData =
        (result.meta?.pageNumber || 1) > 1
          ? [...activitiesSearchResult.data, ...(result.data || [])]
          : result.data || [];
      const updatedActivitiesSearchResult = {
        data: newData,
        included: [...activitiesSearchResult.included, ...(result.included || [])],
        meta: result.meta,
      };
      setActivitiesSearchResult(updatedActivitiesSearchResult);
    },
  });

  return searchLessonResultQuery;
};

export const useHandlePageNumber = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const activitiesSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.activitiesSearchPageNumber
  );

  const setActivitiesSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.setActivitiesSearchPageNumber
  );

  return {
    activitiesSearchPageNumber,
    setActivitiesSearchPageNumber,
  };
};
