import { useNavigate, useParams } from 'react-router-dom';
import UnitService from '../../../../services/UnitService';
import { useQuery } from '@tanstack/react-query';
import ItemDetails from '../../ui/item-details/ItemDetails';
import { useGetRecordType } from '../../SearchLessonsPageHooks';
import { FULL_SORTED_SUBJECT_CONSTANTS } from '../../../../constants/LessonConstant';
import { SEARCH_LESSONS_SUB_URL } from '../../SearchLessonsConstant';
import { APP_URL } from '../../../../routes/RouteConstants';
import { Unit, UnitTypeString } from '../../../../types/unit/UnitType';
import { Lesson } from '../../../../types/lesson/LessonType';
import { getSubjectOrCourseNameForTopicAndUnit } from '../../hooks/SubjectGroupByUserComponentsHooks';

function UnitDetails() {
  const { unitId } = useParams();

  const unitDetailQuery = useQuery({
    queryKey: ['unit-detail', unitId],
    queryFn: async () => {
      if (!unitId) {
        return;
      }
      const result = await UnitService.getUnitDetail(unitId);
      return result;
    },
  });

  const unitOthersQuery = useQuery({
    queryKey: ['unit-others', unitId],
    queryFn: async () => {
      if (!unitId) {
        return;
      }
      const result = await UnitService.getOtherUnits(unitId);
      return result;
    },
  });

  const navigate = useNavigate();
  const unitDetail = unitDetailQuery.data;
  const { getRecordType } = useGetRecordType();

  const subject = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.subjectId === unitDetail?.subjectId);

  const lesson = unitDetail?.unitAssessment
    ? {
        id: String(unitDetail?.unitAssessment!.id),
        attributes: {
          uuid: unitDetail?.unitAssessment!.uuid,
          name: unitDetail?.unitAssessment!.name,
          thumbnailUrl: unitDetail?.unitAssessment!.thumbnailUrl,
        },
      }
    : undefined;

  return (
    <ItemDetails
      data-test="unit-detail"
      itemInformation={{
        isLoading: unitDetailQuery.isLoading,
        title: `Unit ${unitDetail?.name}`,
        titleChips: [
          `${unitDetail?.gradeLevel} Grade`,
          getSubjectOrCourseNameForTopicAndUnit({
            attributes: {
              gradeLevel: unitDetail?.gradeLevel,
              courseName: unitDetail?.courseName,
              subject: subject?.name,
            },
          }),
          getRecordType(UnitTypeString),
        ],
        description: unitDetail?.description,
      }}
      resourceMaterial={{
        isLoading: unitDetailQuery.isLoading,
        courseName: unitDetail?.courseName || '',
        lessonPlan: unitDetail?.lessonPlan,
        unitGuide: unitDetail?.unitGuideInfo,
      }}
      extensionActivity={{
        isLoading: unitDetailQuery.isLoading,
        extensionActivities: unitDetail?.extensionActivities || [],
      }}
      topicList={{
        isLoading: unitDetailQuery.isLoading,
        topics: unitDetail?.topics || [],
        lesson: lesson as Lesson,
      }}
      othersSection={{
        isLoading: unitOthersQuery.isLoading,
        sectionTitle: 'Other Units From This Subject',
        data:
          unitOthersQuery?.data?.map((d) => ({
            id: d.id || '',
            title: `Unit ${d.name}`,
            subTitles: [[subject?.name || '', `${d.gradeLevel || ''} Grade`]],
            image: d.thumbnailUrl,
          })) || [],
        onDetailClick: (id) => {
          navigate(`${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.unitDetails}/${id}`);
        },
      }}
      itemDetailsAction={{
        isLoading: unitDetailQuery.isLoading,
        isAbleToAssign: false,
        getSelectedData: () => {
          const unit: Unit = {
            id: String(unitId),
            type: UnitTypeString,
            attributes: {
              name: unitDetail!.name,
              gradeLevel: unitDetail!.gradeLevel,
              subject: subject?.name,
            },
          };

          return unit;
        },
      }}
    />
  );
}

export default UnitDetails;
