import { SvgIconComponent } from '@mui/icons-material';
import { Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type ResourceMaterialCard = {
  icon: SvgIconComponent;
  title: string;
  description: string;
  'data-test': string;
};
export default function ResourceMaterialCard({ icon: Icon, title, description, ...rest }: ResourceMaterialCard) {
  const theme = useTheme();
  return (
    <Card
      {...rest}
      elevation={0}
      sx={{
        borderRadius: '4px',
        border: `1px solid ${theme.palette.grey[400]}`,
      }}
    >
      <CardActionArea>
        <CardContent>
          <Stack direction="row" alignItems="center" gap={1}>
            {<Icon htmlColor={theme.palette.textSecondary.main} fontSize="large" />}
            <Stack>
              <Typography
                fontWeight={400}
                // color={theme.palette.textSecondary.main}
                sx={{
                  opacity: 0.87,
                }}
                gutterBottom
                variant="h6"
                component="div"
              >
                {title}
              </Typography>
              <Typography color={theme.palette.textSecondary.main} variant="body2">
                {description}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
