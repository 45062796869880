import { Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import arrowDownIcon from '../../../../../assets/images/icons/animated-icons/level_arrows.json';
import { LEVEL_OPTIONS_DEFAULT } from '../../../../../constants/LevelConstant';
import LottieIcon from '../../../../../ui/lottie/LottieIcon';

type SelectLessonLevelDialogProps = {
  setLevel: (value: string) => void;
};

export default function SelectLessonLevelDialog({ setLevel }: SelectLessonLevelDialogProps) {
  const theme = useTheme();

  return (
    <Stack
      gap={2}
      alignItems="center"
      sx={{
        boxShadow: `0px 0px 0px 1px ${theme.palette.grey[200]};`,
        borderRadius: 2,
        paddingTop: 2,
        paddingBottom: 3,
        paddingX: 4,
        position: 'absolute',
        top: '10%',
        // marginLeft: 'auto',
        left: '12%',
        zIndex: 2,
        backgroundColor: 'white',
      }}
      width="450px"
    >
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="flex-start"
        data-test="select-lesson-level-dialog-title"
      >
        <LottieIcon animationData={arrowDownIcon} style={{ width: '40px', height: '40px' }} />
        Choose a Level to Preview
      </Stack>
      <Stack width="100%" data-test="select-lesson-level-dialog-content" gap={2} mt={1}>
        {LEVEL_OPTIONS_DEFAULT.map((option) => (
          <Card
            key={`level-option-${option.value}`}
            data-test={`level-option-${option.value}`}
            elevation={0}
            sx={{
              borderRadius: '4px',
              border: `1px solid ${theme.palette.grey[400]}`,
              width: '100%',
            }}
          >
            <CardActionArea
              data-test={`level-option-action-${option.value}`}
              onClick={() => {
                setLevel(option.value);
              }}
            >
              <CardContent data-test={`level-option-content-${option.value}`}>
                <Stack data-test={`level-option-container-${option.value}`}>
                  <Typography
                    fontWeight={400}
                    gutterBottom
                    variant="body1"
                    component="div"
                    noWrap
                    data-test={`level-option-option-${option.value}`}
                  >
                    Level {option.value}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    color={theme.palette.textSecondary.main}
                    gutterBottom
                    variant="body1"
                    component="div"
                    noWrap
                    data-test={`level-option-label-${option.value}`}
                  >
                    {option.label}
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
}
