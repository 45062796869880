import { Stack } from '@mui/material';
import ViewFilterSelector from './filters/ViewFilterSelector';
import SubjectDropdownSelector from './filters/SubjectDropdownSelector';
import ContentLibrarySelector from './filters/ContentLibrarySelector';
import GradeSelector from './filters/GradeSelector';
import StateStandardsSelector from './filters/StateStandardsSelector';
import CourseSelector from './filters/CourseSelector';
import { useStore } from 'zustand';
import { useUserStore } from '../../../../../stores/UserStore';
import User from '../../../../../utils/User';
import VerticalAlignmentSelector from './filters/VerticalAlignmentSelector';

export default function SearchLessonsFilter() {
  const user = useStore(useUserStore, (state) => state.user);
  return (
    <Stack paddingY={2} paddingX={2} gap={3}>
      <ViewFilterSelector />
      <ContentLibrarySelector />
      <SubjectDropdownSelector />
      <GradeSelector />
      <CourseSelector />
      <StateStandardsSelector />
      {User.isTexasTeacher(user) && <VerticalAlignmentSelector />}
    </Stack>
  );
}
