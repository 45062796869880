import { useContext, useEffect, useState } from 'react';
import {
  ActivitiesAssignmentContext,
  TagsAssignmentContext,
  getUserIds,
  useGetAssignmentLessons,
} from '../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import { useQuery } from '@tanstack/react-query';
import LessonTagService from '../../../../../services/LessonTagService';
import { useUserStore } from '../../../../../stores/UserStore';
import { ActivityTagData, Tag } from '../../../../../types/tag-assignment/TagAssignmentType';
import { StudentTagAssignmentType, StudentsTagsMapType } from '../models/StudentTagAssignmentType';
import { flatten } from '../../../../../utils/ArrayUtils';
import { Student } from '../../../../../types/student/StudentType';
import { useStudentStore } from '../../../../../stores/StudentStore';

export const usePrepareTagsData = () => {
  const user = useStore(useUserStore, (state) => state.user);
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const tagsAssignmentContext = useContext(TagsAssignmentContext);

  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);

  const selectedStudentIds = useStore(activitiesAssignmentContext!, (state) => state.selectedStudentIds);
  const selectedGroupIds = useStore(activitiesAssignmentContext!, (state) => state.selectedGroupIds);

  const tagByUserIdKey = useStore(tagsAssignmentContext!, (state) => state.tagByUserIdKey);
  const selectedLessons = useGetAssignmentLessons();

  const tagsQuery = useQuery({
    queryKey: tagByUserIdKey,
    queryFn: async () => {
      const lessonIds: string[] = selectedLessons?.map((lesson) => lesson.id) || [];
      const userIds = getUserIds(activitiesAssignment!, selectedGroupIds, selectedStudentIds);
      userIds.push(String(user!.id));
      const result: ActivityTagData = await LessonTagService.getTagsByUserIds(userIds, lessonIds);
      return result;
    },
  });
  return tagsQuery;
};

const studentMappingDefaultValue = {
  isSelected: true,
  studentTags: [],
  addedTags: [],
};
const createStudentMappingDefaultValue = (): StudentTagAssignmentType =>
  JSON.parse(JSON.stringify(studentMappingDefaultValue));

export const useDataForUpdate = (tags?: ActivityTagData) => {
  const tagsAssignmentContext = useContext(TagsAssignmentContext);
  const studentsTagsMap = useStore(tagsAssignmentContext!, (state) => state.studentsTagsMap);
  const setStudentsTagsMap = useStore(tagsAssignmentContext!, (state) => state.setStudentsTagsMap);

  const [tagsAddedInThisSession, setTagsAddedInThisSession] = useState<Tag[]>([]);
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);
  const selectedStudentIds = useStore(activitiesAssignmentContext!, (state) => state.selectedStudentIds);
  const selectedGroupIds = useStore(activitiesAssignmentContext!, (state) => state.selectedGroupIds);
  // const selectedLessons = useGetAssignmentLessons();

  useEffect(() => {
    if (tags && tags.data) {
      const studentsTagsMapTemp: StudentsTagsMapType = {};
      // if (selectedLessons && selectedLessons.length === 1) {
      tags.data.forEach((t: Tag) => {
        if (t.relationships && t.relationships.students && t.relationships.students.data) {
          t.relationships.students.data.forEach((trsd) => {
            const studentMapping = studentsTagsMapTemp[trsd.id.toString()] || createStudentMappingDefaultValue();
            studentMapping.studentTags.push(t);
            studentsTagsMapTemp[trsd.id.toString()] = studentMapping;
          });
        }
      });
      // }

      const userIds = getUserIds(activitiesAssignment!, selectedGroupIds, selectedStudentIds);
      const studentIdsWithTags = Object.keys(studentsTagsMapTemp);
      const studentIdsWithNoTags = userIds.filter((id) => !studentIdsWithTags.includes(id));
      studentIdsWithNoTags.forEach((id) => {
        studentsTagsMapTemp[id.toString()] = createStudentMappingDefaultValue();
      });
      setStudentsTagsMap(studentsTagsMapTemp);
    }
  }, [tags]);

  const handleOnStudentSelect = (student: Student, isSelected: boolean) => {
    const updatedData = {
      ...studentsTagsMap,
      [student.id]: {
        ...studentsTagsMap[student.id],
        isSelected,
      },
    };
    setStudentsTagsMap(updatedData);
  };

  const addTagToSelectedStudent = (tagToAdd: Tag) => {
    const studentWithTags = Object.values(studentsTagsMap);
    const selectedStudentWithTags = studentWithTags.filter((s) => {
      const allTagsNames = [...s.addedTags, ...s.studentTags].map((tag) => tag.attributes.name.toLowerCase());
      const isAlreadyAdded = allTagsNames.includes(tagToAdd.attributes.name.toLowerCase());
      return s.isSelected && !isAlreadyAdded;
    });
    selectedStudentWithTags.forEach((s) => {
      s.addedTags.push(tagToAdd);
    });

    setStudentsTagsMap({ ...studentsTagsMap });
  };

  const handleOnTagAdded = (newTag: Tag) => {
    addTagToSelectedStudent(newTag);
    setTagsAddedInThisSession([...tagsAddedInThisSession, newTag]);
  };

  const handleOnTagSelected = (selectedTag: Tag) => {
    addTagToSelectedStudent(selectedTag);
  };

  const handleOnTagDelete = (student: Student, deletedTag: Tag) => {
    const selectedStudent = studentsTagsMap[student.id];
    selectedStudent.addedTags = selectedStudent.addedTags.filter((t) => t.id !== deletedTag.id);
    setStudentsTagsMap({ ...studentsTagsMap });
  };

  return {
    studentsTagsMap,
    tagsAddedInThisSession,
    handleOnStudentSelect,
    handleOnTagAdded,
    handleOnTagSelected,
    handleOnTagDelete,
  };
};

export const useGetLessonTagsForAutoComplete = (tags: Tag[], tagsAddedInThisSession: Tag[]) => {
  const tagsAssignmentContext = useContext(TagsAssignmentContext);
  const studentsTagsMap = useStore(tagsAssignmentContext!, (state) => state.studentsTagsMap);
  const studentWithTags = Object.values(studentsTagsMap);

  const allSelectedTags: Tag[][] = studentWithTags.map((s) => [...s.addedTags, ...s.studentTags]);

  const tagExisingInAllStudents: Tag[] = allSelectedTags.reduce(
    (a, b) =>
      a.filter((i: Tag) => {
        const nameB = b.map((j) => j.attributes.name.toLowerCase());
        const nameA = i.attributes.name.toLowerCase();
        return nameB.includes(nameA);
      }),
    flatten(allSelectedTags)
  );

  const uniqueTagsExisingInAllStudents = tagExisingInAllStudents
    .map((e) => e.attributes.name.toLowerCase())
    // store the keys of the unique objects
    .map((e: string, i, final) => (final.indexOf(e) === i ? i : -1))
    // eliminate the dead keys & store unique objects
    .filter((e: number) => tagExisingInAllStudents[e])
    .map((e: number) => tagExisingInAllStudents[e]);

  const tagsNameExisitingInAllStudents = uniqueTagsExisingInAllStudents.map((u) => u.attributes.name.toLowerCase());

  const allTags = [...tags, ...tagsAddedInThisSession];
  const tagsForAutoComplete = allTags.filter(
    (t) => !tagsNameExisitingInAllStudents.includes(t.attributes.name.toLowerCase())
  );
  return {
    tagsNameExisitingInAllStudents,
    tagsForAutoComplete,
  };
};

export const useGetCurrentSelectedStudents = () => {
  const students = useStore(useStudentStore, (state) => state.students);
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);

  const activitiesAssignment = useStore(activitiesAssignmentContext!, (state) => state.activitiesAssignment);
  const selectedStudentIds = useStore(activitiesAssignmentContext!, (state) => state.selectedStudentIds);
  const selectedGroupIds = useStore(activitiesAssignmentContext!, (state) => state.selectedGroupIds);

  const userIds = getUserIds(activitiesAssignment!, selectedGroupIds, selectedStudentIds);

  const currentSelectedStudents = students!.filter((s) => userIds.toString().includes(s.id.toString()));
  return currentSelectedStudents;
};
