import Logger from './Logger';

/**
 * Session name for user token
 */
const SESSION_NAME_USER_TOKEN = 'SESSION_NAME_USER_TOKEN';

/**
 * Save the token to the VizzleAuthoringWeb memory storage
 * @param {*} token to save
 */
export const getUserToken = () =>
sessionStorage.getItem(SESSION_NAME_USER_TOKEN);

/**
 * Save the token to the VizzleAuthoringWeb memory storage
 * @param {*} token to save
 */
export const saveTokenToMemory = (token: string) => {
  Logger.logWhenDebugModeIsOn({
    OLD_TOKEN: getUserToken(),
    NEW_TOKEN: token,
  });
  sessionStorage.setItem(SESSION_NAME_USER_TOKEN, token);
};
