import vizzleAxios from './service';
import { ContentLibraryScenary } from '../types/lesson/SearchLessonResultType';
import { SearchTopicResult } from '../types/topic/SearchTopicResultType';
import { TopicDetail } from '../types/topic/TopicDetailType';
import { TopicOther } from '../types/topic/TopicOtherType';
import StandardUtils from '../utils/StandardUtils';

export default {
  searchTopicsByScenario: async ({
    pageNumber,
    subjectId,
    grades,
    standardCodes,
    verticalAlignmentIds,
    courseIds,
    keywords,
    scenario,
  }: {
    pageNumber: number;
    subjectId: number | string;
    grades: string[];
    standardCodes: string[];
    verticalAlignmentIds: string[];
    courseIds: number[],
    keywords: string;
    scenario?: ContentLibraryScenary;
  }): Promise<SearchTopicResult> => {
    const url = '/topics/search';
    const standards = StandardUtils.getStandardCodeOrVerticalAlignmentIds(standardCodes, verticalAlignmentIds);
    const result = await vizzleAxios.get(url, {
      params: {
        keywords,
        'page[number]': pageNumber || 1,
        scenario,
        'filter[subjectId]': subjectId || undefined,
        'filter[gradeLevel]': grades && grades.length > 0 ? grades.join(',') : null,
        'filter[standardCode]': standards && standards.length > 0 ? standards.join(',') : null,
        'filter[courseId]': courseIds && courseIds.length > 0 ? courseIds.join(',') : null,
      },
    });
    return result.data;
  },

  getTopicDetail: async (topicId: string): Promise<TopicDetail> => {
    const url = `/topics/${topicId}`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },

  getOtherTopics: async (topicId: string): Promise<TopicOther[]> => {
    const url = `/topics/${topicId}/others`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },
};
