import ArrayUtils from '../../../../../utils/ArrayUtils';
import ViewPdfDialog from '../../dialog/ViewPdfDialog';
import { ExtensionActivityType } from '../types/ExtensionActivityType';
import { Card, CardActionArea, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function ExtensionActivity({ isLoading, extensionActivities }: ExtensionActivityType) {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack direction="column" gap={1}>
        <Skeleton variant="rounded" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60} />
      </Stack>
    );
  }

  if (!extensionActivities || extensionActivities.length === 0) {
    return null;
  }

  return (
    <Stack gap={1} data-test="extension-activities">
      <Typography variant="h5" data-test="title" gutterBottom>
        Extension Activities
      </Typography>
      <Stack direction="column" gap={1}>
        {ArrayUtils.sort(extensionActivities, 'name').map((extensionActivity, index) => (
          <ViewPdfDialog
            key={`extension-activity-${index}`}
            title="Extension Activity"
            pdfKeys={[extensionActivity.url]}
            actionComponent={
              <Card
                elevation={0}
                sx={{
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.grey[400]}`,
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography
                      fontWeight={400}
                      color={theme.palette.textSecondary.main}
                      gutterBottom
                      variant="body2"
                      component="div"
                      noWrap
                    >
                      {extensionActivity.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            }
          />
        ))}
      </Stack>
    </Stack>
  );
}
