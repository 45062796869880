/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { alpha, experimental_extendTheme as extendTheme } from '@mui/material';
export const TOGGLE_BUTTON_VIZZLE_CUSTOM = 'custom';

const theme = extendTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (ownerState.role === TOGGLE_BUTTON_VIZZLE_CUSTOM) {
            return {
              borderRadius: 30,
              borderWidth: 1,
              borderColor: theme.palette.divider,
              borderStyle: 'solid',
              paddingY: 2,
              boxSizing:'border-box',
              '&.MuiToggleButtonGroup-root': {
                padding: 4,
              },
            };
          }
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (ownerState.role === TOGGLE_BUTTON_VIZZLE_CUSTOM) {
            return {
              borderRadius: 30,
              fontWeight: 'normal',
              textTransform: 'none',
              paddingLeft: 3,
              paddingRight: 3,
              paddingTop: 4,
              paddingBottom: 4,
              cursor: 'pointer',
              marginRight: 5,
              border: 'none',
              // borderLeftColor: 'unset',
              '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                borderTopLeftRadius: 16,
                borderBottomLeftRadius: 16,
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
                marginLeft: 0,
                marginRight: 0,
              },
              '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                borderTopLeftRadius: 16,
                borderBottomLeftRadius: 16,
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
                marginLeft: 0,
                marginRight: 0,
              },
              '&.MuiToggleButton-root': {
                color: theme.palette.grey[500],
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff !important',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.8),
                },
              },
              '&.Mui-disabled': {
                border: 'none',
                color: theme.palette.grey[300],
              }
            };
          }
        },
      },
    },
  },
});

export const toggleButtonTheme = theme.components?.MuiToggleButton;
export const toggleButtonGroupTheme = theme.components?.MuiToggleButtonGroup;