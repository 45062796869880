import vizzleAxios from './service';
import { ContentLibraryScenary } from '../types/lesson/SearchLessonResultType';
import { SearchUnitResult } from '../types/unit/SearchUnitResultType';
import { UnitDetail } from '../types/unit/UnitDetailType';
import { UnitOther } from '../types/unit/UnitOtherType';
import StandardUtils from '../utils/StandardUtils';

export default {
  searchUnitsByScenario: async ({
    pageNumber,
    subjectId,
    grades,
    standardCodes,
    verticalAlignmentIds,
    courseIds,
    keywords,
    scenario,
  }: {
    pageNumber: number;
    subjectId: number | string;
    grades: string[];
    standardCodes: string[];
    verticalAlignmentIds: string[];
    courseIds: number[],
    keywords: string;
    scenario?: ContentLibraryScenary;
  }): Promise<SearchUnitResult> => {
    const url = '/units/search';
    const standards = StandardUtils.getStandardCodeOrVerticalAlignmentIds(standardCodes, verticalAlignmentIds);
    const result = await vizzleAxios.get(url, {
      params: {
        keywords,
        'page[number]': pageNumber || 1,
        scenario,
        'filter[subjectId]': subjectId || undefined,
        'filter[gradeLevel]': grades && grades.length > 0 ? grades.join(',') : null,
        'filter[courseId]': courseIds && courseIds.length > 0 ? courseIds.join(',') : null,
        'filter[standardCode]': standards && standards.length > 0 ? standards.join(',') : null,
      },
    });
    return result.data;
  },

  getUnitDetail: async (unitId: string): Promise<UnitDetail> => {
    const url = `/units/${unitId}`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },

  getOtherUnits: async (unitId: string): Promise<UnitOther[]> => {
    const url = `/units/${unitId}/others`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },
};
