import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DatePicker, { DateObject, Value } from 'react-multi-date-picker';
import { DATE_SELECTOR_MODE } from '../../types/ui/DateSelector';
import DateRangeTextField from './DateRangeTextField';
import './MultipleDateSelector.scss';
import WeekSelectorPlugin from './plugins/WeekSelectorPlugin';
import { DATE_FORMAT } from '../../utils/DateUtils';
import { useRef } from 'react';
import ObjectUtils from '../../utils/ObjectUtils';

type IMultipleDateSelector = {
  mode?: DATE_SELECTOR_MODE;
  value?: Value;
  minDate?: Date | string | number | DateObject;
  maxDate?: Date | string | number | DateObject;
  onChange: (selectedDates: string[] | null) => void;
  onModeChange: (mode: DATE_SELECTOR_MODE) => void;
  errorText?: string;
  weekRangeSelector: boolean;
  ['data-test']?: string;
};

const dateRangeField = {
  startDate: 1,
  endDate: 2,
};
export default function MultipleDateSelector({
  mode,
  minDate,
  maxDate,
  value,
  onChange,
  onModeChange,
  errorText,
  weekRangeSelector,
  ...rest
}: IMultipleDateSelector) {
  const theme = useTheme();

  const datePickerRef = useRef();

  const dateRangeSelector = useRef<number>();
  return (
    <Stack className="multiple-date-selector" {...rest}>
      {mode === DATE_SELECTOR_MODE.DATE_RANGE && (
        <DatePicker
          ref={datePickerRef}
          numberOfMonths={2}
          range
          rangeHover
          format={DATE_FORMAT}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          containerClassName="date-picker-container"
          data-test="date-picker-container"
          onClose={() => {
            dateRangeSelector.current = undefined;
          }}
          render={(value, openCalendar, _handleValueChange, _locale, separator) => (
            <Stack gap={1} data-test="date-range-selector-container">
              <Stack direction="row" gap={1} data-test="date-range-selector">
                <DateRangeTextField
                  data-test="start-date"
                  isError={Boolean(errorText)}
                  title="Start Date"
                  value={value.split(separator)[0] || ''}
                  onClick={() => {
                    dateRangeSelector.current = dateRangeField.startDate;
                    openCalendar();
                  }}
                  textFieldProps={{ focused: dateRangeSelector.current === dateRangeField.startDate }}
                />
                <DateRangeTextField
                  data-test="end-date"
                  isError={Boolean(errorText)}
                  title="End Date"
                  value={value.split(separator)[1] || ''}
                  onClick={() => {
                    dateRangeSelector.current = dateRangeField.endDate;
                    openCalendar();
                  }}
                  textFieldProps={{ focused: dateRangeSelector.current === dateRangeField.endDate }}
                />
              </Stack>
              {errorText ? (
                <Typography
                  variant="caption"
                  data-test="error-text"
                  paddingLeft="14px"
                  marginTop="-5px"
                  color={theme.palette.error.main}
                >
                  {errorText}
                </Typography>
              ) : null}
            </Stack>
          )}
          onChange={(selectedDates) => {
            const dates = selectedDates as DateObject[];
            const formattedDates = dates.map((date) => date.format(DATE_FORMAT));
            let result: string[] = [...formattedDates];
            if (dates.length === 1) {
              const valueArray = value as string[];
              const d = new DateObject();
              d.setFormat(DATE_FORMAT);
              if (valueArray.includes(formattedDates[0])) {
                result = [formattedDates[0], formattedDates[0]];
              } else if (dateRangeSelector.current === dateRangeField.startDate) {
                result = [formattedDates[0]];
              } else if (dateRangeSelector.current === dateRangeField.endDate) {
                if (dates[0].toDate().getTime() < d.parse(valueArray[0]).toDate().getTime()) {
                  result = [formattedDates[0], formattedDates[0]];
                } else {
                  result = [valueArray[0], formattedDates[0]];
                }
              }
            }

            onChange(result);
            ObjectUtils.delay(1000).then(() => {
              if (datePickerRef.current && result.length >= 2) {
                (datePickerRef.current as { closeCalendar: () => void }).closeCalendar();
              }
            });
          }}
          plugins={
            weekRangeSelector
              ? [
                  <WeekSelectorPlugin
                    key="week-range-selector"
                    position="left"
                    onSelect={(startDate: DateObject, endDate: DateObject) => {
                      const dates = [startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT)];
                      onChange(dates);
                    }}
                  />,
                ]
              : undefined
          }
        />
      )}

      {mode === DATE_SELECTOR_MODE.MULTIPLE_DATES && (
        <DatePicker
          numberOfMonths={2}
          format={DATE_FORMAT}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          data-test="date-picker-container"
          containerClassName="date-picker-container"
          render={(value, openCalendar) => (
            <Stack>
              <Stack direction="row" gap={1} data-test="custom-dates-selector-container">
                <DateRangeTextField
                  data-test="custom-dates-selector"
                  isError={Boolean(errorText)}
                  helperText={errorText}
                  title="Select Dates"
                  value={value}
                  onClick={openCalendar}
                />
              </Stack>
            </Stack>
          )}
          onChange={(selectedDates) => {
            const dates = selectedDates as DateObject[];
            const formattedDates = dates.sort().map((date) => date.format(DATE_FORMAT));
            onChange(formattedDates);
          }}
        />
      )}
      <FormGroup data-test="date-selector-form-group">
        <FormControlLabel
          data-test="date-selector-form-label"
          control={
            <Checkbox
              data-test="date-selector-mode"
              checked={mode === DATE_SELECTOR_MODE.MULTIPLE_DATES}
              onChange={(e) => {
                let value = DATE_SELECTOR_MODE.DATE_RANGE;
                if (e.target.checked) {
                  value = DATE_SELECTOR_MODE.MULTIPLE_DATES;
                }
                onModeChange(value);
              }}
            />
          }
          label="Custom Date"
        />
      </FormGroup>
    </Stack>
  );
}

MultipleDateSelector.defaultProps = {
  mode: DATE_SELECTOR_MODE.DATE_RANGE,
  weekRangeSelector: false,
};
