import { SUBJECT_CONSTANTS } from '../../../constants/LessonConstant';
import { useGetSubjectsByUserComponents } from '../../../hooks/SubjectsByUsersHook';
import { Lesson } from '../../../types/lesson/LessonType';
import { ContentLibraryType } from '../models/ContentLibraryType';
import { SubjectGroupType } from '../models/SubjectGroupType';

type GetSubjectsGroupByUserComponentsType = {
  includeMisc: boolean;
  includeStandardSubjectInClassic: boolean;
};

export const useGetSubjectsGroupByUserComponents = ({
  includeMisc,
  includeStandardSubjectInClassic,
}: GetSubjectsGroupByUserComponentsType): SubjectGroupType[] => {
  const subjectByUserComponents = useGetSubjectsByUserComponents(includeMisc);

  const standardSubjects = subjectByUserComponents.filter((s) =>
    [
      SUBJECT_CONSTANTS.english.subjectId,
      SUBJECT_CONSTANTS.math.subjectId,
      SUBJECT_CONSTANTS.science.subjectId,
      SUBJECT_CONSTANTS.social.subjectId,
    ].includes(s.subjectId)
  );
  const subjectGroups: SubjectGroupType[] = [
    {
      contentLibrary: ContentLibraryType.academicCurriculum,
      description: 'Standards-aligned academic courses for all grades and skill levels',
      subjects: standardSubjects,
    },
    {
      contentLibrary: ContentLibraryType.skillSuite,
      description: 'Courses to build skills in real-world activities such as social interactions and workplace',
      subjects: subjectByUserComponents.filter((s) =>
        [
          SUBJECT_CONSTANTS.life.subjectId,
          SUBJECT_CONSTANTS.transitionalSkills.subjectId,
          SUBJECT_CONSTANTS.socialSkills.subjectId,
        ].includes(s.subjectId)
      ),
    },
  ];

  const classicSubjects = subjectByUserComponents.filter((s) =>
    [SUBJECT_CONSTANTS.speech.subjectId, SUBJECT_CONSTANTS.arts.subjectId].includes(s.subjectId)
  );

  const lifeSkill =
    subjectByUserComponents.filter((s) => [SUBJECT_CONSTANTS.life.subjectId].includes(s.subjectId)) || [];

  subjectGroups.push({
    contentLibrary: ContentLibraryType.vizzleClassic,
    description: 'Standalone activities ideal for low-incidence students and self-contained rooms',
    subjects: includeStandardSubjectInClassic
      ? [...standardSubjects, ...classicSubjects, ...lifeSkill]
      : classicSubjects,
  });

  return subjectGroups.filter((s) => s.subjects.length > 0);
};

const grade = '9th-12th';
export const getSubjectOrCourseName = (d: Lesson): string => {
  return d?.attributes?.gradeLevel && d.attributes?.gradeLevel?.indexOf(grade) >= 0
    ? d.attributes.courseName || ''
    : d?.attributes?.subjects?.length > 0
    ? d?.attributes?.subjects[0]
    : '';
};

export const getSubjectOrCourseNameForTopicAndUnit = (d: {attributes: {gradeLevel?: string, courseName?: string, subject?: string}}): string => {
  return d?.attributes?.gradeLevel && d?.attributes?.gradeLevel?.indexOf(grade) >= 0
    ? d.attributes.courseName || ''
    : d?.attributes?.subject || '';
};
