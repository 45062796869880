import { useContext } from 'react';
import MultipleDateSelector from '../../../../../../ui/date/MultipleDateSelector';
import { ActivitiesAssignmentContext } from '../../../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import { DATE_SELECTOR_MODE } from '../../../../../../types/ui/DateSelector';
import { defaultDate } from '../../../../stores/ActivitiesAssignmentStore';
import DateUtils from '../../../../../../utils/DateUtils';

export default function DateSelector() {
  const activitiesAssignmentContext = useContext(ActivitiesAssignmentContext);
  const selectedDateMode = useStore(activitiesAssignmentContext!, (state) => state.selectedDateMode);
  const setSelectedDateMode = useStore(activitiesAssignmentContext!, (state) => state.setSelectedDateMode);

  const selectedDates = useStore(activitiesAssignmentContext!, (state) => state.selectedDates);
  const setSelectedDates = useStore(activitiesAssignmentContext!, (state) => state.setSelectedDates);

  const activitiesAssignmentValidationResult = useStore(
    activitiesAssignmentContext!,
    (state) => state.activitiesAssignmentValidationResult
  );
  const validateActivitiesAssignment = useStore(
    activitiesAssignmentContext!,
    (state) => state.validateActivitiesAssignment
  );

  const getErrorText = () => {
    if (!activitiesAssignmentValidationResult?.success) {
      const selectedDatesError = activitiesAssignmentValidationResult?.error.formErrors.fieldErrors.selectedDates;
      return selectedDatesError?.join(',');
    }
    return '';
  };

  return (
    <MultipleDateSelector
      data-test="assign-activities-date-selector"
      mode={selectedDateMode}
      value={selectedDates}
      minDate={new Date()}
      maxDate={DateUtils.getMaxDateFromCurrentDate(180)}
      onChange={(newValue: string[] | null) => {
        setSelectedDates(newValue);
        if (!activitiesAssignmentValidationResult?.success && activitiesAssignmentValidationResult) {
          validateActivitiesAssignment();
        }
      }}
      onModeChange={(mode) => {
        setSelectedDateMode(mode);
        if (mode === DATE_SELECTOR_MODE.DATE_RANGE) {
          setSelectedDates([defaultDate.defaultStartDateString, defaultDate.defaultEndDateString]);
        } else {
          setSelectedDates([]);
        }
        // Reset error message
        if (!activitiesAssignmentValidationResult?.success && activitiesAssignmentValidationResult) {
          validateActivitiesAssignment();
        }
      }}
      errorText={getErrorText()}
      weekRangeSelector
    />
  );
}
