import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Lesson } from '../../../types/lesson/LessonType';
import { ItemRowMenuItemType } from '../../../types/ui/ItemRowMenuItem';
import CopyToEditDialog from './CopyToEditDialog';

export const useCopyToEditAction = () => {
  const [open, setOpen] = useState(false);

  const getCopyToEditAction = (lesson: Lesson): ItemRowMenuItemType => {
    return {
      id: 'copy-to-edit-action',
      label: 'Copy to Edit',
      icon: <ContentCopyIcon />,
      onClick: async () => {
        setOpen(true);
      },
      childrenComponentFunction: (closeDropdownFunction) => {
        return (
          <CopyToEditDialog
            lesson={lesson}
            open={open}
            onClose={() => {
              setOpen(false);
              closeDropdownFunction();
            }}
          />
        );
      },
    };
  };

  return { getCopyToEditAction };
};
