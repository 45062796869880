import { Box, Portal } from '@mui/material';
import Lottie from 'lottie-react';
import { Howl } from 'howler';
import { useEffect, useRef } from 'react';
import { useGetLottieAnimationData, useInitReinforcer, usePreloadReinforcer } from './ReinforcerHooks';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { Reinforcer, Manifest } from '../../../../types/user/TerminologiesType';
import LoadingSpinner from '../../../../ui/loader/LoadingSpinner';

type ReinforcerType = {
  reinforcerList: Reinforcer[],
  enableAudio: boolean,
};

export default function ReinforcerComponent({ reinforcerList, enableAudio }: ReinforcerType) {
  usePreloadReinforcer({ reinforcerList, enableAudio });
  const { reinforceState, setReinforceState } = useInitReinforcer();
  const intervalRef = useRef<ReturnType<typeof setTimeout>>();
  const timeoutRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(
    () => () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
    },
    []);

  const {
    animationData,
    setAnimationData,
    getJsonDataMutaion,
  } = useGetLottieAnimationData();

  const reinforcerData = reinforcerList.filter((r) => r).find((r) => r.id === reinforceState.reinforceId) || {};
  const { manifest } = (reinforcerData || {}) as Reinforcer;
  const sound = (manifest || []).find((m: Manifest) => m.id === 'sound');
  const spritesheetData = (manifest || []).find((m: Manifest) => m.id === 'spritesheetData');

  useEffect(() => {
    if (spritesheetData) {
      getJsonDataMutaion.mutate(spritesheetData.src);
    }
  }, [spritesheetData]);

  if (!spritesheetData) {
    return null;
  }

  if (!reinforceState.open || !reinforcerList || reinforcerList.length === 0) {
    return null;
  }

  const { promise } = reinforceState;
  const handleOnFinish = () => {
    setReinforceState({
      open: false,
      reinforceId: '',
      promise: null,
    });
    setAnimationData(null);
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    timeoutRef.current = undefined;
    ObjectUtils.setTimeout(() => {
      if (promise && promise.resolve) {
        promise.resolve();
      }
    }, 50);
  };

  const handleReject = (logMessage: string) => {
    Logger.logWarn(logMessage);
    handleOnFinish();
  };

  if (!animationData) {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!getJsonDataMutaion.isLoading && animationData) {
        clearInterval(intervalRef.current);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }

      // if the resurces cannot be loaded within 10 seconds, then skip the reinforcer
      if (!timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          clearInterval(intervalRef.current);
          if (!animationData) {
            Logger.logError(`Unable to load reinforcer ${reinforceState.reinforceId}`);
            handleReject(`Assets for ${reinforceState.reinforceId} either are not ready or cannot be loaded.`);
          }
        }, 10000);
      }
    }, 500);
  }

  if (getJsonDataMutaion.isLoading) {
    return (
      <Portal container={document.body}>
        <Box
          position='fixed'
          zIndex={10000}
          top={0}
          left={0}
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          height='100%'
          data-test='reinforcer-player'
        >
          <Box className='reinforcer-container' sx={{ backgroundColor: 'white', padding: '20px', border: '1px solid #E0E0E0', borderRadius: '10px' }}>
            <div className='loading'>
              <LoadingSpinner message='Reinforcer is loading.' />
            </div>
          </Box>
        </Box>
      </Portal>
    );
  }

  if (!animationData) {
    return null;
  }

  return (
    <Portal container={document.body}>
      <Box position='fixed' top={0} left={0} zIndex={10000}>
        <Lottie
          animationData={animationData}
          style={{ width: '100vw', height: '100vh' }}
          loop={false}
          onDOMLoaded={() => {
            if (enableAudio && sound && sound.src) {
              const soundObject = new Howl({
                src: sound.src,
              });
              soundObject.play();
            }
          }}
          onComplete={() => {
            handleOnFinish();
          }}
        />
      </Box>
    </Portal>
  );
}

ReinforcerComponent.defaultProps = {
  enableAudio: false,
};
