/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { experimental_extendTheme as extendTheme } from '@mui/material';

const theme = extendTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => {
          return {
            '.MuiFormControlLabel-label': {
              fontWeight: 300,
            },
          };
        },
      },
    },
  },
});

export const formControlLabelTheme = theme.components?.MuiFormControlLabel;
