import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack } from '@mui/material';
import { ItemRowMenuItemType } from '../../../types/ui/ItemRowMenuItem';
import CustomTooltip from '../tooltip/CustomTooltip';

type ItemRowMenuItemsType = {
  dropdownActions?: ItemRowMenuItemType[];
};
export default function ItemRowMenuItems({ dropdownActions }: ItemRowMenuItemsType) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const theme = useTheme();

  if (!dropdownActions || dropdownActions.length === 0) {
    return null;
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <div>
      <CustomTooltip className="action-button-tooltip" title="More" aria-label="More">
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
      </CustomTooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {dropdownActions.map((action) => (
                    <Box key={`menu-item-${action.id}`}>
                      <MenuItem
                        key={`menu-item-${action.id}`}
                        data-test={`menu-item-${action.id}`}
                        onClick={() => {
                          action.onClick(() => handleClose());
                        }}
                      >
                        <>
                          <Stack
                            direction="row"
                            gap={2}
                            alignItems="center"
                            sx={{
                              color: theme.palette.textSecondary.main,
                            }}
                          >
                            {action.icon} {action.label}
                          </Stack>
                        </>
                      </MenuItem>
                      {action.childrenComponentFunction ? action.childrenComponentFunction(() => handleClose()) : null}
                    </Box>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
