export enum TtsModeEnum {
  RESPONSIVE_VOICE = 'rv',
  AZURE = 'azure',
}

export type Tts = {
  enabled: boolean;
  rate: number;
  pitch: number;
  volume: number;
  voice: number;
  highlightColor: string;
  ttsMode: TtsModeEnum;
};