import { useStore } from 'zustand';
import ActivitiesAssignmenDialog from '../../../../components/activities-assignment/ActivitiesAssignmenDialog';
import VizzleConfirmationSnackbar from '../../../../ui/snackbar/VizzleConfirmationSnackbar';
import { SearchLessonsContext } from '../../SearchLessonsPageHooks';
import { useContext } from 'react';
import { StudentGroup } from '../../../../types/student/StudentGroupType';

export default function ActivitiesAssignmentDialogContainer({ studentGroup }: { studentGroup: StudentGroup[] }) {
  const searchLessonsContext = useContext(SearchLessonsContext);

  const selectedAssignmentItem = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.selectedAssignmentItem
  );
  const setSelectedAssignmentItem = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setSelectedAssignmentItem
  );

  const lessonLevel = useStore(searchLessonsContext!.searchLessonsSelectionStore!, (state) => state.lessonLevel);

  const openActivitiesAssignmentDialog = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.openActivitiesAssignmentDialog
  );
  const setOpenActivitiesAssignmentDialog = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setOpenActivitiesAssignmentDialog
  );

  const openConfirmSnackBar = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.openConfirmSnackBar
  );
  const setOpenConfirmSnackBar = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setOpenConfirmSnackBar
  );
  const selectedAssignmentItems = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.selectedAssignmentItems
  );
  const setSelectedAssignmentItems = useStore(
    searchLessonsContext!.searchLessonsSelectionStore!,
    (state) => state.setSelectedAssignmentItems
  );

  const closeDialogAndSnackbar = () => {
    setOpenConfirmSnackBar(false);
    setOpenActivitiesAssignmentDialog(false);
    setSelectedAssignmentItems([]);
    setSelectedAssignmentItem(undefined);
  };

  return (
    <>
      {selectedAssignmentItems.length > 0 && openConfirmSnackBar && (
        <VizzleConfirmationSnackbar
          data-test="assignment-confirmation-snackbar"
          open={openConfirmSnackBar}
          confirmLabel={`Assign ${selectedAssignmentItems.length} Items`}
          onCancel={() => {
            closeDialogAndSnackbar();
          }}
          onConfirm={() => {
            setOpenActivitiesAssignmentDialog(true);
          }}
          sx={{
            zIndex: 0,
          }}
        />
      )}
      <ActivitiesAssignmenDialog
        data-test="activities-assignment-dialog"
        open={openActivitiesAssignmentDialog}
        onDialogClose={() => {
          setOpenActivitiesAssignmentDialog(false);
          setSelectedAssignmentItem(undefined);
        }}
        activitiesAssignment={{
          assignmentItems: selectedAssignmentItem ? [selectedAssignmentItem] : selectedAssignmentItems,
          showAddToMyList: true,
          studentGroupData: studentGroup || [],
          tagNameForAutoSaving: [],
          lessonLevel: lessonLevel,
        }}
        onSaveTagsAssignmentSuccess={() => {
          closeDialogAndSnackbar();
        }}
        onCustomTagCancel={() => {
          closeDialogAndSnackbar();
        }}
      />
    </>
  );
}
