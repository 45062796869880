import { Tooltip, Typography, TypographyProps } from '@mui/material';

export default function EllipsisTypography({ children, row, ...props }: TypographyProps & { row: number }) {
  return (
    <Tooltip title={children}>
      <Typography
        {...props}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: row,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
}
