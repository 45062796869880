import { useContext } from 'react';
import { Stack } from '@mui/material';
import FormPanel from '../../../../ui/panel/FormPanel';
import { useStore } from 'zustand';
import CustomTagStepAction from './CustomTagStepAction';
import { TagsAssignmentContext } from '../../ActivitiesAssignmentHooks';
import TagsAssignment from './components/TagsAssignment';

type CustomTagFormType = {
  children?: JSX.Element;
  onSaveSuccess: () => void;
  onCustomTagCancel?: () => void;
};

export default function CustomTagForm({ children, onSaveSuccess, onCustomTagCancel }: CustomTagFormType) {
  const tagsAssignmentContext = useContext(TagsAssignmentContext);
  const handleCloseForm = useStore(tagsAssignmentContext!, (state) => state.handleCloseForm);

  return (
    <FormPanel
      data-test="custom-tag-form"
      title="Assign"
      content={
        <Stack alignItems="center" data-test="custom-tag-form-content" paddingTop={2} gap={2}>
          {children}
          <TagsAssignment />
        </Stack>
      }
      bottomBar={<CustomTagStepAction onSaveSuccess={onSaveSuccess} onCustomTagCancel={onCustomTagCancel} />}
      displayCloseIcon
      onClose={() => {
        handleCloseForm();
      }}
    />
  );
}
