import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getUserToken, saveTokenToMemory } from '../utils/VizzleSessionStorage';
import WindowParentUtil from '../utils/WindowParentUtil';

export const apiUrl = import.meta.env.VITE_APP_API_URL;
const vizzleAxios = axios.create({
  baseURL: apiUrl,
  /* other custom settings */
});

const requestInterceptors = (config: InternalAxiosRequestConfig) => {
  // user token cannot be retrieved outside react hook
  // so it needs to be retrived from the sesson storage
  const token = getUserToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const responseInterceptorsOnFullfill = (response: AxiosResponse) => {
  const token = response.headers.vizzle_auth_token;
  if (token) {
    WindowParentUtil.postMessage('update-token', { token });
    saveTokenToMemory(token);
  }
  return response;
};

const responseInterceptorsOnReject = (error: AxiosError | Error) => {
  if (axios.isAxiosError(error) && error && error.response) {
    const { status } = error.response;
    if (status === 401) {
      // dispatchSessionTimeoutEvent('Your session expired please log in again.');
      WindowParentUtil.postMessage('token-expired');
    }
  }
  return Promise.reject(error);
};

vizzleAxios.interceptors.request.use(requestInterceptors);
vizzleAxios.interceptors.response.use(responseInterceptorsOnFullfill, responseInterceptorsOnReject);

export default vizzleAxios;
