import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

type CustomTooltipType = {
  className: string;
}

const CustomTooltip = styled(({ className, ...props }: CustomTooltipType & TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.8em',
  },
}));

export default CustomTooltip;
