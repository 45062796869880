import PropTypes from 'prop-types';
import { Stack, Typography, Button } from '@mui/material';
import { UserProfile } from '../../../types/user/UserProfileType';
import { Terminologies } from '../../../types/user/TerminologiesType';

type SelectAllSettingsType = {
  attributeName: string;
  defaultValue: number[];
  profile: UserProfile;
  terminologies: Terminologies;
  onChangeCallback: (value: number[]) => void;
};

export default function SelectAllSettings({
  attributeName,
  defaultValue,
  profile,
  terminologies,
  onChangeCallback,
}: SelectAllSettingsType) {
  const selectedData = profile[attributeName as keyof UserProfile];
  const terminologiesData = terminologies[attributeName as keyof Terminologies];

  const isSelectedAll = (selectedData as number[]).length === terminologiesData.length;

  const handleSelectAll = () => {
    const allData = terminologiesData.map((t) => t.code);
    onChangeCallback(allData);
  };

  const handleSelectDefault = () => {
    onChangeCallback(defaultValue);
  };

  return (
    <Stack direction="row" gap={3} alignItems="center">
      <Typography>
        {(selectedData as number[]).length} {attributeName} selected
      </Typography>
      {isSelectedAll ? (
        <Button variant="text" className="btn-text-primary" onClick={handleSelectDefault}>
          Reset To Default
        </Button>
      ) : (
        <Button variant="text" className="btn-text-primary" onClick={handleSelectAll}>
          Select All
        </Button>
      )}
    </Stack>
  );
}

SelectAllSettings.propTypes = {
  attributeName: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  profile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};
