import { useStore } from 'zustand';
import { GRADE_LIST, GRADE_LIST_FOR_ALL } from '../../../constants/GradeConstants';
import { ContentLibraryType } from '../models/ContentLibraryType';
import { useContext } from 'react';
import { SearchLessonsContext } from '../SearchLessonsPageHooks';

const getGrades = (contentLibraries: ContentLibraryType[]) => {
  const gradeList = contentLibraries.includes(ContentLibraryType.vizzleClassic) ? GRADE_LIST_FOR_ALL : GRADE_LIST;
  return gradeList;
};

export const useGetGradeByContentLibraries = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const contentLibraries = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.contentLibraries,
  );

  return getGrades(contentLibraries);
};

export const useRemoveGradesNotInList = () => {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const grades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.grades);
  const setGrades = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setGrades);

  const removeGradeNotInList = (valueNumber: ContentLibraryType) => {
    const gradesByContentLibraries = getGrades([valueNumber]);

    const gradeValuesByContenLibraries = gradesByContentLibraries.map((g) => g.value);

    if (valueNumber !== ContentLibraryType.vizzleClassic) {
      const gradesToUpdate = grades.filter((g) => gradeValuesByContenLibraries.includes(g));
      setGrades(gradesToUpdate);
    }
  };

  return removeGradeNotInList;
};
