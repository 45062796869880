import { useContext } from 'react';
import { Alert, IconButton, Typography } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import { useStore } from 'zustand';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';

export default function VerticalAlignmentSummary() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const verticalAlignmentIds = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.verticalAlignmentIds,
  );
  const setVerticalAlignmentIds = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setVerticalAlignmentIds,
  );

  if (!verticalAlignmentIds || verticalAlignmentIds.length === 0) {
    return null;
  }
  return (
    <Alert
      severity="warning"
      iconMapping={{
        warning: <KeyboardDoubleArrowRightIcon fontSize="inherit" />,
      }}
      action={
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontWeight={500} fontSize="1em">
            Remove
          </Typography>
          <IconButton
            aria-label="remove"
            onClick={() => {
              setVerticalAlignmentIds([]);
            }}
          >
            <CloseIcon className="warning-icon-color" />
          </IconButton>
        </Stack>
      }
      sx={{ alignItems: 'center' }}
    >
      <Typography variant="h6" fontSize="1em" fontWeight={500}>
        Vertical Alignment Mode: {verticalAlignmentIds.join(', ')}
      </Typography>
    </Alert>
  );
}
