import { useNavigate } from 'react-router-dom';
import SearchResultContainer from '../../../../ui/SearchResultContainer';
import { useContext } from 'react';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { SEARCH_LESSONS_SUB_URL } from '../../../../SearchLessonsConstant';
import { useSearchUnit } from './UnitViewSearchResultHook';
import { MaxCardSelection } from '../../../../models/MaxCardSelectionConstants';
import { getSubjectOrCourseNameForTopicAndUnit } from '../../../../hooks/SubjectGroupByUserComponentsHooks';

function UnitViewSearchResult() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const navigate = useNavigate();

  const searchUnitResultQuery = useSearchUnit();

  const unitSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.unitSearchPageNumber
  );

  const setUnitSearchPageNumber = useStore(
    searchLessonsContext!.searchLessonsResultStore!,
    (state) => state.setUnitSearchPageNumber
  );

  const unitSearchResult = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.unitSearchResult);

  return (
    <SearchResultContainer
      data-test="unit-view-search-result"
      isLoading={unitSearchPageNumber === 1 && searchUnitResultQuery.isLoading}
      isLoadingMore={searchUnitResultQuery.isLoading && unitSearchPageNumber > 1}
      hasMore={unitSearchResult.data.length < (unitSearchResult.meta?.totalCount || 0)}
      onLoadMore={() => {
        setUnitSearchPageNumber(unitSearchPageNumber + 1);
      }}
      dataList={unitSearchResult.data.map((d) => ({
        id: d.id,
        type: d.type,
        title: `Unit: ${d.attributes.name}`,
        subTitles: [[
          getSubjectOrCourseNameForTopicAndUnit(d), 
          `${d.attributes.gradeLevel} Grade`
        ]],
        image: d.attributes.thumbnailUrl,
      }))}
      getSelectedData={(selectedIndex: number) => {
        if (selectedIndex >= 0) {
          return unitSearchResult.data[selectedIndex];
        }
      }}
      onDetailClick={(selectedIndex) => {
        navigate(`${SEARCH_LESSONS_SUB_URL.unitDetails}/${unitSearchResult.data[selectedIndex].id}`);
      }}
      maxCardsSelected={MaxCardSelection.unit}
      isAbleToAssign={false}
      isSelectable={false}
    />
  );
}

export default UnitViewSearchResult;
