/* eslint-disable react/no-danger */
import DOMPurify from 'dompurify';
import { Typography } from '@mui/material';
import './UnitGuideSection.scss';

type UnitGuideSectionType = {
  title: string;
  className?: string;
  content?: string;
  children?: JSX.Element | JSX.Element[];
};

function sanitizeText(content: string) {
  const sanitizedText = DOMPurify.sanitize(content);
  return sanitizedText.replaceAll('●', '<span style="font-family: Rubik">●</span>');
}

const UnitGuideSection = ({ title, className, content, children }: UnitGuideSectionType) => (
  <div className={`unit-guite-section ${className}`}>
    {title && (
      <Typography variant="h6" className="unit-guide-section-title">
        {title}
      </Typography>
    )}
    <div className="unit-guide-section-body">
      {children}
      {content && <div dangerouslySetInnerHTML={{ __html: sanitizeText(content) }} />}
    </div>
  </div>
);

export default UnitGuideSection;
