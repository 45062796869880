import { useContext } from 'react';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { useQuery } from '@tanstack/react-query';
import CourseService from '../../../../../../services/CourseService';
import FilterableMultiSelector from '../../../../../../ui/selector/FilterableMultiSelector';
import ArrayUtils from '../../../../../../utils/ArrayUtils';
import { ContentLibraryType } from '../../../../models/ContentLibraryType';

const GRADES = ['9', '10', '11', '12'];

export default function CourseSelector() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const courseQueryKey = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => ({
    subjectId: state.selectedSubjectId,
    grades: state.grades,
  }));

  const { courseList, setCourseList } = useStore(searchLessonsContext!.searchLessonsMasterDataStore!, (state) => ({
    courseList: state.courseList,
    setCourseList: state.setCourseList,
  }));
  const contentLibraries = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.contentLibraries);

  const courseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.courseIds);
  const setCourseIds = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.setCourseIds);
  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const found = GRADES.some((r) => courseQueryKey.grades.indexOf(r) >= 0);
  const courseQuery = useQuery({
    queryKey: ['search-course', courseQueryKey],
    queryFn: async () => {
      if (!found || courseList) {
        return null;
      }
      const courses = await CourseService.getLessonCourses();
      setCourseList(courses);
      return courses;
    },
  });

  if (!found || contentLibraries.includes(ContentLibraryType.vizzleClassic)) {
    return null;
  }

  const filteredCourseList = courseList?.filter(
    (c) => [`${GRADES[0]}-${GRADES[GRADES.length - 1]}`, `6-${GRADES[GRADES.length - 1]}`].includes(c.gradeBand) && c.subject === courseQueryKey.subjectId
  );
  const options = filteredCourseList?.map((course) => ({
    id: course.id,
    label: course.name,
  }));

  const sortedOptions = options && ArrayUtils.sort(options, 'label');

  return (
    <FilterableMultiSelector
      title="High School Courses"
      label="Choose High School Courses"
      valueIds={courseIds}
      isMultiple
      filterable
      loading={courseQuery.isLoading}
      options={sortedOptions}
      onApply={(data) => {
        if (!ArrayUtils.isArrayEqual(data as number[], courseIds)) {
          resetSearchData();
          setCourseIds(data as number[]);
        }
      }}
      data-test="course-standard"
    />
  );
}
