import { useContext, useEffect } from 'react';
import { useStore } from 'zustand';
import FilterableMultiSelector from '../../../../../../ui/selector/FilterableMultiSelector';
import { SearchLessonsContext } from '../../../../SearchLessonsPageHooks';
import { useQuery } from '@tanstack/react-query';
import LessonStandardService from '../../../../../../services/LessonStandardService';
import { Standard } from '../../../../../../types/standard/StandardType';
import { useUserStore } from '../../../../../../stores/UserStore';
import ArrayUtils from '../../../../../../utils/ArrayUtils';
import { SUBJECT_ID } from '../../../../../../constants/LessonConstant';
import { Stack } from '@mui/system';
import User from '../../../../../../utils/User';
import { Typography } from '@mui/material';
import StandardUtils from '../../../../../../utils/StandardUtils';

export default function StateStandardsSelector() {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const user = useStore(useUserStore, (state) => state.user);
  const { standardScopes } = userProfile!;

  const searchLessonsContext = useContext(SearchLessonsContext);
  const standardQueryKey = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => ({
    subjectId: state.selectedSubjectId,
    grades: state.grades,
    courseIds: state.courseIds,
  }));

  const selectedSubjectId = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.selectedSubjectId,
  );

  const shouldDisplayStandard = ![SUBJECT_ID.SUBJECT_ID_SPEECH_THERAPY, SUBJECT_ID.SUBJECT_ID_LIFE_ART_MUSIC].includes(
    selectedSubjectId!,
  );

  const standardCodes = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.standardCodes);
  const setStandardCodes = useStore(
    searchLessonsContext!.searchLessonsCriteriaStore!,
    (state) => state.setStandardCodes,
  );

  const resetSearchData = useStore(searchLessonsContext!.searchLessonsResultStore!, (state) => state.resetSearchData);

  const standardsQuery = useQuery({
    queryKey: ['search-standard', standardQueryKey],
    queryFn: async () => {
      if (
        !shouldDisplayStandard ||
        !standardQueryKey.subjectId ||
        ((!standardQueryKey.courseIds || standardQueryKey.courseIds.length === 0) &&
          (!standardQueryKey.grades || standardQueryKey.grades.length === 0))
      ) {
        return [];
      }
      const standardList: Standard[] = await LessonStandardService.getStandardListByCriteria(
        standardQueryKey.subjectId!,
        standardQueryKey.grades,
        standardQueryKey.courseIds,
      );

      const uniqueStandardList = StandardUtils.uniqueStandardsByAttributes(standardList);

      const standardDropdownList = uniqueStandardList
        ? uniqueStandardList.map((s) => {
            const standardScope = standardScopes.find((ss) => ss.scopeCode === s.attributes.scope);
            return {
              id: s.attributes.standardCode,
              label: `${s.attributes.standardCode} - ${s.attributes.standardDescription}`,
              group: standardScope ? standardScope.scopeDisplayName || '' : '',
            };
          })
        : [];
      const result = ArrayUtils.sort(ArrayUtils.sort(standardDropdownList, 'id'), 'group');

      return result;
    },
    onSuccess(data) {
      // Filter out standards not in the list
      const optionData = data as { id: string }[];
      const uniqueStandardCode = optionData.map((standard) => standard.id);
      const standardCodeInList = standardCodes.filter((s) => uniqueStandardCode.includes(s));
      setStandardCodes(standardCodeInList);
    },
  });

  useEffect(() => {
    if (!shouldDisplayStandard) {
      setStandardCodes([]);
    }
  }, [selectedSubjectId]);

  if (!shouldDisplayStandard) {
    return null;
  }

  return (
    <Stack gap={0.5}>
      <FilterableMultiSelector
        title={`State Standard (${user?.state})`}
        label="Choose Standard"
        valueIds={standardCodes}
        isMultiple
        filterable
        helperText={standardQueryKey.grades.length > 0 ? '' : 'Select at least one grade'}
        loading={standardsQuery.isLoading}
        options={standardsQuery.data}
        onApply={(data) => {
          if (!ArrayUtils.isArrayEqual(data as string[], standardCodes)) {
            resetSearchData();
            setStandardCodes(data as string[]);
          }
        }}
        data-test="state-standard"
        disabled={!standardQueryKey.grades || standardQueryKey.grades.length === 0}
      />
      {User.isTexasTeacher(user) && standardCodes && standardCodes.length > 1 && (
        <Typography color="#FF00F5" fontWeight={400} data-test="multiple-standards-selected-text">
          Multiple standards selected
        </Typography>
      )}
    </Stack>
  );
}
