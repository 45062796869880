import vizzleAxios from './service';

export default {
  addToGroupPlay: async (userId: number, lessonIds: string[]) => {
    const url = `/users/${userId}/lessons/groupPlay?operation=add`;
    const payload = { lessonIds };
    const result = await vizzleAxios.post(url, payload);
    return result.data;
  },

  removeFromGroupPlay: async (userId: number, lessonIds: string[]) => {
    const url = `/users/${userId}/lessons/groupPlay?operation=remove`;
    const payload = { lessonIds };
    const result = await vizzleAxios.post(url, payload);
    return result.data;
  }
};
