import StarIcon from '@mui/icons-material/Star';
import { Skeleton, Stack, Typography } from '@mui/material';
import ResourceMaterialCard from './ResourceMaterialCard';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { ResourceMaterialType } from '../types/ResourceMaterialType';
import ViewPdfDialog from '../../dialog/ViewPdfDialog';
import ViewUnitGuideDialog from '../../unit-guide/ViewUnitGuideDialog';

function ResourceMaterial({
  isLoading,
  lessonPlan,
  unitGuide,
  courseName,
  observationalScoring,
}: ResourceMaterialType) {
  if (isLoading) {
    return (
      <Stack direction="row" gap={1}>
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={120} />
      </Stack>
    );
  }

  if (!lessonPlan && !unitGuide) {
    return null;
  }

  return (
    <Stack gap={1} data-test="resource-material">
      <Typography variant="h5" data-test="title">
        Resources & Materials
      </Typography>
      <Stack direction="row" gap={2} flexWrap="wrap">
        {lessonPlan ? (
          <ViewPdfDialog
            title="Lesson Plan"
            pdfKeys={[lessonPlan]}
            shouldCheckPdf
            sx={{ width: '48%' }}
            actionComponent={
              <ResourceMaterialCard
                data-test="lesson-plan-action"
                icon={StarIcon}
                title="Lesson Plan"
                description="Details about how to teach this topic"
              />
            }
          />
        ) : null}

        {unitGuide ? (
          <ViewUnitGuideDialog
            title="Unit Guide"
            courseName={courseName}
            sx={{ width: '48%' }}
            actionComponent={
              <ResourceMaterialCard
                data-test="unit-guide-action"
                icon={CollectionsBookmarkIcon}
                title="Unit Guide"
                description="Details about how to teach this unit"
              />
            }
            unitGuideData={unitGuide}
          />
        ) : null}
        {observationalScoring ? (
          <ViewPdfDialog
            title="Observational Scoring"
            pdfKeys={[observationalScoring]}
            shouldCheckPdf
            sx={{ width: '48%' }}
            actionComponent={
              <ResourceMaterialCard
                data-test="observational-scoring-action"
                icon={SportsScoreIcon}
                title="Observational Scoring"
                description="Data collection sheet for tracking real world application of skills"
              />
            }
          />
        ) : null}
      </Stack>
    </Stack>
  );
}

export default ResourceMaterial;
