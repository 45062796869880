import LandingStudentInterimPage from '../../pages/interim/students/LandingStudentInterimPage';
import LandingStudent from '../../pages/student/LandingStudent';
import { APP_URL } from '../RouteConstants';

const getStudentRoutes = () => [
  { path: `${APP_URL.student}/*`, element: <LandingStudent /> },
  { path: `${APP_URL.interim.student}/*`, element: <LandingStudentInterimPage /> },
];

export default getStudentRoutes;
