import { Box } from '@mui/material';
import { useContext } from 'react';
import { SearchLessonsContext } from '../../../SearchLessonsPageHooks';
import { useStore } from 'zustand';
import { ViewFilterType } from '../../../models/ViewType';
import ActivitiesViewSearchResult from './activities-view/ActivitiesViewSearchResult';
import TopicViewSearchResult from './topic-view/TopicViewSearchResult';
import UnitViewSearchResult from './unit-view/UnitViewSearchResult';
import { useAutoSaveLessonSearchCriteriaByUser } from '../../../hooks/LessonSearchCriteriaByUserHooks';

export default function SearchLessonResult() {
  const searchLessonsContext = useContext(SearchLessonsContext);
  const filterView = useStore(searchLessonsContext!.searchLessonsCriteriaStore!, (state) => state.filterView);

  useAutoSaveLessonSearchCriteriaByUser();

  return (
    <Box flexGrow={1} position="relative" data-test="search-lesson-result-outer-container">
      {filterView === ViewFilterType.topicView && <TopicViewSearchResult />}
      {filterView === ViewFilterType.unitView && <UnitViewSearchResult />}
      {filterView === ViewFilterType.activityView && <ActivitiesViewSearchResult />}
    </Box>
  );
}
