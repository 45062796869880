import { Tts } from '../tts/TtsType';

export type UserProfile = {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  externalId: string;
  interactionType: number;
  switchAccessibility: number;
  playerWorld: number;
  maxMatchingChoices: number;
  filter: number;
  prompt: boolean;
  tts: Tts;
  licenseExpiryDays: number;
  accountManagerEmail: string;
  emailNotificationOn: boolean;
  exitLesson: boolean;
  screenReaderOn: boolean;
  accessibeOn: boolean;
  playExpiredLessons: boolean;
  emailWeeklyAssignmentReport: boolean;
  activeFlag: boolean;
  emailAddress: string;
  adminCommunication: boolean;
  feedbackChat: boolean;
  specialEducation: boolean;
  ssoBaseUrl: string;
  reinforcers: number[];
  celebrations: number[];
  components: {
    identifier: number;
    profiles: number;
    team_members: number;
  }[];
  buildings: string[];
  licenseTypes: string[];
  standardScopes: StandardScope[];
  subjects: Subject[];
  expiredSubjects: ExpiredSubject[];
  searchNode: string;
  playMode: PlayMode;
  reinforcerSound: boolean;
  reinforcerStyle: ReinforcerStyle;
  lessonIds: number[];
  convenience?: boolean;
  studentSettings?: boolean;
};

export type StandardScope = {
  scopeCode: string;
  scopeDisplayName: string;
  subjects: number[];
};

export type Subject = {
  id: string;
  lessons: UserLesson[];
  startIndex: number;
  expired: boolean;
  completed: boolean;
};

export type ExpiredSubject = {
  id: string;
  lessons: UserLesson[];
  expired: boolean;
};

export type UserLesson = {
  id: number;
  uuid: string;
  thumbnailUrl?: string;
  name: string;
  description?: string;
  reviewStatus: ReviewStatus;
  feedbackLoopValue: boolean;
  singlePlay: boolean;
  singleAnswer: boolean;
  unreadChat: boolean;
  currentHistoryId?: number;
  currentLessonPlayId?: number;
  lastPageCompleted?: number;
  played?: boolean;
  preAssessment?: boolean;
  lastAssignedDate?: string;
};

export enum PlayMode {
  selfSelected = 0,
  progressBasedPlay = 1,
}

export enum ReinforcerStyle {
  animated = 0,
  simple = 1,
  none = 2,
}

export enum UserComponent {
  academic = '35',
  skillSuite = '36',
}

export enum ReviewStatus {
  initialPlayId = 0,
  underReview = 1,
  underRevision = 2,
  reviewFinished = 3,
}
