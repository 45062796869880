import { createStore } from 'zustand';
import { Course } from '../../../types/course/CourseType';

const initialState = {
  courseList: null,
};

type StoreType = {
  courseList: Course[] | null;
  setCourseList: (courseList: Course[] | null) => void;
};

export const createSearchLessonsMasterDataStore = () => {
  return createStore<StoreType>((set) => ({
    ...initialState,
    setCourseList: (courseList: Course[] | null) => set({ courseList }),
  }));
};

export type SearchLessonsMasterDataStore = ReturnType<typeof createSearchLessonsMasterDataStore> | null;
