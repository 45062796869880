import { SUBJECT_ID } from '../../../constants/LessonConstant';
import { ContentLibraryScenary } from '../../../types/lesson/SearchLessonResultType';

export enum ContentLibraryType {
  academicCurriculum = 1,
  skillSuite = 2,
  vizzleClassic = 3,
}

export const contentLibraryOptions = {
  [ContentLibraryType.academicCurriculum]: {
    label: 'Academic Curriculum',
    fieldName: 'academicCurriculum',
  },
  [ContentLibraryType.skillSuite]: {
    label: 'Skills Suite',
    fieldName: 'skillSuite',
  },
  [ContentLibraryType.vizzleClassic]: {
    label: 'Vizzle Classic',
    fieldName: 'vizzleClassic',
  },
};

export const convertContentLibraryToSearchScenario = (
  contentLibraries: ContentLibraryType[],
  standardCodes: string[],
  searchText: string,
) => {
  let scenario;
  if (searchText && searchText.trim().includes(' ')) {
    if (contentLibraries.includes(ContentLibraryType.vizzleClassic)) {
      return ContentLibraryScenary.GENERAL;
    }
    return ContentLibraryScenary.COURSES;
  }
  if (contentLibraries.length === 1) {
    const contentLibrary = contentLibraries[0];
    if ([ContentLibraryType.academicCurriculum].includes(contentLibrary)) {
      scenario = ContentLibraryScenary.COURSES;
      if (standardCodes.length > 0 || Boolean(searchText.trim())) {
        scenario = ContentLibraryScenary.COURSES_STANDARD_BASED_SEARCH;
      }
    } else if ([ContentLibraryType.skillSuite].includes(contentLibrary)) {
      scenario = ContentLibraryScenary.COURSES;
      if (standardCodes.length > 0 || Boolean(searchText.trim())) {
        scenario = ContentLibraryScenary.COURSES_STANDARD_BASED_SEARCH;
      }
    } else if ([ContentLibraryType.vizzleClassic].includes(contentLibrary)) {
      scenario = ContentLibraryScenary.GENERAL;
      if (standardCodes.length > 0 || Boolean(searchText.trim())) {
        scenario = ContentLibraryScenary.STANDARD_BASED_SEARCH;
      }
    }
  }
  return scenario;
};

export const convertSelectedSubjectToSearchScenario = (subjectId: number, searchText: string) => {
  if (searchText && searchText.trim().includes(' ')) {
    return ContentLibraryScenary.GENERAL;
  }

  let scenario = ContentLibraryScenary.COURSES_STANDARD_BASED_SEARCH;

  if ([SUBJECT_ID.SUBJECT_ID_SPEECH_THERAPY, SUBJECT_ID.SUBJECT_ID_LIFE_ART_MUSIC].includes(subjectId)) {
    scenario = ContentLibraryScenary.GENERAL;
  }

  return scenario;
};
