import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { TOGGLE_BUTTON_VIZZLE_CUSTOM } from '../../../../theme/default/components/ToggleButtonTheme';
import { useContext, useEffect } from 'react';
import { FilterLessonStatus } from '../../model/FilterLessonStatus';
import { LandingStudentSelfSelectedContext } from '../LandingStudentSelfSelectedHooks';
import { useStore } from 'zustand';
import { filterLessonsFromProfile, hasChatNotification } from '../../hooks/SubjectListHooks';
import { useUserStore } from '../../../../stores/UserStore';
import ChatNotificationIcon from '../../ui/icon/ChatNotificationIcon';

const filterViewOptions = [
  {
    label: 'Current Assignments',
    data: FilterLessonStatus.currentAssignments,
  },
  {
    label: 'Late Assignments',
    data: FilterLessonStatus.lateAssignments,
  },
  {
    label: 'Revision',
    data: FilterLessonStatus.revision,
  },
];

export default function StudentLessonFilter() {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const landingStudentSelfSelectedContext = useContext(LandingStudentSelfSelectedContext);

  const lessonStatus = useStore(
    landingStudentSelfSelectedContext!.studentSelfSelectedFilterStore!,
    (state) => state.lessonStatus
  );

  const subjectId = useStore(
    landingStudentSelfSelectedContext!.studentSelfSelectedFilterStore!,
    (state) => state.subjectId
  );

  const setLessonStatus = useStore(
    landingStudentSelfSelectedContext!.studentSelfSelectedFilterStore!,
    (state) => state.setLessonStatus
  );

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newStatus: number) => {
    if (newStatus !== null) {
      setLessonStatus(newStatus);
    }
  };

  const control = {
    value: lessonStatus,
    onChange: handleChange,
    exclusive: true,
  };

  useEffect(() => {
    // const firstAvailableOption = filterViewOptions.find((option) => {
    //   const lessons = filterLessonsFromProfile(subjectId, option.data, userProfile);
    //   const hasLessons = lessons && lessons.length > 0;
    //   return hasLessons;
    // });

    // if (firstAvailableOption) {
    //   setLessonStatus(firstAvailableOption.data);
    // }
    setLessonStatus(FilterLessonStatus.currentAssignments);
  }, [subjectId]);

  return (
    <ToggleButtonGroup
      fullWidth
      role={TOGGLE_BUTTON_VIZZLE_CUSTOM}
      size="small"
      aria-label="Lesson filter"
      data-test="student-lesson-filter"
      sx={{ width: 530, backgroundColor: 'white' }}
      {...control}
    >
      {filterViewOptions.map((option) => {
        let shouldShowNotification = false;
        const lessons = filterLessonsFromProfile(subjectId, option.data, userProfile);
        shouldShowNotification = hasChatNotification(lessons || []);
        return (
          <ToggleButton
            value={option.data}
            key={`view-filter-${option.data}`}
            role={TOGGLE_BUTTON_VIZZLE_CUSTOM}
            data-test={`view-filter-${option.data}`}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              paddingX: 2,
              gap: 2,
            }}
            disabled={option.data !== FilterLessonStatus.currentAssignments && (!lessons || lessons.length === 0)}
          >
            <Box>{option.label}</Box>
            {shouldShowNotification ? (
              <ChatNotificationIcon fontSize="medium" htmlColor={option.data === lessonStatus ? 'white' : undefined} />
            ) : null}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
