import { RefObject, useEffect, useRef } from 'react';
import Logger from '../../utils/Logger';
import ObjectUtils from '../../utils/ObjectUtils';
import { ElementObjectType } from './types/ElementObjectType';
import { useSwitchAccessStore } from './stores/SwitchAccessStore';
import { useStore } from 'zustand';
import { useUserStore } from '../../stores/UserStore';
import WindowParentUtil from '../../utils/WindowParentUtil';

const KEY_SELECT = 'Enter'; // Enter key
const KEY_SCAN = 'Space'; // Space bar key
const CLASS_SWITCH_SELECTED_COMPONENT = 'switch-selected-component';

const createElementList = (elementsObject: ElementObjectType) => {
  const elements = [];
  if (elementsObject.topBar && elementsObject.topBar.length > 0) {
    elements.push(...elementsObject.topBar);
  }

  if (elementsObject.body && elementsObject.body.length > 0) {
    elements.push(...elementsObject.body);
  }
  if (elementsObject.bottomBar && elementsObject.bottomBar.length > 0) {
    elements.push(...elementsObject.bottomBar);
  }
  return elements;
};

const removeSelectedElementClass = () => {
  const currentElement = document.querySelectorAll(`.${CLASS_SWITCH_SELECTED_COMPONENT}`);
  if (currentElement && currentElement.length > 0) {
    currentElement.forEach((e) => {
      e.classList.remove(CLASS_SWITCH_SELECTED_COMPONENT);
    });
  }
};

const addClassToTheSelectedElement = (element: HTMLElement) => {
  removeSelectedElementClass();
  if (element) {
    element.classList.add('switch-selected-component');
  }
};

export const useRegisterSwitchKeys = (switchAccessibility: number) => {
  const switchAccess = useStore(useSwitchAccessStore, (switchAccess) => switchAccess);

  const { hasDialogShown, elementsForSwitch } = switchAccess;

  const currentSelectedIndex = useRef(-1);
  const continueFromIndex = useRef(-1);
  useEffect(() => {
    if (!switchAccessibility) {
      return undefined;
    }

    window.focus();
    let keyDownEventListener: (e: KeyboardEvent) => void;

    if (hasDialogShown) {
      const elements = createElementList(elementsForSwitch!);
      if (!elements || elements.length === 0) {
        Logger.logWhenDebugModeIsOn('No elements to register the event');
        return undefined;
      }

      Logger.logWhenDebugModeIsOn('Register switch access');
      currentSelectedIndex.current = -1;
      removeSelectedElementClass();

      keyDownEventListener = (e) => {
        e.preventDefault();
        if (KEY_SCAN === e.code) {
          let newIndex = currentSelectedIndex.current;

          if (currentSelectedIndex.current < 0) {
            if (continueFromIndex.current >= 0) {
              newIndex = continueFromIndex.current;
              continueFromIndex.current = -1;
            } else {
              newIndex = elementsForSwitch!.topBar ? elementsForSwitch!.topBar.length : 0;
            }
          } else {
            newIndex = currentSelectedIndex.current + 1;
          }
          if (newIndex >= elements.length) {
            newIndex = 0;
          }
          currentSelectedIndex.current = newIndex;

          const element = elements[currentSelectedIndex.current];
          addClassToTheSelectedElement(element);

          let el = element.querySelector("[data-switch-access-scan='true']");
          if (element.dataset.switchAccessScan === 'true') {
            el = element;
          }
          if (el) {
            el.dispatchEvent(
              new CustomEvent('switchAccessScan', {
                detail: {
                  isSwitchAccess: true,
                  triggeredElement: element,
                },
              })
            );
          }
        } else if (KEY_SELECT === e.code) {
          const element = elements[currentSelectedIndex.current];
          if (element) {
            if (element.dataset && element.dataset.skipSwitchAccessClick === 'true') {
              // ignore
            } else {
              element.click();
            }
          }
        }
      };
    } else {
      Logger.logWhenDebugModeIsOn('register spacebar to close the dialog');
      keyDownEventListener = (e) => {
        if (KEY_SCAN === e.code) {
          switchAccess.updateShowDialog!({ isDialogShowing: false, hasDialogShown: true });
          WindowParentUtil.updateHasDialogShown(true);
          ObjectUtils.setTimeout(() => {
            window.dispatchEvent(new KeyboardEvent('keydown', { code: KEY_SCAN }));
          }, 100);
        } else {
          e.preventDefault();
        }
      };
    }

    if (keyDownEventListener) {
      window.addEventListener('keydown', keyDownEventListener);
    }

    return () => {
      Logger.logWhenDebugModeIsOn('unregister enter and spacebar key');
      if (keyDownEventListener) {
        window.removeEventListener('keydown', keyDownEventListener);
      }
    };
  }, [hasDialogShown, elementsForSwitch]);

  const selectElementByIndex = (index: number) => {
    if (index < 0) {
      return;
    }
    const { elementsForSwitch: elementsForSwitch2 } = switchAccess;
    const elements = createElementList(elementsForSwitch2!);
    const element = elements[index];
    currentSelectedIndex.current = index;
    addClassToTheSelectedElement(element);
  };

  const setContinueFromIndex = (index: number) => {
    if (index < 0) {
      return;
    }
    continueFromIndex.current = index;
  };

  return {
    currentSelectedIndex: switchAccessibility ? currentSelectedIndex : null,
    selectElementByIndex: switchAccessibility ? selectElementByIndex : Function(),
    setContinueFromIndex: switchAccessibility ? setContinueFromIndex : Function(),
  };
};

export const useShowSwitchAccessDialog = ({
  switchAccessibility,
  hasInstruction,
  showDialogDelay,
}: {
  switchAccessibility: boolean;
  hasInstruction: boolean;
  showDialogDelay?: number;
}) => {
  const switchAccess = useStore(useSwitchAccessStore, (switchAccess) => switchAccess);

  const showSwitchAccessDialog = (delay = 500) => {
    if (!switchAccessibility) {
      return false;
    }

    ObjectUtils.setTimeout(() => {
      const { hasDialogShown } = switchAccess;
      if (!hasDialogShown) {
        switchAccess.updateShowDialog!({ isDialogShowing: true, hasDialogShown: false });
      }
    }, delay);
    return switchAccess.hasDialogShown;
  };

  useEffect(() => {
    if (switchAccess.isDialogShowing) {
      // Ensure it starts without showing the dialog
      switchAccess.updateShowDialog!({ isDialogShowing: false, hasDialogShown: switchAccess.hasDialogShown });
    }

    if (!hasInstruction && switchAccessibility) {
      showSwitchAccessDialog(showDialogDelay || 4000);
    }
  }, []);

  return {
    showSwitchAccessDialog,
  };
};

export const useUpdateElementsToScan = () => {
  const switchAccess = useStore(useSwitchAccessStore, (switchAccess) => switchAccess);

  const updateElementsToScan = async (elements: ElementObjectType) => {
    await switchAccess.updateElementToScan!(elements);
  };

  const resetElementsToScan = async () => {
    await switchAccess.updateElementToScan!({
      topBar: [],
      body: [],
      bottomBar: [],
    });
  };
  return {
    updateElementsToScan,
    resetElementsToScan,
  };
};

export const useRegisterEvent = () => {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const { switchAccessibility } = userProfile!;

  const registerSwitchEvent = (
    eventName: string,
    elementRef: RefObject<HTMLDivElement>,
    func: <T>(params: T) => void
  ) => {
    if (!switchAccessibility || switchAccessibility === 0) {
      return Function();
    }

    const handleSwitchAccessScan = <T>(params: T) => {
      func(params);
    };

    if (elementRef.current) {
      elementRef.current.addEventListener(eventName, handleSwitchAccessScan);
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener(eventName, handleSwitchAccessScan);
      }
    };
  };

  return {
    registerSwitchEvent,
  };
};

export const useRegisterSwitchAccessScanEvent = (
  elementRef: RefObject<HTMLDivElement>,
  func: <T>(params: T) => void
) => {
  const { registerSwitchEvent } = useRegisterEvent();

  const cleanupFunction = useRef<ReturnType<typeof registerSwitchEvent>>();
  useEffect(() => {
    cleanupFunction.current = registerSwitchEvent('switchAccessScan', elementRef, func);
    return () => {
      if (cleanupFunction.current) {
        cleanupFunction.current();
      }
    };
  }, [elementRef.current]);

  return cleanupFunction.current;
};

export const useRegisterSwitchAccessSelectEvent = (
  elementRef: RefObject<HTMLDivElement>,
  func: <T>(params: T) => void
) => {
  const { registerSwitchEvent } = useRegisterEvent();

  useEffect(() => {
    const cleanupFunction = registerSwitchEvent('switchAccessSelect', elementRef, func);

    return () => {
      cleanupFunction();
    };
  }, [elementRef.current]);
};
