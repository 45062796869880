import { useState } from 'react';
import { StandardWithStateGroup } from '../../../../../types/standard/StandardType';
import { Button, Stack, Typography } from '@mui/material';

type StandardGroupType = {
  standardGroup: StandardWithStateGroup;
};

const ITEM_LIMIT = 3;

export default function StandardGroup({ standardGroup }: StandardGroupType) {
  const [itemsToShow, setItemsToShow] = useState(ITEM_LIMIT);

  const showmore = () => {
    setItemsToShow(standardGroup.standardList.length);
  };

  const showless = () => {
    setItemsToShow(ITEM_LIMIT);
  };

  return (
    <Stack data-test="standard-group" gap={2} alignItems="flex-start">
      <Typography data-test="standard-state">{standardGroup.title}</Typography>
      <Stack direction="column" component="ul" marginTop={0} gap={0.5} alignItems="flex-start">
        {standardGroup.standardList.slice(0, itemsToShow).map((standard) => (
          <Typography component="li" key={`standard-${standard.id}`}>
            {standard.attributes.standardCode} - {standard.attributes.standardDescription}
          </Typography>
        ))}

        {standardGroup.standardList.length > ITEM_LIMIT && itemsToShow === ITEM_LIMIT && (
          <Button variant="text" onClick={showmore} sx={{ marginLeft: -1, fontWeight: 600 }}>
            Show More
          </Button>
        )}

        {standardGroup.standardList.length > ITEM_LIMIT && itemsToShow !== ITEM_LIMIT && (
          <Button variant="text" onClick={showless} sx={{ marginLeft: -1, fontWeight: 600 }}>
            Show Less
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
