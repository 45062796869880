import { AppBar, Button, Stack, Toolbar } from '@mui/material';
import VizzleLogo from '../../../assets/images/logo/VizzleLogo.svg';
import { switchAccessToolbarElements } from '../../../components/switch-access/SwitchAccessUtils';
import { useNavigate } from 'react-router-dom';
import { APP_URL } from '../../../routes/RouteConstants';
import StudentMenuDropdown from './StudentMenuDropdown';
import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';

export default function StudentToolbar() {
  const navigate = useNavigate();
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  return (
    <AppBar
      color="transparent"
      position="relative"
      sx={{
        boxShadow: '0px 0px 0px 1px #E0E0E0',
      }}
      data-test="student-toolbar"
    >
      <Toolbar data-test="toolbar-container">
        <Stack direction="row" width="100%" data-test="toolbar-inner-container">
          <img
            data-test="vizzle-logo"
            src={VizzleLogo}
            alt="Vizzle logo"
            className="vizzle-logo"
            style={{ width: '135px' }}
            onClick={() => {
              navigate(APP_URL.interim.student);
            }}
          />

          {userProfile?.studentSettings && (
            <Button
              color="textSecondary"
              size="large"
              sx={{ fontWeight: 500, marginLeft: 3 }}
              onClick={() => {
                navigate('..');
              }}
              {...switchAccessToolbarElements}
              data-test="home-button"
            >
              Home
            </Button>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
            data-test="toolbar-buttons-container"
          >
            <StudentMenuDropdown />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
