import { Stack } from '@mui/material';
import DateSelector from './DateSelector';
import OptionalSetting from './OptionalSetting';
import RetakeOption from './RetakeOption';

export default function AssignmentSettings() {
  return (
    <Stack data-test='assignment-settings-container' direction="column" paddingTop={2} paddingBottom={1} paddingLeft={2} paddingRight={1} boxSizing="border-box" height="100%" gap={2}>
      <DateSelector />
      <RetakeOption />
      <OptionalSetting />
    </Stack>
  );
}
