import { useEffect, useRef } from 'react';
import { QueryClient } from '@tanstack/react-query';
import appUiStore from './stores/AppUiStore';
import { useStore } from 'zustand';
import { SNACKBAR_SEVERITY } from './types/ui/VizzleSnackbar';
import { AxiosError } from 'axios';
import WindowParentUtil from './utils/WindowParentUtil';

export const useApp = () => {
  const openSnackbar = useStore(appUiStore, (state) => state.openSnackbar);

  const handleError = (error: unknown) => {
    let message = 'An error occurs while processing. Please check the log.';
    if (error instanceof AxiosError) {
      message = error?.response?.data;
      if (error.response?.status === 401) {
        return;
      }
    } else if (error instanceof Error) {
      message = error.message;
    }

    openSnackbar({
      severity: SNACKBAR_SEVERITY.ERROR,
      open: true,
      message: message,
      duration: 30000,
    });
  };
  const queryClientRef = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: 0,
          useErrorBoundary: true,
          refetchOnWindowFocus: false,
          retry: false,
          onError: handleError,
        },
        mutations: {
          onError: handleError,
        },
      },
    }),
  );
  return { queryClientRef };
};

export const useInitGainsight = () => {
  //@ts-ignore
  window.initGainSight(`${import.meta.env.VITE_APP_MEDIA_API_URL}`);
};

const registerMediaAndTts = () => {
  WindowParentUtil.registerMedia();
};

export const useRegisterMedia = () => {
  useEffect(() => {
    // Register media elements for IOS
    window.addEventListener('mousedown', registerMediaAndTts, { once: true });

    // Cleanup the state
    return () => {
      window.removeEventListener('mousedown', registerMediaAndTts);
    };
  }, []);
};
