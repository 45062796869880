import { Navigate, Route, Routes } from 'react-router-dom';
import LandingStudentTileSubjectSelector from './sub-pages/LandingStudentTileSubjectSelector';
import { LANDING_STUDENT_SUB_URL } from './LandingStudentTileConstants';
import LandingStudentTileLessonSelection from './sub-pages/LandingStudentTileLessonSelection';
import SwitchAccessDialog from '../../../components/switch-access/SwitchAccessDialog';
import { useStore } from 'zustand';
import { useUserStore } from '../../../stores/UserStore';
import { useRegisterSwitchKeys, useShowSwitchAccessDialog } from '../../../components/switch-access/SwitchAccessHooks';
import { useSwitchAccessStore } from '../../../components/switch-access/stores/SwitchAccessStore';

export default function LandingStudentTile() {
  const userProfile = useStore(useUserStore, (state) => state.userProfile);
  const { switchAccessibility } = userProfile!;
  useShowSwitchAccessDialog({
    switchAccessibility: switchAccessibility === 1,
    hasInstruction: false,
    showDialogDelay: 2000,
  });

  useRegisterSwitchKeys(switchAccessibility);

  const switchAccess = useStore(useSwitchAccessStore, (switchAccess) => switchAccess);

  return (
    <>
      <Routes>
        <Route index element={<LandingStudentTileSubjectSelector />} />
        <Route
          path={`${LANDING_STUDENT_SUB_URL.lessonSelection}/:subjectTag`}
          element={<LandingStudentTileLessonSelection />}
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
      <SwitchAccessDialog open={switchAccess.isDialogShowing || false} />
    </>
  );
}
