/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { experimental_extendTheme as extendTheme } from '@mui/material';

const theme = extendTheme({
  components: {
    MuiOutlinedInput: {
      variants: [
        {
          props: {},
          style: {
            '.MuiOutlinedInput-input': {
              paddingTop: 11,
              paddingBottom: 11,
            },
            borderRadius: 8,
          },
        },
      ],
    },
  },
});

export const outlinedInputTheme = theme.components?.MuiOutlinedInput;
