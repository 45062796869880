import { FormControl } from '@mui/material';
import { Box } from '@mui/system';
import DropdownInput from '../../../ui/selector/DropdownInput';
import { useState } from 'react';

const soundEffectOptions = [
  {
    value: 1,
    label: 'On',
  },
  {
    value: 0,
    label: 'Off',
  },
];

type SoundEffectSettingType = {
  soundEffect: boolean;
  onChange: (value: boolean) => void;
};

export default function SoundEffectSetting({ soundEffect, onChange }: SoundEffectSettingType) {
  const [reinforcerSound, setReinforcerSound] = useState(soundEffect);

  return (
    <Box sx={{ mr: 3, display: 'flex' }} data-test="sound-effect-settings-container">
      <FormControl variant="outlined" sx={{ width: 250 }}>
        <DropdownInput
          dropdownValues={soundEffectOptions}
          label="Sound Effects"
          value={Number(reinforcerSound)}
          onChange={(e) => {
            const value = Boolean(e.target.value);
            setReinforcerSound(value);
            onChange(value);
          }}
          data-test="sound-effect-dropdown-select"
        />
      </FormControl>
    </Box>
  );
}
