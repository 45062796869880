import { Box, Stack, StackProps } from '@mui/material';
import LoadingSpinner from '../loader/LoadingSpinner';
import { LoadingButton } from '@mui/lab';
import { useRef } from 'react';

type LoadableMoreContainerType = {
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  children: JSX.Element;
};
export default function LoadableMoreContainer({
  isLoading,
  isLoadingMore,
  hasMore,
  onLoadMore,
  children,
  ...rest
}: LoadableMoreContainerType & StackProps) {
  const scrollContainer = useRef(null);
  const handleOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!hasMore || isLoadingMore) {
      return;
    }
    const listElement = e.target;
    
    // @ts-ignore
    if (listElement.scrollTop > 0 && (listElement.scrollTop + listElement.clientHeight + 100 >= listElement.scrollHeight)) {
      onLoadMore();
    }
  };

  if (isLoading) {
    return (
      <Stack alignItems="center" data-test="loading-container" width="100%" height="100%" paddingY={10}>
        <LoadingSpinner gap={3} data-test="loading" message="Loading" />
      </Stack>
    );
  }

  return (
    <Stack
      ref={scrollContainer}
      onScroll={handleOnScroll}
      position="relative"
      height="100%"
      sx={{ overflowY: 'auto', ...rest.sx }}
      {...rest}
    >
      <Box position="absolute" height="100%" width="100%" >
        <Stack gap={2} data-test="content" marginBottom={1} paddingBottom={2}>
          {children}
        </Stack>
        {hasMore && (
          <LoadingButton
            data-test="load-more-button"
            loadingPosition="center"
            loading={isLoadingMore}
            onClick={onLoadMore}
            size="large"
            sx={{ marginTop: 'auto', minHeight: 40 }}
            fullWidth
          >
            Load More
          </LoadingButton>
        )}
      </Box>
    </Stack>
  );
}
