import { FULL_SORTED_SUBJECT_CONSTANTS, SUBJECT_CONSTANTS } from '../constants/LessonConstant';
import { Lesson } from '../types/lesson/LessonType';

export default {
  getSubjectId: (lesson: Lesson) => {
    let currentSubjectId = SUBJECT_CONSTANTS.misc.subjectId;
    if (lesson.attributes && lesson.attributes.subjects.length > 0 && lesson.attributes.subjects[0]) {
      currentSubjectId = (FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.name === lesson.attributes.subjects[0]) || SUBJECT_CONSTANTS.misc).subjectId;
    }
    return currentSubjectId;
  },
};
