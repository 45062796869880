import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Reinforcer, Theme } from '../../../types/user/TerminologiesType';
import DefaultImage from '../../../assets/images/default_thumbnail.png';
import './SettingsListItem.scss';

type SettingsListItemType = {
  item: Reinforcer | Theme;
  checked: boolean;
  thumbnailAsColor?: boolean;
  onMediaClick?: () => void;
  onCardSelect: (id: string) => void;
};

export default function SettingsListItem({
  item,
  checked,
  thumbnailAsColor,
  onMediaClick,
  onCardSelect,
}: SettingsListItemType) {
  return (
    <Box className="selectable-card-div">
      <Card
        className="settings-selectable-card"
        data-test="settings-settings-list-item"
        onClick={() => onCardSelect(item?.id)}
      >
        <CardActionArea className="settings-action-area">
          <div className="overlay" />
          {thumbnailAsColor ? (
            <Box sx={{ backgroundColor: item.thumbnail }}>
              <CardMedia
                component="img"
                image={DefaultImage}
                sx={{ opacity: 0 }}
                data-test={`selectable-card-img-${item.name}`}
              />
            </Box>
          ) : (
            <CardMedia
              sx={{ minHeight: '152px' }}
              component="img"
              image={item.thumbnail}
              data-test={`selectable-card-img-${item.name}`}
            />
          )}
          <CardContent className="settings-selectable-card-content">
            <Typography
              component="p"
              className="settings-card-name"
              align="center"
              data-test={`selectable-card-text-${item.name}`}
            >
              {item.name}
            </Typography>
            {checked ? (
              <CheckCircleIcon
                className="settings-selectable-card-overlay-icon settings-card-checked"
                data-test={`selectable-card-checked-${item.name}`}
              />
            ) : (
              <RadioButtonUncheckedOutlinedIcon
                className="settings-selectable-card-overlay-icon settings-card-unchecked"
                data-test={`selectable-card-unchecked-${item.name}`}
              />
            )}
            {onMediaClick ? (
              <PlayCircleFilledIcon
                className="settings-selectable-card-overlay-media"
                data-test={`selectable-card-play-action-${item.name}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onMediaClick();
                }}
              />
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
