import { useNavigate, useParams } from 'react-router-dom';
import { useGetRecordType } from '../../SearchLessonsPageHooks';
import ItemDetails from '../../ui/item-details/ItemDetails';
import { useQuery } from '@tanstack/react-query';
import TopicService from '../../../../services/TopicService';
import { mapStandardData } from '../../../../hooks/StandardsHooks';
import { Standard } from '../../../../types/standard/StandardType';
import LessonService from '../../../../services/LessonService';
import { SEARCH_LESSONS_SUB_URL } from '../../SearchLessonsConstant';
import { APP_URL } from '../../../../routes/RouteConstants';
import { useGetActivitiesDropdownMenu } from '../../common-actions/ActivitiesActions';
import { Lesson, LessonTypeString } from '../../../../types/lesson/LessonType';
import WindowParentUtil from '../../../../utils/WindowParentUtil';
import { getSubjectOrCourseName } from '../../hooks/SubjectGroupByUserComponentsHooks';
import ArrayUtils from '../../../../utils/ArrayUtils';
import { useUserStore } from '../../../../stores/UserStore';
import { useStore } from 'zustand';

function ActivityDetails() {
  const { lessonId } = useParams();
  const lessonDetailQuery = useQuery({
    queryKey: ['lesson-detail', lessonId],
    queryFn: async () => {
      if (!lessonId) {
        return;
      }
      const result = await LessonService.getLessonDetail(lessonId);
      return result;
    },
  });

  const user = useStore(useUserStore, (state) => state.user);

  const lessonDetails = lessonDetailQuery.data;
  const lesson = lessonDetails?.data;

  const topicDetailQuery = useQuery({
    queryKey: ['lesson-topic', lesson?.attributes?.topicId || 'no-topic-id'],
    queryFn: async () => {
      const topicId = lesson?.attributes?.topicId;
      if (!topicId) {
        return null;
      }
      const result = await TopicService.getTopicDetail(String(topicId));
      return result;
    },
  });

  const topicOthersQuery = useQuery({
    queryKey: ['lesson-others', lesson?.attributes?.topicId || 'no-other-topic-id'],
    queryFn: async () => {
      const topicId = lesson?.attributes?.topicId;
      if (!topicId) {
        return null;
      }
      const result = await LessonService.getLessonsByTopicIdAndLevel({ topicId: String(topicId), level: '1' });
      return result;
    },
  });

  const { getRecordType } = useGetRecordType();
  const navigate = useNavigate();

  const topicDetail = topicDetailQuery.data;
  const standards = mapStandardData(lesson?.relationships?.standards, (lessonDetails?.included || []) as Standard[]);

  const { getActivitiesDropdownMenu } = useGetActivitiesDropdownMenu({});

  const lessonLabel = getRecordType(LessonTypeString, lesson?.attributes?.topicId);
          
  const additionalActions = [
    {
      variant: 'roundedBorder',
      'data-test': 'activity-teach-action',
      onClick: () => {
        WindowParentUtil.playLesson(lesson!, () => {
        });
      },
      children: 'Teach',
    },
    {
      variant: 'roundedBorder',
      'data-test': 'activity-preview-action',
      onClick: () => {
        WindowParentUtil.previewLesson(lesson!);
      },
      children: 'Preview',
    },
  ];

  if (user!.userModeratorWithDirectAccess && lesson?.meta.editable && lessonLabel && lessonLabel.toLocaleLowerCase().includes('classic')) {
    additionalActions.unshift({
      variant: 'roundedBorder',
      'data-test': 'activity-preview-action',
      onClick: () => {
        WindowParentUtil.redirectTo(`/lessons/${lesson!.attributes.uuid}/update`);
      },
      children: 'Edit',
    });
  }

  return (
    <ItemDetails
      data-test="activity-detail"
      itemInformation={{
        isLoading: lessonDetailQuery.isLoading,
        breadcrumbs:
          lesson?.attributes?.topicId && topicDetail
            ? [
                {
                  text: `Unit ${topicDetail?.unitName || ''}`,
                  href: `${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.unitDetails}/${lesson.attributes.unitId}`,
                },
                {
                  text: `Topic ${lesson?.attributes.topicName || ''}`,
                  href: `${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.topicDetails}/${lesson.attributes.topicId}`,
                },
              ]
            : undefined,
        title: lesson?.attributes?.name || '',
        titleChips: [
          String(`#${lesson?.id}`),
          `${lesson?.attributes?.gradeLevel || ''} Grade`,
          lesson ? getSubjectOrCourseName(lesson) : '',
          lessonLabel,
        ],
        description: lesson?.attributes?.description || '',
      }}
      resourceMaterial={{
        isLoading: topicDetailQuery.isLoading,
        courseName: topicDetail?.courseName || '',
        lessonPlan: topicDetail?.lessonPlan,
        unitGuide: topicDetail?.unitGuide,
      }}
      stateStandards={{
        isLoading: lessonDetailQuery.isLoading,
        standards,
      }}
      extensionActivity={{
        isLoading: topicDetailQuery.isLoading,
        extensionActivities: topicDetail?.extensionActivities || [],
      }}
      othersSection={{
        isLoading: topicOthersQuery.isLoading,
        sectionTitle: 'Other Activities From This Topic',
        data:
          topicOthersQuery.data?.data?.map((d) => ({
            id: d.id || '',
            title: d.attributes.name,
            subTitles: [
              [`Topic ${d.attributes.topicName}` || ''],
              [
                d?.attributes?.subjects?.length > 0 ? d?.attributes?.subjects[0] : '',
                `${d.attributes.gradeLevel} Grade`,
              ],
            ],
            image: d.attributes.thumbnailUrl,
          })) || [],
        onDetailClick: (id) => {
          navigate(`${APP_URL.searchLessons}/${SEARCH_LESSONS_SUB_URL.activitiesDetails}/${id}`);
        },
      }}
      lessonList={
        lesson
          ? {
              id: lessonId!,
              displayLevelSelector: false,
              getLessonsFunction: () => {
                if (lessonDetails?.data.meta.associatedLessons) {

                  const associatedLessons = ArrayUtils.sort(lessonDetails?.data.meta.associatedLessons, 'lessonLevel');
                  const lessonList = associatedLessons.map((associatedLesson) => {
                    const lesson = {
                      id: String(associatedLesson.lessonId),
                      attributes: {
                        name: associatedLesson.name,
                        uuid: associatedLesson.uuid,
                        thumbnailUrl: associatedLesson.thumbnailUrl,
                        lessonLevel: associatedLesson.lessonLevel,
                        vizzleGold: associatedLesson.vizzleGold,
                        submitted: associatedLesson.submitted,
                      },
                    };
                    return lesson as Lesson;
                  });
                  return Promise.resolve(lessonList);
                }

                return Promise.resolve([lesson]);
              },
              goToLessonDetail: lessonDetails?.data.meta.associatedLessons ? true : false,
            }
          : undefined
      }
      itemDetailsAction={{
        isLoading: lessonDetailQuery.isLoading,
        getSelectedData: () => lesson!,
        dropdownActions: getActivitiesDropdownMenu(lesson),
        additionalActions,
      }}
    />
  );
}

export default ActivityDetails;
