import { createStore } from 'zustand';
import { SearchLessonResult } from '../../../types/lesson/SearchLessonResultType';
import { SearchTopicResult } from '../../../types/topic/SearchTopicResultType';
import { SearchUnitResult } from '../../../types/unit/SearchUnitResultType';

const initialState = {
  activitiesSearchResult: {
    data: [],
    included: [],
    meta: undefined,
  },
  activitiesSearchPageNumber: 1,
  topicSearchResult: {
    data: [],
    included: [],
    meta: undefined,
  },
  topicSearchPageNumber: 1,

  unitSearchResult: {
    data: [],
    included: [],
    meta: undefined,
  },
  unitSearchPageNumber: 1,
};

type StoreType = {
  activitiesSearchResult: SearchLessonResult;
  activitiesSearchPageNumber: number;
  setActivitiesSearchResult: (activitiesSearchResult: SearchLessonResult) => void;
  setActivitiesSearchPageNumber: (activitiesSearchPageNumber: number) => void;
  topicSearchPageNumber: number;
  topicSearchResult: SearchTopicResult;
  setTopicSearchResult: (topicSearchResult: SearchTopicResult) => void;
  setTopicSearchPageNumber: (topicSearchPageNumber: number) => void;
  unitSearchResult: SearchUnitResult;
  setUnitSearchResult: (unitSearchResult: SearchUnitResult) => void;
  unitSearchPageNumber: number;
  setUnitSearchPageNumber: (unitSearchPageNumber: number) => void;
  resetSearchData: () => void;
};

export const createSearchLessonsResultStore = () => {
  return createStore<StoreType>((set) => ({
    ...initialState,
    setActivitiesSearchResult: (activitiesSearchResult: SearchLessonResult) => set({ activitiesSearchResult }),
    setActivitiesSearchPageNumber: (activitiesSearchPageNumber: number) => set({ activitiesSearchPageNumber }),
    setTopicSearchResult: (topicSearchResult: SearchTopicResult) => set({ topicSearchResult }),
    setTopicSearchPageNumber: (topicSearchPageNumber: number) => set({ topicSearchPageNumber }),
    setUnitSearchResult: (unitSearchResult: SearchUnitResult) => set({unitSearchResult}),
    setUnitSearchPageNumber: (unitSearchPageNumber: number) => set({ unitSearchPageNumber }),
    resetSearchData: () => set({ ...initialState }),
  }));
};

export type SearchLessonsResultStore = ReturnType<typeof createSearchLessonsResultStore> | null;
