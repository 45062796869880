import { Standards } from '../standard/StandardType';

export const UnitTypeString = 'units';

export type Unit = {
  type: string;
  id: string;
  attributes: Attributes;
  relationships?: Relationships;
};

type Attributes = {
  name: string;
  gradeLevel?: string;
  subject?: string;
  thumbnailUrl?: string;
  courseName?: string;
};

export type Relationships = {
  standards: Standards;
};
