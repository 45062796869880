import { useContext } from 'react';
import FormPanel from '../../../../ui/panel/FormPanel';
import { TagsAssignmentContext } from '../../ActivitiesAssignmentHooks';
import { useStore } from 'zustand';
import SaveAssignmentSuccess from '../save-success/SaveAssignmentSuccess';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';

type AssignActivitiesSaveSuccessPanelType = {
  onClose?: () => void;
};
function AssignActivitiesSaveSuccessPanel({ onClose } : AssignActivitiesSaveSuccessPanelType) {
  const tagsAssignmentContext = useContext(TagsAssignmentContext);
  const handleCloseForm = useStore(tagsAssignmentContext!, (state) => state.handleCloseForm);

  return (
    <FormPanel
      data-test="assign-activities-panel"
      title="Assign"
      content={
        <Stack alignItems="center" data-test="assign-activities-content" paddingTop={2} gap={2}>
          <SaveAssignmentSuccess />
        </Stack>
      }
      bottomBar={
        <Stack
          data-test="assign-activities-save-success-actions-container"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          height="100%"
        >
          <LoadingButton
            variant="roundedBorder"
            onClick={() => {
              handleCloseForm();
              if (onClose) {
                onClose();
              }
            }}
            data-test="close-button"
          >
            Close
          </LoadingButton>
        </Stack>
      }
      displayCloseIcon
      onClose={() => {
        handleCloseForm();
      }}
    />
  );
}

export default AssignActivitiesSaveSuccessPanel;
